import React from 'react';
import PropTypes from 'prop-types';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input, notification } from 'antd';
import { FiCopy } from 'react-icons/fi';
import { Label } from '../Label';
import './styles.scss';
import messageComponent from '../../utils/message';

const InputComponent = (props: any) => {
    const {
        label,
        value = '',
        onChange,
        copyToClipBoard,
        className,
        type,
        disabled = false,
        ...data
    } = props;

    const isPassword = type === 'password';
    const inputProps = {
        ...data
    };
    
    if(isPassword) {
        inputProps.iconRender = (visible: any) => visible ? <EyeTwoTone  /> : <EyeInvisibleOutlined  />
    }

    const setValue = (event: any) => {
        onChange(event.target.value);
    };

    // CSS class names
    const classList = [className];
    if (copyToClipBoard) classList.push('ctc-input');
    if (isPassword) classList.push('password-input');

    const openNotification = () => {
        // notification.open({
        //     message: 'Data Copied Successfully!',
        //     // description: 'Data copied successfully',
        // });
        messageComponent({
            messages:
              "Text copied successfully to clipboard",
            type: "success",
            top: 10,
          });
    };
    const InputField = isPassword ? Input.Password : Input;
    return (
        <div>
            {label && <Label>{label.toUpperCase()}</Label>}
            <ConditionalWrapper
                condition={copyToClipBoard}
                wrapper={(children: any) => (
                    <div className="ctc-container">{children}</div>
                )}
            >
                <InputField
                    className={classList.join(' ')}
                    value={value}
                    type={type}
                    disabled={disabled}
                    onChange={setValue}
                    {...inputProps}
                />
                {copyToClipBoard && (
                    <span
                        className="ctc-icon"
                        onClick={(event) => {
                            event.preventDefault();
                            navigator.clipboard.writeText(value);
                            openNotification();
                        }}
                    >
                        <FiCopy />
                    </span>
                )}
            </ConditionalWrapper>
        </div>
    );
};

InputComponent.extends = {
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    copyToClipBoard: PropTypes.bool,
    type: PropTypes.string,
    disabled: PropTypes.bool
};

InputComponent.defaultProps = {
    label: '',
    value: '',
    onChange: () => {},
    copyToClipBoard: false,
    type: ''
};

// @ts-ignore
const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

export default InputComponent;

export const isViewInScreen = function (div: any): Boolean {
  var sizes = div.getBoundingClientRect();
  if (sizes && sizes.top >= 0 && sizes.left >= 0 && sizes.right <= (window.innerWidth)) {
    return true;
  } else {
    return false;
  }
};

export const convertTimeFromUTCEpoc = (unix_timestamp: number) => {
  let date = new Date(unix_timestamp)
  let hours = date.getHours()
  var ampm = hours < 12 ? 'AM' : 'PM';
  hours = hours > 12 ? hours - 12 : hours

  let minutes = "0" + date.getMinutes();

  let formattedTime = hours + ':' + minutes.substr(-2) + ' ' + ampm

  return formattedTime
};

export const convertDateTimeFromUTCEpoc = (unix_timestamp: number) => {
  let date = new Date(unix_timestamp)
  let mm = ('0' + (date.getMonth() + 1)).slice(-2)
  let dd = ('0' + date.getDate()).slice(-2)

  let hours = date.getHours()
  var ampm = hours < 12 ? 'AM' : 'PM';
  hours = hours > 12 ? hours - 12 : hours

  let minutes = "0" + date.getMinutes();

  let formattedTime = mm + '/' + dd + ', ' + hours + ':' + minutes.substr(-2) + ' ' + ampm

  return formattedTime
};

export const convertDateTimeFromUTCEpocWithYear = (unix_timestamp: number) => {
  let date = new Date(unix_timestamp * 1000)
  let yy = date.getFullYear()
  let mm = ('0' + (date.getMonth() + 1)).slice(-2)
  let dd = ('0' + date.getDate()).slice(-2)

  let hours = date.getHours()
  var ampm = hours < 12 ? 'AM' : 'PM';
  hours = hours > 12 ? hours - 12 : hours

  let minutes = "0" + date.getMinutes();

  let formattedTime = yy + "/" + mm + '/' + dd + ', ' + hours + ':' + minutes.substr(-2) + ' ' + ampm

  return formattedTime
};

export const convertDateFromUTCEpoc = (unix_timestamp: number) => {
  let date = new Date(unix_timestamp * 1000)
  let yy = date.getFullYear()
  let mm = ('0' + (date.getMonth() + 1)).slice(-2)
  let dd = ('0' + date.getDate()).slice(-2)

  let formattedTime = dd + "/" + mm + '/' + yy

  return formattedTime
};

export const pacpostRefreshPage = () =>  window.location.reload()
import React from 'react';
import './styles.scss';

const Title = (props?: any) => {
    const {size,value} = props

    return(
        <div className="title-component" style={{fontSize: `${size ? size : 24}px`, lineHeight: `${size ? size : 24}px`, marginBottom: `${value ? value : 44}px`}}>
            {props.children}
        </div>
    )
}

export default Title;
// ModalComponent.tsx
import React from "react";
import { Modal, Button } from "semantic-ui-react";

interface ExportProps {
  open: boolean;
  onClose: () => void;
  onDownload: () => void;
}

const ExportComponent: React.FC<ExportProps> = ({ open, onClose, onDownload }) => {
  return (
    <Modal
      open={open}
      size="tiny"
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      style={{ fontSize: "20px" }}
    >
      <Modal.Header style={{ fontSize: "20px" }}>Export Chat</Modal.Header>
      <Modal.Content>Are you sure you want to export the chat log</Modal.Content>
      <Modal.Actions>
        <Button color="green" onClick={onDownload} style={{ fontSize: "15px" }}>
          YES
        </Button>
        <Button color="grey" onClick={onClose} style={{ fontSize: "15px" }}>
          CANCEL
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ExportComponent;

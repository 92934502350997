import { useContext } from "react";
import cameraOn from "../../../../pages/Viewer/Images/cameraOn.svg";
import cameraOff from "../../../../pages/Viewer/Images/cameraOff.svg";
import ZoomContext from '../../../../pages/ZoomViewer/context/zoom-context';
import ZoomMediaContext from '../../../../pages/ZoomViewer/context/media-context';
import { useVideo } from "../../../../pages/ZoomViewer/hooks/useVideo";
import { useWhiteboardingStore } from "../../../../store";

const VideoToggle = (props: any) => {
    const { showDominantSpeaker } = props;
    const zmClient = useContext(ZoomContext);
    const { mediaStream } = useContext(ZoomMediaContext);

    const { isVideoOn, onCamClick } = useVideo(zmClient, mediaStream);
    const { resetAllTools } = useWhiteboardingStore();

    return (
        <div className={`top-menu-button-item ${showDominantSpeaker ? "disableddiv" : ""}`} onClick={() => 
            {onCamClick();
            resetAllTools();
             }}>
            {isVideoOn ? (
                <>
                    <img src={cameraOn} alt="" />
                </>
            ) : (
                <>
                    <img
                        src={cameraOff}
                        alt=""
                        style={{ filter: "brightness(1)" }}
                    />
                </>
            )}
            <span>Toggle Video</span>
        </div>
    )
};

export default VideoToggle;

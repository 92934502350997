/**
 * @fileoverview gRPC-Web generated client stub for mcapi
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var MCAPI_Types_pb = require('./MCAPI_Types_pb.js')

var google_protobuf_descriptor_pb = require('google-protobuf/google/protobuf/descriptor_pb.js')
const proto = {};
proto.mcapi = require('./MCAPI_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mcapi.MCAPIClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mcapi.MCAPIPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.EchoRequest,
 *   !proto.mcapi.EchoResponse>}
 */
const methodDescriptor_MCAPI_Echo = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/Echo',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.EchoRequest,
  MCAPI_Types_pb.EchoResponse,
  /**
   * @param {!proto.mcapi.EchoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.EchoResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.EchoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.EchoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.EchoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.echo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/Echo',
      request,
      metadata || {},
      methodDescriptor_MCAPI_Echo,
      callback);
};


/**
 * @param {!proto.mcapi.EchoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.EchoResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.echo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/Echo',
      request,
      metadata || {},
      methodDescriptor_MCAPI_Echo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.GetValuesRequest,
 *   !proto.mcapi.GetValuesResponse>}
 */
const methodDescriptor_MCAPI_GetValues = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/GetValues',
  grpc.web.MethodType.SERVER_STREAMING,
  MCAPI_Types_pb.GetValuesRequest,
  MCAPI_Types_pb.GetValuesResponse,
  /**
   * @param {!proto.mcapi.GetValuesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.GetValuesResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.GetValuesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetValuesResponse>}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.getValues =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mcapi.MCAPI/GetValues',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetValues);
};


/**
 * @param {!proto.mcapi.GetValuesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetValuesResponse>}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIPromiseClient.prototype.getValues =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mcapi.MCAPI/GetValues',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetValues);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.GetAppInfoRequest,
 *   !proto.mcapi.GetAppInfoResponse>}
 */
const methodDescriptor_MCAPI_GetAppInfo = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/GetAppInfo',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.GetAppInfoRequest,
  MCAPI_Types_pb.GetAppInfoResponse,
  /**
   * @param {!proto.mcapi.GetAppInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.GetAppInfoResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.GetAppInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.GetAppInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetAppInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.getAppInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/GetAppInfo',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetAppInfo,
      callback);
};


/**
 * @param {!proto.mcapi.GetAppInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.GetAppInfoResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.getAppInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/GetAppInfo',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetAppInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.GetBinsRequest,
 *   !proto.mcapi.GetBinsResponse>}
 */
const methodDescriptor_MCAPI_GetBins = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/GetBins',
  grpc.web.MethodType.SERVER_STREAMING,
  MCAPI_Types_pb.GetBinsRequest,
  MCAPI_Types_pb.GetBinsResponse,
  /**
   * @param {!proto.mcapi.GetBinsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.GetBinsResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.GetBinsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetBinsResponse>}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.getBins =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mcapi.MCAPI/GetBins',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetBins);
};


/**
 * @param {!proto.mcapi.GetBinsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetBinsResponse>}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIPromiseClient.prototype.getBins =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mcapi.MCAPI/GetBins',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetBins);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.GetBinInfoRequest,
 *   !proto.mcapi.GetBinInfoResponse>}
 */
const methodDescriptor_MCAPI_GetBinInfo = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/GetBinInfo',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.GetBinInfoRequest,
  MCAPI_Types_pb.GetBinInfoResponse,
  /**
   * @param {!proto.mcapi.GetBinInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.GetBinInfoResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.GetBinInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.GetBinInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetBinInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.getBinInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/GetBinInfo',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetBinInfo,
      callback);
};


/**
 * @param {!proto.mcapi.GetBinInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.GetBinInfoResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.getBinInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/GetBinInfo',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetBinInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.GetMobInfoRequest,
 *   !proto.mcapi.GetMobInfoResponse>}
 */
const methodDescriptor_MCAPI_GetMobInfo = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/GetMobInfo',
  grpc.web.MethodType.SERVER_STREAMING,
  MCAPI_Types_pb.GetMobInfoRequest,
  MCAPI_Types_pb.GetMobInfoResponse,
  /**
   * @param {!proto.mcapi.GetMobInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.GetMobInfoResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.GetMobInfoRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetMobInfoResponse>}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.getMobInfo =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mcapi.MCAPI/GetMobInfo',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetMobInfo);
};


/**
 * @param {!proto.mcapi.GetMobInfoRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetMobInfoResponse>}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIPromiseClient.prototype.getMobInfo =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mcapi.MCAPI/GetMobInfo',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetMobInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.SetMobInfoRequest,
 *   !proto.mcapi.SetMobInfoResponse>}
 */
const methodDescriptor_MCAPI_SetMobInfo = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/SetMobInfo',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.SetMobInfoRequest,
  MCAPI_Types_pb.SetMobInfoResponse,
  /**
   * @param {!proto.mcapi.SetMobInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.SetMobInfoResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.SetMobInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.SetMobInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.SetMobInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.setMobInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/SetMobInfo',
      request,
      metadata || {},
      methodDescriptor_MCAPI_SetMobInfo,
      callback);
};


/**
 * @param {!proto.mcapi.SetMobInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.SetMobInfoResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.setMobInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/SetMobInfo',
      request,
      metadata || {},
      methodDescriptor_MCAPI_SetMobInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.GetMobTrackInfoRequest,
 *   !proto.mcapi.GetMobTrackInfoResponse>}
 */
const methodDescriptor_MCAPI_GetMobTrackInfo = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/GetMobTrackInfo',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.GetMobTrackInfoRequest,
  MCAPI_Types_pb.GetMobTrackInfoResponse,
  /**
   * @param {!proto.mcapi.GetMobTrackInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.GetMobTrackInfoResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.GetMobTrackInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.GetMobTrackInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetMobTrackInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.getMobTrackInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/GetMobTrackInfo',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetMobTrackInfo,
      callback);
};


/**
 * @param {!proto.mcapi.GetMobTrackInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.GetMobTrackInfoResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.getMobTrackInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/GetMobTrackInfo',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetMobTrackInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.CreateBinRequest,
 *   !proto.mcapi.CreateBinResponse>}
 */
const methodDescriptor_MCAPI_CreateBin = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/CreateBin',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.CreateBinRequest,
  MCAPI_Types_pb.CreateBinResponse,
  /**
   * @param {!proto.mcapi.CreateBinRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.CreateBinResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.CreateBinRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.CreateBinResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.CreateBinResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.createBin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/CreateBin',
      request,
      metadata || {},
      methodDescriptor_MCAPI_CreateBin,
      callback);
};


/**
 * @param {!proto.mcapi.CreateBinRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.CreateBinResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.createBin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/CreateBin',
      request,
      metadata || {},
      methodDescriptor_MCAPI_CreateBin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.OpenBinRequest,
 *   !proto.mcapi.OpenBinResponse>}
 */
const methodDescriptor_MCAPI_OpenBin = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/OpenBin',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.OpenBinRequest,
  MCAPI_Types_pb.OpenBinResponse,
  /**
   * @param {!proto.mcapi.OpenBinRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.OpenBinResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.OpenBinRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.OpenBinResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.OpenBinResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.openBin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/OpenBin',
      request,
      metadata || {},
      methodDescriptor_MCAPI_OpenBin,
      callback);
};


/**
 * @param {!proto.mcapi.OpenBinRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.OpenBinResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.openBin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/OpenBin',
      request,
      metadata || {},
      methodDescriptor_MCAPI_OpenBin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.ConfigureSRTStreamRequest,
 *   !proto.mcapi.ConfigureSRTStreamResponse>}
 */
const methodDescriptor_MCAPI_ConfigureSRTStream = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/ConfigureSRTStream',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.ConfigureSRTStreamRequest,
  MCAPI_Types_pb.ConfigureSRTStreamResponse,
  /**
   * @param {!proto.mcapi.ConfigureSRTStreamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.ConfigureSRTStreamResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.ConfigureSRTStreamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.ConfigureSRTStreamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.ConfigureSRTStreamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.configureSRTStream =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/ConfigureSRTStream',
      request,
      metadata || {},
      methodDescriptor_MCAPI_ConfigureSRTStream,
      callback);
};


/**
 * @param {!proto.mcapi.ConfigureSRTStreamRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.ConfigureSRTStreamResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.configureSRTStream =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/ConfigureSRTStream',
      request,
      metadata || {},
      methodDescriptor_MCAPI_ConfigureSRTStream);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.GetSRTStreamSettingsRequest,
 *   !proto.mcapi.GetSRTStreamSettingsResponse>}
 */
const methodDescriptor_MCAPI_GetSRTStreamSettings = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/GetSRTStreamSettings',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.GetSRTStreamSettingsRequest,
  MCAPI_Types_pb.GetSRTStreamSettingsResponse,
  /**
   * @param {!proto.mcapi.GetSRTStreamSettingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.GetSRTStreamSettingsResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.GetSRTStreamSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.GetSRTStreamSettingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetSRTStreamSettingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.getSRTStreamSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/GetSRTStreamSettings',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetSRTStreamSettings,
      callback);
};


/**
 * @param {!proto.mcapi.GetSRTStreamSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.GetSRTStreamSettingsResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.getSRTStreamSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/GetSRTStreamSettings',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetSRTStreamSettings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.SetOTSSessionStatusRequest,
 *   !proto.mcapi.SetOTSSessionStatusResponse>}
 */
const methodDescriptor_MCAPI_SetOTSSessionStatus = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/SetOTSSessionStatus',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.SetOTSSessionStatusRequest,
  MCAPI_Types_pb.SetOTSSessionStatusResponse,
  /**
   * @param {!proto.mcapi.SetOTSSessionStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.SetOTSSessionStatusResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.SetOTSSessionStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.SetOTSSessionStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.SetOTSSessionStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.setOTSSessionStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/SetOTSSessionStatus',
      request,
      metadata || {},
      methodDescriptor_MCAPI_SetOTSSessionStatus,
      callback);
};


/**
 * @param {!proto.mcapi.SetOTSSessionStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.SetOTSSessionStatusResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.setOTSSessionStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/SetOTSSessionStatus',
      request,
      metadata || {},
      methodDescriptor_MCAPI_SetOTSSessionStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.GetOTSSessionStatusRequest,
 *   !proto.mcapi.GetOTSSessionStatusResponse>}
 */
const methodDescriptor_MCAPI_GetOTSSessionStatus = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/GetOTSSessionStatus',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.GetOTSSessionStatusRequest,
  MCAPI_Types_pb.GetOTSSessionStatusResponse,
  /**
   * @param {!proto.mcapi.GetOTSSessionStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.GetOTSSessionStatusResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.GetOTSSessionStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.GetOTSSessionStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetOTSSessionStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.getOTSSessionStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/GetOTSSessionStatus',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetOTSSessionStatus,
      callback);
};


/**
 * @param {!proto.mcapi.GetOTSSessionStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.GetOTSSessionStatusResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.getOTSSessionStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/GetOTSSessionStatus',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetOTSSessionStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.GetMediaVolumeListRequest,
 *   !proto.mcapi.GetMediaVolumeListResponse>}
 */
const methodDescriptor_MCAPI_GetMediaVolumeList = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/GetMediaVolumeList',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.GetMediaVolumeListRequest,
  MCAPI_Types_pb.GetMediaVolumeListResponse,
  /**
   * @param {!proto.mcapi.GetMediaVolumeListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.GetMediaVolumeListResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.GetMediaVolumeListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.GetMediaVolumeListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetMediaVolumeListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.getMediaVolumeList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/GetMediaVolumeList',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetMediaVolumeList,
      callback);
};


/**
 * @param {!proto.mcapi.GetMediaVolumeListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.GetMediaVolumeListResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.getMediaVolumeList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/GetMediaVolumeList',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetMediaVolumeList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.GetListOfBinItemsRequest,
 *   !proto.mcapi.GetListOfBinItemsResponse>}
 */
const methodDescriptor_MCAPI_GetListOfBinItems = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/GetListOfBinItems',
  grpc.web.MethodType.SERVER_STREAMING,
  MCAPI_Types_pb.GetListOfBinItemsRequest,
  MCAPI_Types_pb.GetListOfBinItemsResponse,
  /**
   * @param {!proto.mcapi.GetListOfBinItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.GetListOfBinItemsResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.GetListOfBinItemsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetListOfBinItemsResponse>}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.getListOfBinItems =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mcapi.MCAPI/GetListOfBinItems',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetListOfBinItems);
};


/**
 * @param {!proto.mcapi.GetListOfBinItemsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetListOfBinItemsResponse>}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIPromiseClient.prototype.getListOfBinItems =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mcapi.MCAPI/GetListOfBinItems',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetListOfBinItems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.GetOpenProjectInfoRequest,
 *   !proto.mcapi.GetOpenProjectInfoResponse>}
 */
const methodDescriptor_MCAPI_GetOpenProjectInfo = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/GetOpenProjectInfo',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.GetOpenProjectInfoRequest,
  MCAPI_Types_pb.GetOpenProjectInfoResponse,
  /**
   * @param {!proto.mcapi.GetOpenProjectInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.GetOpenProjectInfoResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.GetOpenProjectInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.GetOpenProjectInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetOpenProjectInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.getOpenProjectInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/GetOpenProjectInfo',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetOpenProjectInfo,
      callback);
};


/**
 * @param {!proto.mcapi.GetOpenProjectInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.GetOpenProjectInfoResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.getOpenProjectInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/GetOpenProjectInfo',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetOpenProjectInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.GetCustomProjectDataRequest,
 *   !proto.mcapi.GetCustomProjectDataResponse>}
 */
const methodDescriptor_MCAPI_GetCustomProjectData = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/GetCustomProjectData',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.GetCustomProjectDataRequest,
  MCAPI_Types_pb.GetCustomProjectDataResponse,
  /**
   * @param {!proto.mcapi.GetCustomProjectDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.GetCustomProjectDataResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.GetCustomProjectDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.GetCustomProjectDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetCustomProjectDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.getCustomProjectData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/GetCustomProjectData',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetCustomProjectData,
      callback);
};


/**
 * @param {!proto.mcapi.GetCustomProjectDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.GetCustomProjectDataResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.getCustomProjectData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/GetCustomProjectData',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetCustomProjectData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.SetCustomProjectDataRequest,
 *   !proto.mcapi.SetCustomProjectDataResponse>}
 */
const methodDescriptor_MCAPI_SetCustomProjectData = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/SetCustomProjectData',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.SetCustomProjectDataRequest,
  MCAPI_Types_pb.SetCustomProjectDataResponse,
  /**
   * @param {!proto.mcapi.SetCustomProjectDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.SetCustomProjectDataResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.SetCustomProjectDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.SetCustomProjectDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.SetCustomProjectDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.setCustomProjectData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/SetCustomProjectData',
      request,
      metadata || {},
      methodDescriptor_MCAPI_SetCustomProjectData,
      callback);
};


/**
 * @param {!proto.mcapi.SetCustomProjectDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.SetCustomProjectDataResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.setCustomProjectData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/SetCustomProjectData',
      request,
      metadata || {},
      methodDescriptor_MCAPI_SetCustomProjectData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.ScanAvidMediaFilesFolderRequest,
 *   !proto.mcapi.ScanAvidMediaFilesFolderResponse>}
 */
const methodDescriptor_MCAPI_ScanAvidMediaFilesFolder = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/ScanAvidMediaFilesFolder',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.ScanAvidMediaFilesFolderRequest,
  MCAPI_Types_pb.ScanAvidMediaFilesFolderResponse,
  /**
   * @param {!proto.mcapi.ScanAvidMediaFilesFolderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.ScanAvidMediaFilesFolderResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.ScanAvidMediaFilesFolderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.ScanAvidMediaFilesFolderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.ScanAvidMediaFilesFolderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.scanAvidMediaFilesFolder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/ScanAvidMediaFilesFolder',
      request,
      metadata || {},
      methodDescriptor_MCAPI_ScanAvidMediaFilesFolder,
      callback);
};


/**
 * @param {!proto.mcapi.ScanAvidMediaFilesFolderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.ScanAvidMediaFilesFolderResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.scanAvidMediaFilesFolder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/ScanAvidMediaFilesFolder',
      request,
      metadata || {},
      methodDescriptor_MCAPI_ScanAvidMediaFilesFolder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest,
 *   !proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse>}
 */
const methodDescriptor_MCAPI_CreateClipsFromAvidMediaFilesFolder = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/CreateClipsFromAvidMediaFilesFolder',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.CreateClipsFromAvidMediaFilesFolderRequest,
  MCAPI_Types_pb.CreateClipsFromAvidMediaFilesFolderResponse,
  /**
   * @param {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.CreateClipsFromAvidMediaFilesFolderResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.createClipsFromAvidMediaFilesFolder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/CreateClipsFromAvidMediaFilesFolder',
      request,
      metadata || {},
      methodDescriptor_MCAPI_CreateClipsFromAvidMediaFilesFolder,
      callback);
};


/**
 * @param {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.createClipsFromAvidMediaFilesFolder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/CreateClipsFromAvidMediaFilesFolder',
      request,
      metadata || {},
      methodDescriptor_MCAPI_CreateClipsFromAvidMediaFilesFolder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.CreateCustomColumnRequest,
 *   !proto.mcapi.CreateCustomColumnResponse>}
 */
const methodDescriptor_MCAPI_CreateCustomColumn = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/CreateCustomColumn',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.CreateCustomColumnRequest,
  MCAPI_Types_pb.CreateCustomColumnResponse,
  /**
   * @param {!proto.mcapi.CreateCustomColumnRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.CreateCustomColumnResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.CreateCustomColumnRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.CreateCustomColumnResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.CreateCustomColumnResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.createCustomColumn =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/CreateCustomColumn',
      request,
      metadata || {},
      methodDescriptor_MCAPI_CreateCustomColumn,
      callback);
};


/**
 * @param {!proto.mcapi.CreateCustomColumnRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.CreateCustomColumnResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.createCustomColumn =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/CreateCustomColumn',
      request,
      metadata || {},
      methodDescriptor_MCAPI_CreateCustomColumn);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.GetListOfImportSettingsRequest,
 *   !proto.mcapi.GetListOfImportSettingsResponse>}
 */
const methodDescriptor_MCAPI_GetListOfImportSettings = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/GetListOfImportSettings',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.GetListOfImportSettingsRequest,
  MCAPI_Types_pb.GetListOfImportSettingsResponse,
  /**
   * @param {!proto.mcapi.GetListOfImportSettingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.GetListOfImportSettingsResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.GetListOfImportSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.GetListOfImportSettingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetListOfImportSettingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.getListOfImportSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/GetListOfImportSettings',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetListOfImportSettings,
      callback);
};


/**
 * @param {!proto.mcapi.GetListOfImportSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.GetListOfImportSettingsResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.getListOfImportSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/GetListOfImportSettings',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetListOfImportSettings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.ImportFileRequest,
 *   !proto.mcapi.ImportFileResponse>}
 */
const methodDescriptor_MCAPI_ImportFile = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/ImportFile',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.ImportFileRequest,
  MCAPI_Types_pb.ImportFileResponse,
  /**
   * @param {!proto.mcapi.ImportFileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.ImportFileResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.ImportFileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.ImportFileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.ImportFileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.importFile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/ImportFile',
      request,
      metadata || {},
      methodDescriptor_MCAPI_ImportFile,
      callback);
};


/**
 * @param {!proto.mcapi.ImportFileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.ImportFileResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.importFile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/ImportFile',
      request,
      metadata || {},
      methodDescriptor_MCAPI_ImportFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.GetListOfExportEDLSettingsRequest,
 *   !proto.mcapi.GetListOfExportEDLSettingsResponse>}
 */
const methodDescriptor_MCAPI_GetListOfExportEDLSettings = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/GetListOfExportEDLSettings',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.GetListOfExportEDLSettingsRequest,
  MCAPI_Types_pb.GetListOfExportEDLSettingsResponse,
  /**
   * @param {!proto.mcapi.GetListOfExportEDLSettingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.GetListOfExportEDLSettingsResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.GetListOfExportEDLSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.GetListOfExportEDLSettingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetListOfExportEDLSettingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.getListOfExportEDLSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/GetListOfExportEDLSettings',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetListOfExportEDLSettings,
      callback);
};


/**
 * @param {!proto.mcapi.GetListOfExportEDLSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.GetListOfExportEDLSettingsResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.getListOfExportEDLSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/GetListOfExportEDLSettings',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetListOfExportEDLSettings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.ExportEDLRequest,
 *   !proto.mcapi.ExportEDLResponse>}
 */
const methodDescriptor_MCAPI_ExportEDL = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/ExportEDL',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.ExportEDLRequest,
  MCAPI_Types_pb.ExportEDLResponse,
  /**
   * @param {!proto.mcapi.ExportEDLRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.ExportEDLResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.ExportEDLRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.ExportEDLResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.ExportEDLResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.exportEDL =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/ExportEDL',
      request,
      metadata || {},
      methodDescriptor_MCAPI_ExportEDL,
      callback);
};


/**
 * @param {!proto.mcapi.ExportEDLRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.ExportEDLResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.exportEDL =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/ExportEDL',
      request,
      metadata || {},
      methodDescriptor_MCAPI_ExportEDL);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.GetListOfExportSettingsRequest,
 *   !proto.mcapi.GetListOfExportSettingsResponse>}
 */
const methodDescriptor_MCAPI_GetListOfExportSettings = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/GetListOfExportSettings',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.GetListOfExportSettingsRequest,
  MCAPI_Types_pb.GetListOfExportSettingsResponse,
  /**
   * @param {!proto.mcapi.GetListOfExportSettingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.GetListOfExportSettingsResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.GetListOfExportSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.GetListOfExportSettingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.GetListOfExportSettingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.getListOfExportSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/GetListOfExportSettings',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetListOfExportSettings,
      callback);
};


/**
 * @param {!proto.mcapi.GetListOfExportSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.GetListOfExportSettingsResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.getListOfExportSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/GetListOfExportSettings',
      request,
      metadata || {},
      methodDescriptor_MCAPI_GetListOfExportSettings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.ExportFileRequest,
 *   !proto.mcapi.ExportFileResponse>}
 */
const methodDescriptor_MCAPI_ExportFile = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/ExportFile',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.ExportFileRequest,
  MCAPI_Types_pb.ExportFileResponse,
  /**
   * @param {!proto.mcapi.ExportFileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.ExportFileResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.ExportFileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.ExportFileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.ExportFileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.exportFile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/ExportFile',
      request,
      metadata || {},
      methodDescriptor_MCAPI_ExportFile,
      callback);
};


/**
 * @param {!proto.mcapi.ExportFileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.ExportFileResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.exportFile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/ExportFile',
      request,
      metadata || {},
      methodDescriptor_MCAPI_ExportFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.LoadSettingRequest,
 *   !proto.mcapi.LoadSettingResponse>}
 */
const methodDescriptor_MCAPI_LoadSetting = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/LoadSetting',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.LoadSettingRequest,
  MCAPI_Types_pb.LoadSettingResponse,
  /**
   * @param {!proto.mcapi.LoadSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.LoadSettingResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.LoadSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.LoadSettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.LoadSettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.loadSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/LoadSetting',
      request,
      metadata || {},
      methodDescriptor_MCAPI_LoadSetting,
      callback);
};


/**
 * @param {!proto.mcapi.LoadSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.LoadSettingResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.loadSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/LoadSetting',
      request,
      metadata || {},
      methodDescriptor_MCAPI_LoadSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.AddMarkerRequest,
 *   !proto.mcapi.AddMarkerResponse>}
 */
const methodDescriptor_MCAPI_AddMarker = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/AddMarker',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.AddMarkerRequest,
  MCAPI_Types_pb.AddMarkerResponse,
  /**
   * @param {!proto.mcapi.AddMarkerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.AddMarkerResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.AddMarkerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.AddMarkerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.AddMarkerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.addMarker =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/AddMarker',
      request,
      metadata || {},
      methodDescriptor_MCAPI_AddMarker,
      callback);
};


/**
 * @param {!proto.mcapi.AddMarkerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.AddMarkerResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.addMarker =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/AddMarker',
      request,
      metadata || {},
      methodDescriptor_MCAPI_AddMarker);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mcapi.LoadMobsIntoViewerRequest,
 *   !proto.mcapi.LoadMobsIntoViewerResponse>}
 */
const methodDescriptor_MCAPI_LoadMobsIntoViewer = new grpc.web.MethodDescriptor(
  '/mcapi.MCAPI/LoadMobsIntoViewer',
  grpc.web.MethodType.UNARY,
  MCAPI_Types_pb.LoadMobsIntoViewerRequest,
  MCAPI_Types_pb.LoadMobsIntoViewerResponse,
  /**
   * @param {!proto.mcapi.LoadMobsIntoViewerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  MCAPI_Types_pb.LoadMobsIntoViewerResponse.deserializeBinary
);


/**
 * @param {!proto.mcapi.LoadMobsIntoViewerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mcapi.LoadMobsIntoViewerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mcapi.LoadMobsIntoViewerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mcapi.MCAPIClient.prototype.loadMobsIntoViewer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mcapi.MCAPI/LoadMobsIntoViewer',
      request,
      metadata || {},
      methodDescriptor_MCAPI_LoadMobsIntoViewer,
      callback);
};


/**
 * @param {!proto.mcapi.LoadMobsIntoViewerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mcapi.LoadMobsIntoViewerResponse>}
 *     Promise that resolves to the response
 */
proto.mcapi.MCAPIPromiseClient.prototype.loadMobsIntoViewer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mcapi.MCAPI/LoadMobsIntoViewer',
      request,
      metadata || {},
      methodDescriptor_MCAPI_LoadMobsIntoViewer);
};


module.exports = proto.mcapi;


import React from 'react';
import "./styles.scss";

const PageLayout = (props: any) => {
    return(
        <div className='page-layout-wrapper' {...props}>
            {props.children}
        </div>
    )
}

export const PageContent = (props: any) => {
    return(
        <div className='layout-content' {...props}>
            {props.children}
        </div>
    )
}

export default PageLayout;
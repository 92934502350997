import React from 'react';
import './styles.scss';
import logo from '../../assets/images/logo-dark.png';
import { Result } from 'antd';
const ViewerLogoutPage = () => {
  return (
    <Result
      style={{ margin: '15% 0' }}
      icon={<img alt='logo' src={logo} />}
      title=' Your session has ended.'
    />
  );
};

export default ViewerLogoutPage;

import React, { useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { doSignOut } from "../../firebase/auth";

import { AuthContext } from "../../context/auth";

import "./styles.scss";

const Header = (props: any) => {
  const user: any = useContext(AuthContext);
  const navigate = useNavigate();

  const signout = async () => {
    await doSignOut();
    navigate("/", { replace: true });
  };

  return (
    <header className="header">
      <div className="wrap">
        <div className="logo">
          <Link to="/">
            <div>PPL Light</div>
          </Link>
        </div>

        <div className="callToActions">
          <ul>
            {user && [
              <li key={1} className="hideOnMobile">
                <Link to="/profile">Profile</Link>
              </li>,
              <li key={2} className="hideOnMobile">
                <Link to="/payment_methods">Payment Methods</Link>
              </li>,
              <li key={3}>
                <span onClick={() => signout()}>
                  LogOut
                  {/* <i class="fas fa-sign-out-alt"></i> */}
                </span>
              </li>,
            ]}

            {!user && [
              <li key={1} className="hideOnMobile">
                <Link to="/signup">SIGN UP</Link>
              </li>,
              <li key={2}>
                <Link to="/">
                  LOGIN
                  {/* <i class="fas fa-user-circle"></i> */}
                </Link>
              </li>,
            ]}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;

import { useRef, useState } from "react";

import "./styles.scss";
import { notification } from "antd";
import { useCountdownTimer } from "../hooks/useTimeoutClock";
interface FreeSessionTimerProps {
  expiryTime: number;
  currentDate: number;
  setMessageShown: (value: boolean) => void;
  messageShown: boolean;
}
const FreeSessionTimer = (props: FreeSessionTimerProps) => {
  const { expiryTime } = props;
  const showMsgRef = useRef(false);
  const [refreshToken,setRefreshToken]=useState(false)
  const values = useCountdownTimer(expiryTime,setRefreshToken,refreshToken);

  const renderBody = () => {
    if (values.minutes() < 3 && !!expiryTime && values.asMilliseconds() !==0) {
      if (!showMsgRef.current) {
        notification.info({
          message: `Your session will end in ${values.minutes()}:${values.seconds()} minutes`,
          placement: "bottomRight",
        });
        showMsgRef.current = true;
      }

      return (
        <div className="timer-ui">
          <span>
            Session expires in
            <br />
            {` ${
              String(values.minutes()).length === 1
                ? `0${values.minutes()}`
                : `${values.minutes()}`
            } : ${
              String(values.seconds()).length === 1
                ? `0${values.seconds()}`
                : values.seconds()
            }`}
          </span>
        </div>
      );
    } else return <div className="timer-ui-empty"></div>;
  };

  return <>{renderBody()}</>;
};
export default FreeSessionTimer;

/* API Base and EndPoints */

let baseUrl = process.env.REACT_APP_BASEURL;

/* Service APIs */
const user: any = {
  create: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/user",
  },
  createUser: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/user/create",
  },
  get: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/user",
  },
  updateAccount: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/user/updateaccount",
  },
  changePassword: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/user/changepassword",
  },
  forgotPassword: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/user/forgotpassword",
  },
  checkvieweraccess: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/user/checkvieweraccess",
  },
  viewerLogin: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/user/viewer_login",
  },
  //Duplicate APIS
  isShowPublisher: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/user",
  },
  identify: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/user/identify",
  },
};

const otp: any = {
  googleAuth2FA: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/otp/googleAuth",
  },
  validateGoogleAuth2FA: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/otp/googleAuthValidate",
  },
  disableGoogleAuth2FA: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/otp/disableOTPAuthStatus",
  },
  requestOTP: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/otp/requestOtp",
  },
  validateOTP: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/otp/validateOtp",
  },
  disable2FAClaims:{
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/otp/disable2FAClaims",
  }
}

const stripe: any = {
  createSetupIntent: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/stripe/createSetupIntent",
  },
  listStripePaymentMethods: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/stripe/listPaymentMethods",
  },
  getPricingDetails: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/stripe/price",
  },
  createSubscription: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/stripe/createSubscription",
  },
  cancelSubscription: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/stripe/cancelSubscription",
  },
  reActivateSubscription: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/stripe/reActivateSubscription",
  },
  retrieveSubscription: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/stripe/retrieveSubscription",
  },
  upgradeSubscription: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/stripe/upgradeSubscription"
  },
  detachPayment: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/stripe/detachPayment",
  },
  getInvoiceDetails: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/stripe/getCustomerInvoice",
  },
};
const payment_method: any = {
  createPaymentMethod: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/payment_method",
  },
};
const old: any = {
  remove: {},
  subscribeStream: {}

}
const room: any = {
  getRoom: {
    method: 'GET',
    baseEndPoint: "/app",
    endPoint: "/room",
  },
  createRoom: {
    method: 'PATCH',
    baseEndPoint: "/app",
    endPoint: "/room/addStreamToRoom",
  },
  updateRoom: {
    method: 'PATCH',
    baseEndPoint: "/app",
    endPoint: "/room",
  },
  updateAutoAllowRoom: {
    method: 'PATCH',
    baseEndPoint: "/app",
    endPoint: "/room/auto_allow",
  },
  updateRoomByViewer: {
    method: 'PATCH',
    baseEndPoint: "/app",
    endPoint: "/room/update/viewer",
  },
  removeRoomByViewer: {
    method: 'PATCH',
    baseEndPoint: "/app",
    endPoint: "/room/remove/viewer",
  },
  getAllRoomsOfUser: {
    method: 'GET',
    baseEndPoint: "/app",
    endPoint: "/room",
  },
  getAllActiveRoomsOfUser: {
    method: 'GET',
    baseEndPoint: "/app",
    endPoint: "/room/active",
  },
  refreshCode: {
    method: 'POST',
    baseEndPoint: "/app",
    endPoint: "/room/refresh_code",
  },
  deleteSession: {
    method: 'DELETE',
    baseEndPoint: "/app",
    endPoint: "/room/delete_session",
  },
  deleteRoom: {
    method: 'DELETE',
    baseEndPoint: "/app",
    endPoint: "/room",
  },
  getPublishTokens: {
    method: 'POST',
    baseEndPoint: "/app",
    endPoint: "/room/get_publish_tokens",
  },
  getPublishToken: {
    method: 'GETs',
    baseEndPoint: "/app",
    endPoint: "/room/publishtoken",
  },
  subscribeStream: {
    method: 'POST',
    baseEndPoint: "/app",
    endPoint: "/room/subscribe_stream",
  },
  getTwilioVideoToken: {
    method: 'GET',
    baseEndPoint: "/app",
    endPoint: "/room/twilio/video/token",
  },
  getTwilioRoomsParticipants: {
    method: 'GET',
    baseEndPoint: "/app",
    endPoint: "/room/twilio/getTwilioRoomsParticipants",
  },
  getValidRoom: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/room/valid",
  },
  autoAllow: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/room/allow",
  },
  getGuestList: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/room/guest",
  },
  updateGuestStatus: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/room/guest",
  },
  clearWaitingRoom: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/room/clear_waiting",
  },
  addFreeRoom:{
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/room/add_free_room",
  },
  leaveOrEndConference:{
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/room/leaveOrEndConference",
  },
  generateZoomVideoSDKJWT:{
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/room/generate_zoom_video_sdk_jwt",
  }
}

const featureFlags = {
  listAllFlags: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/featureflags",
  }
};

const zoom = {
  generateVideoSDKJWT: {
      method: "POST",
      baseEndPoint: "/app",
      endPoint: "/room//generate_zoom_video_sdk_jwt",
  },
};

const api = { baseUrl, user, stripe, payment_method, room, old, otp, featureFlags, zoom };

export default api;

import "./styles.scss";

const BadgeComponent = (props: any) => {
  const { text, button } = props;

  return (
    <div className="create-room-badge">
      <span>{text}</span>
      <div className="upgrade-btn">{button}</div>
    </div>
  );
};
export default BadgeComponent;

import { get } from "../../utils/helpers";
import refreshIcon from "./Images/reload.svg";
import messageComponent from "../../utils/message";
import useGetroomupdates from "../../hooks/useGetroomupdates";
interface ReloadProps {
  reloadPage: () => void;
  showDomSpeaker: any;
  admin_user_id: string;
  adminCode: string;
}
let streamingStatus = false;
const Reload = (props: ReloadProps) => {
  const { reloadPage, showDomSpeaker, admin_user_id, adminCode } = props;
  const reloadFlag = useGetroomupdates("users", true, admin_user_id);
  const triggerData = reloadFlag?.doc?.data();

  if (
    streamingStatus !== get(triggerData, "streaming", false) &&
    get(reloadFlag, "type", "") === "modified" &&
    get(triggerData, "stream_admin_code", "") === adminCode &&
    get(triggerData, "stream_status", "") !== "Deleted"
  ) {
    streamingStatus = get(triggerData, "streaming", false);
    if (streamingStatus) {
      messageComponent({
        messages: "Streaming started",
        type: "success",
        top: 10,
      });
      reloadPage();
    }
  }
  return (
    <div
      onClick={reloadPage}
      className={`top-menu-button-item ${showDomSpeaker ? "disableddiv" : ""}`}
    >
      <img src={refreshIcon} alt="" />
      <span>Reload Page</span>
    </div>
  );
};
export default Reload;

import { useRef, useState, useEffect, createContext, useCallback } from "react";
import firebase from "firebase/auth";
import { auth } from "../firebase/utils";
import { get } from "../utils/helpers";

export const AuthContext = createContext<firebase.User | null>(null);

export const AuthProvider = (props: any) => {
  const interval = useRef<NodeJS.Timeout | null>();
  const [currentUser, setCurrentUser] = useState({
    user: null as any,
    claims: {},
    loading: true,
  });

  const authListener = useCallback(async () => {
    const authHandler = async (user: any) => {
      if (user) {
        const tokenResult = await user.getIdTokenResult(true);
        // console.log("user is logged in !");
        // console.log("user : ", user);
        setCurrentUser({
          user,
          claims: get(tokenResult, "claims", {}),
          loading: false,
        });
      } else {
        setCurrentUser({
          user: null,
          claims: {},
          loading: false,
        });
        console.log("user is signed out !");
      }
    };
    // const tokenSubscribe = auth.onIdTokenChanged(authHandler);
    const authSubscribe = auth.onAuthStateChanged(authHandler);
    interval.current = setInterval(() => {
      auth.currentUser?.getIdToken(true);
    }, 59 * 60 * 1000); // Refresh token every hour

    return () => {
      if (interval.current) clearInterval(interval.current); // Clean up interval on component unmount
      // tokenSubscribe(); // Clean up onIdTokenChanged on component unmount
      authSubscribe(); // Clean up onAuthStateChanged on component unmount
    };
  }, [currentUser]);

  useEffect(() => {
    authListener();
  }, []);

  const { user, claims, loading } = currentUser;
  const customUserObj = !!user ? {
    ...user,
    ...claims,
    setCurrentUser:setCurrentUser,
    displayName: !!get(claims, "guestViewer", false)
      ? `${get(claims, "fn", "")} ${get(claims, "ln", "")}`
      : get(user, "displayName", "")
  } : null;

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <div>Authentication in progress</div>
        </div>
      ) : (
        <AuthContext.Provider value={customUserObj}>
          {props.children}
        </AuthContext.Provider>
      )}
    </>
  );
};

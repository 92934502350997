import React, { useState } from "react";
import { Button, Space, Form, List, Typography } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import "./styles.scss";
import PageLayout, { PageContent } from "../../../layouts/PageLayout";
import logo from "../../../assets/images/sidebar-logo.png";
import Title from "../../Title";
import InputComponent from "../../InputComponent";
import Password from "antd/lib/input/Password";
import ErrorComponent from "../../ErrorComponent";
import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";
import Loader from "../../LoaderHOC";
import messageComponent from "../../../utils/message";
import { doSignOut } from "../../../firebase/auth";
import { isAvidBrowser } from "../../../utils/avidBrowserCheck";


const ResetPassword = (props: any) => {
    const [form] = Form.useForm();
    const [passwordValid, setPasswordValid] = useState(false);
    const [newpassword, setNewPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const isAvidBrowserCheck =  isAvidBrowser();
    const sendresetPassword = () => {
        console.log("reset password")
    }
    const navigate = useNavigate();


    const signout = async () => {
        await doSignOut();
        navigate("/", {replace: true})
    };

    const openNotifications = () => {
        messageComponent({
            messages: "Your password is changed successfully",
            type: "success",
            onClose: () => signout(),
            top: 10
        })
        
    };

    const handleResetPassword = async () => {
        console.log("Reset password");
        setLoading(true);
        try {
            // Call Backend to Create User
            const input = {
                password: newpassword
            };
            const data = {
                api: api.user.changePassword,
                payLoad: JSON.stringify(input),
            };

            const response = await backend.save(data, true);
            setLoading(false);
            if (!!response) {
                console.log('was Reset password successful:::::', response)
                openNotifications()
            }

            return;
        } catch (err) {
            setLoading(false);
            throw err;
        }
    };

    return (
        <Loader loading={loading}>
            <PageLayout>
                <PageContent className="resetpassword-left-content">
                    <img src={logo} alt="logo" className="logo" />
                </PageContent>
                <PageContent className="resetpassword-right-content">
                    <div className="resetpassword-container">
                        <Form
                        form={form}
                            onFinish={sendresetPassword}>
                            <Title level={3}>Reset Password </Title>
                            <Form.Item
    name="password"
    rules={[
        ({ getFieldValue }) => ({
            validator: (_, value) => {
                if (value === "") {
                    setPasswordValid(false);
                    return Promise.reject(
                        <ErrorComponent
                            errorMsg={"Please enter a password."}
                            setErrorMsg={setErrorMsg}
                        />
                    );
                } else if (value.length < 12) {
                    setPasswordValid(false);
                    return Promise.reject(
                    );
                } else if (!/[a-z]/.test(value) || !/[A-Z]/.test(value) || !/[0-9]/.test(value) || !/[!"#$%&'()*+,-./:;<=>?_@]/.test(value)) {
                    setPasswordValid(false);
                    return Promise.reject(
                    );
                } else if (getFieldValue('confirm password') !== value) {
                    setPasswordValid(false);
                    form.validateFields(['confirm password'])
                    return Promise.reject(
                    );
                } 
                else {
                    setPasswordValid(true);
                    form.validateFields(['confirm password'])
                    Promise.resolve();
                }
            },
        }),
    ]}
>
    <InputComponent
        key={"PASSWORD"}
        label="New Password"
        value={newpassword}
        type="password"
        onChange={setNewPassword}
    />
</Form.Item>


                            <Form.Item
                                name="confirm password"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            else if (value === "") {
                                                return Promise.reject(<ErrorComponent
                                                    errorMsg={"Please Re-enter the password"}
                                                    setErrorMsg={setErrorMsg}
                                                />);
                                            }
                                            else if (getFieldValue('confirm password') !== getFieldValue('password')) {
                                                return Promise.reject(<ErrorComponent
                                                    errorMsg={"Password not matched!"}
                                                    setErrorMsg={setErrorMsg}
                                                />);
                                            }
                                            
                                            else if (getFieldValue('confirm password') !== value) {
                                                setPasswordValid(true)
                                                return Promise.reject(
                                                    <ErrorComponent
                                                        errorMsg={"Password not matched!"}
                                                        setErrorMsg={setErrorMsg}
                                                    />
                                                    
                                                );
                                            } 
                                            else {
                                                return Promise.reject(<ErrorComponent
                                                    errorMsg={"Password not matched!"}
                                                    setErrorMsg={setErrorMsg}
                                                />);
                                            }
                                        },
                                    })
                                ]}
                            >
                                <InputComponent
                                    key={"CONFIRMPASSWORD"}
                                    label="confirm password"
                                    value={confirmpassword}
                                    type="password"
                                    onChange={setConfirmPassword}
                                />
                            </Form.Item>
                            <div className="bottom-space">
                                <div className="space">
                                    <div className="list-head">
                                        YOUR PASSWORD MUST INCLUDE
                                    </div>
                                    <div className="list-data">
                                        <span><CheckCircleTwoTone twoToneColor={(newpassword.length >= 12) ? "#42B729" : "#A3A3A3"}  /> not less than 12 characters</span>
                                        <span><CheckCircleTwoTone twoToneColor={((/[A-Z]/g).test(newpassword)) ? "#42B729" : "#A3A3A3"}  /> atleast one uppercase letter</span>
                                        <span><CheckCircleTwoTone twoToneColor={((/[a-z]/g).test(newpassword)) ? "#42B729" : "#A3A3A3"}  /> atleast one lowercase letter</span>
                                        <span><CheckCircleTwoTone twoToneColor={((/[0-9]/g).test(newpassword)) ? "#42B729" : "#A3A3A3"}  /> atleast one number</span>
                                        <span><CheckCircleTwoTone twoToneColor={((/[!"#$%&'()*+,-./:;<=>?_@]/g).test(newpassword)) ? "#42B729" : "#A3A3A3"}  /> atleast one special character</span>
                                    </div>
                                </div>

                                <div className="resetpassword-btn-container">
                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            className="resetpassword-btn"
                                            onClick={handleResetPassword}
                                            disabled={(newpassword !== confirmpassword || newpassword?.trim() === '' || confirmpassword?.trim() === '')}
                                        >Reset Password
                                        </Button>
                                    </Form.Item>
                                    {!isAvidBrowserCheck ?
                                    (
                                        <div className="need-support-reset">
                                            <a href="mailto:support@pacpostio.zohodesk.com">Need Support?</a>
                                        </div> 
                                    ) : (
                                        <div className="need-support-copy-reset">
                                          <span className="need-support-copy-text">Need Support? support@pacpostio.zohodesk.com</span>
                                        </div> )}
                                </div>
                            </div>
                        </Form>
                    </div>
                </PageContent>
            </PageLayout>
        </Loader>
    )
};
export default ResetPassword;

import { Dropdown } from "semantic-ui-react";
import useHudsStatsStore from "../../../../store/slices/viewer/hudsStatsStore";
import { get } from "../../../../utils/helpers";

const TranscoderOptions = (props: any) => {
  const {  cameraDropdownOpen, setCameraDropdownOpen } = props;
  const { layerOptions = [], hudsState, selectedLayer, setSelectedLayer } = useHudsStatsStore();
  const changeSimulcast = (e: any, value: any) => {
    hudsState?.milicastView?.select(get(value, "value", {}));
    setSelectedLayer(get(value, "value.encodingId", ""));
    setCameraDropdownOpen(!cameraDropdownOpen);
  };

  if (!layerOptions.length) {
    return <></>;
  }
  return (
    <Dropdown.Item>
      <Dropdown
        text={selectedLayer}
        id="cameraDropdown"
        placeholder="microphone"
        multiple={false}
        fluid
        selection
        pointing="left"
        onChange={changeSimulcast}
        options={layerOptions}
      />
    </Dropdown.Item>
  );
};
export default TranscoderOptions;

export const countryCodes: any = {
   "Afghanistan": "AF",
   "Aland Islands": "AX",
   "Albania": "AL",
   "Algeria": "DZ",
   "American Samoa": "AS",
   "Andorra": "AD",
   "Angola": "AO",
   "Anguilla": "AI",
   "Antarctica": "AQ",
   "Antigua And Barbuda": "AG",
   "Argentina": "AR",
   "Armenia": "AM",
   "Aruba": "AW",
   "Australia": "AU",
   "Austria": "AT",
   "Azerbaijan": "AZ",
   "Bahamas": "BS",
   "Bahrain": "BH",
   "Bangladesh": "BD",
   "Barbados": "BB",
   "Belarus": "BY",
   "Belgium": "BE",
   "Belize": "BZ",
   "Benin": "BJ",
   "Bermuda": "BM",
   "Bhutan": "BT",
   "Bolivia": "BO",
   "Bosnia And Herzegovina": "BA",
   "Botswana": "BW",
   "Bouvet Island": "BV",
   "Brazil": "BR",
   "British Indian Ocean Territory": "IO",
   "Brunei Darussalam": "BN",
   "Bulgaria": "BG",
   "Burkina Faso": "BF",
   "Burundi": "BI",
   "Cambodia": "KH",
   "Cameroon": "CM",
   "Canada": "CA",
   "Cape Verde": "CV",
   "Cayman Islands": "KY",
   "Central African Republic": "CF",
   "Chad": "TD",
   "Chile": "CL",
   "China": "CN",
   "Christmas Island": "CX",
   "Cocos (Keeling) Islands": "CC",
   "Colombia": "CO",
   "Comoros": "KM",
   "Congo": "CG",
   "Congo, Democratic Republic": "CD",
   "Cook Islands": "CK",
   "Costa Rica": "CR",
   "Cote D\"Ivoire": "CI",
   "Croatia": "HR",
   "Cuba": "CU",
   "Cyprus": "CY",
   "Czech Republic": "CZ",
   "Denmark": "DK",
   "Djibouti": "DJ",
   "Dominica": "DM",
   "Dominican Republic": "DO",
   "Ecuador": "EC",
   "Egypt": "EG",
   "El Salvador": "SV",
   "Equatorial Guinea": "GQ",
   "Eritrea": "ER",
   "Estonia": "EE",
   "Ethiopia": "ET",
   "Falkland Islands (Malvinas)": "FK",
   "Faroe Islands": "FO",
   "Fiji": "FJ",
   "Finland": "FI",
   "France": "FR",
   "French Guiana": "GF",
   "French Polynesia": "PF",
   "French Southern Territories": "TF",
   "Gabon": "GA",
   "Gambia": "GM",
   "Georgia": "GE",
   "Germany": "DE",
   "Ghana": "GH",
   "Gibraltar": "GI",
   "Greece": "GR",
   "Greenland": "GL",
   "Grenada": "GD",
   "Guadeloupe": "GP",
   "Guam": "GU",
   "Guatemala": "GT",
   "Guernsey": "GG",
   "Guinea": "GN",
   "Guinea-Bissau": "GW",
   "Guyana": "GY",
   "Haiti": "HT",
   "Heard Island & Mcdonald Islands": "HM",
   "Holy See (Vatican City State)": "VA",
   "Honduras": "HN",
   "Hong Kong": "HK",
   "Hungary": "HU",
   "Iceland": "IS",
   "India": "IN",
   "Indonesia": "ID",
   "Iran, Islamic Republic Of": "IR",
   "Iraq": "IQ",
   "Ireland": "IE",
   "Isle Of Man": "IM",
   "Israel": "IL",
   "Italy": "IT",
   "Jamaica": "JM",
   "Japan": "JP",
   "Jersey": "JE",
   "Jordan": "JO",
   "Kazakhstan": "KZ",
   "Kenya": "KE",
   "Kiribati": "KI",
   "Korea": "KR",
   "North Korea": "KP",
   "Kuwait": "KW",
   "Kyrgyzstan": "KG",
   "Lao People\"s Democratic Republic": "LA",
   "Latvia": "LV",
   "Lebanon": "LB",
   "Lesotho": "LS",
   "Liberia": "LR",
   "Libyan Arab Jamahiriya": "LY",
   "Liechtenstein": "LI",
   "Lithuania": "LT",
   "Luxembourg": "LU",
   "Macao": "MO",
   "Macedonia": "MK",
   "Madagascar": "MG",
   "Malawi": "MW",
   "Malaysia": "MY",
   "Maldives": "MV",
   "Mali": "ML",
   "Malta": "MT",
   "Marshall Islands": "MH",
   "Martinique": "MQ",
   "Mauritania": "MR",
   "Mauritius": "MU",
   "Mayotte": "YT",
   "Mexico": "MX",
   "Micronesia, Federated States Of": "FM",
   "Moldova": "MD",
   "Monaco": "MC",
   "Mongolia": "MN",
   "Montenegro": "ME",
   "Montserrat": "MS",
   "Morocco": "MA",
   "Mozambique": "MZ",
   "Myanmar": "MM",
   "Namibia": "NA",
   "Nauru": "NR",
   "Nepal": "NP",
   "Netherlands": "NL",
   "Netherlands Antilles": "AN",
   "New Caledonia": "NC",
   "New Zealand": "NZ",
   "Nicaragua": "NI",
   "Niger": "NE",
   "Nigeria": "NG",
   "Niue": "NU",
   "Norfolk Island": "NF",
   "Northern Mariana Islands": "MP",
   "Norway": "NO",
   "Oman": "OM",
   "Pakistan": "PK",
   "Palau": "PW",
   "Palestinian Territory, Occupied": "PS",
   "Panama": "PA",
   "Papua New Guinea": "PG",
   "Paraguay": "PY",
   "Peru": "PE",
   "Philippines": "PH",
   "Pitcairn": "PN",
   "Poland": "PL",
   "Portugal": "PT",
   "Puerto Rico": "PR",
   "Qatar": "QA",
   "Reunion": "RE",
   "Romania": "RO",
   "Russian Federation": "RU",
   "Rwanda": "RW",
   "Saint Barthelemy": "BL",
   "Saint Helena": "SH",
   "Saint Kitts And Nevis": "KN",
   "Saint Lucia": "LC",
   "Saint Martin": "MF",
   "Saint Pierre And Miquelon": "PM",
   "Saint Vincent And Grenadines": "VC",
   "Samoa": "WS",
   "San Marino": "SM",
   "Sao Tome And Principe": "ST",
   "Saudi Arabia": "SA",
   "Senegal": "SN",
   "Serbia": "RS",
   "Seychelles": "SC",
   "Sierra Leone": "SL",
   "Singapore": "SG",
   "Slovakia": "SK",
   "Slovenia": "SI",
   "Solomon Islands": "SB",
   "Somalia": "SO",
   "South Africa": "ZA",
   "South Georgia And Sandwich Isl.": "GS",
   "Spain": "ES",
   "Sri Lanka": "LK",
   "Sudan": "SD",
   "Suriname": "SR",
   "Svalbard And Jan Mayen": "SJ",
   "Swaziland": "SZ",
   "Sweden": "SE",
   "Switzerland": "CH",
   "Syrian Arab Republic": "SY",
   "Taiwan": "TW",
   "Tajikistan": "TJ",
   "Tanzania": "TZ",
   "Thailand": "TH",
   "Timor-Leste": "TL",
   "Togo": "TG",
   "Tokelau": "TK",
   "Tonga": "TO",
   "Trinidad And Tobago": "TT",
   "Tunisia": "TN",
   "Turkey": "TR",
   "Turkmenistan": "TM",
   "Turks And Caicos Islands": "TC",
   "Tuvalu": "TV",
   "Uganda": "UG",
   "Ukraine": "UA",
   "United Arab Emirates": "AE",
   "United Kingdom": "GB",
   "United States": "US",
   "United States Outlying Islands": "UM",
   "Uruguay": "UY",
   "Uzbekistan": "UZ",
   "Vanuatu": "VU",
   "Venezuela": "VE",
   "Vietnam": "VN",
   "Virgin Islands, British": "VG",
   "Virgin Islands, U.S.": "VI",
   "Wallis And Futuna": "WF",
   "Western Sahara": "EH",
   "Yemen": "YE",
   "Zambia": "ZM",
   "Zimbabwe": "ZW"
}

// export const ISO3166_CountryCodeSet = [
//     {
//       "englishShortName": "Afghanistan",
//       "frenchShortName": "Afghanistan (l')",
//       "alpha2Code": "AF",
//       "alpha3Code": "AFG",
//       "numeric": 4
//     },
//     {
//       "englishShortName": "Albania",
//       "frenchShortName": "Albanie (l')",
//       "alpha2Code": "AL",
//       "alpha3Code": "ALB",
//       "numeric": 8
//     },
//     {
//       "englishShortName": "Algeria",
//       "frenchShortName": "Algérie (l')",
//       "alpha2Code": "DZ",
//       "alpha3Code": "DZA",
//       "numeric": 12
//     },
//     {
//       "englishShortName": "American Samoa",
//       "frenchShortName": "Samoa américaines (les)",
//       "alpha2Code": "AS",
//       "alpha3Code": "ASM",
//       "numeric": 16
//     },
//     {
//       "englishShortName": "Andorra",
//       "frenchShortName": "Andorre (l')",
//       "alpha2Code": "AD",
//       "alpha3Code": "AND",
//       "numeric": 20
//     },
//     {
//       "englishShortName": "Angola",
//       "frenchShortName": "Angola (l')",
//       "alpha2Code": "AO",
//       "alpha3Code": "AGO",
//       "numeric": 24
//     },
//     {
//       "englishShortName": "Anguilla",
//       "frenchShortName": "Anguilla",
//       "alpha2Code": "AI",
//       "alpha3Code": "AIA",
//       "numeric": 660
//     },
//     {
//       "englishShortName": "Antarctica",
//       "frenchShortName": "Antarctique (l')",
//       "alpha2Code": "AQ",
//       "alpha3Code": "ATA",
//       "numeric": 10
//     },
//     {
//       "englishShortName": "Antigua and Barbuda",
//       "frenchShortName": "Antigua-et-Barbuda",
//       "alpha2Code": "AG",
//       "alpha3Code": "ATG",
//       "numeric": 28
//     },
//     {
//       "englishShortName": "Argentina",
//       "frenchShortName": "Argentine (l')",
//       "alpha2Code": "AR",
//       "alpha3Code": "ARG",
//       "numeric": 32
//     },
//     {
//       "englishShortName": "Armenia",
//       "frenchShortName": "Arménie (l')",
//       "alpha2Code": "AM",
//       "alpha3Code": "ARM",
//       "numeric": 51
//     },
//     {
//       "englishShortName": "Aruba",
//       "frenchShortName": "Aruba",
//       "alpha2Code": "AW",
//       "alpha3Code": "ABW",
//       "numeric": 533
//     },
//     {
//       "englishShortName": "Australia",
//       "frenchShortName": "Australie (l')",
//       "alpha2Code": "AU",
//       "alpha3Code": "AUS",
//       "numeric": 36
//     },
//     {
//       "englishShortName": "Austria",
//       "frenchShortName": "Autriche (l')",
//       "alpha2Code": "AT",
//       "alpha3Code": "AUT",
//       "numeric": 40
//     },
//     {
//       "englishShortName": "Azerbaijan",
//       "frenchShortName": "Azerbaïdjan (l')",
//       "alpha2Code": "AZ",
//       "alpha3Code": "AZE",
//       "numeric": 31
//     },
//     {
//       "englishShortName": "Bahamas (the)",
//       "frenchShortName": "Bahamas (les)",
//       "alpha2Code": "BS",
//       "alpha3Code": "BHS",
//       "numeric": 44
//     },
//     {
//       "englishShortName": "Bahrain",
//       "frenchShortName": "Bahreïn",
//       "alpha2Code": "BH",
//       "alpha3Code": "BHR",
//       "numeric": 48
//     },
//     {
//       "englishShortName": "Bangladesh",
//       "frenchShortName": "Bangladesh (le)",
//       "alpha2Code": "BD",
//       "alpha3Code": "BGD",
//       "numeric": 50
//     },
//     {
//       "englishShortName": "Barbados",
//       "frenchShortName": "Barbade (la)",
//       "alpha2Code": "BB",
//       "alpha3Code": "BRB",
//       "numeric": 52
//     },
//     {
//       "englishShortName": "Belarus",
//       "frenchShortName": "Bélarus (le)",
//       "alpha2Code": "BY",
//       "alpha3Code": "BLR",
//       "numeric": 112
//     },
//     {
//       "englishShortName": "Belgium",
//       "frenchShortName": "Belgique (la)",
//       "alpha2Code": "BE",
//       "alpha3Code": "BEL",
//       "numeric": 56
//     },
//     {
//       "englishShortName": "Belize",
//       "frenchShortName": "Belize (le)",
//       "alpha2Code": "BZ",
//       "alpha3Code": "BLZ",
//       "numeric": 84
//     },
//     {
//       "englishShortName": "Benin",
//       "frenchShortName": "Bénin (le)",
//       "alpha2Code": "BJ",
//       "alpha3Code": "BEN",
//       "numeric": 204
//     },
//     {
//       "englishShortName": "Bermuda",
//       "frenchShortName": "Bermudes (les)",
//       "alpha2Code": "BM",
//       "alpha3Code": "BMU",
//       "numeric": 60
//     },
//     {
//       "englishShortName": "Bhutan",
//       "frenchShortName": "Bhoutan (le)",
//       "alpha2Code": "BT",
//       "alpha3Code": "BTN",
//       "numeric": 64
//     },
//     {
//       "englishShortName": "Bolivia (Plurinational State of)",
//       "frenchShortName": "Bolivie (État plurinational de)",
//       "alpha2Code": "BO",
//       "alpha3Code": "BOL",
//       "numeric": 68
//     },
//     {
//       "englishShortName": "Bonaire, Sint Eustatius and Saba",
//       "frenchShortName": "Bonaire, Saint-Eustache et Saba",
//       "alpha2Code": "BQ",
//       "alpha3Code": "BES",
//       "numeric": 535
//     },
//     {
//       "englishShortName": "Bosnia and Herzegovina",
//       "frenchShortName": "Bosnie-Herzégovine (la)",
//       "alpha2Code": "BA",
//       "alpha3Code": "BIH",
//       "numeric": 70
//     },
//     {
//       "englishShortName": "Botswana",
//       "frenchShortName": "Botswana (le)",
//       "alpha2Code": "BW",
//       "alpha3Code": "BWA",
//       "numeric": 72
//     },
//     {
//       "englishShortName": "Bouvet Island",
//       "frenchShortName": "Bouvet (l'Île)",
//       "alpha2Code": "BV",
//       "alpha3Code": "BVT",
//       "numeric": 74
//     },
//     {
//       "englishShortName": "Brazil",
//       "frenchShortName": "Brésil (le)",
//       "alpha2Code": "BR",
//       "alpha3Code": "BRA",
//       "numeric": 76
//     },
//     {
//       "englishShortName": "British Indian Ocean Territory (the)",
//       "frenchShortName": "Indien (le Territoire britannique de l'océan)",
//       "alpha2Code": "IO",
//       "alpha3Code": "IOT",
//       "numeric": 86
//     },
//     {
//       "englishShortName": "Brunei Darussalam",
//       "frenchShortName": "Brunéi Darussalam (le)",
//       "alpha2Code": "BN",
//       "alpha3Code": "BRN",
//       "numeric": 96
//     },
//     {
//       "englishShortName": "Bulgaria",
//       "frenchShortName": "Bulgarie (la)",
//       "alpha2Code": "BG",
//       "alpha3Code": "BGR",
//       "numeric": 100
//     },
//     {
//       "englishShortName": "Burkina Faso",
//       "frenchShortName": "Burkina Faso (le)",
//       "alpha2Code": "BF",
//       "alpha3Code": "BFA",
//       "numeric": 854
//     },
//     {
//       "englishShortName": "Burundi",
//       "frenchShortName": "Burundi (le)",
//       "alpha2Code": "BI",
//       "alpha3Code": "BDI",
//       "numeric": 108
//     },
//     {
//       "englishShortName": "Cabo Verde",
//       "frenchShortName": "Cabo Verde",
//       "alpha2Code": "CV",
//       "alpha3Code": "CPV",
//       "numeric": 132
//     },
//     {
//       "englishShortName": "Cambodia",
//       "frenchShortName": "Cambodge (le)",
//       "alpha2Code": "KH",
//       "alpha3Code": "KHM",
//       "numeric": 116
//     },
//     {
//       "englishShortName": "Cameroon",
//       "frenchShortName": "Cameroun (le)",
//       "alpha2Code": "CM",
//       "alpha3Code": "CMR",
//       "numeric": 120
//     },
//     {
//       "englishShortName": "Canada",
//       "frenchShortName": "Canada (le)",
//       "alpha2Code": "CA",
//       "alpha3Code": "CAN",
//       "numeric": 124
//     },
//     {
//       "englishShortName": "Cayman Islands (the)",
//       "frenchShortName": "Caïmans (les Îles)",
//       "alpha2Code": "KY",
//       "alpha3Code": "CYM",
//       "numeric": 136
//     },
//     {
//       "englishShortName": "Central African Republic (the)",
//       "frenchShortName": "République centrafricaine (la)",
//       "alpha2Code": "CF",
//       "alpha3Code": "CAF",
//       "numeric": 140
//     },
//     {
//       "englishShortName": "Chad",
//       "frenchShortName": "Tchad (le)",
//       "alpha2Code": "TD",
//       "alpha3Code": "TCD",
//       "numeric": 148
//     },
//     {
//       "englishShortName": "Chile",
//       "frenchShortName": "Chili (le)",
//       "alpha2Code": "CL",
//       "alpha3Code": "CHL",
//       "numeric": 152
//     },
//     {
//       "englishShortName": "China",
//       "frenchShortName": "Chine (la)",
//       "alpha2Code": "CN",
//       "alpha3Code": "CHN",
//       "numeric": 156
//     },
//     {
//       "englishShortName": "Christmas Island",
//       "frenchShortName": "Christmas (l'Île)",
//       "alpha2Code": "CX",
//       "alpha3Code": "CXR",
//       "numeric": 162
//     },
//     {
//       "englishShortName": "Cocos (Keeling) Islands (the)",
//       "frenchShortName": "Cocos (les Îles)/ Keeling (les Îles)",
//       "alpha2Code": "CC",
//       "alpha3Code": "CCK",
//       "numeric": 166
//     },
//     {
//       "englishShortName": "Colombia",
//       "frenchShortName": "Colombie (la)",
//       "alpha2Code": "CO",
//       "alpha3Code": "COL",
//       "numeric": 170
//     },
//     {
//       "englishShortName": "Comoros (the)",
//       "frenchShortName": "Comores (les)",
//       "alpha2Code": "KM",
//       "alpha3Code": "COM",
//       "numeric": 174
//     },
//     {
//       "englishShortName": "Congo (the Democratic Republic of the)",
//       "frenchShortName": "Congo (la République démocratique du)",
//       "alpha2Code": "CD",
//       "alpha3Code": "COD",
//       "numeric": 180
//     },
//     {
//       "englishShortName": "Congo (the)",
//       "frenchShortName": "Congo (le)",
//       "alpha2Code": "CG",
//       "alpha3Code": "COG",
//       "numeric": 178
//     },
//     {
//       "englishShortName": "Cook Islands (the)",
//       "frenchShortName": "Cook (les Îles)",
//       "alpha2Code": "CK",
//       "alpha3Code": "COK",
//       "numeric": 184
//     },
//     {
//       "englishShortName": "Costa Rica",
//       "frenchShortName": "Costa Rica (le)",
//       "alpha2Code": "CR",
//       "alpha3Code": "CRI",
//       "numeric": 188
//     },
//     {
//       "englishShortName": "Croatia",
//       "frenchShortName": "Croatie (la)",
//       "alpha2Code": "HR",
//       "alpha3Code": "HRV",
//       "numeric": 191
//     },
//     {
//       "englishShortName": "Cuba",
//       "frenchShortName": "Cuba",
//       "alpha2Code": "CU",
//       "alpha3Code": "CUB",
//       "numeric": 192
//     },
//     {
//       "englishShortName": "Curaçao",
//       "frenchShortName": "Curaçao",
//       "alpha2Code": "CW",
//       "alpha3Code": "CUW",
//       "numeric": 531
//     },
//     {
//       "englishShortName": "Cyprus",
//       "frenchShortName": "Chypre",
//       "alpha2Code": "CY",
//       "alpha3Code": "CYP",
//       "numeric": 196
//     },
//     {
//       "englishShortName": "Czechia",
//       "frenchShortName": "Tchéquie (la)",
//       "alpha2Code": "CZ",
//       "alpha3Code": "CZE",
//       "numeric": 203
//     },
//     {
//       "englishShortName": "Côte d'Ivoire",
//       "frenchShortName": "Côte d'Ivoire (la)",
//       "alpha2Code": "CI",
//       "alpha3Code": "CIV",
//       "numeric": 384
//     },
//     {
//       "englishShortName": "Denmark",
//       "frenchShortName": "Danemark (le)",
//       "alpha2Code": "DK",
//       "alpha3Code": "DNK",
//       "numeric": 208
//     },
//     {
//       "englishShortName": "Djibouti",
//       "frenchShortName": "Djibouti",
//       "alpha2Code": "DJ",
//       "alpha3Code": "DJI",
//       "numeric": 262
//     },
//     {
//       "englishShortName": "Dominica",
//       "frenchShortName": "Dominique (la)",
//       "alpha2Code": "DM",
//       "alpha3Code": "DMA",
//       "numeric": 212
//     },
//     {
//       "englishShortName": "Dominican Republic (the)",
//       "frenchShortName": "dominicaine (la République)",
//       "alpha2Code": "DO",
//       "alpha3Code": "DOM",
//       "numeric": 214
//     },
//     {
//       "englishShortName": "Ecuador",
//       "frenchShortName": "Équateur (l')",
//       "alpha2Code": "EC",
//       "alpha3Code": "ECU",
//       "numeric": 218
//     },
//     {
//       "englishShortName": "Egypt",
//       "frenchShortName": "Égypte (l')",
//       "alpha2Code": "EG",
//       "alpha3Code": "EGY",
//       "numeric": 818
//     },
//     {
//       "englishShortName": "El Salvador",
//       "frenchShortName": "El Salvador",
//       "alpha2Code": "SV",
//       "alpha3Code": "SLV",
//       "numeric": 222
//     },
//     {
//       "englishShortName": "Equatorial Guinea",
//       "frenchShortName": "Guinée équatoriale (la)",
//       "alpha2Code": "GQ",
//       "alpha3Code": "GNQ",
//       "numeric": 226
//     },
//     {
//       "englishShortName": "Eritrea",
//       "frenchShortName": "Érythrée (l')",
//       "alpha2Code": "ER",
//       "alpha3Code": "ERI",
//       "numeric": 232
//     },
//     {
//       "englishShortName": "Estonia",
//       "frenchShortName": "Estonie (l')",
//       "alpha2Code": "EE",
//       "alpha3Code": "EST",
//       "numeric": 233
//     },
//     {
//       "englishShortName": "Eswatini",
//       "frenchShortName": "Eswatini (l')",
//       "alpha2Code": "SZ",
//       "alpha3Code": "SWZ",
//       "numeric": 748
//     },
//     {
//       "englishShortName": "Ethiopia",
//       "frenchShortName": "Éthiopie (l')",
//       "alpha2Code": "ET",
//       "alpha3Code": "ETH",
//       "numeric": 231
//     },
//     {
//       "englishShortName": "Falkland Islands (the) [Malvinas]",
//       "frenchShortName": "Falkland (les Îles)/Malouines (les Îles)",
//       "alpha2Code": "FK",
//       "alpha3Code": "FLK",
//       "numeric": 238
//     },
//     {
//       "englishShortName": "Faroe Islands (the)",
//       "frenchShortName": "Féroé (les Îles)",
//       "alpha2Code": "FO",
//       "alpha3Code": "FRO",
//       "numeric": 234
//     },
//     {
//       "englishShortName": "Fiji",
//       "frenchShortName": "Fidji (les)",
//       "alpha2Code": "FJ",
//       "alpha3Code": "FJI",
//       "numeric": 242
//     },
//     {
//       "englishShortName": "Finland",
//       "frenchShortName": "Finlande (la)",
//       "alpha2Code": "FI",
//       "alpha3Code": "FIN",
//       "numeric": 246
//     },
//     {
//       "englishShortName": "France",
//       "frenchShortName": "France (la)",
//       "alpha2Code": "FR",
//       "alpha3Code": "FRA",
//       "numeric": 250
//     },
//     {
//       "englishShortName": "French Guiana",
//       "frenchShortName": "Guyane française (la )",
//       "alpha2Code": "GF",
//       "alpha3Code": "GUF",
//       "numeric": 254
//     },
//     {
//       "englishShortName": "French Polynesia",
//       "frenchShortName": "Polynésie française (la)",
//       "alpha2Code": "PF",
//       "alpha3Code": "PYF",
//       "numeric": 258
//     },
//     {
//       "englishShortName": "French Southern Territories (the)",
//       "frenchShortName": "Terres australes françaises (les)",
//       "alpha2Code": "TF",
//       "alpha3Code": "ATF",
//       "numeric": 260
//     },
//     {
//       "englishShortName": "Gabon",
//       "frenchShortName": "Gabon (le)",
//       "alpha2Code": "GA",
//       "alpha3Code": "GAB",
//       "numeric": 266
//     },
//     {
//       "englishShortName": "Gambia (the)",
//       "frenchShortName": "Gambie (la)",
//       "alpha2Code": "GM",
//       "alpha3Code": "GMB",
//       "numeric": 270
//     },
//     {
//       "englishShortName": "Georgia",
//       "frenchShortName": "Géorgie (la)",
//       "alpha2Code": "GE",
//       "alpha3Code": "GEO",
//       "numeric": 268
//     },
//     {
//       "englishShortName": "Germany",
//       "frenchShortName": "Allemagne (l')",
//       "alpha2Code": "DE",
//       "alpha3Code": "DEU",
//       "numeric": 276
//     },
//     {
//       "englishShortName": "Ghana",
//       "frenchShortName": "Ghana (le)",
//       "alpha2Code": "GH",
//       "alpha3Code": "GHA",
//       "numeric": 288
//     },
//     {
//       "englishShortName": "Gibraltar",
//       "frenchShortName": "Gibraltar",
//       "alpha2Code": "GI",
//       "alpha3Code": "GIB",
//       "numeric": 292
//     },
//     {
//       "englishShortName": "Greece",
//       "frenchShortName": "Grèce (la)",
//       "alpha2Code": "GR",
//       "alpha3Code": "GRC",
//       "numeric": 300
//     },
//     {
//       "englishShortName": "Greenland",
//       "frenchShortName": "Groenland (le)",
//       "alpha2Code": "GL",
//       "alpha3Code": "GRL",
//       "numeric": 304
//     },
//     {
//       "englishShortName": "Grenada",
//       "frenchShortName": "Grenade (la)",
//       "alpha2Code": "GD",
//       "alpha3Code": "GRD",
//       "numeric": 308
//     },
//     {
//       "englishShortName": "Guadeloupe",
//       "frenchShortName": "Guadeloupe (la)",
//       "alpha2Code": "GP",
//       "alpha3Code": "GLP",
//       "numeric": 312
//     },
//     {
//       "englishShortName": "Guam",
//       "frenchShortName": "Guam",
//       "alpha2Code": "GU",
//       "alpha3Code": "GUM",
//       "numeric": 316
//     },
//     {
//       "englishShortName": "Guatemala",
//       "frenchShortName": "Guatemala (le)",
//       "alpha2Code": "GT",
//       "alpha3Code": "GTM",
//       "numeric": 320
//     },
//     {
//       "englishShortName": "Guernsey",
//       "frenchShortName": "Guernesey",
//       "alpha2Code": "GG",
//       "alpha3Code": "GGY",
//       "numeric": 831
//     },
//     {
//       "englishShortName": "Guinea",
//       "frenchShortName": "Guinée (la)",
//       "alpha2Code": "GN",
//       "alpha3Code": "GIN",
//       "numeric": 324
//     },
//     {
//       "englishShortName": "Guinea-Bissau",
//       "frenchShortName": "Guinée-Bissau (la)",
//       "alpha2Code": "GW",
//       "alpha3Code": "GNB",
//       "numeric": 624
//     },
//     {
//       "englishShortName": "Guyana",
//       "frenchShortName": "Guyana (le)",
//       "alpha2Code": "GY",
//       "alpha3Code": "GUY",
//       "numeric": 328
//     },
//     {
//       "englishShortName": "Haiti",
//       "frenchShortName": "Haïti",
//       "alpha2Code": "HT",
//       "alpha3Code": "HTI",
//       "numeric": 332
//     },
//     {
//       "englishShortName": "Heard Island and McDonald Islands",
//       "frenchShortName": "Heard-et-Îles MacDonald (l'Île)",
//       "alpha2Code": "HM",
//       "alpha3Code": "HMD",
//       "numeric": 334
//     },
//     {
//       "englishShortName": "Holy See (the)",
//       "frenchShortName": "Saint-Siège (le)",
//       "alpha2Code": "VA",
//       "alpha3Code": "VAT",
//       "numeric": 336
//     },
//     {
//       "englishShortName": "Honduras",
//       "frenchShortName": "Honduras (le)",
//       "alpha2Code": "HN",
//       "alpha3Code": "HND",
//       "numeric": 340
//     },
//     {
//       "englishShortName": "Hong Kong",
//       "frenchShortName": "Hong Kong",
//       "alpha2Code": "HK",
//       "alpha3Code": "HKG",
//       "numeric": 344
//     },
//     {
//       "englishShortName": "Hungary",
//       "frenchShortName": "Hongrie (la)",
//       "alpha2Code": "HU",
//       "alpha3Code": "HUN",
//       "numeric": 348
//     },
//     {
//       "englishShortName": "Iceland",
//       "frenchShortName": "Islande (l')",
//       "alpha2Code": "IS",
//       "alpha3Code": "ISL",
//       "numeric": 352
//     },
//     {
//       "englishShortName": "India",
//       "frenchShortName": "Inde (l')",
//       "alpha2Code": "IN",
//       "alpha3Code": "IND",
//       "numeric": 356
//     },
//     {
//       "englishShortName": "Indonesia",
//       "frenchShortName": "Indonésie (l')",
//       "alpha2Code": "ID",
//       "alpha3Code": "IDN",
//       "numeric": 360
//     },
//     {
//       "englishShortName": "Iran (Islamic Republic of)",
//       "frenchShortName": "Iran (République Islamique d')",
//       "alpha2Code": "IR",
//       "alpha3Code": "IRN",
//       "numeric": 364
//     },
//     {
//       "englishShortName": "Iraq",
//       "frenchShortName": "Iraq (l')",
//       "alpha2Code": "IQ",
//       "alpha3Code": "IRQ",
//       "numeric": 368
//     },
//     {
//       "englishShortName": "Ireland",
//       "frenchShortName": "Irlande (l')",
//       "alpha2Code": "IE",
//       "alpha3Code": "IRL",
//       "numeric": 372
//     },
//     {
//       "englishShortName": "Isle of Man",
//       "frenchShortName": "Île de Man",
//       "alpha2Code": "IM",
//       "alpha3Code": "IMN",
//       "numeric": 833
//     },
//     {
//       "englishShortName": "Israel",
//       "frenchShortName": "Israël",
//       "alpha2Code": "IL",
//       "alpha3Code": "ISR",
//       "numeric": 376
//     },
//     {
//       "englishShortName": "Italy",
//       "frenchShortName": "Italie (l')",
//       "alpha2Code": "IT",
//       "alpha3Code": "ITA",
//       "numeric": 380
//     },
//     {
//       "englishShortName": "Jamaica",
//       "frenchShortName": "Jamaïque (la)",
//       "alpha2Code": "JM",
//       "alpha3Code": "JAM",
//       "numeric": 388
//     },
//     {
//       "englishShortName": "Japan",
//       "frenchShortName": "Japon (le)",
//       "alpha2Code": "JP",
//       "alpha3Code": "JPN",
//       "numeric": 392
//     },
//     {
//       "englishShortName": "Jersey",
//       "frenchShortName": "Jersey",
//       "alpha2Code": "JE",
//       "alpha3Code": "JEY",
//       "numeric": 832
//     },
//     {
//       "englishShortName": "Jordan",
//       "frenchShortName": "Jordanie (la)",
//       "alpha2Code": "JO",
//       "alpha3Code": "JOR",
//       "numeric": 400
//     },
//     {
//       "englishShortName": "Kazakhstan",
//       "frenchShortName": "Kazakhstan (le)",
//       "alpha2Code": "KZ",
//       "alpha3Code": "KAZ",
//       "numeric": 398
//     },
//     {
//       "englishShortName": "Kenya",
//       "frenchShortName": "Kenya (le)",
//       "alpha2Code": "KE",
//       "alpha3Code": "KEN",
//       "numeric": 404
//     },
//     {
//       "englishShortName": "Kiribati",
//       "frenchShortName": "Kiribati",
//       "alpha2Code": "KI",
//       "alpha3Code": "KIR",
//       "numeric": 296
//     },
//     {
//       "englishShortName": "Korea (the Democratic People's Republic of)",
//       "frenchShortName": "Corée (la République populaire démocratique de)",
//       "alpha2Code": "KP",
//       "alpha3Code": "PRK",
//       "numeric": 408
//     },
//     {
//       "englishShortName": "Korea (the Republic of)",
//       "frenchShortName": "Corée (la République de)",
//       "alpha2Code": "KR",
//       "alpha3Code": "KOR",
//       "numeric": 410
//     },
//     {
//       "englishShortName": "Kuwait",
//       "frenchShortName": "Koweït (le)",
//       "alpha2Code": "KW",
//       "alpha3Code": "KWT",
//       "numeric": 414
//     },
//     {
//       "englishShortName": "Kyrgyzstan",
//       "frenchShortName": "Kirghizistan (le)",
//       "alpha2Code": "KG",
//       "alpha3Code": "KGZ",
//       "numeric": 417
//     },
//     {
//       "englishShortName": "Lao People's Democratic Republic (the)",
//       "frenchShortName": "Lao (la République démocratique populaire)",
//       "alpha2Code": "LA",
//       "alpha3Code": "LAO",
//       "numeric": 418
//     },
//     {
//       "englishShortName": "Latvia",
//       "frenchShortName": "Lettonie (la)",
//       "alpha2Code": "LV",
//       "alpha3Code": "LVA",
//       "numeric": 428
//     },
//     {
//       "englishShortName": "Lebanon",
//       "frenchShortName": "Liban (le)",
//       "alpha2Code": "LB",
//       "alpha3Code": "LBN",
//       "numeric": 422
//     },
//     {
//       "englishShortName": "Lesotho",
//       "frenchShortName": "Lesotho (le)",
//       "alpha2Code": "LS",
//       "alpha3Code": "LSO",
//       "numeric": 426
//     },
//     {
//       "englishShortName": "Liberia",
//       "frenchShortName": "Libéria (le)",
//       "alpha2Code": "LR",
//       "alpha3Code": "LBR",
//       "numeric": 430
//     },
//     {
//       "englishShortName": "Libya",
//       "frenchShortName": "Libye (la)",
//       "alpha2Code": "LY",
//       "alpha3Code": "LBY",
//       "numeric": 434
//     },
//     {
//       "englishShortName": "Liechtenstein",
//       "frenchShortName": "Liechtenstein (le)",
//       "alpha2Code": "LI",
//       "alpha3Code": "LIE",
//       "numeric": 438
//     },
//     {
//       "englishShortName": "Lithuania",
//       "frenchShortName": "Lituanie (la)",
//       "alpha2Code": "LT",
//       "alpha3Code": "LTU",
//       "numeric": 440
//     },
//     {
//       "englishShortName": "Luxembourg",
//       "frenchShortName": "Luxembourg (le)",
//       "alpha2Code": "LU",
//       "alpha3Code": "LUX",
//       "numeric": 442
//     },
//     {
//       "englishShortName": "Macao",
//       "frenchShortName": "Macao",
//       "alpha2Code": "MO",
//       "alpha3Code": "MAC",
//       "numeric": 446
//     },
//     {
//       "englishShortName": "Madagascar",
//       "frenchShortName": "Madagascar",
//       "alpha2Code": "MG",
//       "alpha3Code": "MDG",
//       "numeric": 450
//     },
//     {
//       "englishShortName": "Malawi",
//       "frenchShortName": "Malawi (le)",
//       "alpha2Code": "MW",
//       "alpha3Code": "MWI",
//       "numeric": 454
//     },
//     {
//       "englishShortName": "Malaysia",
//       "frenchShortName": "Malaisie (la)",
//       "alpha2Code": "MY",
//       "alpha3Code": "MYS",
//       "numeric": 458
//     },
//     {
//       "englishShortName": "Maldives",
//       "frenchShortName": "Maldives (les)",
//       "alpha2Code": "MV",
//       "alpha3Code": "MDV",
//       "numeric": 462
//     },
//     {
//       "englishShortName": "Mali",
//       "frenchShortName": "Mali (le)",
//       "alpha2Code": "ML",
//       "alpha3Code": "MLI",
//       "numeric": 466
//     },
//     {
//       "englishShortName": "Malta",
//       "frenchShortName": "Malte",
//       "alpha2Code": "MT",
//       "alpha3Code": "MLT",
//       "numeric": 470
//     },
//     {
//       "englishShortName": "Marshall Islands (the)",
//       "frenchShortName": "Marshall (les Îles)",
//       "alpha2Code": "MH",
//       "alpha3Code": "MHL",
//       "numeric": 584
//     },
//     {
//       "englishShortName": "Martinique",
//       "frenchShortName": "Martinique (la)",
//       "alpha2Code": "MQ",
//       "alpha3Code": "MTQ",
//       "numeric": 474
//     },
//     {
//       "englishShortName": "Mauritania",
//       "frenchShortName": "Mauritanie (la)",
//       "alpha2Code": "MR",
//       "alpha3Code": "MRT",
//       "numeric": 478
//     },
//     {
//       "englishShortName": "Mauritius",
//       "frenchShortName": "Maurice",
//       "alpha2Code": "MU",
//       "alpha3Code": "MUS",
//       "numeric": 480
//     },
//     {
//       "englishShortName": "Mayotte",
//       "frenchShortName": "Mayotte",
//       "alpha2Code": "YT",
//       "alpha3Code": "MYT",
//       "numeric": 175
//     },
//     {
//       "englishShortName": "Mexico",
//       "frenchShortName": "Mexique (le)",
//       "alpha2Code": "MX",
//       "alpha3Code": "MEX",
//       "numeric": 484
//     },
//     {
//       "englishShortName": "Micronesia (Federated States of)",
//       "frenchShortName": "Micronésie (États fédérés de)",
//       "alpha2Code": "FM",
//       "alpha3Code": "FSM",
//       "numeric": 583
//     },
//     {
//       "englishShortName": "Moldova (the Republic of)",
//       "frenchShortName": "Moldova (la République de)",
//       "alpha2Code": "MD",
//       "alpha3Code": "MDA",
//       "numeric": 498
//     },
//     {
//       "englishShortName": "Monaco",
//       "frenchShortName": "Monaco",
//       "alpha2Code": "MC",
//       "alpha3Code": "MCO",
//       "numeric": 492
//     },
//     {
//       "englishShortName": "Mongolia",
//       "frenchShortName": "Mongolie (la)",
//       "alpha2Code": "MN",
//       "alpha3Code": "MNG",
//       "numeric": 496
//     },
//     {
//       "englishShortName": "Montenegro",
//       "frenchShortName": "Monténégro (le)",
//       "alpha2Code": "ME",
//       "alpha3Code": "MNE",
//       "numeric": 499
//     },
//     {
//       "englishShortName": "Montserrat",
//       "frenchShortName": "Montserrat",
//       "alpha2Code": "MS",
//       "alpha3Code": "MSR",
//       "numeric": 500
//     },
//     {
//       "englishShortName": "Morocco",
//       "frenchShortName": "Maroc (le)",
//       "alpha2Code": "MA",
//       "alpha3Code": "MAR",
//       "numeric": 504
//     },
//     {
//       "englishShortName": "Mozambique",
//       "frenchShortName": "Mozambique (le)",
//       "alpha2Code": "MZ",
//       "alpha3Code": "MOZ",
//       "numeric": 508
//     },
//     {
//       "englishShortName": "Myanmar",
//       "frenchShortName": "Myanmar (le)",
//       "alpha2Code": "MM",
//       "alpha3Code": "MMR",
//       "numeric": 104
//     },
//     {
//       "englishShortName": "Namibia",
//       "frenchShortName": "Namibie (la)",
//       "alpha2Code": "NA",
//       "alpha3Code": "NAM",
//       "numeric": 516
//     },
//     {
//       "englishShortName": "Nauru",
//       "frenchShortName": "Nauru",
//       "alpha2Code": "NR",
//       "alpha3Code": "NRU",
//       "numeric": 520
//     },
//     {
//       "englishShortName": "Nepal",
//       "frenchShortName": "Népal (le)",
//       "alpha2Code": "NP",
//       "alpha3Code": "NPL",
//       "numeric": 524
//     },
//     {
//       "englishShortName": "Netherlands (the)",
//       "frenchShortName": "Pays-Bas (les)",
//       "alpha2Code": "NL",
//       "alpha3Code": "NLD",
//       "numeric": 528
//     },
//     {
//       "englishShortName": "New Caledonia",
//       "frenchShortName": "Nouvelle-Calédonie (la)",
//       "alpha2Code": "NC",
//       "alpha3Code": "NCL",
//       "numeric": 540
//     },
//     {
//       "englishShortName": "New Zealand",
//       "frenchShortName": "Nouvelle-Zélande (la)",
//       "alpha2Code": "NZ",
//       "alpha3Code": "NZL",
//       "numeric": 554
//     },
//     {
//       "englishShortName": "Nicaragua",
//       "frenchShortName": "Nicaragua (le)",
//       "alpha2Code": "NI",
//       "alpha3Code": "NIC",
//       "numeric": 558
//     },
//     {
//       "englishShortName": "Niger (the)",
//       "frenchShortName": "Niger (le)",
//       "alpha2Code": "NE",
//       "alpha3Code": "NER",
//       "numeric": 562
//     },
//     {
//       "englishShortName": "Nigeria",
//       "frenchShortName": "Nigéria (le)",
//       "alpha2Code": "NG",
//       "alpha3Code": "NGA",
//       "numeric": 566
//     },
//     {
//       "englishShortName": "Niue",
//       "frenchShortName": "Niue",
//       "alpha2Code": "NU",
//       "alpha3Code": "NIU",
//       "numeric": 570
//     },
//     {
//       "englishShortName": "Norfolk Island",
//       "frenchShortName": "Norfolk (l'Île)",
//       "alpha2Code": "NF",
//       "alpha3Code": "NFK",
//       "numeric": 574
//     },
//     {
//       "englishShortName": "North Macedonia",
//       "frenchShortName": "Macédoine du Nord (la)",
//       "alpha2Code": "MK",
//       "alpha3Code": "MKD",
//       "numeric": 807
//     },
//     {
//       "englishShortName": "Northern Mariana Islands (the)",
//       "frenchShortName": "Mariannes du Nord (les Îles)",
//       "alpha2Code": "MP",
//       "alpha3Code": "MNP",
//       "numeric": 580
//     },
//     {
//       "englishShortName": "Norway",
//       "frenchShortName": "Norvège (la)",
//       "alpha2Code": "NO",
//       "alpha3Code": "NOR",
//       "numeric": 578
//     },
//     {
//       "englishShortName": "Oman",
//       "frenchShortName": "Oman",
//       "alpha2Code": "OM",
//       "alpha3Code": "OMN",
//       "numeric": 512
//     },
//     {
//       "englishShortName": "Pakistan",
//       "frenchShortName": "Pakistan (le)",
//       "alpha2Code": "PK",
//       "alpha3Code": "PAK",
//       "numeric": 586
//     },
//     {
//       "englishShortName": "Palau",
//       "frenchShortName": "Palaos (les)",
//       "alpha2Code": "PW",
//       "alpha3Code": "PLW",
//       "numeric": 585
//     },
//     {
//       "englishShortName": "Palestine, State of",
//       "frenchShortName": "Palestine, État de",
//       "alpha2Code": "PS",
//       "alpha3Code": "PSE",
//       "numeric": 275
//     },
//     {
//       "englishShortName": "Panama",
//       "frenchShortName": "Panama (le)",
//       "alpha2Code": "PA",
//       "alpha3Code": "PAN",
//       "numeric": 591
//     },
//     {
//       "englishShortName": "Papua New Guinea",
//       "frenchShortName": "Papouasie-Nouvelle-Guinée (la)",
//       "alpha2Code": "PG",
//       "alpha3Code": "PNG",
//       "numeric": 598
//     },
//     {
//       "englishShortName": "Paraguay",
//       "frenchShortName": "Paraguay (le)",
//       "alpha2Code": "PY",
//       "alpha3Code": "PRY",
//       "numeric": 600
//     },
//     {
//       "englishShortName": "Peru",
//       "frenchShortName": "Pérou (le)",
//       "alpha2Code": "PE",
//       "alpha3Code": "PER",
//       "numeric": 604
//     },
//     {
//       "englishShortName": "Philippines (the)",
//       "frenchShortName": "Philippines (les)",
//       "alpha2Code": "PH",
//       "alpha3Code": "PHL",
//       "numeric": 608
//     },
//     {
//       "englishShortName": "Pitcairn",
//       "frenchShortName": "Pitcairn",
//       "alpha2Code": "PN",
//       "alpha3Code": "PCN",
//       "numeric": 612
//     },
//     {
//       "englishShortName": "Poland",
//       "frenchShortName": "Pologne (la)",
//       "alpha2Code": "PL",
//       "alpha3Code": "POL",
//       "numeric": 616
//     },
//     {
//       "englishShortName": "Portugal",
//       "frenchShortName": "Portugal (le)",
//       "alpha2Code": "PT",
//       "alpha3Code": "PRT",
//       "numeric": 620
//     },
//     {
//       "englishShortName": "Puerto Rico",
//       "frenchShortName": "Porto Rico",
//       "alpha2Code": "PR",
//       "alpha3Code": "PRI",
//       "numeric": 630
//     },
//     {
//       "englishShortName": "Qatar",
//       "frenchShortName": "Qatar (le)",
//       "alpha2Code": "QA",
//       "alpha3Code": "QAT",
//       "numeric": 634
//     },
//     {
//       "englishShortName": "Romania",
//       "frenchShortName": "Roumanie (la)",
//       "alpha2Code": "RO",
//       "alpha3Code": "ROU",
//       "numeric": 642
//     },
//     {
//       "englishShortName": "Russian Federation (the)",
//       "frenchShortName": "Russie (la Fédération de)",
//       "alpha2Code": "RU",
//       "alpha3Code": "RUS",
//       "numeric": 643
//     },
//     {
//       "englishShortName": "Rwanda",
//       "frenchShortName": "Rwanda (le)",
//       "alpha2Code": "RW",
//       "alpha3Code": "RWA",
//       "numeric": 646
//     },
//     {
//       "englishShortName": "Réunion",
//       "frenchShortName": "Réunion (La)",
//       "alpha2Code": "RE",
//       "alpha3Code": "REU",
//       "numeric": 638
//     },
//     {
//       "englishShortName": "Saint Barthélemy",
//       "frenchShortName": "Saint-Barthélemy",
//       "alpha2Code": "BL",
//       "alpha3Code": "BLM",
//       "numeric": 652
//     },
//     {
//       "englishShortName": "Saint Helena, Ascension and Tristan da Cunha",
//       "frenchShortName": "Sainte-Hélène, Ascension et Tristan da Cunha",
//       "alpha2Code": "SH",
//       "alpha3Code": "SHN",
//       "numeric": 654
//     },
//     {
//       "englishShortName": "Saint Kitts and Nevis",
//       "frenchShortName": "Saint-Kitts-et-Nevis",
//       "alpha2Code": "KN",
//       "alpha3Code": "KNA",
//       "numeric": 659
//     },
//     {
//       "englishShortName": "Saint Lucia",
//       "frenchShortName": "Sainte-Lucie",
//       "alpha2Code": "LC",
//       "alpha3Code": "LCA",
//       "numeric": 662
//     },
//     {
//       "englishShortName": "Saint Martin (French part)",
//       "frenchShortName": "Saint-Martin (partie française)",
//       "alpha2Code": "MF",
//       "alpha3Code": "MAF",
//       "numeric": 663
//     },
//     {
//       "englishShortName": "Saint Pierre and Miquelon",
//       "frenchShortName": "Saint-Pierre-et-Miquelon",
//       "alpha2Code": "PM",
//       "alpha3Code": "SPM",
//       "numeric": 666
//     },
//     {
//       "englishShortName": "Saint Vincent and the Grenadines",
//       "frenchShortName": "Saint-Vincent-et-les Grenadines",
//       "alpha2Code": "VC",
//       "alpha3Code": "VCT",
//       "numeric": 670
//     },
//     {
//       "englishShortName": "Samoa",
//       "frenchShortName": "Samoa (le)",
//       "alpha2Code": "WS",
//       "alpha3Code": "WSM",
//       "numeric": 882
//     },
//     {
//       "englishShortName": "San Marino",
//       "frenchShortName": "Saint-Marin",
//       "alpha2Code": "SM",
//       "alpha3Code": "SMR",
//       "numeric": 674
//     },
//     {
//       "englishShortName": "Sao Tome and Principe",
//       "frenchShortName": "Sao Tomé-et-Principe",
//       "alpha2Code": "ST",
//       "alpha3Code": "STP",
//       "numeric": 678
//     },
//     {
//       "englishShortName": "Saudi Arabia",
//       "frenchShortName": "Arabie saoudite (l')",
//       "alpha2Code": "SA",
//       "alpha3Code": "SAU",
//       "numeric": 682
//     },
//     {
//       "englishShortName": "Senegal",
//       "frenchShortName": "Sénégal (le)",
//       "alpha2Code": "SN",
//       "alpha3Code": "SEN",
//       "numeric": 686
//     },
//     {
//       "englishShortName": "Serbia",
//       "frenchShortName": "Serbie (la)",
//       "alpha2Code": "RS",
//       "alpha3Code": "SRB",
//       "numeric": 688
//     },
//     {
//       "englishShortName": "Seychelles",
//       "frenchShortName": "Seychelles (les)",
//       "alpha2Code": "SC",
//       "alpha3Code": "SYC",
//       "numeric": 690
//     },
//     {
//       "englishShortName": "Sierra Leone",
//       "frenchShortName": "Sierra Leone (la)",
//       "alpha2Code": "SL",
//       "alpha3Code": "SLE",
//       "numeric": 694
//     },
//     {
//       "englishShortName": "Singapore",
//       "frenchShortName": "Singapour",
//       "alpha2Code": "SG",
//       "alpha3Code": "SGP",
//       "numeric": 702
//     },
//     {
//       "englishShortName": "Sint Maarten (Dutch part)",
//       "frenchShortName": "Saint-Martin (partie néerlandaise)",
//       "alpha2Code": "SX",
//       "alpha3Code": "SXM",
//       "numeric": 534
//     },
//     {
//       "englishShortName": "Slovakia",
//       "frenchShortName": "Slovaquie (la)",
//       "alpha2Code": "SK",
//       "alpha3Code": "SVK",
//       "numeric": 703
//     },
//     {
//       "englishShortName": "Slovenia",
//       "frenchShortName": "Slovénie (la)",
//       "alpha2Code": "SI",
//       "alpha3Code": "SVN",
//       "numeric": 705
//     },
//     {
//       "englishShortName": "Solomon Islands",
//       "frenchShortName": "Salomon (les Îles)",
//       "alpha2Code": "SB",
//       "alpha3Code": "SLB",
//       "numeric": 90
//     },
//     {
//       "englishShortName": "Somalia",
//       "frenchShortName": "Somalie (la)",
//       "alpha2Code": "SO",
//       "alpha3Code": "SOM",
//       "numeric": 706
//     },
//     {
//       "englishShortName": "South Africa",
//       "frenchShortName": "Afrique du Sud (l')",
//       "alpha2Code": "ZA",
//       "alpha3Code": "ZAF",
//       "numeric": 710
//     },
//     {
//       "englishShortName": "South Georgia and the South Sandwich Islands",
//       "frenchShortName": "Géorgie du Sud-et-les Îles Sandwich du Sud (la)",
//       "alpha2Code": "GS",
//       "alpha3Code": "SGS",
//       "numeric": 239
//     },
//     {
//       "englishShortName": "South Sudan",
//       "frenchShortName": "Soudan du Sud (le)",
//       "alpha2Code": "SS",
//       "alpha3Code": "SSD",
//       "numeric": 728
//     },
//     {
//       "englishShortName": "Spain",
//       "frenchShortName": "Espagne (l')",
//       "alpha2Code": "ES",
//       "alpha3Code": "ESP",
//       "numeric": 724
//     },
//     {
//       "englishShortName": "Sri Lanka",
//       "frenchShortName": "Sri Lanka",
//       "alpha2Code": "LK",
//       "alpha3Code": "LKA",
//       "numeric": 144
//     },
//     {
//       "englishShortName": "Sudan (the)",
//       "frenchShortName": "Soudan (le)",
//       "alpha2Code": "SD",
//       "alpha3Code": "SDN",
//       "numeric": 729
//     },
//     {
//       "englishShortName": "Suriname",
//       "frenchShortName": "Suriname (le)",
//       "alpha2Code": "SR",
//       "alpha3Code": "SUR",
//       "numeric": 740
//     },
//     {
//       "englishShortName": "Svalbard and Jan Mayen",
//       "frenchShortName": "Svalbard et l'Île Jan Mayen (le)",
//       "alpha2Code": "SJ",
//       "alpha3Code": "SJM",
//       "numeric": 744
//     },
//     {
//       "englishShortName": "Sweden",
//       "frenchShortName": "Suède (la)",
//       "alpha2Code": "SE",
//       "alpha3Code": "SWE",
//       "numeric": 752
//     },
//     {
//       "englishShortName": "Switzerland",
//       "frenchShortName": "Suisse (la)",
//       "alpha2Code": "CH",
//       "alpha3Code": "CHE",
//       "numeric": 756
//     },
//     {
//       "englishShortName": "Syrian Arab Republic (the)",
//       "frenchShortName": "République arabe syrienne (la)",
//       "alpha2Code": "SY",
//       "alpha3Code": "SYR",
//       "numeric": 760
//     },
//     {
//       "englishShortName": "Taiwan (Province of China)",
//       "frenchShortName": "Taïwan (Province de Chine)",
//       "alpha2Code": "TW",
//       "alpha3Code": "TWN",
//       "numeric": 158
//     },
//     {
//       "englishShortName": "Tajikistan",
//       "frenchShortName": "Tadjikistan (le)",
//       "alpha2Code": "TJ",
//       "alpha3Code": "TJK",
//       "numeric": 762
//     },
//     {
//       "englishShortName": "Tanzania, the United Republic of",
//       "frenchShortName": "Tanzanie (la République-Unie de)",
//       "alpha2Code": "TZ",
//       "alpha3Code": "TZA",
//       "numeric": 834
//     },
//     {
//       "englishShortName": "Thailand",
//       "frenchShortName": "Thaïlande (la)",
//       "alpha2Code": "TH",
//       "alpha3Code": "THA",
//       "numeric": 764
//     },
//     {
//       "englishShortName": "Timor-Leste",
//       "frenchShortName": "Timor-Leste (le)",
//       "alpha2Code": "TL",
//       "alpha3Code": "TLS",
//       "numeric": 626
//     },
//     {
//       "englishShortName": "Togo",
//       "frenchShortName": "Togo (le)",
//       "alpha2Code": "TG",
//       "alpha3Code": "TGO",
//       "numeric": 768
//     },
//     {
//       "englishShortName": "Tokelau",
//       "frenchShortName": "Tokelau (les)",
//       "alpha2Code": "TK",
//       "alpha3Code": "TKL",
//       "numeric": 772
//     },
//     {
//       "englishShortName": "Tonga",
//       "frenchShortName": "Tonga (les)",
//       "alpha2Code": "TO",
//       "alpha3Code": "TON",
//       "numeric": 776
//     },
//     {
//       "englishShortName": "Trinidad and Tobago",
//       "frenchShortName": "Trinité-et-Tobago (la)",
//       "alpha2Code": "TT",
//       "alpha3Code": "TTO",
//       "numeric": 780
//     },
//     {
//       "englishShortName": "Tunisia",
//       "frenchShortName": "Tunisie (la)",
//       "alpha2Code": "TN",
//       "alpha3Code": "TUN",
//       "numeric": 788
//     },
//     {
//       "englishShortName": "Turkey",
//       "frenchShortName": "Turquie (la)",
//       "alpha2Code": "TR",
//       "alpha3Code": "TUR",
//       "numeric": 792
//     },
//     {
//       "englishShortName": "Turkmenistan",
//       "frenchShortName": "Turkménistan (le)",
//       "alpha2Code": "TM",
//       "alpha3Code": "TKM",
//       "numeric": 795
//     },
//     {
//       "englishShortName": "Turks and Caicos Islands (the)",
//       "frenchShortName": "Turks-et-Caïcos (les Îles)",
//       "alpha2Code": "TC",
//       "alpha3Code": "TCA",
//       "numeric": 796
//     },
//     {
//       "englishShortName": "Tuvalu",
//       "frenchShortName": "Tuvalu (les)",
//       "alpha2Code": "TV",
//       "alpha3Code": "TUV",
//       "numeric": 798
//     },
//     {
//       "englishShortName": "Uganda",
//       "frenchShortName": "Ouganda (l')",
//       "alpha2Code": "UG",
//       "alpha3Code": "UGA",
//       "numeric": 800
//     },
//     {
//       "englishShortName": "Ukraine",
//       "frenchShortName": "Ukraine (l')",
//       "alpha2Code": "UA",
//       "alpha3Code": "UKR",
//       "numeric": 804
//     },
//     {
//       "englishShortName": "United Arab Emirates (the)",
//       "frenchShortName": "Émirats arabes unis (les)",
//       "alpha2Code": "AE",
//       "alpha3Code": "ARE",
//       "numeric": 784
//     },
//     {
//       "englishShortName": "United Kingdom of Great Britain and Northern Ireland (the)",
//       "frenchShortName": "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord (le)",
//       "alpha2Code": "GB",
//       "alpha3Code": "GBR",
//       "numeric": 826
//     },
//     {
//       "englishShortName": "United States Minor Outlying Islands (the)",
//       "frenchShortName": "Îles mineures éloignées des États-Unis (les)",
//       "alpha2Code": "UM",
//       "alpha3Code": "UMI",
//       "numeric": 581
//     },
//     {
//       "englishShortName": "United States",
//       "frenchShortName": "États-Unis d'Amérique (les)",
//       "alpha2Code": "US",
//       "alpha3Code": "USA",
//       "numeric": 840
//     },
//     {
//       "englishShortName": "Uruguay",
//       "frenchShortName": "Uruguay (l')",
//       "alpha2Code": "UY",
//       "alpha3Code": "URY",
//       "numeric": 858
//     },
//     {
//       "englishShortName": "Uzbekistan",
//       "frenchShortName": "Ouzbékistan (l')",
//       "alpha2Code": "UZ",
//       "alpha3Code": "UZB",
//       "numeric": 860
//     },
//     {
//       "englishShortName": "Vanuatu",
//       "frenchShortName": "Vanuatu (le)",
//       "alpha2Code": "VU",
//       "alpha3Code": "VUT",
//       "numeric": 548
//     },
//     {
//       "englishShortName": "Venezuela (Bolivarian Republic of)",
//       "frenchShortName": "Venezuela (République bolivarienne du)",
//       "alpha2Code": "VE",
//       "alpha3Code": "VEN",
//       "numeric": 862
//     },
//     {
//       "englishShortName": "Viet Nam",
//       "frenchShortName": "Viet Nam (le)",
//       "alpha2Code": "VN",
//       "alpha3Code": "VNM",
//       "numeric": 704
//     },
//     {
//       "englishShortName": "Virgin Islands (British)",
//       "frenchShortName": "Vierges britanniques (les Îles)",
//       "alpha2Code": "VG",
//       "alpha3Code": "VGB",
//       "numeric": 92
//     },
//     {
//       "englishShortName": "Virgin Islands (U.S.)",
//       "frenchShortName": "Vierges des États-Unis (les Îles)",
//       "alpha2Code": "VI",
//       "alpha3Code": "VIR",
//       "numeric": 850
//     },
//     {
//       "englishShortName": "Wallis and Futuna",
//       "frenchShortName": "Wallis-et-Futuna",
//       "alpha2Code": "WF",
//       "alpha3Code": "WLF",
//       "numeric": 876
//     },
//     {
//       "englishShortName": "Western Sahara*",
//       "frenchShortName": "Sahara occidental (le)*",
//       "alpha2Code": "EH",
//       "alpha3Code": "ESH",
//       "numeric": 732
//     },
//     {
//       "englishShortName": "Yemen",
//       "frenchShortName": "Yémen (le)",
//       "alpha2Code": "YE",
//       "alpha3Code": "YEM",
//       "numeric": 887
//     },
//     {
//       "englishShortName": "Zambia",
//       "frenchShortName": "Zambie (la)",
//       "alpha2Code": "ZM",
//       "alpha3Code": "ZMB",
//       "numeric": 894
//     },
//     {
//       "englishShortName": "Zimbabwe",
//       "frenchShortName": "Zimbabwe (le)",
//       "alpha2Code": "ZW",
//       "alpha3Code": "ZWE",
//       "numeric": 716
//     },
//     {
//       "englishShortName": "Åland Islands",
//       "frenchShortName": "Åland(les Îles)",
//       "alpha2Code": "AX",
//       "alpha3Code": "ALA",
//       "numeric": 248
//     }
//   ]
import React from "react";
import logo from "../../../assets/images/sidebar-logo.png";
interface Props {
  handleLogoClick: any,renderFreeSessionTimer:any, renderHeaderTopVCControls: any, renderHeaderRightNavControls: any, renderAnnotationChatWindowFrmHeader: any, showPublisherPanel: any, troom: any, usersUpdated: any, setUsersUpdated: any, roomParticipantsListRef: any, roomParticipantsConnectionStatusRef: any, setGuestUsersList: any, showGuestInvite: any
}

const HeaderView: React.FC<Props> = ({ handleLogoClick,renderFreeSessionTimer, renderHeaderTopVCControls, renderHeaderRightNavControls, renderAnnotationChatWindowFrmHeader, showPublisherPanel, troom, usersUpdated, setUsersUpdated, roomParticipantsListRef, roomParticipantsConnectionStatusRef, setGuestUsersList, showGuestInvite}) => {

    return (
      <div className="topbar">
        {/* Left  Logo*/}
        <div className="logo-viewer">
        <img src={logo} alt="logo" style={{margin: "15px 0 0 15px"}}   />
        </div>
        {/* Top VC Controls*/}
        <div className="centerMenuTop">
        {renderFreeSessionTimer()}
          {renderHeaderTopVCControls()}
        </div>
        

        {/* Right Nav Controls with Settings */}
        <div className="menuTopRight" >{renderHeaderRightNavControls()}</div>

        {/*  Chat window */}


        {/*  Invited Users for Non Stream VC (Case-2)*/}
        {/* {renderVCInvitedUsersFrmHeader()} */}
      </div>
    );
};

export default HeaderView
import "./styles.scss";

const TermsAndConditions = (props: any) => {
  const { section } = props;

  if (section === "termsAndConditions") {
    return (
      <div style={{ color: "#000000", opacity: "1", fontSize: "14px" }}>
        <div className="terms-container">
          <div className="heading-center">
            SUBSCRIPTION LICENSE TERMS OF SERVICE
          </div>
          <div className="content-wrapper">
            <p>These terms of service (“Terms of Service”) govern the provision and use of a live video and audio streaming platform for motion picture and television editorial systems (“session”) hosted by a secure server and controlled via desktop infrastructure by a show administrator (“publisher”) and the publisher’s invited guests (“viewers”) (“the Services”).</p>
            <p> PLEASE READ THE TERMS OF SERVICE CAREFULLY. IT CONTAINS IMPORTANT TERMS THAT AFFECT YOU AND YOUR USE OF THE SERVICES. BY USING THE SERVICES, YOU AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE SERVICES. IF YOU ARE ACCESSING OR USING THE SERVICES ON BEHALF OF ANY ENTITY OR OTHERWISE IN PERFORMANCE OF SERVICES FOR ANY ENTITY, YOU REPRESENT AND WARRANT THAT YOU ARE AUTHORIZED TO ACCEPT THIS AGREEMENT ON SUCH ENTITY'S BEHALF, THAT ACCEPTANCE OF THIS AGREEMENT AND USE OF THE SERVICES WILL NOT VIOLATE ANY AGREEMENT YOU HAVE WITH SUCH ENTITY, AND THAT SUCH ENTITY AGREES TO INDEMNIFY YOU AND US FOR VIOLATIONS OF THIS AGREEMENT.</p>
            <p>The Services are provided by Pacific Post Rentals, Inc., a California corporation (doing business as PacPost.io) having its principal office at 4400 W. Riverside Drive, Ste. 110-215, Burbank, CA 91505 (“us”, “we”, “our”).</p>
            <p>The terms of the subscription license granted to the publisher (“Licensee”) to use the Services to invite viewers to take part in sessions is set out in a separate agreement between the Licensee and us (“Agreement”). Unless otherwise expressly set forth in the Agreement these Terms of Service are hereby incorporated into the Agreement in relation to the Licensee’s use of the Services. These Terms of Service also apply to all users (including viewers) to whom the Licensee grants access to the Services under the Licensee’s account, (“you”). By clicking the acceptance button online to these Terms of Service before using the Services, you agree to comply with these Terms of Service and to be legally bound by these Terms of Service when using the Services. If you do not agree to these Terms of Services, you must not use the Services. By accepting the Terms of Service, we hereby grant you a non-exclusive, non-sublicensable, non-transferable license to stream, access, and use the Services during the term of this Agreement for your own internal business use (the “Permitted Purpose”).</p>
            <p>PacPost.io, Pacific Post Rentals Inc., and any other company associated with Pacific Post Rentals Inc. provide their Services “AS-IS” and without any warranties. PacPost.io explicitly disclaims all warranties, including but not limited to merchantability, fitness for a particular purpose, and non-infringement. PacPost.io does not guarantee that the Services will be uninterrupted, secure, or error-free. Your use of the Services is at your own risk. You acknowledge and agree that PacPost.io is not liable for any damages to your computer system, mobile device, or any third party's devices resulting from using the Services. PacPost.io is not responsible for any failure to store, transfer, or delete a file, or for the corruption or loss of any data, information, or content within a file.</p>
            <p>PacPost.io reserves the right to modify, terminate, or expand its Services and may limit access to or remove any features or functionality at its discretion.</p>
            <p>Certain Services may require registration and the provision of accurate data such as email address, password, or payment details. You are responsible for maintaining the accuracy of this information in your account settings. You must safeguard your account and not share your password; you are liable for any activities conducted through your account. PacPost.io reserves the right to delete accounts if registrations or account data are misused. PacPost.io is not responsible for any loss or damage arising from unauthorized use of your account.</p>
            <ol>
              <li>
                <h4>OTHER TERMS THAT MAY APPLY TO YOU</h4>
                <p>Our Privacy Policy also applies to your use of the Services in addition and explains our use of any personally identifiable information that you provide to us when you use the Services.</p>
              </li>
              <li>
                <h4>ELIGIBILITY</h4>
                <p>You represent and warrant that you are at least 18 years of age. If you are under age 18, you may not, under any circumstances or for any reason, use the Services. We may, in our sole discretion, refuse to offer the Services to any person or entity and change this eligibility criteria at any time. You are solely responsible for ensuring that these Terms of Service comply with all laws, rules, and regulations applicable to you and the right to access the Services is revoked where these Terms of Service or use of the Services is prohibited or to the extent offering access to the Services conflicts with any applicable law, rule, or regulation.</p>
              </li>
              <li>
                <h4>GENERAL PROHIBITED USES</h4>
                <p>You may use our Services only for lawful purposes. </p>
                <p>You may not use the Services: </p>
                <ul>
                  <li>a. To breach any applicable local, national, or international law or regulation;</li>
                  <li>b. For any unlawful or fraudulent purpose;</li>
                  <li>c. To harm or attempt to harm minors or any person;</li>
                  <li>d. To bully, insult, intimidate or humiliate any person;</li>
                  <li>e. To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards set out below in these Terms of Service;</li>
                  <li>f. To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware;</li>
                  <li>g. If you are a minor, as legally defined in each applicable jurisdiction. The Services are designed for business use and  are not intended to be used by minors</li>
                  <li>h. To reproduce, duplicate, copy or re-sell any part of our Services in contravention of the provisions of these Terms of Service;</li>
                  <li>i. To access without authority, interfere with, damage, or disrupt:
                    <ul>
                      <li>i. any part of our Services; any equipment or network on which our Services are stored or operate.</li>
                      <li>ii. any software used in the provision of or use of our Services; any equipment or network or software owned or used by any third party.</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <h4>SPECIFIC PROHIBITED USES</h4>
                <h5>US Export controls:</h5>
                <p>You warrant that you are not a person or entity appearing on the lists published by the U.S. Department of Commerce, the U.S. Department of State, the U.S. Department of Treasury, or any other list that may be published by the U.S. Government, as amended from time to time, that is prohibited from acquiring access to or control of items under these Terms of Service, or with which we are prohibited from doing business. Furthermore, you confirm that the Services shall not be used for or in connection with nuclear, chemical, or biological weapons, weapons of mass destruction, missiles, unmanned aerial vehicles, and/or to support terrorist activities, that would violate U.S. export controls or economic sanctions laws or regulations.</p>

                <h5>HIPPA:</h5>
                <p>
                You will not cause, or otherwise request that we create, receive, maintain, or transmit protected health information (as defined at 45 C.F.R. § 160.103) for or on behalf of the Licensee in connection with the Services or in any manner that would make us a business associate (as defined at 45 C.F.R. §160.103) to the Licensee.
                </p>

                <h5>Abusive Behavior:</h5>
                <p>
                You will not harass, threaten, or defame any person or entity. You will not contact any person who has requested no further contact. You will not use ethnic or religious slurs against any person or group.
                </p>

                <h5>Privacy:</h5>
                <p>
                You will not violate the privacy rights of any person. You will not collect or disclose any personal address, social security number, or other personally identifiable information without each holder’s written permission. 
                </p>
                <p>You will not cooperate in or facilitate identity theft.</p>

                <h5>Intellectual Property:</h5>
                <p>
                  You will not infringe the copyrights, trademarks, trade
                  secrets, or other intellectual property rights of any person
                  or entity.
                </p>
                <p>
                  You will not reproduce, publish, or disseminate software,
                  audio recordings, video recordings, photographs, articles, or
                  other works of authorship without the written permission of
                  the copyright holder.
                </p>

                <h5>Hacking, Viruses, & Network Attacks:</h5>
                <p>
                  You will not access any computer or communications system
                  without authorization, including the computers used to access
                  the Services.{" "}
                </p>
                <p>
                You will not attempt to penetrate or disable any security system. You will not intentionally distribute a computer virus, launch a denial-of-service attack, or in any other way attempt to interfere with the functioning of any computer, communications system, service, or website. You will not attempt to access or otherwise interfere with the accounts of other users of the Services.
                </p>

                <h5>Spam:</h5>
                <p>
                You will not send bulk unsolicited emails (“Spam”) or sell or market any product or service advertised by or connected with Spam. {" "}
                </p>
                <p>
                  You will not facilitate or cooperate in the dissemination of
                  Spam in any way.{" "}
                </p>
                <p>You will not violate the CAN-Spam Act of 2003.</p>

                <h5>Fraud:</h5>
                <p>
                  You will not issue fraudulent offers to sell or buy products,
                  services, or investments.{" "}
                </p>
                <p>
                  You will not mislead anyone about the details or nature of a
                  commercial transaction.{" "}
                </p>
                <p>You will not commit fraud in any other way.</p>
              </li>
              <li>
                <h4>INTERACTIVE SERVICES</h4>
                <p>
                We provide interactive services within the Services. We do not oversee, monitor, or moderate any interactive service we provide via our Services, and we expressly exclude our liability for any loss or damage arising from the use of any interactive service by a user in contravention of our content standards.
                </p>
                <p>
                  We <u>do not</u> provide the ability for the client who sets
                  up and controls use of the virtual room, (“Client Room Owner”)
                  to record or download sessions as part of the Service for the
                  room owner. Recording of sessions is strictly prohibited.{" "}
                </p>
              </li>
              <li>
                <h4>CONTENT STANDARDS</h4>
                <p>
                You acknowledge and understand that any video, audio, data, and other input to or output which you contribute to our Services (“Content”) is entirely your responsibility. You acknowledge, agree, and agree to assert and acknowledge in legal proceedings that we exercise no control whatsoever over the Content and that we will not be liable for Content. These content standards apply to all material which you contribute to our Services (“Contribution”), and to any interactive services associated with it. These content standards must be complied with in spirit as well as to the letter. 
                </p>
              </li>
              <li>
                <h4>ACCOUNT SECURITY</h4>
                <p>
                The Services are accessible only to the Licensee and their invited viewers. Login credentials (username/password) are solely and exclusively provided for the Licensee (and for their viewers) own personal use. You agree that any login credentials provided by us will not be shared with any third party including those from the same company, same team, or project. You agree not to transfer, copy, disclose, provide, or otherwise make available any designated user account or any confidential information in any form, such as login credentials, to any third party without the prior written consent, or electronic authorization by us. You agree to implement reasonable security measures to protect all confidential account information, but without limitation to the foregoing, shall use best efforts to maintain the security of the access to the designated account. You will use best efforts to cooperate with and assist us in identifying and preventing any unauthorized use, copying, access to or disclosure relating to the Services.
                </p>
              </li>
              <li>
                <h4>CONSEQUENCES OF YOUR BREACHES</h4>
                <p>
                  When we consider that a breach of these Terms of Service has
                  occurred, we may take such action as we deem appropriate to
                  correct, remediate, or restore the Services or functionality.
                  Failure to comply with these Terms of Service constitutes a
                  material breach of the terms upon which you are permitted to
                  use our Services, and may result in us taking all or any of
                  the following actions:
                </p>
                <ul>
                  <li>
                    a. Immediate, temporary, or permanent withdrawal of your
                    right to use us Services
                  </li>
                  <li>
                    b. Immediate, temporary, or permanent removal of any
                    Contribution uploaded by you to our Services.
                  </li>
                  <li>c. Issuing a warning to you.</li>
                  <li>
                    d. Taking legal proceedings against you, including for
                    reimbursement of all costs on an indemnity basis (including,
                    but not limited to, reasonable administrative and legal
                    costs) resulting from the breach.
                  </li>
                  <li>e. Taking further legal action against you.</li>
                  <li>
                    f. Disclosure of such information to law enforcement
                    authorities as we reasonably feel is necessary or as
                    required by law.
                  </li>
                </ul>
                <p>
                  We exclude our liability for all action we may take in
                  response to breaches of these Terms of Service. The actions we
                  may take are not limited to those described above, and we may
                  take any other action we reasonably deem appropriate.
                </p>
              </li>
              <li>
                <h4>INTELLECTUAL PROPERTY RIGHTS</h4>
                <p>
                We own the full intellectual property rights in and to the Services, software contained therein and supporting documentation, including improvements, enhancements, modifications, and any feedback provided by you. You have no right to any ownership in or title to, the Services, software contained therein or any of our associated supporting documentation including improvements, enhancements, modifications, or feedback. Except as specifically permitted in writing you shall not: modify or create any derivative works from the Services, including – without limitation – translation or localization; (code written to published APIs (Application programming interfaces) for our software shall not be deemed derivative works); copy our software or Services; reverse engineer, decompile, or disassemble or otherwise attempt to derive the source code for the software within the Services (except to the extent applicable laws specifically prohibit such restriction); redistribute, encumber, sell, rent, lease, use the Services in a timesharing or service bureau arrangement, or otherwise transfer rights to the Services or our software. You may not transfer our software or the Services or a designated account under any circumstances; remove or alter any trademark, logo, copyright, or other proprietary notices,legends, symbols, or labels in the Services; publish or make public any results of benchmark tests or permit access to or offer the Services to a third party without our prior written consent.
                </p>
              </li>
              <li>
                <h4>DISCLAIMER</h4>
                <h5>Equipment:</h5>
                <p>
                  You are responsible for obtaining and maintaining any
                  equipment and ancillary services including video-enabled
                  devices, video communication services, modems, hardware,
                  servers, software, operating systems, networking, web servers,
                  internet, and telephone service (“Equipment”) needed to
                  connect to, access or otherwise use the Services.
                </p>

                <h5>Third Party Products:</h5>
                <p>
                  If third party services, applications, code, hardware, or
                  products (“Third Party Products”) are integrated or used in
                  connection with the Services, you agree we make no
                  representations and disclaims all warranties, express or
                  implied, regarding Third Party Products and we shall have, no
                  liability for Third Party Products or any unavailability of
                  Third-Party Products.
                </p>

                <h5>Use of Services by Viewers:</h5>
                <p>
                  
	The Licensee is responsible for ensuring compliance with these Terms of Service by all viewers who participate in a session via the Services. Any use of the Services in a manner not permitted by these Terms of Service is a material breach of the Terms of Service. The Licensee is liable for all activities of users attending meetings that occur in a session using the Licensee’s accounts.


                </p>
              </li>
              <li>
                <h4>WARRANTIES</h4>
                <p>
                EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS OF SERVICE, ALL WARRANTIES AND CONDITIONS, EXPRESS OR IMPLIED INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR CONDITIONS OF DESIGN, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES OF TITLE AND NON-INFRINGEMENT, ARE EXCLUDED. YOU ACKNOWLEDGE THAT THE SERVICES ARE PROVIDED “AS IS” AND THAT WE DO NOT WARRANT THAT THE OPERATION OF THE SERVICES WILL BE UNINTERRUPTED, ERROR FREE, FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS, WILL MEET ANY SPECIFIC USER’S REQUIREMENTS OR THAT THE RESULTS OBTAINED FROM USING THE SERVICES WILL ACHIEVE ANY PARTICULAR RESULTS. THE USER BEARS THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF THE SERVICES. ANY DOWNLOAD AND USE OF THE SERVICES IS AT THE USER’S OWN RISK AND THE USER WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO – WITHOUT LIMITATION – ANY COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM SUCH ACTIVITIES. THE USER SHALL BEAR THE COST OF ALL NECESSARY SERVICING, REPAIR AND/OR CORRECTION CAUSED BY SUCH ACTIVITIES. THE SERVICES SHOULD NOT BE USED FOR EXAMPLE WHERE SENSITIVE AND/OR VALUABLE DATA MAY BE LOST OR COMPROMISED.
                </p>
              </li>
              <li>
                <h4>LIABILITY</h4>
                <p>
                SUBJECT TO APPLICABLE LAW, IN NO EVENT WILL WE BE LIABLE TO YOU FOR – WITHOUT LIMITATION – ANY LOSS OF USE, INTERRUPTION OF BUSINESS, OR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY OR PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND (INCLUDING LOST PROFITS) REGARDLESS OF THE FORM OF ACTION WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT PRODUCT LIABILITY OR OTHERWISE, WHETHER ARISING OUT OF THE USE OR INABILITY TO USE THE SERVICES (INCLUDING BUT NOT LIMITED TO LOSS OF DATA OR DATA BEING RENDERED INACCURATE OR LOSSES SUSTAINED BY USER OR THIRD PARTIES OR A FAILURE OF THE SERVICES TO OPERATE WITH ANY OTHER PROGRAMS), EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
              </li>
              <li>
                <h4>INDEMNITY</h4>
                <p>
                You agree to indemnify, defend, and hold harmless us, our affiliates, officers,directors, employees, consultants, agents, suppliers, and resellers from any and all third-party claims, liability, damages and/or costs (including, but not limited to,attorneys' fees) arising from your use of the Services, your breaches of theseTerms of Service or the infringement or breach by you or any other user of your account, of any intellectual property or other right of any person or entity or applicable law.
                </p>
              </li>
              <li>
                <h4>FORCE MAJEURE</h4>
                <p>
                Neither party shall be in default by reason of any failure in performance of this Terms of Services, except for an obligation to pay money, if such failure arises, directly or indirectly, out of causes reasonably beyond the direct control or foreseeability of such party, including but not limited to, default by subcontractors or suppliers, acts of God or of the public enemy, terrorist act, United States or foreign governmental acts in either a sovereign or contractual capacity, fire, flood, restriction, strikes, or freight embargos, epidemic, pandemic, shelter-in-place orders, quarantines, government shutdowns, substantial interruption to air travel, substantial interruptions in supply chains, and other economic consequences caused by the
response to the foregoing.

                </p>
              </li>
              <li>
                <h4>APPLICABLE LAW</h4>
                <p>
                These Terms of Service, their subject matter and formation are governed by the laws of the State of California and are subject to the exclusive jurisdiction of the state and federal courts located in California.


                </p>
              </li>
              <li>
                <h4>ARBITRATION FOR NON-U.S. LICENSEES. </h4>
                <p>
                IF YOU RESIDE (IF YOU ARE AN INDIVIDUAL) OR ARE DOMICILED (IF YOU ARE ENTERING INTO THIS TERMS OF SERVICE ON BEHALF OF AN ENTITY) OUTSIDE OF THE UNITED STATES, ANY CLAIM OR DISPUTE BROUGHT BY YOU AND ARISING OUT OF OR RELATED TO THIS TERMS OF SERVICE OR THE SERVICES SHALL BE SUBMITTED TO BINDING ARBITRATION IN LOS ANGELES, CALIFORNIA, USA UNLESS WE AGREE IN WRITING TO WAIVE SUCH REQUIREMENT. 
                </p>
                <p>
                Such arbitration shall be before an arbitrator-member of the American Arbitration Association (“AAA”) to be mutually agreed to by the parties, or, in the event the parties cannot agree on a single such arbitrator-member, to a panel of three arbitrator-members selected in accordance with the rules of the AAA. The dispute shall be settled in accordance with the Commercial Arbitration Rules of the AAA and the decision of the arbitrator(s) shall be final and binding upon the parties and judgment may be obtained thereon in a court of competent jurisdiction. The prevailing party shall be entitled to recover from the other party the fees and expenses of the arbitration as well as reasonable attorneys' fees, costs and expenses incurred by the prevailing party. TheConvention on the Recognition and Enforcement of Foreign Arbitral Awards shall apply to this Terms of Service if you reside or are domiciled outside of the United States.
                </p>
              </li>
              <li>
                <h4>CHANGES</h4>
                <p>
                We may amend these Terms of Service from time to time. Every time you wish to use the Services, please check these terms to ensure you understand the terms that apply at that time.
                </p>
              </li>
              <li>
                <h4>Indemnity and Liability</h4>
                <p>
                You agree to defend, indemnify, and hold harmless PacPost.io, Pacific Post Rentals Inc., and any associated companies (including their employees and affiliates) from any claims, liabilities, damages, losses, and expenses, including legal and accounting fees, arising from your access to or use of the Services or any breach of these Terms. This includes claims that content created, used, stored, or shared through the Services by you or via your account infringes or violates any third-party rights.
                </p>
              </li>
              <li>
                <h4>Integration with Third-Party Services</h4>
                <p>
                The Services may integrate with third-party services. You acknowledge that PacPost.io, Pacific Post Rentals Inc., and associated companies are not responsible for the actions or omissions of such third-party services, are not their agents, and your use of those services is subject to applicable terms and conditions between you and the providers of such services.
                </p>
              </li>
              <li>
                <h4>Liability Limitation</h4>
                <p>
                To the extent permitted by local mandatory law, PacPost.io, Pacific Post Rentals Inc., and associated companies are not liable for any damage or personal injury resulting from the use of the Services, including temporary unavailability or accidental removal of your Content or account. This limitation of liability doesn’t apply if the damage is caused by their intent or gross negligence. If they are liable for damage under mandatory law, their aggregate liability to you - to the extent permissible by local mandatory law - will not exceed one hundred euros ($100.00) per incident.
                </p>
              </li>
              <li>
                <h4>Experimental Services</h4>
                <p>
                You agree that some of the software product or portions of the service may be experimental.
                </p>
                <p>
                These Terms of Service were most recently updated on December 18, 2023
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  } else if (section === "privacyPolicy") {
    return (
      <div style={{ color: "#000000", opacity: "1", fontSize: "14px" }}>
        <div className="terms-container">
          <div className="heading-center">PRIVACY POLICY</div>
          <div className="content-wrapper">
            <p>
            Pacific Post Rentals, Inc. (doing business as PacPost.io) (“we”, “us”, “our”) are committed to protecting and respecting your privacy.  We will never sell or rent your personal data to anyone.
            </p>
            <p>
            This privacy policy (“Privacy Policy”) and any other documents referred to herein, sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. By visiting <a href="https://pacpost.io/">https://pacpost.io/</a>(“Site”) you are accepting and consenting to the practices described in this Privacy Policy
            </p>
            <ol>
              <li>
                <h4>DATA CONTROLLER</h4>
                <p>
                In relation to the personal data of individuals located in the EU and for the purposes of the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016, (“GDPR”) or any subsequent amendment or replacement or supplementary legislation (“Data Protection Law”), the data controller is Pacific Post Rentals, Inc. of 4400 W. Riverside Drive, Ste. 110-215, Burbank, CA 91505, USA.
                </p>
              </li>
              <li>
                <h4>LEGAL BASIS FOR PROCESSING</h4>
                <p>
                We collect and use the personal data described below to provide you with access to our Site and Services in a reliable and secure manner. We also collect and use personal data for our legitimate business needs; to fulfil our contractual obligations to you; and to comply with our legal obligations.
                </p>
              </li>
              <li>
                <h4>INFORMATION WE MAY COLLECT FROM YOU</h4>
                <p>
                  We may collect and process the following information about
                  you:
                </p>
                <p>
                Information you give us. You may give us information about you by filling in forms on our Site or by corresponding with us by phone, email or otherwise. This includes information you provide when you register to use our Site, apps and subscribe to our services, or participate in secure video meetings or conferences, sessions, or chats, as a publisher, viewer, or sub-user, and when you report a problem with our Site or Services (“Services”). The information you give us may include your name, address, email address and phone number, company, project, or associated business. When you choose to be an active, streaming participant in virtual PacPost.io room, you grant us access to the audio and video from your connected devices.
                </p>
                <p>
                When you register to use the Site and each time you use the Site and our Services, we will collect the following information in order to provide access to the Services to you: display name, email address, IP address, organization/enterprise associations (if any), date and time of access to different site features (i.e. recordings, rooms you enter and authentication requests).  We also collect your operating system version, browser version, and certain plug-ins related to our services for troubleshooting purposes.


                </p>
                <p>
                We store transmitted RTP packets containing encoded audio and video information. We also store: service access attempts (both successful and unsuccessful) to various parts of our application; invitations created and accepted; rooms created, edited, and deleted; recordings created, edited, and deleted; any annotations or chat messages that take place in a live session; any interaction with the site, including but not limited to UI element clicks, text entered (whether submitted or not submitted), and any other actions taken within the user interface of the PacPost.io application. We also attempt to differentiate between your devices and store different device identifiers. We store this information for additional authentication security, compliance, and auditing purposes, and also to provide you a better experience on each of your different devices.
                </p>
                <p>
                  Information we collect about you. With regard to each of your
                  visits to our Site we may automatically collect the following
                  information:
                </p>
                <p>
                Technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform.
                </p>
                <p>
                Information about your visit, including the full Uniform Resource Locators (URL) you visited and arrived from, including date and time of access; PacPost.io virtual room description; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page and any phone number used to call our customer service number.
                </p>
                <p>
                We may collect any request headers your browser sends, WebRTC session call stats and signals, and (while streaming) data from any audio or video devices you have selected and granted us access to; we collect this information to provide better security and to troubleshoot stability and performance issues, as well as to deliver the service.
                </p>
                <p>
                Information from other online accounts to which you have given us permission to collect data from within your settings or the privacy policies of these other online services. For example, this can be by choosing to send us mobile device permissions, your location data if/when accessing our Site from your smartphone; or it can be from the integrations and connections that you choose to install when using the Services like from an Enterprise Identity Provider for single sign on.
                </p>
                <p>
                Information we receive from other sources. We may receive information about you if you use any other services we may provide. In this case we will have informed you when we collected that data that it may be shared internally and combined with data collected on this Site. 
                </p>
                <p>
                Information collected by third parties. We are also working closely with third parties (including, for example, sub-contractors in technical, payment and delivery services, analytics providers, content delivery networks) and may receive information about you from them.


                  <br />
                  Zendesk: to connect information about customer help and
                  information inquiry requests (for support purposes) <br />
                  <br />
                  Google: to enable email communications with you and provide
                  Site analytics data.
                  <br />
                  <br />
                  Twilio: to allow software developers to programmatically make
                  and receive phone calls, send and receive text messages, and
                  perform other communication functions using its web service
                  APIs for video conference.
                  <br />
                  <br />
                  Milicat: to broadcast video and audio streams at lowest
                  latency streaming format across a custom- built Web Real-Time
                  Communication (WebRTC) content delivery network (CDN).
                </p>
              </li>
              <li>
                <h4>COOKIES AND OTHER IDENTIFIERS</h4>
                <p>
                We use cookies and browser storage (aka localStorage) on our Site to distinguish you from other users of our Site. This helps us to provide you with a good experience when you browse our Site and also allows us to improve the Site.
                </p>
                <h5>What are cookies</h5>
                <p>
                  Cookies are small text files that are placed on your computer
                  by websites that you visit. They are widely used in order to
                  make websites work, or work more efficiently, as well as to
                  provide information to the owners of the Site. Cookies can be
                  “persistent” or “session” cookies. We use persistent cookies
                  and session cookies on our Site.
                </p>

                <h5>Persistent Cookies</h5>
                <p>
                A persistent cookie is stored on a user’s device in between browser sessions which allows the preferences or actions of a user across the Site (or in some cases across different websites) to be remembered. We use persistent cookies to save your login information for future logins to the Site.
                </p>

                <h5>Session Cookies</h5>
                <p>
                  A session cookie allows the Site to link your actions during a
                  browser session. We use session cookies to enable certain
                  features of the Site, to better understand how you interact
                  with the Site and to monitor aggregate usage by users and web
                  traffic routing on the Site. Unlike persistent cookies,
                  session cookies are deleted from your computer when you log
                  off from the Site and then close your browser.
                </p>
              </li>
              <li>
                <h4>USES MADE OF THE INFORMATION</h4>
                <p>We use information held about you in the following ways.</p>
                <p>
                To carry out our obligations arising from any contracts entered into between you and us; to provide you with the information, products and services that you request from us; to notify you about changes to our Site and Services; to ensure that content from our Site and Services is presented in the most effective manner for you and for your computer; to administer our Site and Services and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes; to inform you about scheduled Service downtimes and new features; to improve our Site and; to allow you to participate in interactive features of our service, when you choose to do so; as part of our efforts to keep our Site and Services safe and secure; to monitor your use of the Site is in compliance with any legal agreements we have with you.
                </p>
                <p>
                We may combine information we receive from other sources with information you give to us and information we collect about you. We may use this information and the combined information for the purposes set out above (depending on the types of information we receive).
                </p>
                <p>
                We will not share, sell, or rent your personal data to any 3rd party company or entity.
                </p>
              </li>
              <li>
                <h4>DISCLOSURE OF YOUR INFORMATION</h4>
                <p>
                We may share certain of your information with selected third parties including professional advisors acting as service providers to us in relation to the Site - including lawyers, bankers, auditors, and insurers on an as needed basis but not part of day-to-day activities; and analytics and search engine providers that assist us in the improvement and optimization of our Site such as Google Analytics. 
                </p>
                <p>
                We may disclose your personal data to third parties in the event that we sell or buy any business or assets; if we or a member of our group of companies or substantially all of their assets are acquired by a third party, in which case personal data held by them about their customers will be one of the transferred assets; if we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our legal agreement with you and/or any other agreements; or to protect our rights, property, safety, our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection.
                </p>
              </li>
              <li>
                <h4>WHERE WE STORE YOUR PERSONAL DATA</h4>
                <p>
                Our Services are global and your information (including personal data) may be stored and processed in any country where we have operations or where we engage service providers, and we may transfer your information to countries outside of your country of residence, which may have data protection rules that are different from those of your country of residence.
                </p>
                <p>
                We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy. This means that your personal data will only be transferred to a country that provides an adequate level of protection (for example, where the European Commission has determined that a country provides an adequate level of protection) or where the recipient is bound by standard contractual clauses according to conditions provided by the European Commission (“Standard Contractual Clauses”).
                </p>
                <p>
                Our Site and Services are accessible via the internet and may potentially be accessed by anyone around the world. Other users may access the Site or Services from outside the EEA. This means that where you chose to post your data on our Site or within the Services, it could be accessed from anywhere around the world and therefore a transfer of your data outside of the EEA may be deemed to have occurred. You consent to such transfer of your data for and by way of this purpose.
                </p>
              </li>
              <li>
                <h4>PROTECTION OF YOUR INFORMATION</h4>
                <p>
                All information you provide to us is stored on our secure servers. Where we have given you (or where you have chosen) a password which enables you to access certain parts of the Site or Services, you are responsible for keeping this password confidential. We ask you not to share any password with anyone.
                </p>
                <p>
                Transmissions within PacPost.io are encrypted and secure. Unfortunately, transmissions of information via the Internet to PacPost.io, may not be completely secure. Although we will endeavor to protect your personal data. we cannot guarantee the security of your data transmitted to our Site or the Services. Any transmission is at your own risk. Once we have received your information, we do use strict procedures and security features in the effort to prevent unauthorized access.
                </p>
              </li>
              <li>
                <h4>LINKS TO OTHER WEBSITES</h4>
                <p>
                Our Site and Services may, from time to time, contain links to and from the websites of others. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies should you choose to submit any personal data to these websites.


                </p>
              </li>
              <li>
                <h4>DATA RETENTION</h4>
                <p>
                We retain personal data for as long as necessary for the relevant activity for which it was provided or collected. This will be for as long as we provide access to the Site or Services to you, your account with us remains open or any period set out in any relevant contract you have with us. However, we may keep some data after your account is closed or you cease using the Site or Services for the purposes set out below.
                </p>
                <p>
                We may retain personal data where reasonably necessary to comply with our legal obligations (including law enforcement requests), meet regulatory requirements, maintain security, prevent fraud and abuse, resolve disputes and enforce our legal agreements with you. We will retain de-personalized information after your account has been closed.
                </p>
                <p>
                Please note: After you have closed your account or deleted information from your account, any information you have shared with others will remain visible. We do not control data that other users may have copied from the Site or Services.
                </p>
              </li>
              <li>
                <h4>AGE OF USERS</h4>
                <p>
                  This Site and the Services are not intended for and shall not
                  be used by anyone under the age of 18.
                </p>
              </li>
              <li>
                <h4>
                  PROVISIONS APPLICABLE TO USERS LOCATED IN THE EU OR THE UK
                </h4>
                <p>
                You have the right under Data Protection Law, free of charge, to request access to your personal data; rectification or deletion of your personal data; a restriction on the processing of your personal data; object to the processing of your personal data; a transfer of your personal data (data portability) in a structured, machine readable and commonly used format; and withdraw your consent to us processing your personal data, at any time.
                </p>
                <p>
                You can make a request in relation to any of the above rights by contacting us as set out at the end of this Privacy Policy. We will respond to such queries within 30 days and deal with requests we receive from you, in accordance with the provisions of Data Protection Law.
                </p>

                <h5>Storage of your personal data</h5>
                <p>
                The personal data that we collect from you may therefore be transferred to, and stored at, a destination outside the European Economic Area (“EEA”). It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers or partners. Such staff or subcontractors may be engaged in, among other things, the fulfilment of your order, the processing of your payment details or the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing outside of the EEA.
                </p>

                <h5>Marketing Communications</h5>
                <p>
                We will send you marketing emails if you “opt in” to receive marketing emails when registering on our Site, or if you have enquired about, or purchased any of our goods or services.
Please note that, if you change your mind about being sent marketing emails you can “opt out” at any time by clicking the “unsubscribe” link at the bottom of any marketing email. Once you “opt out”, you will no longer receive any marketing emails from us. We will continue to communicate with you regarding your service billing and support via email.

                </p>
                <p>
                  We send push notifications from time to time in order to
                  update you about any service updates, events and promotions we
                  may be running. If you no longer wish to receive these
                  communications, please disable these in the settings on your
                  device.
                </p>

              </li>
              <li>
                <h4>COMPLAINTS</h4>
                <p>
                If you have any complaints about our use of your personal data please contact us as set out at the end of this Privacy Policy or contact your local data protection supervisory authority in the country in which you are located.
                </p>
                <p>Our EU representative is:</p>
                <div className="address-para">[name]</div>
                <div className="address-para">
                4400 W. Riverside Drive, Ste. 110-215, Burbank, CA 91505, USA
                </div>
                <div>
                  <a href="mailto:privacy@PacPost.io.us">privacy@PacPost.io.us</a>
                </div>
              </li>
              <li>
                <h4>CHANGES TO OUR PRIVACY POLICY</h4>
                <p>
                Any changes we may make to our Privacy Policy in the future will be posted on this page and, where appropriate, notified to you by email. Please check back frequently to see any updates or changes to our Privacy Policy.
                </p>
                <p>
                This Privacy Policy was last updated on August 22, 2022, and replaces any other Privacy Policy previously applicable from this date.
                </p>
              </li>
              <li>
                <h4>CONTACT</h4>
                <p>
                Questions, comments, and requests regarding this Privacy Policy are welcomed and should be addressed to:


                </p>
                <p>
                By post: Pacific Post Rentals, Inc. of 4400 W. Riverside Drive, Ste. 110-215, Burbank, CA 91505, USA; By email: support@PacPost.io.us
                  <br />
                  Rev.#1 August 22, 2022
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ color: "#000000", opacity: "1", fontSize: "14px" }}>
        <div className="terms-container">
          <div className="heading-center">SUBSCRIPTION AGREEMENT</div>
          <div className="content-wrapper">
            <p>
              
	This Subscription Agreement (“Agreement”) governs the provision and use of a live video and audio streaming platform for motion picture and television editorial systems (“session”) hosted by a secure server and controlled via desktop infrastructure by a show administrator (“Customer”) and the publisher’s invited guests (“viewers”) (collectively “the Services”).

            </p>
            <p>
            The Services are provided by Pacific Post Rentals, Inc., a California corporation(doing business as PacPost.io) having its principal office at 4400 W. Riverside Drive, Ste. 110-215, Burbank, CA 91505 (“Company”).
            </p>
            <p>
            The Agreement grants the publisher (“Licensee”) the right to use the Services to invite viewers to take part in sessions. The Agreement and the separate Terms of Services and Privacy Policy also applies to all users (including viewers) to whom the Licensee grants access to the Services under the Licensee’s account (“you”). By clicking the acceptance button online to the Terms of Service before using the Services, you agree to comply with this Agreement, the Terms of Service, and Privacy Policy, and to be legally bound thereby when using the Services. If you do not agree to the Agreement, the Terms of Services, or the Privacy Policy then you must not use the Services. (the “Permitted Purpose”).
            </p>
            <h4>IT IS AGREED BETWEEN THE PARTIES THAT:</h4>
            <ol>
              <li>
                <h4>SERVICES </h4>
                <ul>
                  <li>
                    1.1 &nbsp;&nbsp;&nbsp;The Customer engages the Company, and the Company agrees to provide the Services to the Customer in accordance with the terms of this Agreement from the Effective Date for the Term.
                  </li>
                </ul>
              </li>
              <li>
                <h4>LICENSE TO USE THE SERVICES</h4>
                <ul>
                  <li>
                    2.1 &nbsp;&nbsp;&nbsp;Subject to the Customer's payment of the fees set out in the Customer Profile payable by the Customer during the Term, the Customer is granted a non-exclusive, non-transferable license to permit viewers to use the and Services from the means the effective date set out in the Customer Profile for the Customer's internal business operations. Such license permits the Customer to receive the Services via the Internet. Where open-source software is used as part of the Services, such software use by the Customer will be subject to the terms of the open-source licenses. No additional implied rights are granted beyond those specifically mentioned herein.
                  </li>
                  <li>
                    2.2 &nbsp;&nbsp;&nbsp;The Customer may not lease, loan, resell, assign, license, distribute or otherwise permit access to the Services; nor use the Services to provide ancillary services related to Services; nor permit access to or use of the Services by or on behalf of any third partyexcept as expressly permitted in this Agreement.
                  </li>
                  <li>
                    2.3 &nbsp;&nbsp;&nbsp;The Company reserves the right to electronically monitor the Customer’s use of theServices.
                  </li>
                </ul>
              </li>
              <li>
                <h4>INTELLECTUAL PROPERTY RIGHTS</h4>
                <ul>
                  <li>
                    3.1 &nbsp;&nbsp;&nbsp;All Company’s intellectual property rights, including copyrights, patents, utility models, trademarks, service marks, business names and logos (“IPR”) and title to the Services shall remain with the Company and/or its licensors and subcontractors. No interest or ownership in the Services or IPR is transferred to the Customer under this Agreement.
                  </li>
                  <li>
                    3.2 &nbsp;&nbsp;&nbsp;The Customer shall retain sole ownership of all right, title and interest in and to its pre-existing IPR and to data imported by Customer into the Services for the purpose of using the Services or facilitating the Customer’s use of the Services (“Customer Data”), and it shall have the sole responsibility for the legality, reliability, integrity, accuracy and quality of the Customer Data. The Customer grants the Company a non-exclusive, license to use Customer Data, Customer IPR and any third party owned item from the Effective Date for the Term to the extent required for the provision of the Services.
                  </li>
                  <li>
                    3.3 &nbsp;&nbsp;&nbsp;The Customer is not allowed to remove any proprietary marks or copyright notices from the Services or training materials and user manuals relating to the use of the Services (“Documentation”).
                  </li>
                  <li>
                    3.4 &nbsp;&nbsp;&nbsp;The Customer grants the Company a non-exclusive, non-transferable, revocable license to display the Customer’s name, logo and trademarks, as designated and/or amended by the Customer from time to time and as required in the creation of correspondence, documentation and website front ends in the provision of the Services.
                  </li>
                  <li>
                    3.5 &nbsp;&nbsp;&nbsp;The Customer assigns all rights, title, and interest in any innovations or suggestions created by Customer or viewers regarding the attributes, performance or features of the Services (“Feedback”) to the Company. If for any reason such assignment is ineffective, the Customer shall grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.
                  </li>
                </ul>
              </li>
              <li>
                <h4>TERM</h4>
                <ul>
                  <li>
                    4.1 &nbsp;&nbsp;&nbsp;This Agreement shall commence on the
                    Effective Date and continue until either party terminates
                    the Agreement by giving the other at least 30 days’ notice
                    at any time.
                  </li>
                </ul>
              </li>
              <li>
                <h4>THIRD PARTY PROVIDERS AND INTERFACES</h4>
                <ul>
                  <li>
                    5.1 &nbsp;&nbsp;&nbsp;The Customer acknowledges that use of the Services may enable or assist it to access the website content of, correspond with, and purchase products and services from, third parties via third-party websites and that it does so solely at its own risk. The Company makes no representation or commitment and shall have no liability or obligation whatsoever in relation to the content or use of, or correspondence with, any such third-party website, or any transactions completed, and any contract entered into by the Customer, with any such third party. Any contract entered into and any transaction completed via any third-party website is between the Customer and the relevant third party, and not the Company. 
                  </li>
                </ul>
              </li>
              <li>
                <h4>FEES AND INVOICING</h4>
                <ul>
                  <li>
                    6.1 &nbsp;&nbsp;&nbsp;The Company shall invoice the Customer the fees set out in the Customer Profile payable by the Customer during the Term (“Fees”).  All invoices shall be issued and paid in the currency stated in the Customer Profile. All Fees exclude any Tax legally payable on the date of the invoice, which shall be paid by the Customer in addition, where applicable.
                  </li>
                </ul>
              </li>
              <li>
                <h4>PAYMENT TERMS</h4>
                <ul>
                  <li>
                    7.1 &nbsp;&nbsp;&nbsp;The Customer shall pay the Company the
                    Fees for the provision of the Services under this Agreement
                    as set out in the Customer Profile.
                  </li>
                  <li>
                    7.2 &nbsp;&nbsp;&nbsp;Payment of all Fees is due within 30
                    days of the date of invoices. If the Customer believes that
                    any invoice is incorrect, it must notify the Company in
                    writing within 30 days of the invoice date.
                  </li>
                  <li>
                    7.3 &nbsp;&nbsp;&nbsp;Where payment of any Fee is not
                    received within 14 days of the due payment date, the Company
                    may, without liability to the Customer, disable the
                    Customer’s password, account and access to all or part of
                    the Services and the Company shall be under no obligation to
                    provide any or all of the Services while the invoice(s)
                    concerned remains unpaid. The Company shall be entitled to
                    charge interest on overdue Fees at the applicable statutory
                    rate.
                  </li>
                  <li>
                    7.4 &nbsp;&nbsp;&nbsp;The Company reserves the right to
                    recover any costs and reasonable legal fees it incurs in
                    recovering overdue payments.
                  </li>
                </ul>
              </li>
              <li>
                <h4>CONFIDENTIAL INFORMATION</h4>
                <ul>
                  <li>
                    8.1 &nbsp;&nbsp;&nbsp;Any and all information in any form whatsoever relating to the Company or the Customer, or the business, prospective business, finances, technical process, computer software (both source code and object code) and IPR of the Company or the Customer (as the case may be), which comes into a party’s possession as a result of this Agreement or provision of the Services, and which the party regards, or could reasonably be expected to regard, as confidential and any and all information which has been or may be derived or obtained from any such information (“Confidential Information”) shall be used by either party only for the purposes of this Agreement. Each party must keep confidential all Confidential Information disclosed to it, except where the recipient of Confidential Information is required to disclose the Confidential Information by law to any regulatory, governmental, or other authority with relevant powers to which either party is subject.
                  </li>
                  <li>
                    8.2 &nbsp;&nbsp;&nbsp;Each party may disclose the Confidential Information of the other party to those of its employees and agents who need to know the Confidential Information for the purposes of this Agreement, but only if the employee or agent is bound by confidentiality undertakings equivalent to those set out in this Agreement.
                  </li>
                  <li>
                    8.3 &nbsp;&nbsp;&nbsp;Both parties agree to return (or destroy) all documents, materials or data containing Confidential Information to the disclosing party without delay upon completion of the Services or termination or expiry of this Agreement.
                  </li>
                </ul>
              </li>
              <li>
                <h4>DATA PROTECTION</h4>
                <ul>
                  <li>
                    9.1 &nbsp;&nbsp;&nbsp;Each party undertakes to comply with its obligations under relevant applicable data protection laws, principles and agreements.


                  </li>
                  <li>
                    9.2 &nbsp;&nbsp;&nbsp;To the extent that personal data is processed when the Customer or Viewers use the Solution and Services, the parties acknowledge that the Company is a data processor, and the Customer is a data controller.
                  </li>
                  <li>
                    9.3 &nbsp;&nbsp;&nbsp;The parties shall comply with their respective obligations under the terms of the DPA.
                  </li>
                  <li>
                    9.4 &nbsp;&nbsp;&nbsp;If a third party alleges infringement
                    of its data protection rights, the Company shall be entitled
                    to take measures necessary to prevent the infringement of a
                    third party’s rights from continuing.
                  </li>
                  <li>
                    9.5 &nbsp;&nbsp;&nbsp;Where the Company collects and
                    processes personal data of the Customer, as a data
                    controller, when providing the Solution and Services to the
                    Customer, such collection and processing shall be in
                    accordance with the Privacy Policy.
                  </li>
                </ul>
              </li>
              <li>
                <h4>REPRESENTATIONS AND WARRANTIES</h4>
                <ul>
                  <li>
                    10.1 &nbsp;&nbsp;&nbsp;Customer warrants and represents
                    that it has full power and authority to enter into this
                    Agreement and to perform the obligations required hereunder;
                    the execution and performance of its obligations under this
                    Agreement does not violate or conflict with the terms of any
                    other agreement to which it is a party and is in accordance
                    with any applicable laws; that it rightfully owns the
                    necessary user rights, copyrights and ancillary copyrights
                    and permits required for it to fulfil its obligations under
                    this Agreement.
                  </li>
                </ul>
              </li>
              <li>
                <h4>LIABILITY</h4>
                <ul>
                  <li>
                    11.1 &nbsp;&nbsp;&nbsp;Company shall not be liable for any
                    consequential loss arising out of or related to this
                    Agreement or in tort (including negligence or breach of
                    statutory duty), misrepresentation or however arising.
                  </li>
                  <li>
                    11.2 &nbsp;&nbsp;&nbsp;The Customer shall be liable for any
                    breaches of this Agreement caused by the acts, omissions or
                    negligence of any viewers who access the Services as if such
                    acts, omissions or negligence had been committed by the
                    Customer itself
                  </li>
                  <li>
                    11.3 &nbsp;&nbsp;&nbsp;The Customer shall not raise any
                    claim under this Agreement more than 1 year after the
                    discovery of the circumstances giving rise to a claim; or
                    the effective date of termination or expiry of this
                    Agreement. The parties acknowledge and agree that in
                    entering into this Agreement, each had recourse to its own
                    skill and judgement and have not relied on any
                    representation made by the other, their employees or agents.{" "}
                  </li>
                </ul>
              </li>
              <li>
                <h4>TERMINATION</h4>
                <ul>
                  <li>
                    12.1 &nbsp;&nbsp;&nbsp;The Company may terminate this
                    Agreement or the provision of any Services with immediate
                    effect if the Customer has used or permitted use of the
                    Services other than in accordance with this Agreement; or
                    the Company is prohibited under applicable law, or otherwise
                    from providing the Services.
                  </li>
                  <li>
                    12.2 &nbsp;&nbsp;&nbsp;Company may terminate this Agreement
                    immediately, with cause, if the Customer ceases or threatens
                    to cease or carry-on business; is unable to pay its debts or
                    enters compulsory insolvency or voluntary liquidation;
                    convenes a meeting of its creditors or has a receiver,
                    manager or similar official appointed in respect of its
                    assets; or a Force Majeure event (as defined the Terms and
                    Conditions) lasts for more than 28 days.
                  </li>
                  <li>
                    12.3 &nbsp;&nbsp;&nbsp;Either party may terminate this
                    Agreement for material breach of any term by giving the
                    breaching party written notice. However, where the breach is
                    capable of remedy, provided that the breach is specified and
                    remedy of the breach is requested, the notice shall only be
                    effective if the breaching party fails to remedy the breach
                    within 10 days of receipt of the notice.
                  </li>
                  <li>
                    12.4 &nbsp;&nbsp;&nbsp;Termination of this Agreement for any
                    reason shall not affect the accrued rights of the parties
                    arising under this Agreement and without limitation the
                    right to recover damages against the other. Termination of
                    this Agreement for whatever reason shall not affect the
                    accrued rights of the parties. All clauses which by their
                    nature should continue after termination shall, for the
                    avoidance of doubt, survive the expiration or sooner
                    termination of this Agreement and shall remain in force and
                    effect.
                  </li>
                  <li>
                    12.5 &nbsp;&nbsp;&nbsp;Upon termination of this Agreement,
                    the Customer shall promptly pay the Company all unpaid Fees
                    and all licenses granted under the Agreement shall terminate
                    on the effective date of termination; and the Company shall
                    cease providing the Services to the Customer.{" "}
                  </li>
                </ul>
              </li>
              <li>
                <h4>SECURITY</h4>
                <ul>
                  <li>
                    13.1 &nbsp;&nbsp;&nbsp;The Company shall permit the Customer
                    to specify which Viewers may access the Services. The
                    Customer and Viewers must ensure that each password is only
                    used by the user to which it has been assigned. The Customer
                    is responsible for all activities that occur under the
                    Customer’s account and via the Customer’s passwords. The
                    Customer will immediately notify the Company if the Customer
                    becomes aware of any unauthorized use of the Customer’s
                    account, the Customer’s passwords or breach of security
                    known to the Customer. The Company shall have no liability
                    for any loss or damage arising from the Customer’s failure
                    to comply with these requirements.
                  </li>
                  <li>
                    13.2 &nbsp;&nbsp;&nbsp;The Company may suspend access to the
                    Services at any time, if in the Company’s sole reasonable
                    discretion, the integrity or security of the Services is in
                    danger of being compromised by acts of the Customer or
                    Viewers. The Company shall give the Customer prior written
                    notice, before suspending access to the Services, giving
                    specific details of its reasons.
                  </li>
                  <li>
                    13.3 &nbsp;&nbsp;&nbsp;The Customer is solely responsible
                    for the use and streaming rights of the content being
                    streamed through the Services. This is including all room
                    participants, streaming rights, recording rights, and
                    playback rights.
                  </li>
                </ul>
              </li>
              <li>
                <h4>CONTACTS</h4>
                <ul>
                  <li>
                    14.1 &nbsp;&nbsp;&nbsp;The Customer shall provide a
                    designated application support contact who will be
                    responsible for and have sufficient information to respond
                    to support questions, and a designated billing contact with
                    all relevant contact information to respond to billing and
                    payment questions regarding the Services.
                  </li>
                </ul>
              </li>
              <li>
                <h4>MISCELLANEOUS</h4>
                <ul>
                  <li>
                    15.1 &nbsp;&nbsp;&nbsp;This Agreement: (i) is governed by California substantive and procedural law and, without regard to choice of law rules, California courts shall have jurisdiction for all purposes; (ii) merges in it all prior representations and negotiations, and, together with any exhibits or schedules, is the final, exclusive statement of the terms and conditions of agreement; (iii) shall not be assignable without written consent of all parties; (iv) shall benefit and bind each party's successor, of whatever legal description; (v) may be executed by electronic facsimile and in counterparts, which nevertheless constitute one and the same agreement; (vi) is deemed the joint legal product of all parties, no matter the draftsman's identity; (vii) shall be kept in confidence by the parties except where required by a competent legal or administrative order; (viii) may not be amended, terminated or modified except by a writing signed by the party to be charged, and (ix) is severable and divisible as to all of its provisions, and if any provision is held invalid, the remaining provisions shall continue in full force and effect.
                  </li>
                  <li>
                    15.2 &nbsp;&nbsp;&nbsp;Except with respect to the Customer’s obligation to pay the Fees, if a party is wholly or partially unable to comply with its obligations under this Agreement due to Force Majeure, then that party’s obligation to perform in accordance with this Agreement will be suspended for the duration of the Force Majeure.
                  </li>
                  <li>
                    15.3 &nbsp;&nbsp;&nbsp;Neither party shall make any public statement, press release or other announcement relating to the terms or existence of this Agreement, or the business relationship of the parties, without the prior written consent of the other party. Notwithstanding the aforesaid the Company may use the Customer’s name and trademarks (logo only) to list the Customer as a client of the Company on its website and in other marketing materials and information.
                  </li>
                </ul>
              </li>
            </ol>
            <p>
            This document has been most recently updated on August 22, 2022
            </p>
          </div>
        </div>
      </div>
    );
  }
};
export default TermsAndConditions;

import React, { useState } from "react";
import './styles.scss';
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Result } from 'antd';
import { useChatStore } from '../../../../../store';
interface EmojiImageProps {
  emoji: string;
  sendEmoji: any;
}

const EmojiImage: React.FC<EmojiImageProps> = ({ emoji,sendEmoji }) => {
  const { showEmoji, setShowEmoji } = useChatStore();


  const handleEmojiClick = (emoji: any) => {
    console.log("Selected emoji:", emoji.native);
    setShowEmoji(true);
    sendEmoji(emoji.native);
  };

  return (
    <div>
      <div
        className="emoji-img-wrapper testt"
        onClick={() =>setShowEmoji(!showEmoji)}
      >
        <img
          src={emoji}
          className="emoji-img"
          alt="Emoji"
        />
      </div>
      {showEmoji && (
        <div className="emoji-picker-zoom">
          <Picker onEmojiSelect ={handleEmojiClick} />
        </div>
      )}
    </div>
  );
};

export default EmojiImage;
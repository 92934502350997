import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../../assets/images/sidebar-logo.png";
import { Button, Checkbox, Space, Divider, Form, Modal } from "antd";
import { signInWithEmailAndPassword } from "firebase/auth";
import { AuthContext } from "../../../context/auth";

import ErrorComponent from "../../ErrorComponent";
// import { useForm } from "../../../hooks/useForm";

import PageLayout, { PageContent } from "../../../layouts/PageLayout";
import InputComponent from "../../InputComponent";
import Title from "../../Title";
import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";
import SocialSignupLogin from "../SocialSignupLogin";

import "./styles.scss";
import { auth } from "../../../firebase/utils";
import Loader from "../../LoaderHOC";
import { doSignOut } from "../../../firebase/auth";
import { get } from "../../../utils/helpers";
import { isAvidBrowser } from "../../../utils/avidBrowserCheck";
import { signInSuccess } from "../../../firebase/analytics";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const SignIn = (props: any) => {
  const [form] = Form.useForm();
  const [userMail, setUserMail] = useState(localStorage.userMail);
  const [password, setPassWord] = useState("");
    const [loginLoading, setLoginLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [signinErr, setSigninErr] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [navModal, setNavModal] = useState(false);
  const [rememberMe, setRememberMe] = useState(localStorage.rememberMePPLLogin === "true");
  const navigate = useNavigate();
  const user: any = useContext(AuthContext);
  const isAvidBrowserCheck =  isAvidBrowser();
  const freeRoomAddRef = useRef(false);
  useEffect(() => {

    
    const navigateUser = async () => {
      try {
        const data = {
          api: api.room.getAllActiveRoomsOfUser,
        };

        setLoading(true);
        const rooms = await backend.fetch(data, true);
        if (
          !get(user,'linkLogin',false) &&
          !rooms.length &&
          !freeRoomAddRef.current
        ) {
          //Add free room
          await addFreeTrialRoom();
        } else {
          sessionStorage.setItem('is_firstVisit', '1')
          await signInSuccess();
          navigate(
               "/home"
          );
        }
      } catch (err: any) {
        console.error(err);
      }
    };

    if (user && get(user,'emailVerified',false)) {
      // resetValues();
      if(get(user,'linkLogin',false)){
        doSignOut();
        return; 
      }
      setUserMail("");
      setPassWord("");
      navigateUser();
    }
  }, [user]);

  const addFreeTrialRoom = async () => {
    try {
      const data = { api: api.room.addFreeRoom };
      freeRoomAddRef.current = true;
      const result = await backend.save(data, true);
      if(result){
        sessionStorage.setItem('is_firstVisit', '1')
        await signInSuccess();
        navigate("/home")
      }
      return result;
    } catch (error) {
      freeRoomAddRef.current = false;
      console.log(error);
    }
  };
  
  const getUserInformation = async () => {
    try {
      const data = {
        api: api.user.get,
      };
      setLoading(true);
      const response = await backend.fetch(data, true);

      if (!response || !response.two_fa_enabled) {
        sessionStorage.setItem('is_firstVisit', '1')
        await signInSuccess();
        if (!!response.force_password) {
          navigate(
               "/resetpassword"
          );
        } else {
          navigate(
               "/home"
          );
        }
      } else {
        sessionStorage.setItem('is_firstVisit', '1')
        await signInSuccess();
        navigate(
          "/otp"
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function saveRememberMePostLogin() {
    if (rememberMe && !isAvidBrowserCheck) {
      localStorage.userMail = userMail;
      localStorage.rememberMePPLLogin = true;
    } else {
      localStorage.userMail = "";
      localStorage.rememberMePPLLogin = false;
    }
  }
  function getUserMailFromLocalStore(){
    const res = localStorage.rememberMePPLLogin ? localStorage.userMail : "";
    return res;
  }
  async function loginUser() {
    // To-do: Add Proper validation of email
    const { userMail, password } = form.getFieldsValue()
    if (!userMail) {
      setError("Email can not be empty");
      return;
    }
    if (!password) {
      setError("Password can not be empty");
      return;
    }


    try {
      setLoginLoading(true);
      setError("");
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        userMail,
        password
      );
      console.log(userCredentials);
      if (userCredentials) {
        // resetValues();
        saveRememberMePostLogin();
        setUserMail("");
        setPassWord("");
        getUserInformation();      
      }
    } catch (err: any) {
      console.error(err);
      if (
        err.message === "Firebase: Error (auth/user-not-found)." ||
        err.message === "Firebase: Error (auth/wrong-password)."
      ) {
        setEmailValid(false);
        setPasswordValid(false);
        setSigninErr("Login fail. Please Try Again");
        //messageComponent({messages:"Login fail. Please Try Again", type:"error",top:10})
      } else if (err.message === "Firebase: Error (auth/user-disabled).") {
        setEmailValid(false);
        setPasswordValid(false);
        setSigninErr("Your account has been blocked.");
      }
      setError(err.message);
    } finally {
      setLoginLoading(false);
    }
  }

  return (
    <Loader loading={loading}>
      <PageLayout>
        <PageContent className="left-content">
          <img src={logo} alt="logo" className="logo" />
        </PageContent>
        <PageContent className="signin-right-content">
          {/* <form onSubmit={onSubmit} className="signin-form"> */}

          <Space direction="vertical" size={10} className="loginForm-container">
            <Title level={3}>Log into your PacPost.io account </Title>
            <div /*className="loginForm-container"*/>
              <Form
               form={form}
                name="login"
                onFinish={loginUser}
                initialValues={{
                  rememberMe: localStorage.rememberMePPLLogin === "true",
                  userMail: getUserMailFromLocalStore()
                }}
                autoComplete={rememberMe ? "on" : "off"}
              >
                <Form.Item
                  name="userMail"
                  rules={[
                    {
                      validator: async (_: any, val: any) => {
                                                if (val === "") {
                          setEmailValid(false);
                          return Promise.reject(
                            <ErrorComponent
                              errorMsg={"Please enter email"}
                              setErrorMsg={setErrorMsg}
                            />
                          );
                        } else if (!emailRegex.test(val)) {
                          setEmailValid(false);
                          return Promise.reject(
                            <ErrorComponent
                              errorMsg={"Invalid email format"}
                              setErrorMsg={setErrorMsg}
                            />
                          );
                        } else {
                          setEmailValid(true);
                          setSigninErr("");
                        }
                      },
                    },
                  ]}
                >
                  <div>
                    <label className="custum-label">
                      EMAIL<span className="required">*</span>
                    </label>
                    <InputComponent
                      key={"userMail"}
                      value={userMail}
                      onChange={setUserMail}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      validator: async (_: any, val: any) => {
                        if (val === "") {
                          setPasswordValid(false);
                          return Promise.reject(
                            <ErrorComponent
                              errorMsg={"Please enter Password"}
                              setErrorMsg={setErrorMsg}
                            />
                          );
                        } else {
                          setPasswordValid(true);
                          setSigninErr("");
                          return Promise.resolve;
                        }
                      },
                    },
                  ]}
                >
                  <div>
                    <label className="custum-label">
                      PASSWORD<span className="required">*</span>
                    </label>
                    <InputComponent
                      key={"PASSWORD"}
                      value={password}
                      type="password"
                      onChange={setPassWord}
                    />
                  </div>
                </Form.Item>
                <div className="rememberme-forgotpwd">
                   
                  <div className="rememberme-wrapper">
                    {/* TODO: Checkbox styling as per UX */}
                    {!isAvidBrowserCheck && (<Form.Item name="rememberMe">
                      <Checkbox
                        checked={rememberMe}
                        onChange={(e) => {
                          form.setFieldValue('rememberMe', e.target.checked); 
                          setRememberMe(e.target.checked);
                        }}
                      >
                        Remember me
                      </Checkbox>
                    </Form.Item>)}
                  </div>
                  
                  <div className="forgotpwd-wrapper">
                    <Button
                      type="link"
                      className="register-link"
                      onClick={() =>
                        navigate("/forgotpassword"
                        )
                      }
                    >
                      Forgot Password?
                    </Button>
                  </div>
                </div>
                <div className="login-btn-container">
                  <Form.Item>
                    <div className="sign-in-err">
                      <ErrorComponent
                        errorMsg={signinErr}
                        setErrorMsg={setErrorMsg}
                      />
                    </div>
                    <Button
                      htmlType="submit"
                      className="login-btn"
                      // onClick={loginUser}
                      disabled={(
                        (errorMsg !== "" || (!emailValid || !passwordValid)) &&
                        ((!userMail || !password) || !emailRegex.test(userMail))
                      )}
                      loading={loginLoading}
                    >
                      Log in
                    </Button>
                  </Form.Item>

                  <Button
                    type="link"
                    className="register-link"
                    onClick={() =>
                      navigate("/SignUp"
                      )
                    }
                  >
                    Don’t have an account yet? Create new*
                  </Button>
                </div>
              </Form>
            </div>

            <Divider>or Log In with</Divider>
            <SocialSignupLogin setLoading={setLoading} setError={setError} />
          </Space>
          {/* </form> */}
        </PageContent>
      </PageLayout>
    </Loader>
  );
};

export default SignIn;

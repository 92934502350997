import logo from "../../../assets/images/sidebar-logo.png";
import RightNavControls from "./RightNavControls";
import HeaderTopVCControls from "./HeaderTopVCControls";
import { useConferenceCommands } from "../hooks/useConferenceCommands";
import useAutoLogout from "../../../pages/ZoomViewer/hooks/useAutoLogout";

const ZoomHeaderView = (props: any) => {
  const {
    admin_user_id,
    adminAccessCode,
    role,
    room_code,
    room_id,
    onScreenShareClick,
    editorDirectLogin
  } = props;

  useAutoLogout(admin_user_id)
  const { handelVideoConfEnd } = useConferenceCommands(
    room_code,
    room_id,
    admin_user_id
  );

  return (
    <div className="topbar">
      {/* Left  Logo*/}
      <div className="logo-viewer">
        <img src={logo} alt="logo" style={{ margin: "15px 0 0 15px" }} />
      </div>
      {/* Top VC Controls*/}
      <div className="centerMenuTop">
        <HeaderTopVCControls
          admin_user_id={admin_user_id}
          adminAccessCode={adminAccessCode}
          role={role}
          room_code={room_code}
          room_id={room_id}
          onScreenShareClick={onScreenShareClick}
        />
      </div>

      {/* Right Nav Controls with Settings */}
      <div className="menuTopRight">
        <RightNavControls handelVideoConfEnd={handelVideoConfEnd} editorDirectLogin={editorDirectLogin}/>
      </div>
    </div>
  );
};

export default ZoomHeaderView;

import React, { useState, useEffect, useContext, useCallback, useReducer, useMemo, useRef } from 'react';
import produce from 'immer';
import { Layout, message, Modal } from 'antd';
import ZoomVideo, { ConnectionState, ReconnectReason } from '@zoom/videosdk';
import ZoomHeaderView from './ZoomHeaderView';
import ChatMain from './ChatMenu'
import GuestViewer from './GuestView/GuestInvite'
import ZoomContext from '../../pages/ZoomViewer/context/zoom-context';
import ZoomMediaContext from '../../pages/ZoomViewer/context/media-context';
import { MediaStream } from '../../pages/ZoomViewer/types/index-types';
import ZoomVideoTray from '../ZoomVideoTray';
import { useViewerSlice } from '../../store';
import Stream from './ShowStream';
import { get } from '../../utils/helpers';
import './styles.scss';
import './newshowviewer_zoom.scss';
import GuestInvite from './GuestView/GuestInvite';
import { useAudio } from '../../pages/ZoomViewer/hooks/useAudio';
import { useConferenceCommands } from './hooks/useConferenceCommands';
import useAutoLogout from '../../pages/ZoomViewer/hooks/useAutoLogout';
import { useUpdateGuestViewers } from '../../pages/ZoomViewer/hooks/useUpdateGuestViewers';
import { AuthContext } from '../../context/auth';
import useWhiteboarding from './hooks/useWhiteboarding';
import useVratio from './hooks/useVRatio';
import ProgressLoader from '../Common/ProgressLoader';
import { GuestListProvider } from '../../pages/ZoomViewer/context/guest-context';
import HudStats from './HudsStats/HudsStats';
import useHudsStatsStore from '../../store/slices/viewer/hudsStatsStore';

const { Header, Sider, Content } = Layout;

/*
 * White boarding
 */
type Coordinate = {
  x: number;
  y: number;
};

interface AppProps {
  videoConfEndRef: any;
  admin_user_id: string;
  room_id: string;
  room_name: string;
  room_code: string;
  role: string;
  firstName: string;
  lastName: string;
  uuid: string;
  resetValues: string;
  guestList: any;
  accessCode: string;
  editorDirectLogin: boolean;
  meetingArgs: {
    sdkKey: string;
    topic: string;
    name: string;
    password?: string;
    webEndpoint?: string;
    enforceGalleryView?: string;
    enforceVB?: string;
    customerJoinId?: string;
    lang?: string;

    useVideoPlayer?: string;
  };
  signature: string;
}

const mediaShape = {
  audio: {
    encode: false,
    decode: false
  },
  video: {
    encode: false,
    decode: false
  },
  share: {
    encode: false,
    decode: false
  }
};
const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case 'audio-encode': {
      draft.audio.encode = action.payload;
      break;
    }
    case 'audio-decode': {
      draft.audio.decode = action.payload;
      break;
    }
    case 'video-encode': {
      draft.video.encode = action.payload;
      break;
    }
    case 'video-decode': {
      draft.video.decode = action.payload;
      break;
    }
    case 'share-encode': {
      draft.share.encode = action.payload;
      break;
    }
    case 'share-decode': {
      draft.share.decode = action.payload;
      break;
    }
    case 'reset-media': {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    default:
      break;
  }
}, mediaShape);

declare global {
  interface Window {
    webEndpoint: string | undefined;
    zmClient: any | undefined;
    mediaStream: any | undefined;
    crossOriginIsolated: boolean;
    ltClient: any | undefined;
    logClient: any | undefined;
  }
}

const ZoomViewer = (props: AppProps) => {
  const {
    meetingArgs: {
      sdkKey,
      topic,
      name,
      password,
      webEndpoint: webEndpointArg,
      enforceGalleryView,
      enforceVB,
      customerJoinId,
      lang,
      useVideoPlayer
    },
    signature,
    accessCode,
    guestList,
    room_id,
    room_code,
    admin_user_id,
    role,
    editorDirectLogin,
    videoConfEndRef,
  } = props;

  // const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  // const toggleSidebar = () => {
  //   setIsSidebarOpen(!isSidebarOpen);
  // };

  const zmClient = useContext(ZoomContext);
  const [loading, setIsLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('');
  const [isFailover, setIsFailover] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('closed');
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  const { renderCanvas } = useWhiteboarding()
  const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);
  const galleryViewWithoutSAB = Number(enforceGalleryView) === 1 && !window.crossOriginIsolated;
  const vbWithoutSAB = Number(enforceVB) === 1 && !window.crossOriginIsolated;
  
  const shareViewRef = useRef<{ selfShareRef: HTMLCanvasElement | HTMLVideoElement | null }>(null);
  const alreadyJoinedGuests = useRef<number[]>([])
  
  const { handelVideoConfEnd } = useConferenceCommands(room_code, room_id, admin_user_id)

  const userLoggedIn: any = useContext(AuthContext);
  const isGuestViewer = get(userLoggedIn, 'guestViewer', false);
  // const list = useUpdateGuestViewers(guestList, isGuestViewer);

  const [vratio, setVRatio, vratioRef] = useVratio(1.6);
  const [clearChatFlag, setclearChatFlag] =  useState(false);
  

  useEffect(() => {
    const init = async () => {
      const webEndpoint = webEndpointArg || 'zoom.us';
      await zmClient.init('en-US', 'Global', {
        webEndpoint,
        // enforceMultipleVideos: true,
        enforceVirtualBackground: vbWithoutSAB,
        stayAwake: true,
        patchJsMedia: true,
        leaveOnPageUnload: false
      });
      try {
        setLoadingText('Joining the session...');
        await zmClient.join(topic, signature, name, password).catch((e) => {
          console.log(e);
        });

        const canvas: any = document.getElementById("canvas");
        renderCanvas(canvas, vratioRef.current);

        const stream = zmClient.getMediaStream();
        stream.startAudio({ mute: true })
        setMediaStream(stream);
        setIsLoading(false);
        videoConfEndRef.current = handelVideoConfEnd;
      } catch (e: any) {
        setIsLoading(false);
        message.error(e.reason);
        console.log(e.reason)
      }
    };
    if (topic && signature && name && !mediaStream) {
      init();
    }
    // return () => {
    //   ZoomVideo.destroyClient();
    // };
  }, [sdkKey, signature, zmClient, topic, name, password, customerJoinId, lang, galleryViewWithoutSAB, vbWithoutSAB, webEndpointArg]);

  const onConnectionChange = useCallback(
    (payload: any) => {
      if (payload.state === ConnectionState.Reconnecting) {
        setIsLoading(true);
        setIsFailover(true);
        setStatus('connecting');
        const { reason, subsessionName } = payload;
        if (reason === ReconnectReason.Failover) {
          setLoadingText('Session Disconnected,Try to reconnect');
        } else if (reason === ReconnectReason.JoinSubsession || reason === ReconnectReason.MoveToSubsession) {
          setLoadingText(`Joining ${subsessionName}...`);
        } else if (reason === ReconnectReason.BackToMainSession) {
          setLoadingText('Returning to Main Session...');
        }
      } else if (payload.state === ConnectionState.Connected) {
        setStatus('connected');
        if (isFailover) {
          setIsLoading(false);
        }
        window.zmClient = zmClient;
        window.mediaStream = zmClient.getMediaStream();

        console.log('getSessionInfo', zmClient.getSessionInfo());
      } else if (payload.state === ConnectionState.Closed) {
        setStatus('closed');
        dispatch({ type: 'reset-media' });
        if (payload.reason === 'ended by host') {
          handelVideoConfEnd()
          // Modal.warning({
          //   title: 'Meeting ended',
          //   content: 'This meeting has been ended by host'
          // });
        }
      }
    },
    [isFailover, zmClient]
  );
  const onMediaSDKChange = useCallback((payload: any) => {
    const { action, type, result } = payload;
    dispatch({ type: `${type}-${action}`, payload: result === 'success' });
  }, []);

  // const onDeviceChange = useCallback(() => {
  //   console.log("onDeviceChange called")
  // }, []);

  const onCommandChannelMessage = useCallback(async (payload: any) => {
    const canvas: any = document.getElementById("canvas");
    console.log('payload-onCommandChannelMessage', payload)
    const {
      isPainting,
      color,
      mousePosition,
      newMousePosition,
      incoming_canvas,
      erase,
      clear,
      globalMute,
      nameTextId,
      className,
      clearAnnotationChat,
      clearGroupChat,
      isCircle,
      endConference,
    } = JSON.parse(payload.text);
    if (isPainting && canvas) {
      const curr_canvas: any = document.getElementById("canvas");
      let bb = curr_canvas?.getBoundingClientRect();
      const bbWidth = get(bb, "width", 0);
      const bbHeight = get(bb, "height", 0);
      const curr_canvas_width = bbWidth;
      const curr_canvas_height = bbHeight;

      let oldMousePos: Coordinate, newMousePos: Coordinate;

      if (curr_canvas) {
        oldMousePos = {
          x: (mousePosition.x / incoming_canvas.width) * curr_canvas_width,
          y:
            (mousePosition.y / incoming_canvas.height) * curr_canvas_height,
        };
        newMousePos = {
          x:
            (newMousePosition.x / incoming_canvas.width) *
            curr_canvas_width,
          y:
            (newMousePosition.y / incoming_canvas.height) *
            curr_canvas_height,
        };
        if (isCircle) {
          drawCircle(color, oldMousePos, newMousePos);
        } else {
          console.log('erase', erase)
          drawLine(color, oldMousePos, newMousePos, erase);
        }
        canvas.className = "canvas-pencil-on";
      }
    }

    if (clear) {
      const canvas: any = document.getElementById("canvas");
      let context = canvas.getContext("2d");
      const canvasWidth = get(canvas, "width", 0);
      const canvasHeight = get(canvas, "height", 0);
      context.clearRect(0, 0, canvasWidth, canvasHeight);
    }

    if(clearGroupChat){
      setclearChatFlag(true)
    }
    
  }, []);

  const onLeaveOrJoinSession = useCallback(async () => {
    if (status === 'closed') {
      setIsLoading(true);
      await zmClient.join(topic, signature, name, password);
      setIsLoading(false);
    } else if (status === 'connected') {
      await zmClient.leave();
      message.warn('You have left the session.');
    }
  }, [zmClient, status, topic, signature, name, password]);

  useEffect(() => {
    zmClient.on('connection-change', onConnectionChange);
    zmClient.on('media-sdk-change', onMediaSDKChange);
    zmClient.on('command-channel-message', onCommandChannelMessage);
    // zmClient.on('device-change', onDeviceChange)
    return () => {
      zmClient.off('connection-change', onConnectionChange);
      zmClient.off('media-sdk-change', onMediaSDKChange);
      zmClient.off('command-channel-message', onCommandChannelMessage);
      // zmClient.on('device-change', onDeviceChange)
      ZoomVideo.destroyClient()
    };
  }, [zmClient, onConnectionChange, onMediaSDKChange, onCommandChannelMessage]);

  const [adminAccessCode, setAdminAccessCode] = useState(accessCode)

  // White boarding
  // const inMemCanvas: HTMLCanvasElement = document.createElement("canvas");

  // const [vratio, _setVRatio] = useState(1.6);
  // const vratioRef = useRef(vratio);

  // const [isPainting, setIsPainting] = useState(false);
  // const [mousePosition, setMousePosition] = useState<Coordinate | undefined>(
  //   undefined
  // );

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const drawLine = (
    color: any,
    originalMousePosition: Coordinate,
    newMousePosition: Coordinate,
    erase: Boolean
  ) => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;

    const context = canvas.getContext("2d");
    if (context) {
      context.lineJoin = context.lineCap = "round";

      context.beginPath();

      if (!erase) {
        context.globalCompositeOperation = "source-over";
        context.strokeStyle = color;
        context.lineWidth = 5;
      } else {
        context.globalCompositeOperation = "destination-out";
        context.lineWidth = 20;
      }

      context.moveTo(originalMousePosition.x, originalMousePosition.y);
      context.lineTo(newMousePosition.x, newMousePosition.y);
      context.closePath();

      context.stroke();
    }
  };

  const drawCircle = (
    color: any,
    originalMousePosition: Coordinate,
    newMousePosition: Coordinate
  ) => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;

    const context = canvas.getContext("2d");
    if (context) {
      context.lineJoin = context.lineCap = "round";

      context.beginPath();
      context.globalCompositeOperation = "source-over";
      context.strokeStyle = color;
      context.lineWidth = 3;

      // calculating the midX and midY
      var midY =
        originalMousePosition.y +
        (newMousePosition.y - originalMousePosition.y) * 0.5;
      var midX =
        originalMousePosition.x +
        (newMousePosition.x - originalMousePosition.x) * 0.5;
      var radius =
        Math.hypot(
          newMousePosition.x - originalMousePosition.x,
          newMousePosition.y - originalMousePosition.y
        ) / 2;

      context.arc(midX, midY, radius, 0, 2 * Math.PI);

      context.stroke();
    }
  };

  const onScreenShareClick = async () => {
    const stream: any = zmClient.getMediaStream();
    if (stream?.isStartShareScreenWithVideoElement()) {
      await stream.startShareScreen(
        document.querySelector(`#screen-share-content-video`)
      )
      // screen share successfully started and rendered
    } else {
      await stream.startShareScreen(
        document.querySelector(`#screen-share-content-canvas`)
      )
    }
  };

  

  const VideoTrayContainer = () => {  
    return <div className='zoom-video-tray'>
        <ZoomVideoTray alreadyJoinedGuests={alreadyJoinedGuests} />
      </div>
  }

  const RightSideContainer = () => {
    const { rightActivePanel } = useViewerSlice();
    return (
      <Sider
        style={{ display: rightActivePanel ? "block" : "none" }}
        className="viewer-side-panel"
      >
        {rightActivePanel === "chat" ? (
          <ChatMain
            editorDirectLogin={editorDirectLogin}
            admin_user_id={admin_user_id}
            room_id={room_id}
            clearChatFlag={clearChatFlag} 
            setclearChatFlag={setclearChatFlag}
          />
        ) : null}
        {!isGuestViewer &&<GuestListProvider
          room_id={room_id}
          isHost={!isGuestViewer}
          guestList={guestList}
        >
          {" "}
          {rightActivePanel === "guest" ? (
            <GuestInvite admin_user_id={admin_user_id} room_id={room_id} />
          ) : null}
        </GuestListProvider>}
      </Sider>
    );
  };

  const RenderHudsStats = () =>{
    const { hudsState } = useHudsStatsStore();
    if (!hudsState.openHudStatsPanel) {
      return<></>
    }
    return(<HudStats />)
  }

  return (
    <ZoomMediaContext.Provider value={mediaContext}>
      <ProgressLoader loading={loading} />
      <Layout className="layout show-new-viewer zoom-viewer">
        <Header className="zoom-viewer-header" >
          <ZoomHeaderView
            admin_user_id={admin_user_id}
            adminAccessCode={adminAccessCode}
            role={role}
            room_code={room_code}
            room_id={room_id}
            onScreenShareClick={onScreenShareClick}
            editorDirectLogin={editorDirectLogin}
          />
        </Header>
        <Layout>
        <RenderHudsStats />
          <Content  id='container-wrapper' className="content" style={{
            display: 'flex',
            flexDirection:'column'
          }}>
            <div className='zoom-video-player' style={{flexGrow: 1}}>
              <Stream
                canvasRef={canvasRef}
                room_id={room_id}
                admin_user_id={admin_user_id}
              />
            </div>
            <VideoTrayContainer />
          </Content>
          <RightSideContainer />
        </Layout>
        <div id='zoom-full-screen' style={{display:'flex', justifyContent:'center'}}><video-player-container id='fullscreen-player'></video-player-container></div>
      </Layout>     
    </ZoomMediaContext.Provider>
  );
}

export default ZoomViewer;

import React from "react";
import "./UnSupported.scss";

const UnSupported = () => {
  return (
    <>
    <div className="unsupported-browser-page">
      <div className="unsupported-browser-page-heading">
        <span>Unsupported Browser</span>
      </div>
      <p className="unsupported-browser-page-para">
        We apologize for the inconvenience, but your browser is not supported by{" "}
        <strong>Pacpost</strong>.
      </p>
      <ul>
        <li>You may also use one of the following supported browsers:</li>
        <ul>
          <li>
            <strong>Google Chrome</strong>
          </li>
          <li>
            <strong>Apple Safari</strong>
          </li>
        </ul>
      </ul>
    </div>
    </>
  );
};

export default UnSupported;

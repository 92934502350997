import axios from "axios";
import api from "./restApi"
import * as auth from "../../firebase/auth";


let { baseUrl } = api

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json"
  }
});

const get = async (data: any) => {
  try {
    if (!data) { throw new Error(`No Data`) }
    if (data && !data.endPoint) { throw new Error(`No API Endpoint`) }

    if (data.authToken) {
      axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + data.authToken;
    }

    // End Point
    let endPoint = data.endPoint
    if (data.urlParam) {
      endPoint = endPoint + "/" + data.urlParam
    }

    const response = await axiosInstance.get(endPoint, { params: data.queryParam })

    if (response && response.data && response.data.status === 'success') {
      const { data } = response.data
      return data
    } else {
      // Ideally we should not come here if response is structured properly
      return response
    }
  } catch (error: any) {
    if (error.response) {
      let e = {
        errMessage: 'Error while getting data',
        data: null,
        errCode: null
      }

      const errorResponse = error.response
      if (errorResponse.data) {
        if (errorResponse.data.status === 'error') {
          e.errMessage = errorResponse.data.message;
          e.data = errorResponse.data.data;
          e.errCode = errorResponse.data.code;
        }
      }

      throw e;
    } else {
      throw error;
    }
  }
}

const post = async (data: any) => {
  try {
    if (!data) { throw new Error(`No Data`) }
    if (data && !data.endPoint) { throw new Error(`No API Endpoint`) }

    if (data.authToken) {
      axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + data.authToken;
    }

    // End Point
    const endPoint = data.endPoint
    // Payload
    let payLoad
    if (data.payLoad) {
      payLoad = data.payLoad
    }

    const response = await axiosInstance.post(endPoint, payLoad)

    if (response && response.data && response.data.status === 'success') {
      const { data } = response.data
      return data
    } else {
      return response
    }
  } catch (error: any) {
    if (error.response) {
      let e = {
        errMessage: 'Error while posting data',
        data: null,
        errCode: null
      }

      const errorResponse = error.response
      if (errorResponse.data) {
        if (errorResponse.data.status === 'error') {
          e.errMessage = errorResponse.data.message;
          e.data = errorResponse.data.data;
          e.errCode = errorResponse.data.code;
        }
      }

      throw e;
    } else {
      throw error;
    }
  }
}

const patch = async (data: any) => {
  try {
    if (!data) { throw new Error(`No Data`) }
    if (!data.endPoint) { throw new Error(`No API Endpoint`) }
    if (!data.payLoad) { throw new Error(`No payload for update`) }

    if (data.authToken) {
      axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + data.authToken;
    }

    // End Point
    let endPoint = data.endPoint
    if (data.urlParam) {
      endPoint = endPoint + "/" + data.urlParam
    }

    // Payload
    let payLoad = data.payLoad

    const response = await axiosInstance.patch(endPoint, payLoad)

    if (response && response.data && response.data.status === 'success') {
      const { data } = response.data
      return data
    } else {
      // Ideally we should not come here if response is structured properly
      return response
    }
  } catch (error: any) {
    if (error.response) {
      let e = {
        errMessage: 'Error while updating data',
        data: null,
        errCode: null
      }

      const errorResponse = error.response
      if (errorResponse.data) {
        if (errorResponse.data.status === 'error') {
          e.errMessage = errorResponse.data.message;
          e.data = errorResponse.data.data;
          e.errCode = errorResponse.data.code;
        }
      }

      throw e;
    } else {
      // console.error(error)
      throw error;
    }
  }
}

const uDelete = async (data: any) => {
  try {
    if (!data) { throw new Error(`No Data`) }
    if (data && !data.endPoint) { throw new Error(`No API Endpoint`) }

    if (data.authToken) {
      axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + data.authToken;
    }

    // End Point
    let endPoint = data.endPoint
    if (data.urlParam) {
      endPoint = endPoint + "/" + data.urlParam
    }

    const response = await axiosInstance.delete(endPoint, { params: data.queryParam })
    if (response && response.data && response.data.status === 'success') {
      const { data } = response.data
      return data
    } else {
      return response
    }
  } catch (error: any) {
    if (error.response) {
      let e = {
        errMessage: 'Error while deleting data',
        data: null,
        errCode: null
      }

      const errorResponse = error.response
      if (errorResponse.data) {
        if (errorResponse.data.status === 'error') {
          e.errMessage = errorResponse.data.message;
          e.data = errorResponse.data.data;
          e.errCode = errorResponse.data.code;
        }
      }

      throw e;
    } else {
      throw error;
    }
  }
}

const save = async (data: any, protectedRoute: Boolean = true, accessToken?: string) => {
  // console.log('save')
  // console.log(data)
  try {
    // Pre Validation
    if (!data) { throw new Error(`No Data`) }
    if (data && !data.api) { throw new Error(`No API Details`) }
    if (data && data.api && !data.api.method) { throw new Error(`No API Method`) }

    const baseEndPoint = data.api.baseEndPoint
    let endPoint = baseEndPoint + data.api.endPoint

    const token: string | any = protectedRoute ? !!accessToken ? accessToken : await auth.getToken() : "";

    const d = {
      endPoint: endPoint,
      urlParam: data.urlParam ? data.urlParam : null,
      payLoad: data.payLoad ? data.payLoad : null,
      authToken: token ? token : null
    }

    if (data.api.method === 'POST') {
      return await post(d)
    }
    if (data.api.method === 'PATCH') {
      return await patch(d)
    }
    if (data.api.method === 'PUT') {
      //return await put(d)
    }
    if (data.api.method === 'DELETE') {
      return await uDelete(d)
    }

  } catch (err) {
    throw err;
  }
}

const fetch = async (data: any, protectedRoute: Boolean = false, accessToken?: string) => {
  try {
    // Pre Validation
    if (!data) { throw new Error(`No Data`) }
    if (data && !data.api) { throw new Error(`No API Details`) }
    if (data && data.api && !data.api.method) { throw new Error(`No API Method`) }

    const baseEndPoint = data.api.baseEndPoint
    let endPoint = baseEndPoint + data.api.endPoint

    const token: string | any = protectedRoute ? !!accessToken ? accessToken : await auth.getToken() : "";

    const d = {
      endPoint: endPoint,
      urlParam: data.urlParam ? data.urlParam : null,
      queryParam: data.queryParam ? data.queryParam : null,
      authToken: token ? token : null
    }

    if (data.api.method === 'GET') {
      return await get(d)
    }
  } catch (err) {
    throw err;
  }
}

const remove = async (data: any) => {
  try {
    // Pre Validation
    if (!data) { throw new Error(`No Data`) }
    if (data && !data.api) { throw new Error(`No API Details`) }
    if (data && data.api && !data.api.method) { throw new Error(`No API Method`) }

    const baseEndPoint = data.api.baseEndPoint
    let endPoint = baseEndPoint + data.api.endPoint

    const token: string | null = await auth.getToken()

    const d = {
      endPoint: endPoint,
      urlParam: data.urlParam ? data.urlParam : null,
      queryParam: data.queryParam ? data.queryParam : null,
      authToken: token ? token : null
    }

    if (data.api.method === 'DELETE') {
      return await uDelete(d)
    }
  } catch (err) {
    throw err;
  }
}

export const userSignOut = async (navigate: any) => {
  // const navigate = useNavigate();
  await auth.doSignOut();
  navigate("/", { replace: true });
};

const backend = { save, userSignOut, fetch, remove }

export default backend

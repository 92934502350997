import { initializeApp } from "firebase/app";
import {
  getAuth,
  OAuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import { config } from "./config";
import { getAnalytics, logEvent } from 'firebase/analytics';

export const app = initializeApp(config);

export const auth = getAuth(app);
const analyticsData = getAnalytics(app);

export const GoogleProvider = new GoogleAuthProvider();
GoogleProvider.addScope("profile");
GoogleProvider.addScope("email");
GoogleProvider.setCustomParameters({ prompt: "select_account" });

export const FacebookProvider = new FacebookAuthProvider();
FacebookProvider.addScope("email");
FacebookProvider.addScope("public_profile");

export const TwitterProvider = new TwitterAuthProvider();
TwitterProvider.addScope("email");
TwitterProvider.setCustomParameters({ prompt: "select_account" });

export const AppleProvider = new OAuthProvider("apple.com");
AppleProvider.addScope("email");
AppleProvider.setCustomParameters({ prompt: "select_account" });

export const firestore = getFirestore(app);

export const analytics = {
  analyticsData,
  logEvent,
};
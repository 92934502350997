import React, { useState, useEffect, useCallback, memo } from 'react';
import ZoomAvatar from '../ZoomAvatar';
import { useAudio } from '../../pages/ZoomViewer/hooks/useAudio';
import { useVideo } from '../../pages/ZoomViewer/hooks/useVideo';
import { get } from '../../utils/helpers';
import None_thumb from "../../pages/Viewer/Images/None_thumb.svg";
import Abstract from "../../pages/Viewer/Images/Abstract.jpg";
import Bookshelf from "../../pages/Viewer/Images/Bookshelf.jpg";
import CoffeeShop from "../../pages/Viewer/Images/CoffeeShop.jpg";
import SanFrancisco from "../../pages/Viewer/Images/SanFrancisco.jpg";
import Blur_thumb from "../../pages/Viewer/Images/Blur_thumb.svg";
import Abstract_thumb from "../../pages/Viewer/Images/Abstract_thumb.jpg";
import Bookshelf_thumb from "../../pages/Viewer/Images/Bookshelf_thumb.jpg";
import CoffeeShop_thumb from "../../pages/Viewer/Images/CoffeeShop_thumb.jpg";
import SanFrancisco_thumb from "../../pages/Viewer/Images/SanFrancisco_thumb.jpg";
import { Button, Modal } from 'semantic-ui-react';

const backgrounds = [
  { value: "none", image: "none", thumbImage: None_thumb },
  { value: "blur", image: "blur", thumbImage: Blur_thumb },
  { value: "abstract", image: Abstract, thumbImage: Abstract_thumb },
  { value: "bookshelf", image: Bookshelf, thumbImage: Bookshelf_thumb },
  { value: "coffeeshop", image: CoffeeShop, thumbImage: CoffeeShop_thumb },
  { value: "sanfrancisco", image: SanFrancisco, thumbImage: SanFrancisco_thumb },
];

const ZoomVideoTile = (props: any) => {
  const {
    zmClient,
    user,
    aspectRatio,
    mediaStream,
    videoPlayerListRef,
    setVideoPlayerRef,
  } = props
  const { isAudioOn, onMicClick } = useAudio(zmClient, mediaStream)
  const { isVideoOn, onCamClick } = useVideo(zmClient, mediaStream, videoPlayerListRef)
  // console.log("rendered vtile",videoPlayerListRef.current) 
  // const [isStartedVideo, setIsStartedVideo] = useState(zmClient.getCurrentUserInfo()?.bVideoOn);
  const isLocalUser = user.userId ===  zmClient.getCurrentUserInfo()?.userId;
  const [isFullscreen, setIsFullscreen] = useState(false);

  const [localParticipantBGModalOpen, setLocalParticipantBGModalOpen] =
    useState(false);
  const [tempActiveBG, setTempActiveBG] = useState("");

  // Event listener to detect exiting full screen mode
  document.addEventListener('fullscreenchange', () => {
    if (!document.fullscreenElement) {
    const mainContainer = document.getElementById(`fullscreen-player`);
    if(mainContainer && mainContainer.children[0]){
      mainContainer.children[0].remove()
      mainContainer.classList.remove('full-screen-player')
    }
      setIsFullscreen(false);
    }
  });
  const isCurrentUserStartedVideo = zmClient.getCurrentUserInfo()?.bVideoOn;
  const isVideoSwitchedOn:boolean = isLocalUser ? isCurrentUserStartedVideo : user.bVideoOn

  const isAudioSwitchedOn:boolean = ((isLocalUser && zmClient.getCurrentUserInfo().audio !== '' && !user.muted) || (!isLocalUser && user.audio !== '' && !user.muted));
  
  const onCameraClick = useCallback(async () => {
    if(!isLocalUser){
      return
    }
    onCamClick()
  }, [isLocalUser]);

  const onMicrophoneClick = useCallback(async () => {
    if(!isLocalUser){
      return
    }
    await onMicClick()
  }, [isLocalUser]);

  const onScreenShareChange = async (payload: any) => {
    if (payload.state === 'Active') {
        mediaStream.startShareView(
          document.querySelector('#screen-share-content-canvas'),
          payload.userId
        )
      } else if (payload.state === 'Inactive') {
        mediaStream.stopShareView()
      }
  }

  useEffect(() => {
    zmClient.on('active-share-change', onScreenShareChange);
    return () => {
      zmClient.off('active-share-change', onScreenShareChange);
    };
  }, [zmClient, onScreenShareChange]);

  // useEffect(() => {
  //   if (!isVideoSwitchedOn) {
  //     console.log("stopped here")
  //     mediaStream?.stopShareView()
  //   }
  // }, [mediaStream, isVideoSwitchedOn])

  // useEffect(() => {
  //   document.addEventListener('keydown', handleKeyDown);
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [isFullscreen]);

  // const handleKeyDown = (event:any) => {
  //   console.log(":::::::",event.key,isFullscreen)
  //   if (event.key === 'Escape' && isFullscreen) {
  //     const mainContainer = document.getElementById(`zoom-full-screen`);
  //     if(mainContainer && mainContainer.children[0]){
  //       console.log(":::::::",mainContainer.children[0])
  //       mainContainer.children[0].remove()
  //     }
  //     setIsFullscreen(false);
  //   }
  // };
  

  const handleFullscreen = (user_id:string) => {
    if (!user.sharerOn) {
      const fullscreenContainer = document.getElementById(`zoom-full-screen`);
      const playerContainer = fullscreenContainer?.querySelector('#fullscreen-player');
      if(playerContainer){
        playerContainer.classList.add('full-screen-player')
      }
      const videoTile = document.getElementById(`player_${user_id}`)
      const videoTileCopy:any =  videoTile?.cloneNode(true);
      if(videoTileCopy) {
        videoTileCopy.style.width = '100%'
        videoTileCopy.style.oveflow = 'auto'
        playerContainer?.appendChild(videoTileCopy);
      }
      fullscreenContainer?.requestFullscreen();
    } else {
      isLocalUser ? document.getElementById(`screen-share-content-video`)?.requestFullscreen() : document.getElementById(`screen-share-content-canvas`)?.requestFullscreen();
    }
    setIsFullscreen(true);
  }

  return (
    <div
      className="video-cell"
      key={user.userId}
      style={
        // Bugs in react, aspectRatio doesn't work. https://github.com/facebook/react/issues/21098
        aspectRatio[`${user.userId}`]
          ? {
              aspectRatio: aspectRatio[`${user.userId}`]
            }
          : {}
      }
    >
      {/* {((isLocalUser && isCurrentUserStartedVideo) || (user.bVideoOn)) && !user.sharerOn && ( */}
        {/* // Issues in react-router https://github.com/remix-run/react-router/issues/8834#issuecomment-1118083034 */}
        <div  id={`player_${user.userIdentity}`} style={{ display: !user.sharerOn ? 'block' : 'none' }}>
          {/* <video-player
            id={`player_${user.userId}`}
            key={user.userId}
            class="video-player-div"
            node-id={Number(user.userId)}
            ref={(element) => {
              // setVideoPlayerRef(user.userId, element);
            }}
          /> */}
        </div>
      {/* )} */}
      {(!user.sharerOn) && <ZoomAvatar
        participant={user}
        key={user.userId}
        isActive={false}
        networkQuality={{}}
        videoOn={isVideoSwitchedOn}
      />}
      <video id={`screen-share-content-video`} className={`screen-share-video`} controls={false}></video>
      {user.sharerOn ? <canvas id={`screen-share-content-canvas`} className="screen-share-canvas"></canvas> : <></>}
      
      {!isFullscreen && <div className="video-controls">
        <span className='overlay-text-red prevent-textcopy'>{user.displayName}</span>
        <i className={`${isAudioSwitchedOn ? 'icon overlay-audio-on' : 'icon overlay-audio-off'}`} onClick={onMicrophoneClick} id={`audio-${user.userId}`} style={{pointerEvents: isLocalUser ? 'all' : 'none'}}></i>
        <i className={`${isVideoSwitchedOn ? 'icon overlay-video-on-tray' : 'icon overlay-video-off-tray'}`} onClick={onCameraClick} id={`video-${user.userId}`} style={{pointerEvents: isLocalUser ? 'all' : 'none'}}></i>
        <i className={`${isVideoSwitchedOn ? 'icon fullscreen-on' : 'icon fullscreen-off'}`} onClick={()=>{handleFullscreen(user.userIdentity)}} id={`video-${user.userId}`}></i>
        <i className={`${(isVideoSwitchedOn && isLocalUser) ? 'icon virtual-bg-menu' : 'icon fullscreen-off'}`}  id={`video-${user.userId}`} onClick={()=>{
          setLocalParticipantBGModalOpen(true);
        }}></i>
      </div>}

      <Modal
            open={localParticipantBGModalOpen}
            size={"tiny"}
            // onClick={() => setLocalParticipantBGModalOpen(false)}
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Modal.Header>Choose Virtual Background</Modal.Header>
            <Modal.Content>
              <div className="background-selector-wrapper">
                {backgrounds.map((item: any) => {
                  return (
                    <div
                      className={`background-selector ${
                        tempActiveBG === item.value ? "active" : ""
                      }`}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setTempActiveBG(item.value);
                      }}
                    >
                      <img src={item.thumbImage} alt={item.value} />
                    </div>
                  );
                })}
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="green"
                onClick={async () => {
                  sessionStorage.setItem("activeBG", tempActiveBG)
                  const tempActiveBGimg = tempActiveBG;
                  const bgImg = backgrounds.filter(
                    (item: any) => item.value === tempActiveBGimg
                  )[0];
                  if(bgImg?.image){
                    await mediaStream.updateVirtualBackgroundImage(bgImg?.image === "none" ? undefined : bgImg?.image)
                  }
                  setLocalParticipantBGModalOpen(false);
                }}
              >
                APPLY
              </Button>
              <Button
                color="grey"
                onClick={() => {
                  setLocalParticipantBGModalOpen(false);
                }}
              >
                CANCEL
              </Button>
            </Modal.Actions>
          </Modal>
    </div>
  )
}

export default (ZoomVideoTile);

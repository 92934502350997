import { useContext, useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { doSignOut } from "../../../firebase/auth";
import {
  Layout,
  Menu,
  Button,
  Tabs,
  DatePicker,
  DatePickerProps,
  Select,
  Modal,
  Switch,
  Popconfirm,
  Tooltip
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import {
  CloseOutlined,
  DownOutlined,
  RightOutlined,
  PlayCircleFilled,
  SyncOutlined,
  ExportOutlined,
  PlayCircleOutlined 
} from "@ant-design/icons";
import { AuthContext } from "../../../context/auth";
import { get, getInitials } from "../../../utils/helpers";
import Tab1Image from "../../../assets/images/statistic-board.png";
// import Tab2Image from '../../../assets/images/add-profile.png';
// import Tab3Image from '../../../assets/images/business.png';
import Title from "../../Title";
import "./styles.scss";
import InputComponent from "../../InputComponent";
import { Label } from "../../Label";
import { countryCodes } from "../../../utils/countryCodesISO";
import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";
import { AppMenu } from "../../AppMenu";
import messageComponent from "../../../utils/message";
import logo from "../../../assets/images/logo-dark.png";
import BadgeComponent from "../../BadgeComponent";
import ErrorComponent from "../../ErrorComponent";
import { convertDateFromUTCEpoc } from "../../../utils/utils";
import Loader from "../../LoaderHOC";
import { ROOM_STATE } from "../../../utils/constants";
import Checkbox from "antd/lib/checkbox/Checkbox";
import copyIcon from "../../../assets/images/copyIcon.png";
import UpgradeModal from "../UpgradeModal";
import { refreshUser } from "../../../utils/refreshUser";
import {ComponentState} from "../structs"

const { Content } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;



const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  // Can not select days before today and today
  return current && current < moment().endOf("day");
};

let expiredSessionFlag = false;

const CreateRoom = (props: any) => {
  const navigate = useNavigate();
  const user: any = useContext(AuthContext);
  console.log(":::::",get(user,'displayName',''))
  //const initials = getInitials(user?.displayName);
  const [showInputs, setShowInputs] = useState(false);
  
  const toggleInputs = () => {
    setShowInputs(!showInputs);
  };
  const {
    rooms,
    selectedRoomID,
    setRooms,
    fetchActiveRooms,
    setLoading,
    loading,
  } = props;

  const [rpstate, setRPState] = useState<ComponentState>({
    isCopyModal: false,
    isRefreshModalVisible: false,
    isDeleteModalVisible:false,
    upgradeModalVisible:false,
    isAddRoom :true,
    streamExists: true,
    roomNameValid: true,
    autoAllow: false,
    isExpiredSessionModal: false,
    isRoomId:"",
    selectedCountries : [],
    isfreeRoom: "",
    dayTrialEndDate: '',
    subscriptionEndDate: 0,
    roomName:"",
    streamEndDate: undefined,
    streamName:"",
    errorMsg:"",
    streamAdminUrl: "",
    streamAdminCode:"",
    SRTstream: "",
    SRT_passphrase:"",
    publishToken:"",
    trialEnd: "",
    isSubscriptionStatus :"",
    isSubscriptionID: "",
    countries: Object.keys(countryCodes).map((country: any) => ({
      name: country,
      value: countryCodes[country],   
    })),
    isAVIDMode: false,
    ipAddress:"",
    portDetails:"",
  });
  useEffect(() => {
    const populateData = () => {
      const selectedRoom =
        rooms.find((room: any) => room.id === selectedRoomID) || {};
      const {
        room_name,
        stream_room_name,
        stream_millicast_name,
        stream_admin_code,
        stream_admin_url,
        stream_vendor_id,
        geo_allowed_countries,
        expiry,
        publish_token,
        tiny_admin_url,
        trial_end,
        srt_stream_id,
        srt_passphrase,
        auto_allow = true,
        isAvidRoom = false, //Todo: REMOVE THE TEMP AVID TEST VALUE  
      } = selectedRoom;
      const convertTrialEndTS = convertDateFromUTCEpoc(trial_end);
      // moment(trial_end).utc().toString()

      // set subscription status

      let subStatus = !!rooms && rooms[0]?.stripe_subscription_status;
      let subID = !!rooms && rooms[0]?.stripe_subscription_id;
      let roomId = !!rooms && rooms[0]?.id;
      //console.log("selectedRoom", moment(get(selectedRoom, "day_trial_end", '')).format("LL"));
      setRPState((prevState) => ({
        ...prevState,
        isfreeRoom:get(selectedRoom,'free_room_flag',false),
        subscriptionEndDate:get(selectedRoom,'subscription_end_time',0),
        isRoomId:roomId,
        trialEnd:convertTrialEndTS,
        isSubscriptionStatus:subStatus,
        IsSubscriptionID: subID
      }));

      if(get(selectedRoom,'room_state','') === ROOM_STATE.DAY_TRIAL && get(selectedRoom,'free_room_flag',false)) {      
        setRPState((prevState) => ({
          ...prevState,
         dayTrialEndDate:moment(get(selectedRoom, "day_trial_end", '')).format("LL"),
        }));

      }

      //Refresh the auth token in case display name is null
      if(!get(user,'displayName','')){
        refreshUser()
      } 

      const shortUrl =
        !!tiny_admin_url && !!tiny_admin_url.shortUrl
          ? tiny_admin_url.shortUrl
          : "";
      setRPState((prevState) => ({
        ...prevState,
        autoAllow:get(selectedRoom, "auto_allow", false),
        roomName: !!stream_millicast_name ? stream_room_name || room_name : "",
        streamName: stream_millicast_name,
        streamAdminUrl: shortUrl,
        streamAdminCode:stream_admin_code,
        SRTstream: srt_stream_id,
        SRT_passphrase: srt_passphrase,
        publishToken : publish_token || ""
      }));
      if (!!geo_allowed_countries && rpstate.countries.length > 0) {
        const selectedCountriesList = geo_allowed_countries.map(
          (item: string) => {
            const countryName = rpstate.countries.find(
              (country: any) => country.value === item
            );
            return countryName;
          }
        );
        setRPState((prevState) => ({
          ...prevState,
         selectedCountries:selectedCountriesList.map((country:any) => country.name),
        }));
      } else {
        setRPState((prevState) => ({
          ...prevState,
         selectedCountries:[],
        }));
        
      }
      
      if (!!expiry && !!expiry._seconds && !!expiry._nanoseconds) {
        const formatted: any = moment(
          new Date(expiry._seconds * 1000 + expiry._nanoseconds / 1000000)
        );
        console.log("expiry", rpstate.roomName, expiry, formatted);
        setRPState((prevState) => ({
          ...prevState,
          streamEndDate:formatted,
        }));

      } else {
        setRPState((prevState) => ({
          ...prevState,
          streamEndDate:undefined,
        }));
      }
      if (stream_admin_url && stream_admin_code) {
        setRPState((prevState) => ({
          ...prevState,
          isAddRoom: false,
        }));
      } else {
        setRPState((prevState) => ({
          ...prevState,
          isAddRoom: true,
        }));
      }
    };
    populateData();
    
    
  }, [selectedRoomID, rooms, loading]);

  useEffect(() => {
    //Used to check whether stream has expired or not.
    const endDateValue = moment(rpstate.streamEndDate).unix();
    const currentDateValue = moment().unix();
    const expiryValue = endDateValue >= currentDateValue ? true : false;
    
    setRPState((prevState) => ({
      ...prevState,
      streamExists: expiryValue
    }));
    if (!expiryValue && !expiredSessionFlag) {
      expiredSessionFlag = true;
      setRPState((prevState) => ({
        ...prevState,
       isExpiredSessionModal:true,
      }));
    }

    if (rooms.length > 0) {
      const selectedRoom = rooms.find(
        (room: any) => room.id === selectedRoomID
      );

      const { stream_vendor_id } = selectedRoom;
      if (!!stream_vendor_id) {
        setRPState((prevState) => ({
          ...prevState,
          roomNameValid:true,
          errorMsg:''
        }));

      }
    }
  }, [rpstate.roomName]);

  useEffect(() => {
    const countryData = Object.keys(countryCodes).map((country: any) => {
      return { name: country, value: countryCodes[country] };
    });
    setRPState((prevState) => ({
      ...prevState,
      countries:countryData,
    }));

  }, []);

  const signout = async () => {
    await doSignOut();
    navigate("/", { replace: true });
  };

  // const upgradeToPaidSubscription = async () => {
  //   try {
  //     if (!isSubscriptionID && !rooms) {
  //       return;
  //     }

  //     const data = {
  //       api: api.stripe.upgradeSubscription,
  //       payLoad: JSON.stringify({
  //         subscription_id: isSubscriptionID,
  //         roomId: isRoomId,
  //         subscription_status: "active",
  //       }),
  //     };
  //     setLoading(true);
  //     const result = await backend.save(data, true);
  //     // messageComponent({
  //     //   messages: "You have successfully upgraded your trial subscription",
  //     //   type: "success",
  //     //   top: 10,
  //     // });
  //     if (result) {
  //       navigate("/setuproom");
  //     }
  //   } catch (error) {
  //     console.log("subscription upgrade failed", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
    if (!!date) {
      const dateObj: any = date;
      console.log("dateObj", dateObj, dateObj.format("YYYY/MM/DD"));
      setRPState((prevState) => ({
        ...prevState,
        streamEndDate:dateObj,
      }));
    } else {
      setRPState((prevState) => ({
        ...prevState,
        streamEndDate:undefined,
      }));
    }
  };

  const handleRoomName = (value: string) => {
    if (!/^[a-zA-Z0-9_]+$/.test(value)) {
      setRPState((prevState) => ({
        ...prevState,
        roomNameValid:false,
        roomName:value.replace(/\s/g, ""),
        errorMsg:'Sorry, only letters, numbers, and "_" are allowed.'
      }));
    } else if (!value) {

      setRPState((prevState) => ({
        ...prevState,
        roomNameValid:false,
        roomName:value.replace(/\s/g, ""),
        errorMsg:'Please enter the room name.'
      }));
    } else {
      setRPState((prevState) => ({
        ...prevState,
        roomNameValid:true,
        roomName:value.replace(/\s/g, ""),
        errorMsg:""
      }));
    }
  };

  const handleChangeCountry = (value: string[]) => {
    if (value.length < 6) {
      setRPState((prevState) => ({
        ...prevState,
       selectedCountries:value,
      }));
    }
  };

  const handleautoAllow = () => {
    const newVal = !rpstate.autoAllow
    setRPState((prevState) => ({
      ...prevState,
      autoAllow:newVal,
    }));
    handleUpdateAutoAllowRoom(newVal);
  }

  const getUnixTimeStamp = (date: any) => {
    const dateUTS = new Date(date);
    const unixTimestamp = Math.floor(dateUTS.getTime());
    console.log(unixTimestamp);
    return unixTimestamp;
  };

  const handleCreateRoom = async () => {
    try {
      const selectedRoom =
        rooms.find((room: any) => room.id === selectedRoomID) || {};

      const selectedCountriesCodeList = rpstate.selectedCountries.map(
        (item: string) => {
          const countryCode = rpstate.countries.find(
            (country: any) => country.name === item
          );
          return countryCode;
        }
      );
      const validRoom = rooms.filter((room: any) => {
        return !!room.stream_vendor_id && room.room_name === rpstate.roomName;
      });
      if (validRoom.length === 0) {
        const input = {
          room_id: selectedRoom.id,
          room_code: selectedRoom.room_code,
          room_name: rpstate.roomName,
          // stream_user_name: streamName,
          geo_allowed_countries: selectedCountriesCodeList.map((country:any) => country.value),
          expiry: !!rpstate.streamEndDate ? getUnixTimeStamp(rpstate.streamEndDate) : null,
          auto_allow: rpstate.autoAllow
        };

        console.log("input :>> ", input);
        const data = {
          api: api.room.createRoom,
          payLoad: input,
        };
        setLoading(true);
        const result = await backend.save(data, true);
        if (result) {
          fetchActiveRooms();
          messageComponent({
            messages: "Created a session successfully",
            type: "success",
            top: 10,
          });
        }
      } else {
        messageComponent({
          messages: "This Room Name Already Exists",
          type: "error",
          top: 10,
        });
      }
    } catch (err: any) {
      console.log("err :>> ", err);
      messageComponent({ messages: err.errMessage, type: "error", top: 10 });
      setLoading(false);
    }
  };
  const handleUpdateAutoAllowRoom = async (autoAllow:boolean) => {
    try {
      const selectedRoom = rooms.find((room: any) => room.id === selectedRoomID) || {};
        const input = {
          room_id: selectedRoom.id,
          auto_allow: autoAllow
        };
        console.log("input :>> ", input);
        const data = {
          api: api.room.updateAutoAllowRoom,
          payLoad: input,
          urlParam: selectedRoom.id
        };
        //setLoading(true);
        const result = await backend.save(data, true);
        console.log("########result:::", result);
    } catch (err: any) {
      console.log("err :>> ", err);
      messageComponent({ messages: err.errMessage, type: "error", top: 10 });
      setLoading(false);
    }
  };

  const refreshRoomCode = async () => {
    try {
      setRPState((prevState) => ({
        ...prevState,
        isRefreshModalVisible: false,
      }));
      const selectedRoom =
        rooms.find((room: any) => room.id === selectedRoomID) || {};
      const input = {
        room_id: selectedRoom.id,
      };

      const data = {
        api: api.room.refreshCode,
        payLoad: input,
      };
      setLoading(true);
      const result = await backend.save(data, true);
      console.log("result :>> ", result);
      if (result) {
        fetchActiveRooms();
        messageComponent({
          messages:
            "Session's verification code has been successfully refreshed",
          type: "success",
          top: 10,
        });
      }
    } catch (err) {
      console.log("err :>> ", err);
      setLoading(false);
    }
  };

  const deleteSession = async () => {
    try {
      setRPState((prevState) => ({
        ...prevState,
        isDeleteModalVisible: false,
        isExpiredSessionModal:false,
      })); 
      setLoading(true);
      const selectedRoom =
        rooms.find((room: any) => room.id === selectedRoomID) || {};
      const data = {
        api: api.room.deleteSession,
        urlParam: selectedRoom.id,
      };
      const result = await backend.remove(data);
      console.log("result :>> ", result);
      if (result) {
        await fetchActiveRooms();
        setLoading(false);
        messageComponent({
          messages: "Deleted Session Successfully",
          type: "success",
          top: 10,
        });
      }
    } catch (err) {
      console.log("err :>> ", err);
    } finally {
      setLoading(false);
    }
  };
  const showRefreshModal = () => {
    setRPState((prevState) => ({
      ...prevState,
      isRefreshModalVisible: true,
    })); 
  };
  const showDeleteModal1 = () => {
    setRPState((prevState) => ({
      ...prevState,
      isDeleteModalVisible: true,
    })); 

  };
  const handleRefreshCancel = () => {
    setRPState((prevState) => ({
      ...prevState,
      isRefreshModalVisible: false,
    })); 
  };
  const handleDeleteCancel = () => {
    setRPState((prevState) => ({
      ...prevState,
      isDeleteModalVisible: false,
    })); 
  };

  const handleExpiredSessionModalOk = () => {
    deleteSession();
  };

  const handleExpiredSessionModalCancel = () => {
    setRPState((prevState) => ({
      ...prevState,
     isExpiredSessionModal:false,
    }));
  };
  
  const copyText =  get(user,'displayName','').toUpperCase()+" has invited you to join Pacpost.io"+"\n"+
  "\n"+"URL: "+ rpstate.streamAdminUrl+"\n"+
  "Session Code: "+rpstate.streamAdminCode;

  const handleCopyModalOk = () => {
    navigator.clipboard.writeText(copyText);
    messageComponent({messages:"Host Invitation Copied", type: "info",top: 10})
    setRPState((prevState) => ({
      ...prevState,
      isCopyModal: false,
    }));
  }
  const handleCopyModalCancel = () => {
   
    setRPState((prevState) => ({
      ...prevState,
      isCopyModal: false,
    }));
  } 

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: <div onClick={() => {}}>Account Settings</div>,
        },
        {
          key: "2",
          label: (
            <div
              onClick={() => {
                navigate("/changepassword");
              }}
            >
              Change Password
            </div>
          ),
        },
        {
          key: "3",
          label: (
            <div
              onClick={() => {
                navigate("/manage2FA");
              }}
            >
              Manage 2FA
            </div>
          ),
        },
        {
          key: "1",
          label: <div onClick={() => signout()}>LogOut</div>,
        },
      ]}
    />
  );

  const operations = <AppMenu className="header-menu" />;
  const tab1Content = () => {
    return (
      <div className="tab1-wrapper">
        <div className="create-room-content">
          <Title>
            {rpstate.isAddRoom ? `Create a Session` : "Session Details"}
          </Title>
          <div className="create-room-row1">
            <div>
              <label>
                SESSION NAME<span>*</span>
              </label>
              <InputComponent
                // label="room name"
                value={rpstate.roomName}
                onChange={handleRoomName}
                autoComplete="new-password"
                readOnly={!rpstate.isAddRoom}
                maxLength={20}
                className={
                  rpstate.roomNameValid ? "ant-input" : "ant-input-error"
                }
              />
              <div className="err-msg-wrapper">
                <ErrorComponent errorMsg={rpstate.errorMsg} />
              </div>
            </div>
            <div className="select-waiting-wrapper-checkbox">
              <Popconfirm
                title={
                  !rpstate.autoAllow
                    ? "Do you want to disable waiting room ?"
                    : "Do you want to enable waiting room ?"
                }
                onConfirm={handleautoAllow}
                placement={"topLeft"}
              >
                <Checkbox checked={!rpstate.autoAllow}></Checkbox>
                <label className="inline-waiting-room">WAITING ROOM</label>
              </Popconfirm>
              <div>
                <span className="support-text">
                  Only guests admitted by the editor can join the room.
                </span>
              </div>
            </div>
          </div>
          <div className="create-room-row1">
            <div className="select-countries-wrapper">
              <Label className="inline">
                ALLOWED COUNTRIES
                <span className="support-text">max. 5 countries</span>
              </Label>
              <Select
                mode="multiple"
                allowClear
                showArrow
                style={{ width: "100%" }}
                placeholder="Select allowed countries"
                onChange={handleChangeCountry}
                value={rpstate.selectedCountries}
                disabled={!rpstate.isAddRoom}
                className={"countries-dropdown"}
              >
                {rpstate.countries.map((country: any) => (
                  <Option value={country.name} key={country.value}>
                    {country.name}
                  </Option>
                ))}
              </Select>
              <span className="support-text editor-guest-credentials">
                USA is allowed by default.
              </span>
            </div>
            <div className="stream-end-date">
              <Label>STREAM END DATE</Label>
              <DatePicker
                onChange={onChange}
                value={rpstate.streamEndDate}
                format={"YYYY/MM/DD"}
                inputReadOnly={true}
                disabled={!rpstate.isAddRoom}
                disabledDate={disabledDate}
              />
            </div>
          </div>
        </div>
        {!rpstate.isAddRoom && (
          <div className="host-details-wrapper">
            {!!rpstate.streamExists ? (
              <>
                <div className="host-details-header">
                  <div className="title-wrapper">
                    <Title>Editor Login Credentials Only</Title>
                    <Tooltip
                      className="copy-invitation"
                      title="Copy Invitation"
                      placement="top"
                    >
                      <Button
                        onClick={() =>
                          setRPState((prevState) => ({
                            ...prevState,
                            isCopyModal: true,
                          }))
                        }
                        className="copy-btn"
                      >
                        {/* <ExportOutlined />Copy Invitation */}
                        <img
                          src={copyIcon}
                          alt={copyIcon}
                          className="copy-icon"
                        />
                      </Button>
                    </Tooltip>
                  </div>
                  <Modal
                    open={rpstate.isCopyModal}
                    onOk={handleCopyModalOk}
                    onCancel={handleCopyModalCancel}
                    okText="Copy Host Invitation"
                    className="copy-modal"
                    style={{ height: "500px" }}
                    title="Copy Host Invitation"
                  >
                    <b>Join pacpost.io Room</b>
                    <br />
                    <br />
                    URL : {rpstate.streamAdminUrl}
                    <br />
                    Session Code : {rpstate.streamAdminCode}
                    <br />
                    <br />
                    <br />
                  </Modal>
                  <div className="refresh-btn-wrapper">
                    <Tooltip title="Watch the Live Stream" placement="top">
                      <Button
                        onClick={() => {
                          window.open(get(rooms, "0.stream_admin_url", ""));
                        }}
                        className="navigate-viewer"
                      >
                        <PlayCircleOutlined  />
                        Watch the Live Stream
                      </Button>
                    </Tooltip>
                    <Button className="refresh-btn" onClick={showRefreshModal}>
                      <SyncOutlined  />
                      Refresh Session Codes
                    </Button>
                  </div>
                  <Modal
                    title="Refresh Verification Code"
                    open={rpstate.isRefreshModalVisible}
                    onOk={refreshRoomCode}
                    onCancel={handleRefreshCancel}
                  >
                    <div>
                      <h2> Do you want to refresh the session access code ?</h2>
                      <h3>
                        Note: If you log in as a viewer through the editor link
                        using the same browser, you will be logged out from both
                        this page and the viewer page.
                      </h3>
                    </div>
                  </Modal>
                </div>
                <div className="host-details-content">
                  <div>
                    <InputComponent
                      label="Editor's Link"
                      value={rpstate.streamAdminUrl}
                      readOnly
                      copyToClipBoard={true}
                    />
                    <span className="support-text editor-guest-credentials">
                      Please do not share this link or code. Editor link only.
                    </span>
                  </div>
                  <InputComponent
                    label="Session Code"
                    value={rpstate.streamAdminCode}
                    readOnly
                    copyToClipBoard={true}
                  />
                  <InputComponent
                    label="stream name"
                    value={rpstate.streamName}
                    readOnly
                    copyToClipBoard={true}
                  />
                  <InputComponent
                    label="stream token"
                    value={rpstate.publishToken}
                    readOnly
                    copyToClipBoard={true}
                  />
                  {/* srt pass phrase is not shown on the web mode */}
                  {/* <InputComponent
                    label="srt passphrase"
                    value={rpstate.SRT_passphrase}
                    readOnly
                    copyToClipBoard={true}
                  />
                  <div style={{visibility:'hidden'}}> Hidden div </div> */}
                  {/* <InputComponent
                    label="SRT Stream ID"
                    readOnly
                    copyToClipBoard={!isAVIDMode}
                  /> */}
                </div>
              </>
            ) : null}
          </div>
        )}
        <div className="actions-wrapper">
          {rpstate.isAddRoom ? (
            <Button
              type="primary"
              className="ppl-btn"
              disabled={!rpstate.roomName || !rpstate.roomNameValid}
              onClick={handleCreateRoom}
            >
              <PlayCircleFilled style={{ fontSize: "20px" }}   />
              Create Session
            </Button>
          ) : (
            <>
              {/* <Button
                    type='primary'
                    className="ppl-btn" onClick={updateRoom}>
                    <PlayCircleFilled style={{ fontSize: '20px' }} />
                    Update room
                  </Button> */}
              <div className="adv-setting" onClick={toggleInputs}>
                {showInputs ? <DownOutlined /> : <RightOutlined  />}ADVANCED SRT
                SETTINGS
              </div>
            </>
          )}
        </div>
        {showInputs && (
          <div className="host-details-wrapper">
            <div className="host-details-content">
              <InputComponent
                label="SRT Stream ID"
                value={rpstate.SRTstream}
                readOnly
                copyToClipBoard={true}
              />
              <InputComponent
                label="IP Address"
                value={
                  "73999b287c9441368e311faf8f79ccd7-w6RPh8.transcoder.millicast.com"
                }
                readOnly
                copyToClipBoard={true}
              />
              <InputComponent
                label="Port details"
                value={10000}
                readOnly
                copyToClipBoard={true}
              />
              <InputComponent
                label="srt passphrase"
                value={rpstate.SRT_passphrase}
                readOnly
                copyToClipBoard={true}
              />
            </div>
          </div>
        )}
        <div className="actions-wrapper">
        {!rpstate.isAddRoom && (
        <Button className="ppl-btn delete" onClick={showDeleteModal1}>
          <PlayCircleFilled style={{ fontSize: "20px" }}  />
          Delete Session
        </Button>
        )}
        <Modal
          title="Delete Session"
          open={rpstate.isDeleteModalVisible}
          onOk={deleteSession}
          onCancel={handleDeleteCancel}
        >
          <h2>Do you want to delete the Session ?</h2>
          <h3>
            Note: If you log in as a viewer through the editor link using the
            same browser, you will be logged out from both this page and the
            viewer page.
          </h3>
        </Modal>
        
      </div>
      </div>
    );
  };

  return (
    <Layout className="rooms-layout">
      <Content>
        <Tabs centered tabBarExtraContent={operations} defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <img src={Tab1Image} alt="logo" className="tab-icon" />
              </span>
            }
            key="1"
          >
            {!rpstate.streamExists ? (
              <div className="banner-container">
                <BadgeComponent  
                  text={
                    "This session is expired and is no longer valid, please delete the session."
                  }
                />
              </div>
            ) : null}
            {rpstate.isfreeRoom &&
              ((
                <div className="banner-container">
                  <BadgeComponent
                    text={`This is a free session and will end on ${rpstate.dayTrialEndDate}. Upgrade by clicking here`}
                    button={
                      <Button 
                      onClick={() => {
                        setRPState((prevState) => ({
                          ...prevState,
                          upgradeModalVisible: true,
                        }));
                      }}
                      className="upgrade-btn"
                    >
                        Upgrade
                      </Button>
                    }
                  />
                </div>
              ) ??
                " ")}
            {!!rpstate.subscriptionEndDate && (
              <div className="banner-container">
                <BadgeComponent
                  text={`Your paid subscription will be ending by ${moment.unix(rpstate.subscriptionEndDate).format("LL")}.`}
                  // button={
                  //   <Button
                  //     className="upgrade-btn"
                  //     onClick={() => {
                  //       setUpgradeModalVisible(true);
                  //     }}
                  //   >
                  //     Upgrade
                  //   </Button>
                  // }
                />
              </div>
            )}
            <UpgradeModal
              open={rpstate.upgradeModalVisible}
              onCancel={() =>
                setRPState((prevState) => ({
                  ...prevState,
                  upgradeModalVisible: false,
                }))
              }
              destroyOnClose
              className="upgrade-modal"
              bodyStyle={{ borderRadius: "10px", height: "480px" }}
              width={828}
              footer={null}
              closable={false}
              loading={loading}
              logo={logo}
            />
            <Modal
              title={
                <div style={{ fontSize: "1.2em", fontWeight: "bold" }}> Session Expired</div>
              }
              open={rpstate.isExpiredSessionModal}
              onOk={handleExpiredSessionModalOk}
              onCancel={handleExpiredSessionModalCancel}
            >
              <h2>
                This session has expired, and the links are no longer valid.
                Please delete the session by selecting <strong>'OK'</strong>.
              </h2>
            </Modal>
            {tab1Content()}
          </TabPane>
          {/* <TabPane tab={
                        <span>
                            <img src={Tab2Image} alt="logo" className="tab-icon" />
                        </span>
                    } disabled key="2">
                        Content of tab 2
                    </TabPane>
                    <TabPane tab={
                        <span>
                            <img src={Tab3Image} alt="logo" className="tab-icon" />
                        </span>
                    } disabled key="3">
                        Content of tab 3
                    </TabPane> */}
        </Tabs>
      </Content>
    </Layout>
  );
};

export default CreateRoom;

export enum CHIME_VALUES {
    CHIME_ON = "CHIME_ON",
    CHIME_OFF = "CHIME_OFF"  
  }

// Feature Flags
export enum FEATURE_NAME {
    TIME_CODE = "TIME_CODE",
    CHILD_SHOWS = "CHILD_SHOWS",
    ALLOW_GUEST_INVITE="ALLOW_GUEST_INVITE",
    ALLOW_STRIPE_LICENCE="ALLOW_STRIPE_LICENCE",
    VC_DIRECT = "VC_DIRECT",
    ALLOW_LICENSE_MANGER = "ALLOW_LICENSE_MANGER",
  }
  
export const turnApiUrl = 'https://turn.millicast.com/webrtc/_turn'

// Session status
export const enum ROOM_STATE {
  EXPIRED = 'expired',
  DAY_TRIAL = 'day_trial',
  MINUTE_TRIAL = 'minute_trial',
  PAID = 'paid'
}
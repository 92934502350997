import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { signInWithEmailAndPassword } from "firebase/auth";

import { auth } from "../../../firebase/utils";

import { AuthContext } from "../../../context/auth";

// import { useForm } from '../../../hooks/useForm';
// import Button from "../../../components/forms/Button";

import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";

import PageLayout, { PageContent } from "../../../layouts/PageLayout";

import "./styles.scss";
import InputComponent from "../../InputComponent";

import logo from "../../../assets/images/sidebar-logo.png";
import {
  Button,
  Anchor,
  Form,
  Typography,
  Space,
  Divider,
  Modal,
  Tooltip,
  List,
  Checkbox,
} from "antd";
import { InfoCircleOutlined, DoubleRightOutlined } from "@ant-design/icons";
import SocialSignupLogin from "../SocialSignupLogin";
import Loader from "../../LoaderHOC";
import ErrorComponent from "../../ErrorComponent";
import messageComponent from "../../../utils/message";
import TermsAndConditions from "../TermsAndConditions";
import { get } from "../../../utils/helpers";
import { doSignOut } from "../../../firebase/auth";
import { signInSuccess, signUpSuccess } from "../../../firebase/analytics";

const { Title } = Typography;
const { Link } = Anchor;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const initialState = {
  first_name: "",
  last_name: "",
  user_email: "",
  password: "",
  confirm_password: "",
  // organization_name: "",
};

const Signup = (props: any) => {


  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userMail, setUserMail] = useState("");
  const [signUPpassword, setSignUPpassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const user: any = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [signUperr,setSignUperr]=useState("");
  const[errorMsg,setErrorMsg]=useState('');
  const[termsAgreed,setTermsAgreed]=useState(false);
  const[section,setSection]=useState("")
  const freeRoomAddRef = useRef(false);
  const [userNotExistsError, setUserNotExistsError] = useState("");
  

  const [error, setError] = useState("");
  //TODO: use customHook useForm - facing issue for onChange while using common input component
  // const { onChange, onSubmit, resetValues, values } = useForm(
  //     create_user,
  //     initialState
  // );

  useEffect(() => {
    const navigateUser = async () => {
      const data = {
        api: api.room.getAllActiveRoomsOfUser,
      };

      setLoading(true);
      const rooms = await backend.fetch(data, true);
      console.log("rooms :>> ", rooms);
      if(!rooms.length && !get(user,'linkLogin',false) && !freeRoomAddRef.current){
        //Add free room
        await addFreeTrialRoom()
        navigate("/home");
      }
      if (rooms && rooms.length > 0) {
        sessionStorage.setItem('is_firstVisit', '1')
        await signInSuccess();
        navigate(
        "/home");
      } 
      // else {
      //   navigate(!!url && !!isViewer ? `${url}` : "/setuproom");
      // }
    };

    if (user) {
    //Check if user is using link login or not guest user or not
    if(get(user,'linkLogin',false)){
      doSignOut();
      return; 
    }
      setFirstName("");
      setLastName("");
      setUserMail("");
      setSignUPpassword("");
      setRepeatedPassword("");
      navigateUser();
    }
  }, [user]);

  const addFreeTrialRoom = async () => {
    try {
      const data = { api: api.room.addFreeRoom };
      freeRoomAddRef.current = true;
      const result = await backend.save(data, true);
      if(result){
        await signInSuccess();
        sessionStorage.setItem('is_firstVisit', '1')
        navigate("/home")
      }
      return result;
    } catch (error) {
      freeRoomAddRef.current = false ;
      console.log(error);
    }
  };

  //Login
  const user_logIn = async () => {
    try {
      setLoading(true);
      setError("");
      const { user } = await signInWithEmailAndPassword(
        auth,
        userMail,
        signUPpassword
      );
      await signUpSuccess();
      setLoading(false);
      console.log("User logged-in successfully");
      console.log(user);
    } catch (err: any) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  //Signup
  async function create_user() {
    setError("");

    // const {
    //     first_name,
    //     last_name,
    //     user_email,
    //     password,
    //     confirm_password
    // } = values;

    if (!firstName) {
      setError("Fisrt name can not be empty");
      return;
    }
    if (!lastName) {
      setError("Last name can not be empty");
      return;
    }
    if (!userMail) {
      setError("Email can not be empty");
      return;
    }
    if (!signUPpassword || !repeatedPassword) {
      setError("Passwords can not be empty");
      return;
    }
    if (signUPpassword !== repeatedPassword) {
      setError("Passwords Don't match");
      return;
    }

    // To:do: Add more validation of data before creating user

    try {
      setUserNotExistsError("");

      // Call Backend to Create User
      const input = {
        first_name: firstName,
        last_name: lastName,
        user_email: userMail,
        password: signUPpassword,
        confirm_password: repeatedPassword,
        // organization_name
      };

      const data = {
        api: api.user.create,
        payLoad: JSON.stringify(input),
      };

      setLoading(true);
      const response = await backend.save(data, true);
      setLoading(false);
      console.log(response);

      console.log(
        "Registration successful. Account created in Auth and Trigger pushed the data to Firestore"
      );

      //User Login
      await user_logIn();

      // Update Profile in background.
      // To-do: Investigate how to handel if trigger didn't push the data to firestore
      // Shall we remove the trigger and create firestore data in signup itself after creating user in Firebase Auth ?
      // await update_profile();
      //   console.log("update_profile");
      return;
    } catch (err:any) {
      if (err.errCode === 10204) {
        setUserNotExistsError("Account already exist with this email.")
      }

      setLoading(false);
      setSignUperr(err.errMessage);
      messageComponent({messages:err.errMessage, type:"error",top:10})
      setEmailValid(false);
      setTermsAgreed(false);
    }
  }

  const passwordcriteria = [
    " not less than 12 characters",
    " atleast one uppercase letter",
    " atleast one lowercase letter",
    " atleast one number",
    " atleast one special character",
  ];

  const selectTermsSection=(sectionNumber:number)=>{
    setModalVisible(true);
    setSection(sectionNumber === 1?"termsAndConditions":
    (sectionNumber ===2?"privacyPolicy":"subscriptionAgreement"))
    
   
  }
   
  return (
    <Loader loading={loading}>
      <PageLayout>
        <PageContent className="left-side-signup-content">
          <img src={logo} alt="logo" className="logo" />
        </PageContent>
        <PageContent className="right-side-signup-content">
          <Space
            direction="vertical"
            size={32}
            className="registerForm-container"
          >
            <div className="signup-title">
              <Title level={3}>Create your PacPost.io account </Title>
              <Button
                type="link"
                className="register-link"
                onClick={() => navigate(
                  "/")}
              >
                Login instead
              </Button>
            </div>
            <Form
              name="signUp"
             // initialValues={{ remember: true }}
              autoComplete="new-password"
            >
              <div className="signup-name-row">
                <Form.Item
                  name="firstName"
                  rules={[
                    { required: true, message:  <ErrorComponent errorMsg={"Please enter firstname"} setErrorMsg={setErrorMsg}/>},
                  ]}
                >
                  <InputComponent
                    label="first name"
                    key={"firstName"}
                    onChange={setFirstName}
                    value={firstName}
                  />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  rules={[{ required: true, message: <ErrorComponent errorMsg={"Please enter lastname"} setErrorMsg={setErrorMsg}/> }]}
                >
                  <InputComponent
                    label="last name"
                    key={"lastName"}
                    onChange={setLastName}
                    value={lastName}
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  name="userMail"
                  rules={[
                    {
                      validator: async (_: any, val: any) => {
                        if (val === "") {
                          setEmailValid(false);
                          return Promise.reject(<ErrorComponent errorMsg={"Please enter email"} setErrorMsg={setErrorMsg} />);
                        } else if (!emailRegex.test(val)) {
                          setEmailValid(false);
                          return Promise.reject(
                            <ErrorComponent errorMsg={"Invalid email format"} setErrorMsg={setErrorMsg} />
                          );
                        } else {
                          setEmailValid(true);
                          setSignUperr("")
                        }
                      },
                    },
                  ]}
                >
                  <InputComponent
                    key={"userMail"}
                    label="email"
                    value={userMail}
                    onChange={setUserMail}
                  />
                </Form.Item>
              </div>
              <div>
                <div className="signup-name-row">
                  <Form.Item
                    name="signUPpassword"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            value !== "" &&
                            /[a-z]/g.test(value) &&
                            /[A-Z]/g.test(value) &&
                            /[0-9]/g.test(value) &&
                            /[!"#$%&'()*+,-./:;<=>?_@]/g.test(value) &&
                            value.length >= 12
                          ) {
                            Promise.resolve();
                            setPasswordValid(true);
                          } else if (value === "") {
                            setPasswordValid(false);
                            return Promise.reject(<ErrorComponent errorMsg={"Please Enter Password"} setErrorMsg={setErrorMsg} />);
                          } else if (value.length < 12) {
                            setPasswordValid(false);
                            return Promise.reject(
                              <ErrorComponent errorMsg={"Password must be of 12 characters"} setErrorMsg={setErrorMsg} /> 
                            );
                          } else {
                            setPasswordValid(false);
                            return Promise.reject(
                              <ErrorComponent errorMsg={"Password does not match Criteria"} setErrorMsg={setErrorMsg} />
                            );
                          }
                        },
                      },
                    ]}
                  >
                    <div className="input-icon">
                    <label>PASSWORD&nbsp;&nbsp;
                      <span>
                      <Tooltip
                        title={
                          <List
                            dataSource={passwordcriteria}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                          />
                        }
                        placement="top"
                        color={"#FFFFFF"}
                      >
                        <InfoCircleOutlined  />
                      </Tooltip>
                      </span>
                      </label>
                      <InputComponent
                        name="signUPpassword"
                        key={"signUPpassword"}
                       // label="PASSWORD"
                        type="password"
                        onChange={setSignUPpassword}
                        value={signUPpassword}
                        autoComplete="new-password"
                      />
                      
                    </div>
                  </Form.Item>

                  <Form.Item
                    name="repeatedPassword"
                    dependencies={["signUPpassword"]}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue("signUPpassword") === value) {
                            return Promise.resolve();
                          } else if (value === "") {
                            return Promise.reject(
                              <ErrorComponent errorMsg={"Please Re-enter the password"} setErrorMsg={setErrorMsg} />
                            );
                          } else {
                            return Promise.reject(
                              <ErrorComponent errorMsg={"The passwords does not match"} setErrorMsg={setErrorMsg} />
                            );
                          }
                        },
                      }),
                    ]}
                  >
                    <InputComponent
                      key={"repeatedPassword"}
                      label="re-enter password"
                      type="password"
                      onChange={setRepeatedPassword}
                      value={repeatedPassword}
                    />
                  </Form.Item>
                </div>
                
              </div>
              <div className="terms-conditions-box">
                <Checkbox
                  className="terms-check-box"
                  checked={termsAgreed}
                  onChange={() => {
                    termsAgreed == true ? setTermsAgreed(false):setTermsAgreed(true)
                    }}
                    > 
                    I Agree 
                </Checkbox>
                <span className="terms-text">
                  By creating an account or continuing to use a 
                  Pacpost.io you acknowledge and agree that you have 
                  accepted the <a onClick={()=>{selectTermsSection(1)}}>Terms of Service </a> 
                  {""}and reviewed the <a onClick={()=>{selectTermsSection(2)}}> Privacy Policy </a> 
                  {""}and <a onClick={()=>{selectTermsSection(3)}}>Subscription Agreement</a>
                </span>
                <Modal
                  title={null}
                  open={modalVisible}
                  //onOk={deleteSession}
                  //onCancel={()=>setModalVisible(false)}
                  className="terms-conditions-modal"
                  width={900}
                  closable={false}
                  footer={null}
                  //bodyStyle={{borderRadius: '10px'}}
                >
                  <TermsAndConditions section={section} />
                  <div className="modal-footer">
                    <Button 
                    key="back" 
                    onClick={()=>setModalVisible(false)} 
                    className="modal-button"
                    >
                      Close
                    </Button>
                  </div>
                </Modal>
              </div>
                
              <div className="register-btn-container">
              
                <Button
                  onClick={create_user}
                  size="large"
                  className="register-btn"
                  htmlType="submit"
                  disabled={
                    firstName?.trim() === "" ||
                    lastName?.trim() === "" ||
                    !emailValid ||
                    !passwordValid ||
                    signUPpassword !== repeatedPassword ||
                    termsAgreed === false
                  }
                >
                  Sign Up
                </Button>
                {
              userNotExistsError === "" ? <></> : <span style={{color: "red"}}>{userNotExistsError}</span>
                }
              </div>
            </Form>
            
            <Divider>or Sign Up with</Divider>
            <SocialSignupLogin setLoading={setLoading} setError={setError} termsAgreed={termsAgreed}/>
          </Space>
        </PageContent>
      </PageLayout>
    </Loader>
  );
};

export default Signup;

import { Button, Modal } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/auth";
import Loader from "../../LoaderHOC";
import {
    CloseOutlined
  } from "@ant-design/icons";
const UpgradeModal = (props:any) => {
    const navigate = useNavigate();
    const user: any = useContext(AuthContext);
    const {loading, logo} = props
  return (
    <Modal
      {...props}
    >
      <Loader loading={loading}>
        <div className="logoWrapper">
          <div>
            <img src={logo} alt="logo" />
          </div>

          <div>
            <button
              className="close-btn"
              onClick={props.onCancel}
            >
              <CloseOutlined />
            </button>
          </div>
        </div>

        {/* {isSubscriptionStatus === "trialing" && (
                  <BadgeComponent
                    text={`Your Pacpost lite trial will end on ${trialEnd}`}
                  />
                )} */}
        <div className="modal-text">
          <span className="modal-text-title">
            Upgrade your Pacpost.io subscription today.
            <br />
          </span>
          <div className="modal-text-content">
            <a
              onClick={() => {
                navigate("/setuproom");
              }}
            >
              Buy a subscription{" "}
            </a>
            at any time using your Pacpost.io ID "{user?.email}".
          </div>
        </div>
        <div className="continue-btn-wrapper">
          <Button
            className="cancel-btn"
            onClick={props.onCancel}
          >
            Cancel
          </Button>
          <Button
            className="continue-btn"
            // onClick={upgradeToPaidSubscription}
            onClick={() => {
              navigate("/setuproom");
            }}
          >
            Continue
          </Button>
        </div>
      </Loader>
    </Modal>
  );
};
export default UpgradeModal;

import { useContext, useEffect, useState } from "react";
import useGetroomupdates from "../../../hooks/useGetroomupdates";
import { get } from "../../../utils/helpers";
import { ROOM_STATE } from "../../../utils/constants";
import { useConferenceCommands } from "../../../components/ZoomViewer/hooks/useConferenceCommands";
import ZoomContext from "../context/zoom-context";

export default function useAutoLogout(admin_user_id: string) {
  const trigger = useGetroomupdates("users", true, admin_user_id);
  const zmClient = useContext(ZoomContext);
  const triggerData = trigger?.doc?.data();

  const [roomCode, setRoomCode] = useState("");
  const [roomId, setRoomId] = useState("");
  const [accessCode, setAcessCode] = useState("");

  const { handelVideoConfEnd } = useConferenceCommands(roomCode, roomId,admin_user_id);
  const [expireTime, setExpireTime] = useState(0);

  useEffect(() => {
    async function checkStreamExpired() {
      if (!roomCode && get(triggerData, "room_code", "")) {
        setRoomCode(get(triggerData, "room_code", ""));
      }
      if (!roomId) {
        setRoomId(get(trigger, "doc.id", ""));
      }

      // Logout the user when admin refresh room code.
      if (
        !!zmClient &&
        !!accessCode &&
        get(triggerData, "stream_admin_code", "") &&
        accessCode !== get(triggerData, "stream_admin_code", "")
      ) {
        await handelVideoConfEnd();
      }

      if (!accessCode && get(triggerData, "stream_admin_code", "")) {
        setAcessCode(get(triggerData, "stream_admin_code", ""));
      }

      // Logout the user when session is deleted.
      if (
        (!!zmClient &&
          get(triggerData, "minute_trial_expired", false) &&
          get(triggerData, "room_state", "") === ROOM_STATE.MINUTE_TRIAL &&
          get(triggerData, "free_room_flag", false)) ||
        (!!zmClient && get(triggerData, "stream_status", "") === "Deleted")
      ) {
        await handelVideoConfEnd();
      }

      // Expire timer currently not in use 30/05/24
      // Can be used when minute trial is enabled.
      if (!expireTime && get(triggerData, "minute_trial_end_time", 0)) {
        setExpireTime(get(triggerData, "minute_trial_end_time", 0));
      }
    }
    checkStreamExpired();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerData]);

  return expireTime;
}

import React from "react";
import { useState } from "react";

const ErrorComponent = (props:any)=>{
    
    const {errorMsg}=props;
    
    return(
    <div style={{color:'red',opacity:'1', fontSize:'14px',}}>{errorMsg}</div>

    )


};
export default ErrorComponent;
import { Badge, message, Popconfirm } from "antd";
import React, { useState } from "react";
import visaLogo from "../../../../assets/brand/visa-logo.png"
import "./styles.scss";
import { CloseCircleFilled } from '@ant-design/icons';

const SavedCard = (props:any) => {
  const {paymentmethod,loading, index, deletePayment } = props;

  const deleteCard = () => {
    console.log('paymentmethod :>> ', paymentmethod);
    try {
      deletePayment(paymentmethod.id)
    } catch (err: any) {
      console.log(err);
    }
  }

  // handle popconfirm event
  const confirm = (e: React.MouseEvent<HTMLElement>) => {
    console.log(e);
  };

  const cancel = (e:any) => {
    console.log(e);
  };

  return (
    <Popconfirm
      title="Are you sure you want to delete card?"
      onConfirm={deleteCard}
      onCancel={cancel}
      okText="Yes"
      cancelText="No"
    >
      <Badge
        count={<button className="close-icon" onClick={confirm}><CloseCircleFilled style={{ color: '#f5222d', fontSize: '20px' }}/></button>}
        offset={[-1, 1]}
        title="Delete Card">
        <div className="savedcard">
          {/* <p>{paymentmethod.card.brand} card {paymentmethod.card.last4}</p> */}
          <div className="card-type">
            {paymentmethod.card.brand == 'visa' ? (
              <img src={visaLogo} alt="visa" />
            ) : paymentmethod.card.brand}
          </div>
          <div className="card-last4">
            ****  {paymentmethod.card.last4}
          </div>
          <br />
        </div>
      </Badge>
    </Popconfirm>
  );
};

export default SavedCard;

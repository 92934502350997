import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { useChatStore } from '../../../store';

interface ClearChatProps {
  clearChat: () => void;
}

const ClearChat: React.FC<ClearChatProps> = ({
  clearChat
}) => {

  const { clearChatModalOpen, setClearChatModalOpen } = useChatStore();
  return (
    <Modal
      open={clearChatModalOpen}
      size="tiny"
      closeOnEscape={false}
      closeOnDimmerClick={false}
      style={{ fontSize: "20px" }}
    >
      <Modal.Header style={{ fontSize: "20px" }}>Clear Chat</Modal.Header>
      <Modal.Content>
        You are about to clear the chat messages
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="green"
          onClick={() => clearChat()}
          style={{ fontSize: "15px" }}
        >
          OK
        </Button>
        <Button
          color="grey"
          onClick={() => setClearChatModalOpen(false)}
          style={{ fontSize: "15px" }}
        >
          CANCEL
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ClearChat;
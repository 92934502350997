import { useEffect, useState } from 'react';
import useGetroomupdates from '../../../hooks/useGetroomupdates';
import { get } from '../../../utils/helpers';
import { doSignOut } from "../../../firebase/auth";
import { useNavigate } from 'react-router-dom';
import { ROOM_STATE } from '../../../utils/constants';

export default function useFreeSessionTimeout(
  admin_user_id: string,
  handelVideoConfEndAllRef: (autoLogoutFlag: boolean) => Promise<void>,
  accessCode: string,
  twilio_vc_room_name: string
) {
  const trigger = useGetroomupdates('users', true, admin_user_id);
  const triggerData = trigger?.doc?.data();
  const [expireTime, setExpireTime] = useState(0);
  const navigate = useNavigate();
  const signout = async () => {
    await doSignOut();
    localStorage.removeItem('url');
    navigate('/viewerLogoutPage', { replace: true });
  };

  useEffect(() => {
    async function checkStreamExpired() {
      if (
        !!twilio_vc_room_name &&
        !!get(triggerData, 'stream_admin_code', '') &&
        !!accessCode &&
        get(triggerData, 'stream_admin_code', '') !== accessCode
      ) {
        handelVideoConfEndAllRef(true);
        // navigate("/", { replace: true });
        signout();
      }

      if ( !!twilio_vc_room_name &&
        ((get(triggerData, 'minute_trial_expired', false) &&
          get(triggerData, 'room_state', '') === ROOM_STATE.MINUTE_TRIAL &&
          get(triggerData, 'free_room_flag', false)) ||
        get(triggerData, 'stream_status', '') === 'Deleted')
      ) {
        // Logout the user
        console.log(':::::::user logged out');
        handelVideoConfEndAllRef(true);
        // navigate("/", { replace: true });
        signout();
      }
      setExpireTime(get(triggerData, 'minute_trial_end_time', 0));
    }
    checkStreamExpired();
  }, [triggerData]);

  return expireTime;
}

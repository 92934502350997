import { useCallback, useContext, useState } from "react";
import ZoomContext from "../../../pages/ZoomViewer/context/zoom-context";
import { AuthContext } from "../../../context/auth";
import { get } from "../../../utils/helpers";
import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";
import { auth } from "../../../firebase/utils";
import { useNavigate } from "react-router-dom";

export function useConferenceCommands(
  room_code: string,
  room_id: string,
  admin_user_id: string
) {
  const zmClient = useContext(ZoomContext);
  const userLoggedIn: any = useContext(AuthContext);
  const [endorLeaveConferenceLoading, setEndorLeaveConferenceLoading] =
    useState(false);
  const isGuestViewer = get(userLoggedIn, "guestViewer", false);
  const displayName: string = !isGuestViewer
    ? `${get(userLoggedIn, "fn", "")} ${get(userLoggedIn, "ln", "")}`
    : `${get(userLoggedIn, "displayName", "")}`;
  
  const uuid = `${get(userLoggedIn, "uid", "")}`;
  const userIdentity = `${
    isGuestViewer ? "member" : "admin"
  }#${uuid}_${displayName}`;
  const navigate = useNavigate();

  const endConferenceandRefreshCode = useCallback(async () => {
    try {
      await handelVideoConfEndAll(true);
    } catch (err: any) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zmClient, room_code]);

  const handelVideoConfEndAll = useCallback(
    async (refreshFlag?: boolean) => {
      try {
        if (isGuestViewer) {
          return;
        }
        setEndorLeaveConferenceLoading(true);
        await zmClient.leave(true);
        const identity = userIdentity;
        const removeWaitingRoom = {
          api: api.room.clearWaitingRoom,
          payLoad: JSON.stringify({
            room_code: room_code,
            user_id: admin_user_id,
            room_id: room_id,
            identity,
            end: true,
            refreshRoomFlag: !!refreshFlag,
          }),
        };
        await backend.save(removeWaitingRoom);
        await auth.signOut();
        //TO DO:leave chat, close all panels beforelogout.
        sessionStorage.clear();
        localStorage.removeItem("url");
        navigate("/viewerLogoutPage", { replace: true });
      } catch (err: any) {
        console.error(err);
      } finally {
        setEndorLeaveConferenceLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [zmClient, room_code]
  );

  const handelVideoConfEnd = useCallback(async () => {
    try {
      const participants = await zmClient.getAllUser();
      setEndorLeaveConferenceLoading(true);
     
      let endFlag = false;
      if (participants.length === 1) {
        //End the conference if user is the last user in vc
        endFlag = true;
      }
      await zmClient.leave();

      const identity = userIdentity;
      const removeWaitingRoom = {
        api: api.room.clearWaitingRoom,
        payLoad: JSON.stringify({
          room_code: room_code,
          user_id: admin_user_id,
          room_id: room_id,
          identity,
          end: endFlag,
        }),
      };
      await backend.save(removeWaitingRoom);
      await auth.signOut();
      //TO DO:leave chat, close all panels beforelogout.
      sessionStorage.clear();
      localStorage.removeItem("url");
      navigate("/viewerLogoutPage", { replace: true });
    } catch (err: any) {
      console.error(err);
    } finally {
      setEndorLeaveConferenceLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zmClient, room_code]);

  return {
    endorLeaveConferenceLoading,
    handelVideoConfEndAll,
    handelVideoConfEnd,
    endConferenceandRefreshCode,
  };
}

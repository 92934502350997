import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AuthProvider } from "./context/auth";
import "./default.scss";
import PrivateRoute from "./utils/PrivateRoute";

import HomePage from "./pages/HomePage";
import HomepageLayout from "./layouts/HomePageLayout";
import AppLayout from "./layouts/AppLayout";
import LogIn from "./pages/Login";
import Registration from "./pages/Registration";
import Profile from "./pages/Profile";
import TwoFA from "./pages/TwoFA";
import PaymentMethods from "./pages/PaymentMethods";
import SetupRoom from "./pages/PurchaseRoom";
import Viewer from "./pages/Viewer";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import GuestViewer from "./pages/GuestViewer";
import PageNotFound from "./pages/404";
import "./App.scss";
import OtpPage from "./components/Auth/Otp";
import EmailOTPPage from "./components/Auth/EmailOTP";
import SessionHandler from "./components/SessionHandle";
import ViewerLogoutPage from "./components/ViewerLogoutPage";
import UnSupported from "./utils/UnSupported";
import ZoomShowViewerDashboard from "./pages/ZoomViewer/Viewer";
import Invoice from "./components/Auth/Invoice";

const App = () => {
  const [browserCheck, setBrowserCheck] = useState("unknown");

  useEffect(() => {
    const agent = window.navigator.userAgent.toLowerCase();
    
    let browserName = "unknown";
    switch (true) {
      case agent.indexOf("edge") > -1:
        browserName = "unknown";
        break;
      case agent.indexOf("edg/") > -1:
        browserName = "unknown";
        break;
      case agent.indexOf("opr") > -1:
        browserName = "unknown";
        break;
      case agent.indexOf("chrome") > -1 && !!window.chrome:
        browserName = "Google Chrome";
        break;
      case agent.indexOf("trident") > -1:
        browserName = "unknown";
        break;
      case agent.indexOf("firefox") > -1:
        browserName = "unknown";
        break;
      case agent.indexOf("safari") > -1:
        browserName = "Apple Safari";
        break;
      default:
        browserName = "unknown";
    }
    if (browserName === "Chrome") {
      setBrowserCheck("Google Chrome");
    } else if (browserName === "Safari") {
      setBrowserCheck("Apple Safari");
    } else {
      setBrowserCheck(browserName);
    }
  }, []);

  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <SessionHandler />
          <Routes>
            {["/404", "/viewer/404"].map((path: string) => (
              <Route
                path={path}
                element={
                  <AppLayout>
                    <PageNotFound />
                  </AppLayout>
                }
              />
            ))}
            <Route
              path="/404"
              element={
                <AppLayout>
                  <PageNotFound />
                </AppLayout>
              }
            />
            {["/", "/viewer/login"].map((path: string) => (
              <Route
                path={path}
                element={
                  browserCheck !== "unknown" ? (
                    <AppLayout>
                      <LogIn />
                    </AppLayout>
                  ) : (
                    <UnSupported />
                  )
                }
              />
            ))}
            <Route
              path={"/gl"}
              element={
                browserCheck !== "unknown" ? (
                  <AppLayout>
                    <GuestViewer />
                  </AppLayout>
                ) : (
                  <UnSupported />
                )
              }
            />

            {["/otp", "/viewer/otp"].map((path: string) => (
              <Route
                path={path}
                element={
                  <AppLayout>
                    <OtpPage />
                  </AppLayout>
                }
              />
            ))}
            {["/signup", "/viewer/signup"].map((path: string) => (
              <Route
                path={path}
                element={
                  <AppLayout>
                    <Registration />
                  </AppLayout>
                }
              />
            ))}
            {["/forgotpassword", "/viewer/forgotpassword"].map(
              (path: string) => (
                <Route
                  path={path}
                  element={
                    <AppLayout>
                      <ForgotPasswordPage />
                    </AppLayout>
                  }
                />
              )
            )}
            {["/resetpassword", "/viewer/resetpassword"].map((path: string) => (
              <Route
                path={path}
                element={
                  <AppLayout>
                    <ResetPasswordPage />
                  </AppLayout>
                }
              />
            ))}
            {["/changepassword", "/viewer/changepassword"].map(
              (path: string) => (
                <Route
                  path={path}
                  element={
                    <PrivateRoute>
                      <AppLayout>
                        <ChangePasswordPage />
                      </AppLayout>
                    </PrivateRoute>
                  }
                />
              )
            )}
            {["/emailOTP", "/viewer/emailOTP"].map((path: string) => (
              <Route
                path={path}
                element={
                  <AppLayout>
                    <EmailOTPPage />
                  </AppLayout>
                }
              />
            ))}
            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <AppLayout>
                    <HomePage />
                  </AppLayout>
                </PrivateRoute>
              }
            />
            {["/profile", "/viewer/profile"].map((path: string) => (
              <Route
                path={path}
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
            ))}
            {["/manage2FA", "/viewer/manage2FA"].map((path: string) => (
              <Route
                path={path}
                element={
                  <PrivateRoute>
                    <TwoFA />
                  </PrivateRoute>
                }
              />
            ))}
            {["/invoice"].map((path: string) => (
              <Route
                path={path}
                element={
                  <PrivateRoute>
                    <Invoice/>
                  </PrivateRoute>
                }
              />
            ))}
            <Route
              path="/payment_methods"
              element={
                <PrivateRoute>
                  <HomepageLayout>
                    <PaymentMethods />
                  </HomepageLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/setuproom"
              element={
                <PrivateRoute>
                  <AppLayout>
                    <SetupRoom />
                  </AppLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/viewerLogoutPage"
              element={
                <AppLayout>
                  <ViewerLogoutPage />
                </AppLayout>
              }
            />
             <Route
              path="/zoom-video"
              element={
                <PrivateRoute>
                  <AppLayout>
                    {/* Added temporarily for dev testing */}
                    <ZoomShowViewerDashboard
                      room_name="New Test"
                      firstName="John"
                      lastName="Doe"
                    />
                  </AppLayout>
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
};

export default App;

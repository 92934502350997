//import streamPolygon from "../.../icons/stream-polygon.svg"

import {
  LocalDataTrack,
  connect,
  createLocalTracks,
  createLocalVideoTrack,
  createLocalAudioTrack,
  LocalVideoTrackPublication,
  LocalVideoTrack,
  LocalAudioTrackPublication,
  LocalAudioTrack,
  LocalTrack,
  NoiseCancellationOptions
} from "twilio-video";
import {
  GaussianBlurBackgroundProcessor,
  VirtualBackgroundProcessor,
  ImageFit,
} from "@twilio/video-processors";
import ColorHash from "color-hash";
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
  useContext
} from "react";
import { useNavigate, useLocation,useNavigationType } from "react-router-dom";
import {
  Input,
  Button,
  Dropdown,
  Popup,
  Modal,
  Confirm,
  TextArea,
} from "semantic-ui-react";

import axios from "axios";
import PubNub from "pubnub";
import { Parser } from "json2csv";

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { turnApiUrl, CHIME_VALUES, FEATURE_NAME, ROOM_STATE } from "../../utils/constants";
import { get } from "../../utils/helpers";
import api from "../../service/rest/restApi";
import backend from "../../service/rest/restService";
import ShowStream from "./ShowStream";
import ProgressLoader from "../../components/Common/ProgressLoader";
import * as auth from "../../firebase/auth";

import { isViewInScreen, convertDateTimeFromUTCEpoc } from "../../utils/utils";
import "./newshowviewer.scss";
//Header VC control images
import pacpost_img from "./Images/logo_PP_white_header.png";
import phoneFilled from "./Images/newPhone_outline.svg";
import settingsImg from "./Images/settings.svg";
import download_chat from "./Images/download_arrow.png";
import fitToScreen from "./Images/fullScreen.svg";
import golbalAudioMute from "./Images/muteAll.svg";
import golbalAudioUnMute from "./Images/unmuteAll.svg";
import pencil from "./Images/pencil.svg";
import erase from "./Images/eraser.svg";
import clear from "./Images/clear.svg";
import trash from "./Images/trash.svg";
import microphoneOn from "./Images/micOn.svg";
import microphoneOff from "./Images/micOff.svg";
import cameraOn from "./Images/cameraOn.svg";
import cameraOff from "./Images/cameraOff.svg";
import noiseCancellationOn from "./Images/noise-cancellation-on.svg";
import noiseCancellation from "./Images/noise-cancellation.svg";
import changeView from "./Images/changeView.svg";
import volume from "./Images/newVolume.svg";
import volumeMuteIcon from "./Images/volume_mute.svg";
import refreshIcon from "./Images/reload.svg";
import greeTick from "./Images/green-tick.png";
import crossTick from "./Images/red-cancel.png";
import editPencil from "./Images/pencil.png";
import volumeLevel2 from "./Images/volumeLevel2.svg"
import volumeLevel3 from "./Images/volumeLevel3.svg"

//Right nav control
import guestWhite from "./Images/guest_whitebg.svg";
import chatImg from "./Images/chat.svg";
import closeImg from "./Images/close.svg";

//left sidebar Images
import sendMsgIcon from "./Images/send-chat.svg";
import configSource from "./Images/config-source.svg";

import Abstract_thumb from "./Images/Abstract_thumb.jpg";
import Bookshelf_thumb from "./Images/Bookshelf_thumb.jpg";
import CoffeeShop_thumb from "./Images/CoffeeShop_thumb.jpg";
import SanFrancisco_thumb from "./Images/SanFrancisco_thumb.jpg";
import Abstract from "./Images/Abstract.jpg";
import Bookshelf from "./Images/Bookshelf.jpg";
import CoffeeShop from "./Images/CoffeeShop.jpg";
import SanFrancisco from "./Images/SanFrancisco.jpg";
import Blur_thumb from "./Images/Blur_thumb.svg";
import None_thumb from "./Images/None_thumb.svg";
import VideoTiles from "./VideoTiles";
import HeaderView from "./HeaderView";
import ChatMenu from "./ChatMenu";
import GuestInvite from "./GuestInvite";
import { AppMenu } from "../../components/AppMenu";
import { AuthContext } from "../../context/auth";
import useDisconnectRoomOnPageUnload from './useDisconnectRoomOnPageUnload';
import useFreeSessionTimeout from "./hooks/useFreeSessionTimeout";
import FreeSessionTimer from "./FreesessionTimer/Freesessiontimer";
import Reload from "./Reload";
import ConfirmationalPopup from "./ConfirmationModal";
import { notification } from "antd";
import useNoiseCancellation from "./useNoiseCancellation";
import { HudStatState } from "./HudsStats/structs";
import HudStatsPanel from "./HudsStats/HudsStatsPanel";
import { GuestListProvider } from "../ZoomViewer/context/guest-context";


const virtualBackgroundAssets = "/virtualbackground";
let blurProcessor: GaussianBlurBackgroundProcessor;
let virtualBackgroundProcessor: VirtualBackgroundProcessor;

const backgrounds = [
  { value: "none", image: "", thumbImage: None_thumb },
  { value: "blur", image: "", thumbImage: Blur_thumb },
  { value: "abstract", image: Abstract, thumbImage: Abstract_thumb },
  { value: "bookshelf", image: Bookshelf, thumbImage: Bookshelf_thumb },
  { value: "coffeeshop", image: CoffeeShop, thumbImage: CoffeeShop_thumb },
  {
    value: "sanfrancisco",
    image: SanFrancisco,
    thumbImage: SanFrancisco_thumb,
  },
];

//chime_audio
const chimeAudioUrl = require("./sounds/chime.mp3");
let colorHash = new ColorHash();

const annotationColorOptions = [
  { text: "red", value: "red" },
  { text: "green", value: "green" },
  { text: "blue", value: "blue" },
  { text: "cyan", value: "cyan" },
  { text: "magenta", value: "magenta" },
  { text: "yellow", value: "yellow" },
  { text: "black", value: "black" },
  { text: "white", value: "white" },
];

const noiseCancellationOptions: NoiseCancellationOptions = {
  sdkAssetsPath: `${process.env.PUBLIC_URL || window.location.origin}/${
    process.env.NOISE_CANCELLATION_PLUGIN_PATH
  }`,
  vendor: 'krisp',
};

const NewShowViewerDashboard = (props: any) => {
  const { search } = useLocation();
  interface viewerLinkData{
    accessLink:string,
    accessCode:string
  }
  const [viewerData, setViewerData] = useState<viewerLinkData>({
    accessLink:'',
    accessCode:''
  })
  const userLoggedIn: any = useContext(AuthContext);
  
  const isGuestViewer = get(userLoggedIn,'guestViewer',false)

  //console.log("props", props);
  const {
    user_id,
    admin_user_id,
    room_id,
    // room_name,
    room_code,
    role,
    firstName,
    lastName,
    uuid,
    resetValues,
    videoConfEndRef,
    guestList = [],
    accessCode,
    editorDirectLogin,
    isViewer,
  } = props;
  const displayName:string = !editorDirectLogin ? `${get(userLoggedIn,'fn','')} ${get(userLoggedIn,'ln','')}` : `${get(userLoggedIn,'displayName','')}`
  
  const room_name = sessionStorage.getItem('room_name') || '';
  let history = useNavigate();
  const navigationType = useNavigationType();

  const [timeCodePopup, setTimeCodePopup] = useState(false);

  const [loading, setLoading] = useState(false);
  const [annotateLoading, setAnnotateLoading] = useState(false);
  const [chatClearLoading, setChatClearLoading] = useState(false);
  const [annotateChatClearLoading, setAnnotateChatClearLoading] =
    useState(false);
  const [chatExportLoading, setChatExportLoading] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);

  const [showViewer, setShowViewer] = useState(false);

  const [streamLabel, setStreamLabel] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [showId, setShowId] = useState("");
  const [selectedStream, setStreamId] = useState("");
  const [activeStreams, setActiveStreams] = useState([] as any);

  const [stream, setStream] = useState({} as any);
  console.log(":::stream::::",stream)
  const [streamKey, setStreamKey] = useState(Math.random());
  const [iceServers, setIceServers] = useState([] as any);

  const [activeStreamVCRoom, setRoom] = useState({} as any);
  const [isVCHost, setIsVCHost] = useState(false);
  const [troom, setTRoom] = useState({} as any);
  const troomRef = useRef<any>();
  troomRef.current = troom;
  const [localParticipant, setLocalParticipant] = useState({} as any);
  const [cameraId, setCameraId] = useState("");
  const [cameraName, setCameraName] = useState("Select Camera");
  const cameraIdRef = useRef<string>();
  const [micId, setMicId] = useState("");
  const [micName, setMicName] = useState("Select Microphone");
  const micIdRef = useRef<string>();
  cameraIdRef.current = cameraId;
  micIdRef.current = micId;
  const [muteAll, setMuteAll] = useState(false);
  const [localMute, setlocalMute] = useState(true);

  let refreshRoomFlag = false;

  //Config Stream
  const [showConfig, setShowConfig] = useState([]);
  //const [activeStreamCount, setActiveStreamCount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [clearChatModalOpen, setClearChatModalOpen] = useState(false);
  const [exportChartConfirmationOpen, setExportChartConfirmationOpen] =
    useState(false);

  // streamMenuActive, setStreamMenuActive, showUser, setUsers, showCloseUser, setCloseUsers, showOpenUser, setOpenUsers, showChat, setChatWindow, showAnnotationChat, setAnnotationChatWindow, isModalOpen, setModalStatus, showCloseDisable, setCloseDisable, showAnnotationCloseDisable, setAnnotationCloseDisable, showChatDisable, setChatDisable, showAnnotationChatDisable, setAnnotationChatDisable

  //left sidebar
  const [showStreamMenu, setShowStreamMenu] = useState(false);
  const [streamMenuActive, setStreamMenuActive] = useState(true);
  const [showUser, setUsers] = useState(false);
  const [showCloseUser, setCloseUsers] = useState(false);
  const [showOpenUser, setOpenUsers] = useState(true);
  const [showChat, setChatWindow] = useState(false);
  const [showAnnotationChat, setAnnotationChatWindow] = useState(false);
  const [isModalOpen, setModalStatus] = useState(false);
  const [showCloseDisable, setCloseDisable] = useState(false);
  const [showAnnotationCloseDisable, setAnnotationCloseDisable] =
  useState(false);
  const [showChatDisable, setChatDisable] = useState(true);
  const [showAnnotationChatDisable, setAnnotationChatDisable] = useState(true);
  const leftBarRef = useRef<HTMLDivElement>(null);

  //Pubnub
  const [pubnub, setPubNub] = useState({} as any);
  const [channels, setChannels] = useState([] as any[]);
  let [allMessages, setAllMessages] = useState([] as any[]);
  let [allAnnotateMessages, setAllAnnotateMessages] = useState([] as any[]);
  const [input, setInput] = useState("");
  const [annotateInput, setAnnotateInput] = useState("");
  const [typingInd, setTypingInd] = useState("");
  // const [editInput, setEditInput] = useState("");
  const [editAnnotateInput, setAnnotateEditInput] = useState("");
  //  const [editMessage, setEditMessage] = useState(false);

  const [editAnnotateMessage, setEditAnnotateMessage] = useState(false);

  const [editingAnnotateMessageId, setEditingAnnotateMessageId] = useState("");

  let photoURL: any;
  if (auth && auth.getUser() && auth.getUser().photoURL) {
    photoURL = auth.getUser().photoURL;
  }
  const [user_imageurl, setProfileImageUrl] = useState(photoURL);
  const [user_chime_preferance, setUserChimePreferance] = useState(false);

  const [signalStrengthOpen, setsignalStrengthOpen] = useState(false);
  const [signalStrengthClose, setsignalStrengthClose] = useState(false);
  const [overlay, setOverlay] = useState({ style: { display: "none" } });
  const [overlayConnected, setOverlayConnected] = useState({
    style: { display: "none" },
  });
  const [overlayDisconnected, setOverlayDisconnected] = useState({
    style: { display: "none" },
  });
  const [overlayMsg, setOverlaymsg] = useState("");
  const [overlayMsgConnected, setOverlayMsgConnected] = useState("");
  const [overlayMsgDisconnected, setOverlayMsgDisconnected] = useState("");
  const [user_invited, setUserInvited] = useState(true);

  //Uploadfile
  const [uploadFiles, setUploadFiles] = useState([] as any);
  const [fileName, setFileName] = useState("");
  // const [browseInput, setbrowseInput] = useState(false);
  const [uploadAlert, setUploadAlert] = useState(false);

  //popup
  const [showPopup, setShowPopup] = useState(false);

  //Drawing option
  const [showDrawingOption, setDrawingOption] = useState(false);

  // Layout
  const [layoutMode, setLayoutMode] = useState("normal");

  //Volume show and hide
  const [showVolume, setShowVolume] = useState(false);
  const [playIconStatus, setPlayIconStatus] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(100);
  const [volumeMute, setVolumeMute] = useState(false);

  //emoji
  const [showEmoji, setShowEmoji] = useState(false);
  //full screen
  const fullScreenHandler = useFullScreenHandle();

  const [selectedAnnotateColor, setSelectedAnnotateColor] = useState("");
  const [VFXcolor, setVFXcolor] = useState("red");
  const [SFXcolor, setSFXcolor] = useState("red");
  const [MXcolor, setMXcolor] = useState("red");
  const [PIXcolor, setPIXcolor] = useState("red");
  const [modalAnnotateColorOpen, setModalAnnotateColorOpen] = useState(false);
  // const [messages, setMessages] = useState([] as any[]);
  // const [annotateMessages, setAnnotateMessages] = useState([] as any[]);

  // Camera and microphone selection
  const [cameraOptions, _setCameraOptions] = useState([]);

  const setCameraOptions = (data: any) => {
    _setCameraOptions(data);
  };

  const [micOptions, _setMicOptions] = useState([]);

  const setMicOptions = (data: any) => {
    _setMicOptions(data);
  };

  const [messages, _setMessages] = useState([] as any[]);
  const messagesRef = useRef(messages);
  const setMessages = (data: any[]) => {
    messagesRef.current = data;
    _setMessages(data);
  };

  const [annotateMessages, _setAnnotateMessages] = useState([] as any[]);
  const annotateMessagesRef = useRef(annotateMessages);
  const setAnnotateMessages = (data: any[]) => {
    annotateMessagesRef.current = data;
    _setAnnotateMessages(data);
  };

  const [invitedViewers, _setInvitedViewers] = useState([] as any);
  const invitedViewersRef = useRef(invitedViewers);
  const setInvitedViewers = (data: any) => {
    invitedViewersRef.current = data;
    _setInvitedViewers(data);
  };

  const [lParticipant, _setLLocalParticipant] = useState({} as any);
  const lParticipantRef = useRef(lParticipant);
  const setLLocalParticipant = (data: any) => {
    lParticipantRef.current = data;
    _setLLocalParticipant(data);
  };

  // White boarding
  const inMemCanvas: HTMLCanvasElement = document.createElement("canvas");

  const [isEraser, _setMyEraser] = useState(false);
  const myEraserRef = useRef(isEraser);
  const setMyEraser = (data: boolean) => {
    myEraserRef.current = data;
    _setMyEraser(data);
  };

  const [isPencilCircle, _setMyPencilCircle] = useState(false);
  const myPencilCircleRef = useRef(isPencilCircle);
  const setMyPencilCircle = (data: boolean) => {
    myPencilCircleRef.current = data;
    _setMyPencilCircle(data);
  };

  const [isPencilLine, _setMyPencilLine] = useState(false);
  const myPencilLineRef = useRef(isPencilLine);
  const setMyPencilLine = (data: boolean) => {
    myPencilLineRef.current = data;
    _setMyPencilLine(data);
  };

  const [vratio, _setVRatio] = useState(1.6);
  const vratioRef = useRef(vratio);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const endConfBtn = useRef(null);
  const [isPainting, setIsPainting] = useState(false);
  const [mousePosition, setMousePosition] = useState<Coordinate | undefined>(
    undefined
  );

  const [localAudioMute, _setLocalAudioMute] = useState(true);
  const localAudioMuteRef = useRef(localAudioMute);
  const setLocalAudioMute = (data: boolean) => {
    localAudioMuteRef.current = data;
    _setLocalAudioMute(data);
  };

  const [localVideoMute, _setLocalVideoMute] = useState(true);
  const localVideoMuteRef = useRef(localVideoMute);
  const setLocalVideoMute = (data: boolean) => {
    localVideoMuteRef.current = data;
    _setLocalVideoMute(data);
  };

  const [dataTrack, _setDataTrack] = useState<any>();
  const dataTrackRef = useRef(dataTrack);
  const setDataTrack = (data: any) => {
    dataTrackRef.current = data;
    _setDataTrack(data);
  };

  const [roomParticipantsList, _setRoomParticipantsList] = useState([] as any);
  const [
    roomParticipantsConnectionStatus,
    _setRoomParticipantsConnectionStatus,
  ] = useState([] as any);
  const roomParticipantsListRef = useRef(roomParticipantsList);
  const roomParticipantsConnectionStatusRef = useRef(
    roomParticipantsConnectionStatus
  );
  const setRoomParticipantsList = (data: any) => {
    roomParticipantsListRef.current = data;
    let connectionStatusArr: any = [],
      connectionStatusObjArr: any = [];
    data.forEach((item: any) => {
      let member = item.identity.substring(
        item?.identity.lastIndexOf("#") + 1,
        item?.identity.lastIndexOf("_")
      );
      let statusObj: any = { user: member, status: item.state };
      let index = connectionStatusArr.indexOf(member);
      if (index === -1) {
        connectionStatusArr.push(member);
        connectionStatusObjArr.push(statusObj);
      } else {
        connectionStatusObjArr[index] = statusObj;
      }
    });
    roomParticipantsConnectionStatusRef.current = connectionStatusObjArr;
    _setRoomParticipantsConnectionStatus(connectionStatusObjArr);
    _setRoomParticipantsList(data);
  };

  const [isDND, _setMyDND] = useState(false);
  const myDNDRef = useRef(isDND);
  const setMyDND = (data: boolean) => {
    myDNDRef.current = data;
    _setMyDND(data);
  };
  const [roomParticipantsWithTracks, setRoomParticipantsWithTracks] = useState(
    [] as any
  );
 
  //publisher panel
  const [showPublisherPanel, setShowPublisherPanel] = useState(false);
  const [showPublisherPanelCloseDisable, setShowPublisherPanelCloseDisable] =
    useState(false);
 

  //Guest Invite
  const [showGuestInvite, setShowGuestInvite] = useState(false);
  const [showGuestInviteCloseDisable, setShowGuestInviteCloseDisable] =
    useState(false);

  // const sendTextMessageInput = useRef<HTMLInputElement>(null);

  const [cameraDropdownOpen, setCameraDropdownOpen] = useState(false);

  const [guestUsersList, setGuestUsersList] = useState([] as any[]);

  const [usersUpdated, setUsersUpdated] = useState(false);

  const [messageShown, setMessageShown] = useState(false);

  // Dominant Speaker
  const [showDominantSpeaker, setShowDominantSpeaker] = useState(false);
  const [dominantSpeakerID, _setDominantSpeakerID] = useState("");
  const dominantSpeakerIDRef = useRef(dominantSpeakerID);
  const setDominantSpeakerID = (data: any) => {
    dominantSpeakerIDRef.current = data;
    _setDominantSpeakerID(data);
  };

  //Dominant Speaker track
  const [dominantSpeakerTrack, _setDominantSpeakerTrack] = useState({} as any);
  const dominantSpeakerTrackRef = useRef(dominantSpeakerTrack);
  const setDominantSpeakerTrack = (data: any) => {
    dominantSpeakerTrackRef.current = data;
    _setDominantSpeakerTrack(data);
  };

  //Local Participant track
  const [localParticipantTrack, _setLocalParticipantTrack] = useState(
    {} as any
  );
  const localParticipantTrackRef = useRef(localParticipantTrack);
  const setLocalParticipantTrack = (data: any) => {
    localParticipantTrackRef.current = data;
    _setLocalParticipantTrack(data);
  };

  const [showStreamUpdate, _setShowStreamUpdate] = useState(1);
  const showStreamUpdateRef = useRef(showStreamUpdate);
  const setShowStreamUpdate = (data: any) => {
    showStreamUpdateRef.current = data;
    _setShowStreamUpdate(data);
  };

  //Hudstats related  
  const [hudsState, setHudsState] = useState<HudStatState>({
    activesStreamExists:false,
    activesStreamStatus:false,
    openHudStatsPanel:false,
    milicastView: undefined
  })

  const [layerOptions, setLayerOptions] = useState([]);
  const [selectedLayer, setSelectedLayer] = useState('Video Quality');

  //Virtual Background
  const [localParticipantBGModalOpen, setLocalParticipantBGModalOpen] =
    useState(false);
  const [tempActiveBG, setTempActiveBG] = useState("");
  const [activeBG, _setActiveBG] = useState("none");
  const activeBGRef = useRef(activeBG);
  const setActiveBG = (data: any) => {
    activeBGRef.current = data;
    _setActiveBG(data);
  };

  const handelVideoConfEndAllRef: any = useRef(null)
  useEffect(() => {
    handelVideoConfEndAllRef.current = handelVideoConfEndAll
  }, [])
  const twilio_vc_room_name_ref = useRef("") 
  const [adminAccessCode,setAdminAccessCode]=useState(accessCode)
  const freeSessionTime = useFreeSessionTimeout(admin_user_id,handelVideoConfEndAllRef.current,adminAccessCode,twilio_vc_room_name_ref.current );
  const [minute_trial_end_time, setMinute_trial_end_time ] = useState(0)
  const [isFreeRoom,setIsFreeRoom]=useState(false)
  const [hasNoiseCancellation, cancelNoise, setCancelNoise, clearCancelNoise] =
  useNoiseCancellation(troom);

  
  //TO-DO: Back Button
  useEffect(() => {
    return () => {
      if (navigationType === 'POP') {
        if (troom && !isEmpty(troom)) {
          troom.disconnect();
        }
        clearWhiteBoard();
      } else if (troom && !isEmpty(troom) && navigationType === 'PUSH') {
        troom.localParticipant.tracks.forEach((publication: any) => {
          if (publication.kind === 'video') {
            publication.track.stop();
            publication.unpublish();
          }
        });

        troom.disconnect();
        leaveOrEndConference(false);
      }
    };
  }, [navigationType, troom]);

  useEffect(() => {
    const stream_id = sessionStorage.getItem("stream_id");
    const stream_label: any = sessionStorage.getItem("stream_label");

    const fetchStreamData = async () => {
      if (stream_id) {
        let stream = { stream_id, stream_label };
        setStream(stream);

        setStreamKey(Math.random());

        // Pull VC Room Details and start/join VC
        await fetchVCRoomAndStartOrJoin(stream_id, stream_label);
      }
    };
    if (stream_id && stream_label) {
      setShowStreamMenu(true);
      setStreamMenuActive(false);
      setStreamId(stream_id);
      setStreamLabel(stream_label);
      fetchStreamData();
    }
  }, []);

  useEffect(() => {
    // Clean use effect
    const fetchIceServers = async () => {
      try {
        setLoading(true);
        const response = await fetch(turnApiUrl, {
          method: "PUT",
        });

        if (response.status >= 300) {
          throw new Error(
            "TURN API return unexpected status " + response.status
          );
        }

        const result = await response.json();
        const { iceServers = [] } = result.v;
        let iceServersData = iceServers.map((val: any) => {
          val.urls = val.url;
          return val;
        });
        setLoading(false);

        setIceServers(iceServersData);
      } catch (err) {
        const msg = "Error while pulling ICE Servers !";
        console.error(msg);
      } finally {
        setLoading(false);
      }
    };
    if (room_id) {
      // Pull VC Room Details and start/join VC
      fetchIceServers();
      fetchVCRoomAndStartOrJoin(room_id, room_name);
    }
  }, [room_id]);

  const isEmpty = (obj: any) => {
    return Object.keys(obj).length === 0;
  };

  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const showOverlay = async () => {
    let sstyle: any = { style: { display: "block" } };
    setOverlay(sstyle);

    await sleep(3000);

    let hstyle: any = { style: { display: "none" } };
    setOverlay(hstyle);
  };

  /*
   * White boarding
   */
  type Coordinate = {
    x: number;
    y: number;
  };

  const drawLine = (
    color: any,
    originalMousePosition: Coordinate,
    newMousePosition: Coordinate,
    erase: Boolean
  ) => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;

    const context = canvas.getContext("2d");
    if (context) {
      context.lineJoin = context.lineCap = "round";

      context.beginPath();

      if (!erase) {
        context.globalCompositeOperation = "source-over";
        context.strokeStyle = color;
        context.lineWidth = 5;
      } else {
        context.globalCompositeOperation = "destination-out";
        context.lineWidth = 20;
      }

      context.moveTo(originalMousePosition.x, originalMousePosition.y);
      context.lineTo(newMousePosition.x, newMousePosition.y);
      context.closePath();

      context.stroke();
    }
  };

  const drawCircle = (
    color: any,
    originalMousePosition: Coordinate,
    newMousePosition: Coordinate
  ) => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;

    const context = canvas.getContext("2d");
    if (context) {
      context.lineJoin = context.lineCap = "round";

      context.beginPath();
      context.globalCompositeOperation = "source-over";
      context.strokeStyle = color;
      context.lineWidth = 3;

      // calculating the midX and midY
      var midY =
        originalMousePosition.y +
        (newMousePosition.y - originalMousePosition.y) * 0.5;
      var midX =
        originalMousePosition.x +
        (newMousePosition.x - originalMousePosition.x) * 0.5;
      var radius =
        Math.hypot(
          newMousePosition.x - originalMousePosition.x,
          newMousePosition.y - originalMousePosition.y
        ) / 2;

      context.arc(midX, midY, radius, 0, 2 * Math.PI);

      context.stroke();
    }
  };

  const startPaint = (coordinates: Coordinate) => {
    if (coordinates) {
      setMousePosition(coordinates);
      setIsPainting(true);

      if (!canvasRef.current) {
        return;
      }
      const canvas: HTMLCanvasElement = canvasRef.current;

      if (myPencilCircleRef.current || myPencilLineRef.current) {
        canvas.className = "canvas-pencil-on";
      }

      if (myEraserRef.current) {
        canvas.className = "canvas-eraser-on";
      }
    }
  };

  const startPaintMouse = useCallback((event: MouseEvent) => {
    const coordinates = getCoordinates(event);
    if (coordinates) {
      startPaint(coordinates);
    }
  }, []);

  const startPaintTouch = useCallback((event: TouchEvent) => {
    const coordinates = getTouchCoordinates(event);
    if (coordinates) {
      startPaint(coordinates);
    }
  }, []);

  const paint = (
    newMousePosition: Coordinate,
    isPainting: boolean,
    mousePosition: Coordinate | undefined,
    isCircle: boolean
  ) => {
    let erase: Boolean = false;

    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;

    if (isPainting && (myPencilCircleRef.current || myPencilLineRef.current)) {
      if (mousePosition && newMousePosition) {
        const color = colorHash.hex(dataTrackRef.current.id);
        if (isCircle) {
          drawCircle(color, mousePosition, newMousePosition);
        } else {
          drawLine(color, mousePosition, newMousePosition, false);
        }
        canvas.className = "canvas-pencil-on";

        const icanvas: any = document.getElementById("canvas");
        const canvasWidth = get(icanvas, "width", 0);
        const canvasHeight = get(icanvas, "height", 0);
        const incoming_canvas = {
          width: canvasWidth,
          height: canvasHeight,
        };

        dataTrackRef.current.send(
          JSON.stringify({
            isPainting,
            color,
            mousePosition,
            newMousePosition,
            incoming_canvas,
            erase,
            isCircle,
          })
        );
        setMousePosition(newMousePosition);
      }
    } else if (isPainting && myEraserRef.current) {
      erase = true;
      if (mousePosition && newMousePosition) {
        const color = colorHash.hex(dataTrackRef.current.id);
        drawLine(color, mousePosition, newMousePosition, true);
        canvas.className = "canvas-eraser-on";
        const icanvas: any = document.getElementById("canvas");
        const incoming_canvas = {
          width: icanvas.width,
          height: icanvas.height,
        };
        dataTrackRef.current.send(
          JSON.stringify({
            isPainting,
            color,
            mousePosition,
            newMousePosition,
            incoming_canvas,
            erase,
            isCircle,
          })
        );
        setMousePosition(newMousePosition);
      }
    }
  };

  const changeSimulcast = (e: any, value: any) => {
    hudsState?.milicastView?.select(get(value, 'value', {}));
    setSelectedLayer(get(value, 'value.encodingId', ''));
    setCameraDropdownOpen(!cameraDropdownOpen);
  };

  const paintMouse = useCallback(
    (event: MouseEvent) => {
      if (isPencilLine || isEraser) {
        const newMousePosition = getCoordinates(event);
        if (newMousePosition) {
          paint(newMousePosition, isPainting, mousePosition, false);
        }
      }
    },
    [isPainting, mousePosition]
  );

  const paintTouch = useCallback(
    (event: TouchEvent) => {
      const newMousePosition = getTouchCoordinates(event);
      if (newMousePosition) {
        paint(newMousePosition, isPainting, mousePosition, false);
      }
    },
    [isPainting, mousePosition]
  );

  const exitPaint = useCallback((event: TouchEvent) => {
    setIsPainting(false);
    setMousePosition(undefined);
    if (!canvasRef.current) {
      return;
    }

    const canvas: HTMLCanvasElement = canvasRef.current;
    canvas.className = "canvas-cursor-auto";
  }, []);

  const mouseExitPaint = useCallback(
    (event: MouseEvent) => {
      if (isPencilCircle && event.type === "mouseup") {
        const newMousePosition = getCoordinates(event);
        if (newMousePosition) {
          paint(newMousePosition, isPainting, mousePosition, true);
        }
      }

      if (isPencilLine) {
        setIsPainting(false);
        setMousePosition(undefined);
      }

      if (!canvasRef.current) {
        return;
      }

      const canvas: HTMLCanvasElement = canvasRef.current;
      canvas.className = "canvas-cursor-auto";
    },
    [isPainting, mousePosition]
  );

  const preventDragging = useCallback((e: TouchEvent) => {
    e.preventDefault();
  }, []);


  const refreshRoomCode = async () => {
    try {
      refreshRoomFlag = true;
      handelVideoConfEndAll();
      setLoading(false);
    } catch (err) {
      console.log("err :>> ", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;

    canvas.addEventListener("mousedown", startPaintMouse);
    canvas.addEventListener("mousemove", paintMouse);
    canvas.addEventListener("mouseup", mouseExitPaint);
    canvas.addEventListener("mouseleave", mouseExitPaint);

    canvas.addEventListener("touchstart", startPaintTouch);
    canvas.addEventListener("touchmove", paintTouch);
    canvas.addEventListener("touchend", exitPaint);

    window.addEventListener("touchmove", preventDragging, { passive: false });

    return () => {
      canvas.removeEventListener("mousedown", startPaintMouse);
      canvas.removeEventListener("mousemove", paintMouse);
      canvas.removeEventListener("mouseup", mouseExitPaint);
      canvas.removeEventListener("mouseleave", mouseExitPaint);

      canvas.removeEventListener("touchstart", startPaintTouch);
      canvas.removeEventListener("touchmove", paintTouch);
      canvas.removeEventListener("touchend", exitPaint);

      window.removeEventListener("touchmove", preventDragging);
    };
  }, [startPaint, paint, exitPaint]);

  const getCoordinates = (event: MouseEvent): Coordinate | undefined => {
    if (!canvasRef.current) {
      return;
    }

    const canvas: HTMLCanvasElement = canvasRef.current;
    const BB = canvas?.getBoundingClientRect();

    return {
      x: event.clientX - BB.left,
      y: event.clientY - BB.top,
    };
  };

  // Get the position of a touch relative to the canvas
  const getTouchCoordinates = (
    touchEvent: TouchEvent
  ): Coordinate | undefined => {
    if (!canvasRef.current) {
      return;
    }

    const canvas: HTMLCanvasElement = canvasRef.current;
    const BB = canvas?.getBoundingClientRect();
    return {
      x: touchEvent.touches[0].clientX - BB.left,
      y: touchEvent.touches[0].clientY - BB.top,
    };
  };

  const setupLocalDataTrack = () => {
    const dataTrack = new LocalDataTrack();
    return dataTrack;
  };

  // pencil toggle function
  const pencilToggled = async () => {
    setMyEraser(false);
    setMyPencilCircle(false);
    setMyPencilLine(!isPencilLine);
    setDrawingOption(!showDrawingOption);
  };

  //circle toggled function
  const circleToggled = async () => {
    setMyEraser(false);
    setMyPencilCircle(!isPencilCircle);
    setMyPencilLine(false);
    setDrawingOption(!showDrawingOption);
  };

  // eraser toggle function
  const eraserToggled = async () => {
    setMyEraser(!isEraser);
    setMyPencilCircle(false);
    setMyPencilLine(false);
  };

  // Clear WhiteBoard for host
  const clearWhiteBoard = async () => {
    setMyEraser(false);
    setMyPencilCircle(false);
    setMyPencilLine(false);
    const canvas: any = document.getElementById("canvas");
    if (canvas) {
      let context = canvas.getContext("2d");
      const canvasWidth = get(canvas, "width", 0);
      const canvasHeight = get(canvas, "height", 0);
      context.clearRect(0, 0, canvasWidth, canvasHeight);
    }
  };

  // Clear WhiteBoard for all
  const clearWhiteBoardAll = async () => {
    setMyEraser(false);
    setMyPencilCircle(false);
    setMyPencilLine(false);
    const canvas: any = document.getElementById("canvas");
    if (canvas) {
      let context = canvas.getContext("2d");
      const canvasWidth = get(canvas, "width", 0);
      const canvasHeight = get(canvas, "height", 0);
      context.clearRect(0, 0, canvasWidth, canvasHeight);
    }

    // also send the clear function to others in the same twilio room
    const clear = true;
    dataTrackRef.current.send(
      JSON.stringify({
        clear,
      })
    );
  };

  const selectAudioVideo = async () => {
    setCameraDropdownOpen(!cameraDropdownOpen);
  };

  //Full screen video
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const focusedElement = document.activeElement;
      const focusedElementType = focusedElement?.tagName.toLowerCase() || '';
      const excludedElementTypes = ['input', 'textarea', 'select'];

      if (event.key.toLowerCase() === 'f' && !showDominantSpeaker && !excludedElementTypes.includes(focusedElementType)) {
        fullScreenVideo();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [showDominantSpeaker]);

  const fullScreenVideo = async () => {
    // if (!!videoObj) {
    //   videoObj.enter()
    // }
    const elem: any = document.getElementsByTagName("video")[0];
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  const volumeUpDown = async (event: any) => {
    const elem: any = document.getElementsByTagName("video")[0];
    if (elem) {
      const value = event.currentTarget.value;
      setVolumeLevel(value);
      elem.volume = value / 100;
      if (elem.volume === 0) {
        setVolumeMute(true);
      } else {
        setVolumeMute(false);
      }
    }
  };

  const getVolumeImage = () => {
    if (volumeMute) {
      return volumeMuteIcon;
    } else if (volumeLevel >= 1 && volumeLevel <= 34) {
      return volume;
    } else if (volumeLevel >= 35 && volumeLevel <= 65) {
      return volumeLevel2;
    } else if (volumeLevel >= 66 && volumeLevel <= 100) {
      return volumeLevel3;
    } else {
      return volume;
    }
  };

  /*
   * PubNub
   */

  const leaveChat = () => {
    // Disconnect from chat channel
    if (pubnub && !isEmpty(pubnub)) {
      pubnub.unsubscribe({
        channels: [channels[0]],
      });

      const msgs = messagesRef.current;
      msgs.length = 0;
      setMessages(msgs);

      //Close chat window
      setChatWindow(false);
      setCloseDisable(false);
      setChatDisable(true);
    }
  };

  const leaveAnnotateChat = () => {
    // Disconnect from annotate chat channel
    if (pubnub && !isEmpty(pubnub)) {
      pubnub.unsubscribe({
        channels: [channels[1]],
      });

      const msgs = annotateMessagesRef.current;
      msgs.length = 0;
      setAnnotateMessages(msgs);

      //Close chat window
      setAnnotationChatWindow(false);
      setAnnotationCloseDisable(false);
      setAnnotationChatDisable(true);
    }
  };

  const leaveGuestInvite = () => {
    setShowGuestInvite(false);
    setShowGuestInviteCloseDisable(false);
    resizeCanvas();
  };

  const leavePublisherPanel = () => {
    setShowPublisherPanel(false);
    setShowPublisherPanelCloseDisable(false);
  };

  //Participant while joind the vc show the status in pubnub chat
  const vcParticipants = async (participant: any, status: any) => {
    const user = auth.getUser();
    const uid = get(user, "uid", "");
    let identity = participant.identity.split("_");
    const participantdisplayName = identity[1];
    const currTime = new Date().getTime();
    // Update local state with new msg
    const new_msg: any = {
      message_id: uid + String(currTime),
      userDisplay: "Pacpost.io",
      description: `${participantdisplayName} has ${status} the room`,
      userid: uid,
      imageurl: pacpost_img,
      status: status,
      timetoken: currTime,
    };
    const msgs = [...messagesRef.current];
    msgs.push(new_msg);
    setMessages(msgs);
  };
  // Group Chat
  const sendMessage = useCallback(
    async (message: any) => {
      if (uploadFiles.length > 0) {
        setFileUploadLoading(true);
        //const user = auth.getUser();
        const uid = `#${uuid}_${displayName}`;
        const currTime = new Date().getTime();
        const result = await pubnub.sendFile({
          channel: channels[0],
          file: uploadFiles[0],
          message: {
            userDisplay: displayName,
            imageurl: user_imageurl,
            message_id: uid + currTime,
            description: "",
            userid: uid, // Needed for old msgs as they dont have publisher id
            publisher: auth.getUserId(),
          },
        });
      } else if (message.length > 0) {
        const currTime = new Date().getTime();
        const uid = `#${uuid}_${displayName}`;
        // const displayName = get(user, "displayName", "");
        await pubnub.publish({
          channel: channels[0],
          message: {
            message_id: uid + currTime,
            userDisplay: displayName,
            description: message,
            userid: uid, // Needed for old msgs as they dont have publisher id
            imageurl: user_imageurl,
          },
        });
      }
      await pubnub.signal({
        channel: channels[0],
        message: `typing_off:${displayName}`,
      });
      setUploadFiles([]);
      setInput("");
      setShowEmoji(false);
    },
    [pubnub, channels, uploadFiles, user_imageurl]
  );

  // Annotation Chat
  const sendAnnotationMessage = async (
    message: string,
    annotateNote: string,
    annotateColor: string
  ) => {
    if (annotateNote === "" && message.length < 1) {
      return;
    }

    setAnnotateInput("");

    const user: any = auth.getUser();
    let currTime = new Date().getTime();
    const uid = get(user, "uid", "");
    // Update local state with new msg
    const new_msg: any = {
      publisher: uid,
      message_id: uid + currTime,
      annotationTitle: "Loading...",
      userDisplay: displayName,
      description: message,
      userid: uid,
      imageurl: user_imageurl,
      annotateNote: annotateNote,
      annotateColor: annotateColor,
      timecode: "Loading...",
    };

    const msgs = [...annotateMessagesRef.current];
    msgs.push(new_msg);
    setAnnotateMessages(msgs);

    let dataURI: string = "";
    let timeCodeResponse: any = {};
    dataURI = getTimecodeImage();

    if (dataURI) {
      let result: any = await getTimeCode(dataURI);

      if (result) {
        // Delete the local message
        let filteredMsgs = msgs.filter(
          (msg: any) => msg.message_id !== new_msg.message_id
        );

        setAnnotateMessages([...filteredMsgs]);

        timeCodeResponse = result;

        currTime = new Date().getTime();
        let title = "Annotation Title";
        if (timeCodeResponse && timeCodeResponse.title) {
          title = timeCodeResponse.title;
        }
        const uid = get(user, "uid", "");
        const pubnubResponse = await pubnub.publish({
          channel: channels[1],
          message: {
            message_id: uid + currTime,
            annotationTitle: title,
            userDisplay: displayName,
            description: message,
            userid: uid,
            imageurl: user_imageurl,
            annotateNote: annotateNote,
            annotateColor: annotateColor,
            timecode: timeCodeResponse.timecode,
          },
        });

        if (message === "") {
          handleAnnotateEditMessageClick(pubnubResponse.timetoken, message);
        }
      }
    }

    await pubnub.signal({
      channel: channels[1],
      message: `typing_off:${displayName}`,
    });
  };

  const updateAnnotateMessage = async (
    input: any,
    message: any,
    messageIndex: any
  ) => {
    const user = auth.getUser();

    let hideEdit = document.getElementById("edit_button" + message.timetoken);
    hideEdit?.removeAttribute("style");
    const uid = get(user, "uid", "");
    const result1 = await pubnub.publish({
      channel: channels[1],
      message: {
        message_id: message.message_id,
        annotationTitle: message.annotationTitle,
        userDisplay: displayName,
        description: input,
        userid: uid, // Needed for old msgs as they dont have publisher id
        imageurl: user_imageurl,
        annotateNote: message.annotateNote,
        annotateColor: message.annotateColor,
        timecode: message.timecode,
        usecase: "update",
        deleted: false,
        is_update: true,
      },
    });

    await pubnub.signal({
      channel: channels[1],
      message: `typing_off:${displayName}`,
    });

    setAnnotateEditInput("");
    renderAnnotateMessage(input, messageIndex);
  };

  const clearChat = useCallback(async () => {
    setClearChatModalOpen(false);
    setChatClearLoading(true);

    const deleteUrl = `https://ps.pndsn.com/v3/history/sub-key/${process.env.REACT_APP_PN_SUBSCRIBE}/channel/${channels[0]}`;

    const deleteResponse = await axios.delete(deleteUrl);

    // Removing the local messages too so the local chat window will be clear.
    setMessages([]);

    const clearGroupChat = true;
    dataTrackRef.current.send(JSON.stringify({ clearGroupChat }));

    setChatClearLoading(false);

    const user = auth.getUser();
    const currTime = new Date().getTime();
    const uid = get(user, "uid", "");
    setTimeout(async () => {
    await pubnub.publish({
      channel: channels[0],
      message: {
        message_id: uid + currTime,
        userDisplay: displayName,
        description: `${displayName} has just cleared all chat.`,
        userid: uid, // Needed for old msgs as they dont have publisher id
        imageurl: user_imageurl,
      },
    });

    await pubnub.signal({
      channel: channels[0],
      message: `typing_off:${displayName}`,
    });
  }, 500);
  }, [channels]);

  const clearAnnotationChat = useCallback(async () => {
    setAnnotateChatClearLoading(true);

    const deleteUrl = `https://ps.pndsn.com/v3/history/sub-key/${process.env.REACT_APP_PN_SUBSCRIBE}/channel/${channels[1]}`;

    await axios.delete(deleteUrl);

    // Removing the local messages too so the local chat window will be clear.
    setAnnotateMessages([]);

    const clearAnnotationChat = true;
    dataTrackRef.current.send(JSON.stringify({ clearAnnotationChat }));

    setAnnotateChatClearLoading(false);
  }, [channels]);

  const handleUploadCancel = () => {
    setUploadAlert(false);
    setInput("");
    setUploadFiles([]);
  };

  const handleUploadConfirm = async (event: any) => {
    setUploadAlert(false);
  };

  const onChangeAnnotateMessage = async (input: string) => {
    setAnnotateInput(input);
    // const user = auth.getUser();
    await pubnub.signal({
      channel: channels[1],
      message: `typing_on:${displayName}`,
    });
  };

  const onChangeAnnotateEditMessage = async (input: string) => {
    setAnnotateEditInput(input);
    await pubnub.signal({
      channel: channels[1],
      message: `typing_on:${displayName}`,
    });
  };

  const getAllMessages = async (
    timetoken: string,
    pubnubObj?: any,
    channelsArray?: any
  ) => {
    const pubNubInstance = pubnubObj ? pubnubObj : pubnub;

    const channelsInstance = channelsArray ? channelsArray : channels;

    try {
      const historyResponse = await pubNubInstance.history({
        channel: channelsInstance[0],
        stringifiedTimeToken: true, // false is the default
        start: timetoken, // start time token to fetch
      });

      if (historyResponse) {
        let allMsgs = allMessages;
        allMsgs.push(...historyResponse.messages);
        setAllMessages(allMsgs);
        let start = historyResponse.startTimeToken;

        // if 100 msgs were retrieved, there might be more; call history again
        if (historyResponse.messages.length === 100) {
          await getAllMessages(start, pubNubInstance, channelsInstance);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllAnnotateMessages = async (
    timetoken: string,
    pubnubObj?: any,
    channelsArray?: any
  ) => {
    const pubNubInstance = pubnubObj ? pubnubObj : pubnub;

    const channelsInstance = channelsArray ? channelsArray : channels;

    try {
      const historyResponse = await pubNubInstance.history({
        channel: channelsInstance[1],
        stringifiedTimeToken: true, // false is the default
        start: timetoken, // start time token to fetch
      });

      if (historyResponse) {
        let allMsgs = allAnnotateMessages;
        allMsgs.push(...historyResponse.messages);
        setAllAnnotateMessages(allMsgs);

        let start = historyResponse.startTimeToken;

        // if 100 msgs were retrieved, there might be more; call history again
        if (historyResponse.messages.length === 100) {
          await getAllAnnotateMessages(start, pubNubInstance, channelsInstance);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const downloadMessages = async () => {
    setChatExportLoading(true);

    await getAllMessages("");

    const newLine = "\n";
    const newTab = "\t";

    const allMessagesList = allMessages.map((message: any) => {
      return {
        userDisplay: `${newLine}${message.entry.userDisplay}${newTab}`,
        description: message.entry.description,
        timetoken: `${newLine}${convertDateTimeFromUTCEpoc(
          Math.ceil(parseInt(message.timetoken) / 10000)
        )}`,
      };
    });

    const fields = ["userDisplay", "description", "timetoken"];

    const json2csvParser = new Parser({
      delimiter: "",
      fields: fields,
      quote: "",
    });

    const finalCSV = json2csvParser.parse(allMessagesList);

    const fileBlob = new Blob([finalCSV.replaceAll(",", " ")], {
      type: "application/octet-binary",
    });
    const hiddenElement = document.createElement("a");
    hiddenElement.href = URL.createObjectURL(fileBlob);
    // hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(finalCSV);
    hiddenElement.target = "_blank";
    hiddenElement.download = `${streamLabel}.txt`;
    hiddenElement.click();

    sendMessage(`${displayName} exported chat`);

    setChatExportLoading(false);
  };

  const downloadAnnotateMessages = async () => {
    setAnnotateLoading(true);

    await getAllAnnotateMessages("");
    // Now we need to filter only annotate messages which are not deleted and replace the updated messages
    allAnnotateMessages = allAnnotateMessages
      .map((annotateMsg) => annotateMsg.entry)
      .filter((msg) => msg.annotateNote && msg.annotateNote !== "");

    const msgs: any[] = [];
    for (let msg of allAnnotateMessages) {
      const old_msg: any = msg;
      old_msg.version = "v1";
      old_msg.number = 1;
      let outerIndex = -1;
      msgs.forEach((iMsg, index) => {
        if (
          msg.message_id &&
          iMsg.message_id &&
          iMsg.message_id == msg.message_id
        ) {
          outerIndex = index;
          return index;
        }
      });

      if (outerIndex < 0) {
        msgs.push(old_msg);
      } else {
        if (msg.deleted) {
          msgs.splice(outerIndex, 1);
        } else {
          msgs[outerIndex] = old_msg;
        }
      }
    }

    setAllAnnotateMessages([]);

    const fields = [
      "userDisplay",
      "annotationTitle",
      "version",
      "annotateColor",
      "description",
      "number",
    ];

    const json2csvParser = new Parser({
      delimiter: "\t",
      fields: fields,
      quote: "",
    });

    const finalCSV = json2csvParser.parse(msgs);

    var fileBlob = new Blob([finalCSV.replaceAll(",", " ")], {
      type: "application/octet-binary",
    });
    var hiddenElement = document.createElement("a");
    hiddenElement.href = URL.createObjectURL(fileBlob);
    // hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(finalCSV);
    hiddenElement.target = "_blank";
    hiddenElement.download = `Annotation Title.txt`;
    hiddenElement.click();

    setVFXcolor("red");
    setSFXcolor("red");
    setMXcolor("red");
    setPIXcolor("red");
    setAnnotateLoading(false);
  };

  // JOIN PUBNUB
  const joinPubNubChat = async (identity: any, roomName: string) => {
    let new_pubnub;
    new_pubnub = new PubNub({
      publishKey: process.env.REACT_APP_PN_PUBLISH || "",
      subscribeKey: process.env.REACT_APP_PN_SUBSCRIBE || "",
      uuid: identity,
      ssl: true,
    });

    if (!new_pubnub) {
      return;
    }
    setPubNub(new_pubnub);

    const mainChannelName = roomName;
    const annotateChannelName = `${roomName}_tc`;

    let new_channels = [mainChannelName];

    setChannels(new_channels);

    // Subscribe to channel
    new_pubnub.subscribe({ channels: new_channels, withPresence: true });

    // Fetch if you have any recent messages (Max 25 msg per channel)
    const recentmsg_responses: any = await new_pubnub.fetchMessages({
      channels: new_channels,
      count: 100,
    });

    if (recentmsg_responses && recentmsg_responses.channels) {
      const channel_msgs = recentmsg_responses.channels;

      if (channel_msgs && channel_msgs[mainChannelName]) {
        const recet_msgs: any[] = channel_msgs[mainChannelName] || [];

        const msgs = messagesRef.current;
        for (let msg of recet_msgs) {
          const old_msg: any = msg.message;

          // Recent msgs doesn't have publisher id, so need to pass userid in message
          old_msg.publisher = old_msg.userid;
          old_msg.timetoken = msg.timetoken;

          if (msg.channel === mainChannelName) {
            let outerIndex = -1;
            msgs.forEach((iMsg, index) => {
              if (
                msg.message.message_id &&
                iMsg.message_id &&
                iMsg.message_id === msg.message.message_id
              ) {
                outerIndex = index;
                return index;
              }
            });

            if (outerIndex < 0) {
              if (!old_msg.deleted) {
                msgs.push(old_msg);
              }
            } else {
              if (old_msg.deleted) {
                msgs.splice(outerIndex, 1);
              } else {
                msgs[outerIndex] = old_msg;
              }
            }
          }
        }

        setMessages([...msgs]);
      }

      if (channel_msgs && channel_msgs[annotateChannelName]) {
        const recet_msgs: any[] = channel_msgs[annotateChannelName] || [];

        const msgs = annotateMessagesRef.current;
        for (let msg of recet_msgs) {
          const old_msg: any = msg.message;

          // Recent msgs doesn't have publisher id, so need to pass userid in message
          old_msg.publisher = old_msg.userid;
          old_msg.timetoken = msg.timetoken;

          if (msg.channel === annotateChannelName) {
            let outerIndex = -1;
            msgs.forEach((iMsg, index) => {
              if (
                msg.message.message_id &&
                iMsg.message_id &&
                iMsg.message_id === msg.message.message_id
              ) {
                outerIndex = index;
                return index;
              }
            });

            if (outerIndex < 0) {
              if (!old_msg.deleted) {
                msgs.push(old_msg);
              }
            } else {
              if (old_msg.deleted) {
                msgs.splice(outerIndex, 1);
              } else {
                msgs[outerIndex] = old_msg;
              }
            }
          }
        }

        setAnnotateMessages([...msgs]);
      }
    }

    // Listen to messages
    new_pubnub.addListener({
      message: (messageEvent: any) => {
        const new_msg: any = messageEvent.message;
        new_msg.publisher = messageEvent.publisher;
        new_msg.timetoken = messageEvent.timetoken;

        if (messageEvent.channel === mainChannelName) {
          const msgs = messagesRef.current;
          let outerIndex = -1;
          msgs.forEach((iMsg, index) => {
            if (
              new_msg.message_id &&
              iMsg.message_id &&
              iMsg.message_id === new_msg.message_id
            ) {
              outerIndex = index;
              return index;
            }
          });

          if (outerIndex < 0) {
            if (!new_msg.deleted) {
              msgs.push(new_msg);
            }
          } else {
            if (new_msg.deleted) {
              msgs.splice(outerIndex, 1);
            } else {
              msgs[outerIndex] = new_msg;
            }
          }

          setMessages([...msgs]);
        }
        if (messageEvent.channel === annotateChannelName) {
          const msgs = annotateMessagesRef.current;
          let outerIndex = -1;
          msgs.forEach((iMsg, index) => {
            if (
              new_msg.message_id &&
              iMsg.message_id &&
              iMsg.message_id === new_msg.message_id
            ) {
              outerIndex = index;
              return index;
            }
          });

          if (outerIndex < 0) {
            if (!new_msg.deleted) {
              msgs.push(new_msg);
            }
          } else {
            if (new_msg.deleted) {
              msgs.splice(outerIndex, 1);
            } else {
              msgs[outerIndex] = new_msg;
            }
          }

          setAnnotateMessages([...msgs]);
        }
      },
      signal: (signal: any) => {
        var message = signal.message; // The Payload
        var publisher = signal.publisher; //The Publisher
        const participant_type = isGuestViewer ? 'member' : 'admin';
        const identity = `${participant_type}#${uuid}_${displayName}`;
        if (publisher.indexOf(`${identity}`) === -1) {
          var signalName = message.split(":")[0];

          if (signalName === "typing_on") {
            var senderName = message.split(":")[1];
            if (senderName) {
              setTypingInd(senderName + " typing ...");
            }
          } else if (signalName === "typing_off") {
            setTypingInd("");
          }
        }
      },
      // @ts-ignore
      file: (event: any) => {
        const all_msgs = [...messagesRef.current];
        all_msgs.push(event);
        setMessages([...all_msgs]);
        setFileUploadLoading(false);
      },
    });
    return new_pubnub;
  };

  /*
   * Timecode Annotation Supported Actions
   */

  const getTimeCode = async (dataURI: string) => {
    if (!dataURI) {
      return;
    }
    try {
      const data = {
        api: api.old.remove,
        payLoad: JSON.stringify({ show_id: showId, file: dataURI }),
      };

      const result: any = await backend.save(data);
      return result;
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getTimecodeImage = () => {
    const video_player = document.getElementById("player") as HTMLVideoElement;

    const canvas = document.getElementById("canvas") as HTMLCanvasElement;

    const v_canvas = document.createElement("canvas") as HTMLCanvasElement;
    const canvasWidth = get(canvas, "width", 0);
    const canvasHeight = get(canvas, "height", 0);
    if (!!v_canvas) {
      v_canvas.width = canvasWidth;
      v_canvas.height = canvasHeight;
    }

    let dataURI: any = "";
    if (video_player && v_canvas) {
      // get complete image
      const v_canvasWidth = get(v_canvas, "width", "");
      const v_canvasHeight = get(v_canvas, "height", 0);
      const ctx = v_canvas.getContext("2d") as CanvasRenderingContext2D;
      ctx.drawImage(video_player, 0, 0, v_canvasWidth, v_canvasHeight);
      var imageData = ctx.getImageData(0, 0, v_canvasWidth, 30);

      // destination canvas
      const img_capture_canvas = document.createElement(
        "canvas"
      ) as HTMLCanvasElement;
      if (!!img_capture_canvas) {
        img_capture_canvas.width = v_canvasWidth;
        img_capture_canvas.height = 30;
      }
      const ctx1 = img_capture_canvas.getContext(
        "2d"
      ) as CanvasRenderingContext2D;
      ctx1.putImageData(imageData, 0, 0);

      // Convert to desired file format
      dataURI = img_capture_canvas.toDataURL("image/jpg");

      img_capture_canvas.parentNode?.removeChild(img_capture_canvas);

      v_canvas.parentNode?.removeChild(v_canvas);

      // Clear the captured image at the end
      ctx.clearRect(0, 0, v_canvasWidth, v_canvasHeight);
    }

    return dataURI;
  };

  /*
   ****************************
   * VC Supported Actions
   ****************************
   */

  const getPlayerBox = (rect: DOMRect, ratio: number): DOMRect => {
    let r = new DOMRect();
    const rectWidth = get(rect, "width", 0);
    const rectHeight = get(rect, "height", 0);
    let ar = rectWidth / rectHeight;

    if (ar < ratio) {
      let vidh = rectWidth / ratio;
      r.width = rectWidth; // Width is OK
      r.height = Math.floor(vidh); // We know the aspect ratio so we can calculate the height
      r.x = 0; // The width fits
      r.y = Math.floor((rectHeight - vidh) / 2); // But there's a vertical gap
    } else {
      let vidw = rectHeight * ratio;
      r.width = Math.floor(vidw); // We know the aspect ratio so we can calculate the width
      r.height = rectHeight; // Height is OK
      r.x = Math.floor((rectWidth - vidw) / 2); // Horizontal gap
      r.y = 0; // No vertical gap
    }

    return r;
  };

  const playbackStarted = () => {
    const video_player: HTMLVideoElement = document.getElementById(
      "player"
    ) as HTMLVideoElement;

    if (
      !!video_player &&
      !!video_player.videoHeight &&
      video_player.videoHeight !== 0
    ) {
      let new_ratio = video_player.videoWidth / video_player.videoHeight;
      vratioRef.current = new_ratio;
      const canvas: any = document.getElementById("canvas");
      renderCanvas(canvas, new_ratio);
    }
  };

  const renderCanvas = (canvas: any, ratio: number) => {
    const video_player: HTMLVideoElement = document.getElementById(
      "player"
    ) as HTMLVideoElement;

    if (video_player && !!canvas) {
      
      // The width and height of the video element
      let bb = video_player?.getBoundingClientRect();
      let player_box = getPlayerBox(bb, ratio);
      const player_box_width = get(player_box, "width", 0);
      const player_box_height = get(player_box, "height", 0);
      canvas.width = player_box_width;
      canvas.height = player_box_height;

      canvas.style.width = `${player_box_width}px`;
      canvas.style.height = `${player_box_height}px`;
      canvas.style.marginTop = `${player_box.y}px`;
      canvas.style.marginLeft = `${player_box.x}px`;
      return { w: player_box_width, h: player_box_height };
    }
    return { w: 0, h: 0 };
  };
  const resizeCanvas = () => {
    setTimeout(() => window.dispatchEvent(new Event("resize")), 25);
  };

  const setupCanvas = () => {
    window.addEventListener("resize", () => {
      const canvas: any = document.getElementById("canvas");
      if (canvas) {
        const ctx: CanvasRenderingContext2D = canvas.getContext("2d");

        inMemCanvas.width = canvas.width;
        inMemCanvas.height = canvas.height;
        const memCtx = inMemCanvas.getContext("2d");
        // console.log("memCtx", memCtx, canvas);
        if (memCtx) {
          memCtx.drawImage(canvas, 0, 0);
        }

        const size = renderCanvas(canvas, vratioRef.current); // The new size
        if (memCtx) {
          let ctx2: CanvasRenderingContext2D = canvas.getContext("2d");
          ctx2.drawImage(inMemCanvas, 0, 0, size.w, size.h);
        }
      }
    });
  };

  const videoReady = () => {
    const video_player: HTMLVideoElement = document.getElementById(
      "player"
    ) as HTMLVideoElement;

    video_player.addEventListener("loadedmetadata", playbackStarted);
    setupCanvas();
  };

  const gotDevices = (mediaDevices: any) => {
    let cameras: any = [];
    let microphones: any = [];
    let count = 1;
    mediaDevices.forEach((mediaDevice: any) => {
      if (mediaDevice.kind === "videoinput") {
        if (mediaDevice.deviceId === "default") {
          setCameraName(mediaDevice.label);
        }
        cameras.push({
          value: mediaDevice.deviceId,
          text: mediaDevice.label,
          key: mediaDevice.label,
        });
      } else if (mediaDevice.kind === "audioinput") {
        if (mediaDevice.deviceId == "default") {
          setMicName(mediaDevice.label);
        }
        microphones.push({
          value: mediaDevice.deviceId,
          text: mediaDevice.label,
          key: mediaDevice.label,
        });
      }
    });
    cameras.push({
      value: "DESKTOP",
      text: "Share Your Desktop",
      key: "desktop",
    });

    _setCameraOptions(cameras);
    _setMicOptions(microphones);
  };

  const changeLocalView = (pid: string, track: LocalVideoTrack) => {
    const div = document.getElementById(pid);
    // Look for the video element for the local preview div:
    if (div) {
      const preview = div.getElementsByTagName("video");

      const vid_elem = track.attach();
      div.removeChild(preview[0]);
      div.appendChild(vid_elem);
    }
  };

  const changeLocalAudio = (pid: string, track: LocalAudioTrack) => {
    const div = document.getElementById(pid);
    // Look for the audio element for the local preview div:
    if (div) {
      const preview = div.getElementsByTagName("audio");
      div.removeChild(preview[0]);
      div.appendChild(track.attach());
    }
  };

  const getImage = (): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img);
      };
      img.onerror = reject;
      const bgImg = backgrounds.filter(
        (item: any) => item.value === activeBGRef.current
      );
      img.src = bgImg[0]?.image;
    });
  };

  const changeCameraTrack = async (cameraId: string | undefined) => {
    const localParticipant = troomRef.current.localParticipant;

    if (cameraId === "DESKTOP") {
      // @ts-ignore
      navigator.mediaDevices
        .getDisplayMedia({
          video: { height: 720, frameRate: 24, width: 1280 },
          audio: true,
        })
        .then((stream: any) => {
          const localVideoTrack = new LocalVideoTrack(stream.getTracks()[0]);
          const tracks: Array<LocalVideoTrackPublication> = Array.from(
            localParticipant.videoTracks.values()
          );
          localParticipant.unpublishTrack(tracks[0].track);
          localParticipant
            .publishTrack(localVideoTrack)
            .then((publication: LocalVideoTrackPublication) => {
              changeLocalView(localParticipant.sid, localVideoTrack);
            });
        });
    } else {
      if (!localVideoMuteRef.current) {
        let localVideoTrack: LocalVideoTrack;
        if (!!cameraId) {
          localVideoTrack = await createLocalVideoTrack({
            deviceId: { exact: cameraId },
          });
        } else {
          localVideoTrack = await createLocalVideoTrack();
          setCameraId("");
        }
        const tracks: Array<LocalVideoTrackPublication> = Array.from(
          localParticipant.videoTracks.values()
        );
        const bgVideoTrack = await applyBGFilter(localVideoTrack, tracks);
        setLocalParticipantTrack(bgVideoTrack);
        localParticipant.unpublishTrack(tracks[0].track);
        localParticipant
          .publishTrack(bgVideoTrack)
          .then((publication: LocalVideoTrackPublication) => {
            changeLocalView(localParticipant.sid, bgVideoTrack);
          });
      }
    }
  };

  const applyBGFilter = async (
    localVideoTrack: LocalVideoTrack,
    tracks: any
  ) => {
    if (activeBGRef.current === "none") {
      // remove processor -- not needed to do anything
      //localVideoTrack.removeProcessor(tracks[0].track.processor);
    } else if (activeBGRef.current === "blur") {
      blurProcessor = new GaussianBlurBackgroundProcessor({
        assetsPath: virtualBackgroundAssets,
      });
      await blurProcessor.loadModel();
      localVideoTrack.addProcessor(blurProcessor);
    } else {
      virtualBackgroundProcessor = new VirtualBackgroundProcessor({
        assetsPath: virtualBackgroundAssets,
        backgroundImage: await getImage(),
        fitType: ImageFit.Fill,
      });
      await virtualBackgroundProcessor.loadModel();
      localVideoTrack.addProcessor(virtualBackgroundProcessor);
    }

    return localVideoTrack;
  };

  const changeCamera = (e: any, v: any) => {
    setCameraName(v.name);
    setCameraDropdownOpen(!cameraDropdownOpen);

    if (localVideoMute) {
      setCameraId(v.value);
      return;
    }

    // Video isn't muted - go ahead and do the switcheroo
    changeCameraTrack(v.value);
  };

  const changeMicrophoneTrack = (microphoneId: string) => {
    const localParticipant = troomRef.current.localParticipant;
    createLocalAudioTrack({
      deviceId: { exact: microphoneId },
      noiseCancellationOptions,
    }).then(function (localAudioTrack: any) {
      const tracks: Array<LocalAudioTrackPublication> = Array.from(
        localParticipant.audioTracks.values()
      );
      localParticipant.unpublishTrack(tracks[0].track);
      localParticipant.publishTrack(localAudioTrack);
      const previewContainer = document.getElementById("local-media");
      changeLocalAudio(localParticipant.sid, localAudioTrack);
    });
  };

  const changeMicrophone = (e: any, v: any) => {
    setMicName(v.name);
    setCameraDropdownOpen(!cameraDropdownOpen);

    if (localAudioMute) {
      setMicId(v.value);
      return;
    }
    changeMicrophoneTrack(v.value);
  };

  const setupLocalTracks = async () => {
    try {
      let localTracks: LocalTrack[];

      try {
        localTracks = await createLocalTracks({
          audio: { noiseCancellationOptions },
          video: true,
        });
      } catch (err: any) {
        try {
          localTracks = await createLocalTracks({
            audio: true,
            video: true,
          });
        } catch (err: any) {
          try {
            localTracks = await createLocalTracks({
              audio: true,
              video: false,
            });
          } catch (err: any) {
            localTracks = await createLocalTracks({
              audio: false,
              video: false,
            });
          }
        }
      }
      console.log(localTracks)
      return localTracks;
    } catch (err: any) {
      throw err;
    }
  };

  let tokenData: any = null;
  const joinTwilioRoom = async (room: any, participant_type: string) => {
    try {
      setLoading(true);

      // 1. Get VGT
      console.log("room", room);
      const twilio_vc_room_name: string = room.twilio_vc_room_name;
      twilio_vc_room_name_ref.current = room.twilio_vc_room_name; 
      const identity = `${participant_type}#${uuid}_${displayName}`;
      console.log("identity :>> ", identity);
      const data = {
        api: api.room.getTwilioVideoToken,
        queryParam: {
          room_id,
          identity: identity,
          roomName: twilio_vc_room_name,
        },
      };
      const token: string = await backend.fetch(data,true);

      // 2. Create Local Tracks at the time of room creation
      // let localTracks: any;
      const localTracks: LocalTrack[] = await setupLocalTracks();

      // 3. Setup Local data track
      const dataTrack = setupLocalDataTrack();
      setDataTrack(dataTrack);

      // 4. Merge all tracks
      const tracks = localTracks.concat(dataTrackRef.current);

      // Query local media
      navigator.mediaDevices.enumerateDevices().then(gotDevices);
      // console.log("troom.participants", roomParticipantsList)
      // 5. Create Room

      console.log("tracks", tracks);
      console.log("twilio_vc_room_name", room.twilio_vc_room_name);
      if (!tokenData) {
        tokenData = token;
        console.log("tokenData", tokenData);
        const new_room: any = await connect(tokenData, {
          name: room.twilio_vc_room_name,
          tracks: tracks,
          video: { height: 720, frameRate: 24, width: 1280 },
          bandwidthProfile: {
            video: {
              mode: "collaboration",
              dominantSpeakerPriority: "standard",
            },
          },
          dominantSpeaker: true,
          maxAudioBitrate: 16000, //For music remove this line
          preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
          networkQuality: {
            local: 1,
            remote: 1,
          },
        });
        console.log("new_room", new_room);
        //let resultPubnub = null;
        if (new_room) {
          setTRoom(new_room);

          // 6. Disable audio and video by default
          localTracks.forEach((track: any) => {
            if (track.isEnabled) {
              track.disable();
            }
          });

          // Pubnub
          joinPubNubChat(identity, twilio_vc_room_name);
        }
        setLoading(false);
        return new_room;
      }

      setLoading(false);
      return null;
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  //Esto es para apagar microfono
  const toggleAudioMute = (
    localParticipant: any,
    audioButtonElement: any,
    nameTextElement: any
  ) => {
    localParticipant.tracks.forEach((publication: any) => {
      if (publication.kind === "audio") {
        if (publication.isTrackEnabled) {
          publication.track.disable();
          audioButtonElement.className = "icon overlay-audio-off";
          setLocalAudioMute(true);
          setlocalMute(true);
        } else {
          if (micIdRef.current != "") {
            publication.track.enable();
            if (micIdRef.current) {
              changeMicrophoneTrack(micIdRef.current);
            }
            setMicId("");
          } else {
            publication.track.enable();
          }
          audioButtonElement.className = "icon overlay-audio-on";
          setLocalAudioMute(false);
          setlocalMute(false);
        }

        if (localAudioMuteRef.current || localVideoMuteRef.current) {
          nameTextElement.className = "overlay-text-red prevent-textcopy";
          dataTrackRef.current.send(
            JSON.stringify({
              nameTextId: nameTextElement.id,
              className: nameTextElement.className,
            })
          );
        } else {
          nameTextElement.className = "overlay-text prevent-textcopy";
          dataTrackRef.current.send(
            JSON.stringify({
              nameTextId: nameTextElement.id,
              className: nameTextElement.className,
            })
          );
        }
      }
    });
  };

  const handelVideoConfStart = async (
    room: any,
    room_id: string,
    participant_type: string
  ) => {
    try {
      setLoading(true);

      const twilio_vc_room_name = room.twilio_vc_room_name || null;
      if (!twilio_vc_room_name) {
        // Update room name in database so other viewer can auto join
        const roomData: any = await updateTwilioRoom(room_id);
        room.twilio_vc_room_name = roomData;
      }

      const new_room = await joinTwilioRoom(room, participant_type);

      // Pull details to create state
      await fetchRoomDetails(room_id);

      // Manage Room
      if (new_room) {
        manageRoom(new_room);
      }

      setLoading(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const startOrJoinVC = async (
    room: any,
    room_id: string,
    room_label: string,
    participant_type: string
  ) => {
    try {
      console.log("startOrJoinVC");
      if (troom && !isEmpty(troom) && troom.name === room.twilio_vc_room_name) {
        const canvas: any = document.getElementById("canvas");
        renderCanvas(canvas, vratioRef.current);
        return;
      }
      if (room && room.twilio_vc_room_name) {
        // Join VC
        const roomData = await joinTwilioRoom(room, participant_type);
        if (roomData) {
          manageRoom(roomData);
        }
      } else {
        // Start VC (Join vc and update twilio room link in backend)
        await handelVideoConfStart(room, room_id, participant_type);
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchRoomDetails = async (room_id: string) => {
    try {
      const data = {
        api: api.room.getRoom,
        urlParam: room_id,
        queryParam: { admin_user_id },
      };

      setLoading(true);
      const room: any = await backend.fetch(data);
      if((get(room,'room_state','') === ROOM_STATE.MINUTE_TRIAL) && get(room,'free_room_flag',false)) {
        setIsFreeRoom(get(room,'free_room_flag',false))
        setMinute_trial_end_time(get(room,'minute_trial_end_time',0));
      }
      setAdminAccessCode(get(room, 'stream_admin_code', ''))
      sessionStorage.setItem('room_name',get(room,'room_name',''))
      if(!isGuestViewer){
        setViewerData((prevState)=>({
          ...prevState,
          accessCode:get(room,'dec_viewer_code',''),
          accessLink:get(get(room,'tiny_viewer_url',''),'shortUrl','')
        }))}
      console.log("*** room details");
      console.log(room);
      setLoading(false);

      if (room) {
        setRoom(room);
      }

      return room;
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchVCRoomAndStartOrJoin = async (
    room_id: string,
    room_label: string
  ) => {
    try {
      setLoading(true);
      let room: any = await fetchRoomDetails(room_id);

      if (room) {
        setRoom(room);
        
        setUserInvited(true);

        if (role === "host") {
          setIsVCHost(true);

          await startOrJoinVC(room, room_id, room_label, "admin");
        } else {
          setIsVCHost(false);

          await startOrJoinVC(room, room_id, room_label, "member");
        }
        // Open chat window
        setChatWindow(true);
        setCloseDisable(true);
        setChatDisable(false);
        leaveGuestInvite();
      } else {
        console.log("No room found");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const dominantSpeakerChanged = (participant: any) => {
    let track: any = {};

    if (!participant) {
      setDominantSpeakerID("");
      setDominantSpeakerTrack({});
      return;
    }

    participant.tracks.forEach((publication: any) => {
      if (publication.kind === "video") {
        track = publication.track;
      }
    });

    setDominantSpeakerTrack(track);

    setDominantSpeakerID(participant.sid);
    // Get speaker div
    const dominatSpeakerDiv = document.getElementById(participant.sid);

    const vtray = document.getElementById("vtray");
    if (dominatSpeakerDiv && vtray) {
      // Switch only if dominant speaker not in screen
      if (!isViewInScreen(dominatSpeakerDiv)) {
        vtray.removeChild(dominatSpeakerDiv);

        // Switch to second place.
        const localparticipant = vtray.children[2];
        localparticipant.parentNode?.insertBefore(
          dominatSpeakerDiv,
          localparticipant.nextSibling
        );
      } else {
        console.log("In the screen, no switch");
      }
    }
  };

  //Show profile image when remote participant mutes video
  const showProfileImage = async (activeViewers: any, sid: any, uid: any) => {
    if (uid) {
      uid = /\#(.*?)\_/.exec(uid);
      uid = uid[1];
    }
    const remoteparticipantDiv = document.getElementById(sid);
    if (remoteparticipantDiv) {
      var disableVideoEle = remoteparticipantDiv.getElementsByTagName("video");
      if (disableVideoEle && disableVideoEle[0]) {
        disableVideoEle[0].classList.add("hide-profile");
      }
    }
    if (activeViewers) {
      activeViewers.forEach((viewer: any) => {
        if (viewer && viewer.user_id === uid) {
          const user_profile_img = document.getElementById(sid + "_image");
          if (!user_profile_img) {
            const backupName = document.createElement("div");
            // console.log(viewer);
            backupName.textContent =
              viewer.user_name.user_name_first +
              " " +
              viewer.user_name.user_name_last;
            backupName.className = "backup-name";
            backupName.id = viewer.user_id + "_backupName";

            //const image = document.createElement("img");
            // image.src = viewer.user_image_url_path || basic_profile_img;
            // image.id = sid + "_image";
            // image.alt = "Profile image shown here";
            // image.style.minWidth = "239px";
            // image.setAttribute('class', 'profile_img11');

            if (remoteparticipantDiv) {
              remoteparticipantDiv.insertBefore(
                backupName,
                remoteparticipantDiv.childNodes[0]
              );
            }
          }
        }
      });
    }
  };

  //hide profile image when remote participant unmutes video
  const hideProfileImage = async (activeViewers: any, sid: any, uid: any) => {
    if (uid) {
      uid = /\#(.*?)\_/.exec(uid);
      uid = uid[1];
    }
    const remoteparticipantDiv = document.getElementById(sid);
    if (remoteparticipantDiv) {
      var disableVideoEle = remoteparticipantDiv.getElementsByTagName("video");
      disableVideoEle[0]?.classList.remove("hide-profile");
    }
    activeViewers.map((viewer: any) => {
      if (viewer.user_id == uid) {
        const removeBackupName = document.getElementById(
          viewer.user_id + "_backupName"
        );
        if (removeBackupName) {
          if (remoteparticipantDiv) {
            remoteparticipantDiv.removeChild(removeBackupName);
          }
        }
      }
    });
  };

  // Hide the remote video and show avatar
  const handleRemoteTrack = (remotetrack: any, remoteparticipant: any) => {
    remotetrack.on("disabled", () => {
      // Audio Disabled
      if (remotetrack.kind === "audio" && !remotetrack.isEnabled) {
        const audioButtonElement = document.getElementById(
          remoteparticipant.sid + "_audio"
        );
        if (audioButtonElement) {
          audioButtonElement.className = "icon overlay-audio-off";
        }

        // If admin is mute then we are not sure whether it is global mute, can not use identity tag approach here
      }

      // Video Disabled
      if (remotetrack.kind === "video" && !remotetrack.isEnabled) {
        const overlayBackupName = document.getElementById(
          remoteparticipant.sid + "_backupName"
        );
        if (overlayBackupName) {
          overlayBackupName.style.removeProperty("display");
        }
        const videoButtonElement = document.getElementById(
          remoteparticipant.sid + "_video"
        );
        if (videoButtonElement) {
          videoButtonElement.className = "icon overlay-video-off-tray";

          const remoteparticipantDiv = document.getElementById(
            remoteparticipant.sid
          );

          const vtray = document.getElementById("vtray");
          if (remoteparticipantDiv) {
            vtray?.removeChild(remoteparticipantDiv);
            vtray?.appendChild(remoteparticipantDiv);
          }
        }
        const fullscreenButtonElement = document.getElementById(
          remoteparticipant.sid + "_fullscreen"
        );
        if (fullscreenButtonElement) {
          fullscreenButtonElement.className =
            "icon overlay-fullscreen-off-tray";
        }

        const activeViewers = invitedViewersRef.current;
        //remotetrack.removeProcessor(remotetrack.processor);
        showProfileImage(
          activeViewers,
          remoteparticipant.sid,
          remoteparticipant.identity
        );
      }

      if (
        remotetrack.kind === "video" &&
        dominantSpeakerIDRef.current === remoteparticipant.sid
      ) {
        setDominantSpeakerTrack(remotetrack);
        setDominantSpeakerID(dominantSpeakerIDRef.current);
        setShowStreamUpdate(showStreamUpdateRef.current + 1);
      }
    });

    remotetrack.on("enabled", () => {
      let audioTrackEnabled = false,
        videoTrackEnabled = false;
      // Audio Enabled
      if (remotetrack.kind === "audio" && remotetrack.isEnabled) {
        audioTrackEnabled = true;
        const audioButtonElement = document.getElementById(
          remoteparticipant.sid + "_audio"
        );
        if (audioButtonElement) {
          audioButtonElement.className = "icon overlay-audio-on";
        }
      }

      // Video Enabled
      if (remotetrack.kind === "video" && remotetrack.isEnabled) {
        videoTrackEnabled = true;
        const overlayBackupName = document.getElementById(
          remoteparticipant.sid + "_backupName"
        );
        if (overlayBackupName) {
          overlayBackupName.style.display = "none";
        }
        const videoButtonElement = document.getElementById(
          remoteparticipant.sid + "_video"
        );
        const fullscreenButtonElement = document.getElementById(
          remoteparticipant.sid + "_fullscreen"
        );
        if (videoButtonElement) {
          const remoteparticipantDiv = document.getElementById(
            remoteparticipant.sid
          );

          const vtray = document.getElementById("vtray");

          if (vtray?.children[2]) {
            if (remoteparticipantDiv) {
              // list of all un muted video trays
              var audEle = document.querySelectorAll(".overlay-video-on-tray");
              if (audEle.length > 0) {
                var audId = audEle[audEle.length - 1].id;
                audId = audId.replace("_video", "");

                //last un muted video tray
                var lastVideoOnPerson = document.getElementById(audId);
                var isnextDiv = lastVideoOnPerson?.nextSibling;
                if (isnextDiv != null) {
                  vtray?.insertBefore(remoteparticipantDiv, isnextDiv);
                  videoButtonElement.className = "icon overlay-video-on-tray";
                  if (fullscreenButtonElement) {
                    fullscreenButtonElement.className =
                      "icon overlay-fullscreen-on-tray";
                  }
                } else {
                  vtray?.removeChild(remoteparticipantDiv);
                  vtray?.appendChild(remoteparticipantDiv);
                  videoButtonElement.className = "icon overlay-video-on-tray";
                  if (fullscreenButtonElement) {
                    fullscreenButtonElement.className =
                      "icon overlay-fullscreen-on-tray";
                  }
                }
              } else {
                //if everyone is in mute then we need to move 2nd tray
                const localparticipant = vtray.children[2];
                localparticipant.parentNode?.insertBefore(
                  remoteparticipantDiv,
                  localparticipant.nextSibling
                );
                videoButtonElement.className = "icon overlay-video-on-tray";
                if (fullscreenButtonElement) {
                  fullscreenButtonElement.className =
                    "icon overlay-fullscreen-on-tray";
                }
              }
            }
          } else {
            videoButtonElement.className = "icon overlay-video-on-tray";
            if (fullscreenButtonElement) {
              fullscreenButtonElement.className =
                "icon overlay-fullscreen-on-tray";
            }
          }
        }

        // Remove profile image when remote participant video unmuted
        const activeViewers = invitedViewersRef.current;
        hideProfileImage(
          activeViewers,
          remoteparticipant.sid,
          remoteparticipant.identity
        );
      }

      if (audioTrackEnabled && videoTrackEnabled) {
        const nameTextElement = document.getElementById(
          remoteparticipant.sid + "_name"
        );
        if (nameTextElement) {
          nameTextElement.className = "overlay-text prevent-textcopy";
        }
      }

      if (
        remotetrack.kind === "video" &&
        dominantSpeakerIDRef.current === remoteparticipant.sid
      ) {
        setDominantSpeakerTrack(remotetrack);
        setDominantSpeakerID(dominantSpeakerIDRef.current);
        setShowStreamUpdate(showStreamUpdateRef.current + 1);
      }
    });
  };

  const switchToFullScreen = (elem: any, sid: any) => {
    if (!elem) {
      return;
    }

    // This looks like the easiest way to determine whether the video we're trying
    // to switch to full screen is currently muted. I said "easy", not "good."
    const mutedImage = document.getElementById(sid + "_image");

    if (mutedImage) {
      return;
    }

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  const participantConnected = (participant: any) => {
    console.log("participantConnected", participant);
    const roomParticipantsList = [...roomParticipantsListRef.current];
    roomParticipantsList.push({
      sid: participant.sid,
      identity: participant.identity,
      state: participant.state,
    });
    setRoomParticipantsList(roomParticipantsList);

    const roomParticipants = [...roomParticipantsWithTracks];
    roomParticipants.push(participant);
    setRoomParticipantsWithTracks(roomParticipants);
    // Play chime
    if (user_chime_preferance) {
      const audioEl: HTMLAudioElement = document.getElementsByClassName(
        "audio-element"
      )[0] as HTMLAudioElement;
      audioEl.play();
    }

    const div = document.createElement("div");
    div.id = participant.sid;
    div.style.width = "200px";
    div.style.minWidth = "200px";
    div.setAttribute("class", "videoparticipant");

    const backupName = document.createElement("div");
    backupName.textContent = participant.identity.split("_")[1];
    backupName.className = "backup-name";
    backupName.id = participant.sid + "_backupName";

    div.insertBefore(backupName, div.childNodes[0]);

    //vtray-overlay
    const childDiv = document.createElement("div");
    childDiv.id = participant.sid + "_overlay";
    childDiv.className = "overlay-vtray";

    let identity = participant.identity.split("_");
    const nameTextElement = document.createElement("p");
    nameTextElement.id = participant.sid + "_name";
    nameTextElement.className = "overlay-text-red prevent-textcopy";

    //Show maximum 15 charecters
    let string = identity[1];
    let length = 11;
    let trimmedString = string;
    if (string.length > 11) {
      trimmedString = string.substring(0, length).concat("...");
      nameTextElement.textContent = trimmedString;
    } else {
      nameTextElement.textContent = trimmedString;
    }

    // Audio Button
    const audioButtonElement = document.createElement("i");
    audioButtonElement.id = participant.sid + "_audio";

    // Video Button (Default Muted)
    const videoButtonElement = document.createElement("i");
    videoButtonElement.id = participant.sid + "_video";

    // Fullscreen Button
    const fullscreenButtonElement = document.createElement("i");
    fullscreenButtonElement.id = participant.sid + "_fullscreen";

    let audioEnabled = false,
      videoEnabled = false;
    participant.tracks.forEach((publication: any) => {
      if (publication.kind === "audio") {
        if (publication.isTrackEnabled) {
          audioButtonElement.className = "icon overlay-audio-on";
          audioEnabled = true;
        } else {
          audioButtonElement.className = "icon overlay-audio-off";
        }
      }
      if (publication.kind === "video") {
        if (publication.isTrackEnabled) {
          videoButtonElement.className = "icon overlay-video-on-tray";
          fullscreenButtonElement.className = "icon overlay-fullscreen-on-tray";
          videoEnabled = true;
        } else {
          //set default image profiles
          videoButtonElement.className = "icon overlay-video-off-tray";
          fullscreenButtonElement.className =
            "icon overlay-fullscreen-off-tray";
          const activeViewers = invitedViewersRef.current;
          setTimeout(function () {
            showProfileImage(
              activeViewers,
              participant.sid,
              participant.identity
            );
          }, 250);
        }
      }
    });

    childDiv.appendChild(nameTextElement);
    childDiv.appendChild(audioButtonElement);
    childDiv.appendChild(videoButtonElement);
    childDiv.appendChild(fullscreenButtonElement);

    fullscreenButtonElement.onclick = () => {
      // Take participant's video to fullscreen mode
      const elem: any =
        childDiv.parentElement?.getElementsByTagName("video")[0];

      switchToFullScreen(elem, participant.sid);
    };

    participant.on("trackSubscribed", (track: any) =>
      trackSubscribed(div, track)
    );

    // Handle RemoteTracks published after connecting to the Room.
    participant.on("trackPublished", (track: any) => {
      if (track && track.trackName) {
        participant.tracks.forEach((publication: any) => {
          if (
            track.trackName === publication.trackName &&
            publication.kind === "video"
          ) {
            if (publication.isSubscribed) {
              handleRemoteTrack(publication.track, participant);
            }
            publication.on("subscribed", (track: any) =>
              handleRemoteTrack(track, participant)
            );
          }
        });
      }
    });

    participant.on("trackUnsubscribed", trackUnsubscribed);

    participant.tracks.forEach((publication: any) => {
      if (publication.isSubscribed) {
        trackSubscribed(div, publication.track);
      }
    });

    participant.tracks.forEach((publication: any) => {
      if (publication.isSubscribed) {
        handleRemoteTrack(publication.track, participant);
      }
      publication.on("subscribed", (track: any) =>
        handleRemoteTrack(track, participant)
      );
    });

    div.appendChild(childDiv); //appending vtray-overlay
    const vtray = document.getElementById("vtray");
    vtray?.appendChild(div);
    setUsersUpdated(true);
  };

  const participantDisconnected = (participant: any) => {
    const roomParticipantsList = [...roomParticipantsListRef.current];
    roomParticipantsList.push({
      sid: participant.sid,
      identity: participant.identity,
      state: participant.state,
    });
console.log("::::::::>>>>>>",participant.identity)
    setRoomParticipantsList(roomParticipantsList);

    // Play chime
    if (user_chime_preferance) {
      const audioEl: HTMLAudioElement = document.getElementsByClassName(
        "audio-element"
      )[0] as HTMLAudioElement;
      audioEl.play();
    }

    const participantSidElem = document.getElementById(participant.sid);
    if (participantSidElem !== null) {
      participantSidElem.remove();
    }
    setUsersUpdated(true);
  };

  const trackSubscribed = (div: any, track: any) => {
    // Audio and Video
    if (track.kind === "audio" || track.kind === "video") {
      const preview = div.getElementsByTagName("video");
      div.appendChild(track.attach());
      //TO DO: I know this loop is junck code, Need to change
      for (let i = 0; i < preview.length; i++) {
        if (i > 0) {
          div.removeChild(preview[i]);
        }
      }
    }

    // White borading
    if (track.kind === "data") {
      const canvas: any = document.getElementById("canvas");
      track.on("message", (data: any) => {
        const {
          isPainting,
          color,
          mousePosition,
          newMousePosition,
          incoming_canvas,
          erase,
          clear,
          globalMute,
          nameTextId,
          className,
          clearAnnotationChat,
          clearGroupChat,
          isCircle,
          endConference,
        } = JSON.parse(data);
        if (!!endConference) {
          handelVideoConfEnd();
          // resetValues(endConference);
        }
        if (isPainting && canvas) {
          const curr_canvas: any = document.getElementById("canvas");
          let bb = curr_canvas?.getBoundingClientRect();
          const bbWidth = get(bb, "width", 0);
          const bbHeight = get(bb, "height", 0);
          const curr_canvas_width = bbWidth;
          const curr_canvas_height = bbHeight;

          let oldMousePos: Coordinate, newMousePos: Coordinate;

          if (curr_canvas) {
            oldMousePos = {
              x: (mousePosition.x / incoming_canvas.width) * curr_canvas_width,
              y:
                (mousePosition.y / incoming_canvas.height) * curr_canvas_height,
            };
            newMousePos = {
              x:
                (newMousePosition.x / incoming_canvas.width) *
                curr_canvas_width,
              y:
                (newMousePosition.y / incoming_canvas.height) *
                curr_canvas_height,
            };
            if (isCircle) {
              drawCircle(color, oldMousePos, newMousePos);
            } else {
              drawLine(color, oldMousePos, newMousePos, erase);
            }
            canvas.className = "canvas-pencil-on";
          }
        }

        if (clear) {
          const canvas: any = document.getElementById("canvas");
          let context = canvas.getContext("2d");
          const canvasWidth = get(canvas, "width", 0);
          const canvasHeight = get(canvas, "height", 0);
          context.clearRect(0, 0, canvasWidth, canvasHeight);
        }

        if (globalMute) {
          setlocalMute(true);
          const locParticipant = lParticipantRef.current;
          const audioButton = document.getElementById(
            locParticipant.sid + "_audio"
          );
          // Name
          const nameTextElement = document.createElement("p");
          if (audioButton) {
            audioButton.className = "icon overlay-audio-off";
            nameTextElement.className = "overlay-text-red prevent-textcopy";
          }

          locParticipant.tracks.forEach((publication: any) => {
            if (publication.kind === "audio") {
              if (publication.isTrackEnabled) {
                publication.track.disable();
              }
            }
          });
        }

        // unmute other participants
        if (globalMute === false) {
          setlocalMute(false);
          const locParticipant = lParticipantRef.current;
          const audioButton = document.getElementById(
            locParticipant.sid + "_audio"
          );
          // Name
          const nameTextElement = document.createElement("p");
          if (audioButton) {
            audioButton.className = "icon overlay-audio-on";
            nameTextElement.className = "overlay-text prevent-textcopy";
          }

          locParticipant.tracks.forEach((publication: any) => {
            if (publication.kind === "audio") {
              if (!publication.isTrackEnabled) {
                publication.track.enable();
              }
            }
          });
        }

        if (nameTextId && className) {
          const nameTextElement = document.getElementById(nameTextId);
          if (nameTextElement) {
            nameTextElement.className = className;
          }
        }

        if (clearAnnotationChat) {
          setAnnotateMessages([]);
        }

        if (clearGroupChat) {
          setMessages([]);
        }
      });
    }
  };

  const trackUnsubscribed = (track: any) => {
    if (track.kind === "audio" || track.kind === "video") {
      track.detach().forEach((element: any) => element.remove());
    }
  };

  const createLocalParticipant = (localParticipant: any) => {
    const roomParticipantsList = [...roomParticipantsListRef.current];
    roomParticipantsList.push({
      sid: localParticipant.sid,
      identity: localParticipant.identity,
      state: localParticipant.state,
    });

    setRoomParticipantsList(roomParticipantsList);

    // Participant Window
    // const div = React.createElement("div",{
    //   id: localParticipant.sid,
    //   style: {
    //     with: "200px",
    //     minWidth: "200px"
    //   },
    //   className: 'videoparticipant'
    // });
    const div = document.createElement("div");
    div.id = localParticipant.sid;
    div.style.width = "200px";
    div.style.minWidth = "200px";
    div.setAttribute("class", "videoparticipant");

    //Local participant profile image when video mute intially
    const user_profile_img = document.getElementById(
      localParticipant.sid + "_image"
    );
    if (!user_profile_img) {
      // const name
      const backupName = document.createElement("div");
      backupName.textContent = localParticipant.identity.split("_")[1];
      backupName.className = "backup-name";
      backupName.id = localParticipant.sid + "_backupName";

      div.insertBefore(backupName, div.childNodes[0]);
    }

    //vtray-overlay
    const childDiv = document.createElement("div");
    childDiv.id = localParticipant.sid + "_overlay";
    childDiv.className = "overlay-vtray";

    // Name
    let identity = localParticipant.identity.split("_");
    const nameTextElement = document.createElement("p");
    nameTextElement.id = localParticipant.sid + "_name";
    nameTextElement.className = "overlay-text-red prevent-textcopy";

    //Show max 15 charecters
    let string = identity[1];
    let length = 11;
    let trimmedString = string;
    if (string.length > 11) {
      trimmedString = string.substring(0, length).concat("...");
      nameTextElement.textContent = trimmedString;
    } else {
      nameTextElement.textContent = trimmedString;
    }

    //   const myData = [
    //     {id: localParticipant.sid + "_audio", className: "icon overlay-audio-off"},
    //     {id: localParticipant.sid + "_video", className: "icon overlay-video-off-tray"},
    //     {id: localParticipant.sid + "_fullscreen", className: "icon overlay-fullscreen-off-tray"},
    //     {id: localParticipant.sid + "_signal", className: "icon overlay-signal overlay-high-signal"},
    //     {id: localParticipant.sid + "_background", className: "icon overlay-signal overlay-high-signal"},
    //     {id: localParticipant.sid + "_signal", className: "icon menu-vertical", tittle: "Change Video Background", display: "none"},
    //   ];

    //   const children = myData.map((val) => (
    //     React.createElement("i", {id: val["id"], className: val["className"]})
    // ));

    //   const childDiv = React.createElement("div", {id: localParticipant.sid + "_overlay", className: "overlay-vtray"}, children);

    // Audio Button
    const audioButtonElement = document.createElement("i");
    audioButtonElement.id = localParticipant.sid + "_audio";
    audioButtonElement.className = "icon overlay-audio-off";

    // Video Button
    const videoButtonElement = document.createElement("i");
    videoButtonElement.id = localParticipant.sid + "_video";
    videoButtonElement.className = "icon overlay-video-off-tray";

    // Fullscreen Button
    const fullscreenButtonElement = document.createElement("i");
    fullscreenButtonElement.id = localParticipant.sid + "_fullscreen";
    fullscreenButtonElement.className = "icon overlay-fullscreen-off-tray";

    // Signal Button
    const signalButtonElement = document.createElement("i");
    signalButtonElement.id = localParticipant.sid + "_signal";
    signalButtonElement.className = "icon overlay-signal overlay-high-signal";

    //More Option
    const moreButtonElement = document.createElement("i");
    moreButtonElement.id = localParticipant.sid + "_background";
    moreButtonElement.className = "icon menu-vertical";
    moreButtonElement.title = "Change Video Background";
    moreButtonElement.style.display = "none";

    childDiv.appendChild(nameTextElement);
    div.appendChild(signalButtonElement);
    childDiv.appendChild(audioButtonElement);
    childDiv.appendChild(videoButtonElement);
    childDiv.appendChild(fullscreenButtonElement);
    childDiv.appendChild(moreButtonElement);

    fullscreenButtonElement.onclick = () => {
      // Take local participant's video to fullscreen mode
      const elem: any =
        childDiv.parentElement?.getElementsByTagName("video")[0];

      switchToFullScreen(elem, localParticipant.sid);
    };

    //Delay backslash click
    let delayBackslasClick = (() => {
      let timer: any;
      return function (callback: any, ms: number) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
      };
    })();

    // Listen to audio mute key
    document.addEventListener("keyup", (e) => {
      if (e.code === "BracketRight") {
        const elem: any = document.getElementsByTagName("video")[0];
        elem.muted = !elem.muted;
        setVolumeMute(elem.muted);
      }
      if (e.code === "Backquote") {
        toggleAudioMute(localParticipant, audioButtonElement, nameTextElement);
      }
      if (e.code === "Backslash") {
        delayBackslasClick(function () {
          videoButtonClick();
        }, 500);
      }
    });

    // Listening to Audio and Video Button Click
    audioButtonElement.onclick = () => {
      toggleAudioMute(localParticipant, audioButtonElement, nameTextElement);
    };

    moreButtonElement.onclick = () => {
      openBGSelectionModal();
    };

    function videoButtonClick() {
      localParticipant.tracks.forEach((publication: any) => {
        if (publication.kind === "video") {
          setLocalParticipantTrack(publication.track);
          if (publication.isTrackEnabled) {
            // //adding profile image when video muted
            // const image = document.createElement("img");
            const backupName = document.createElement("div");
            backupName.textContent = localParticipant.identity.split("_")[1];
            backupName.className = "backup-name";
            backupName.id = localParticipant.sid + "_backupName";

            // image.src = user_imageurl || basic_profile_img;
            // image.className = "profile_img11";
            // image.id = localParticipant.sid + "_image";
            backupName.style.display = "none";
            //div.insertBefore(image, div.childNodes[0]);
            setTimeout(function () {
              backupName.style.display = "flex";
              div.insertBefore(backupName, div.childNodes[0]);
            }, 250);

            publication.track.disable();
            videoButtonElement.className = "icon overlay-video-off-tray";
            setLocalVideoMute(true);
            fullscreenButtonElement.className =
              "icon overlay-fullscreen-off-tray";

            //Hiding virtual background button when video muted
            const more_button_ele: any = document.getElementById(
              localParticipant.sid + "_background"
            );
            if (more_button_ele) {
              more_button_ele.style.display = "none";
            }
          } else {
            if (localParticipant.networkQualityLevel >= 2) {
              setsignalStrengthOpen(false);

              //delete profile image when video unmute
              const hideBackupName = document.getElementById(
                localParticipant.sid + "_backupName"
              );
              if (hideBackupName) {
                div.removeChild(hideBackupName);
              }
              // changeCameraTrack(cameraIdRef.current);
              setTimeout(function () {
                publication.track.enable();
              }, 250);
              videoButtonElement.className = "icon overlay-video-on-tray";
              setLocalVideoMute(false);
              fullscreenButtonElement.className =
                "icon overlay-fullscreen-on-tray";

              //Enable virtual background button when video unmuted
              const more_button_ele: any = document.getElementById(
                localParticipant.sid + "_background"
              );
              if (more_button_ele) {
                more_button_ele.style.display = "block";
              }
            } 
            else {
              notification.open({
                type:'info',
                message:'Your network quality is less than optimal, so we are restricting you from enabling your video!',
                placement:'bottomRight'
              })
              // setOverlaymsg(
              //   "Your network quality is less than optimal, so we are restricting you from enabling your video!"
              // );
              // showOverlay();
            }
          }

          if (localAudioMuteRef.current || localVideoMuteRef.current) {
            nameTextElement.className = "overlay-text-red prevent-textcopy";
            dataTrackRef.current.send(
              JSON.stringify({
                nameTextId: nameTextElement.id,
                className: nameTextElement.className,
              })
            );
          } else {
            nameTextElement.className = "overlay-text prevent-textcopy";
            dataTrackRef.current.send(
              JSON.stringify({
                nameTextId: nameTextElement.id,
                className: nameTextElement.className,
              })
            );
          }
        }
      });
    }
    videoButtonElement.onclick = () => {
      videoButtonElement.classList.add("disabled-button");
      setTimeout(function () {
        videoButtonClick();
        videoButtonElement.classList.remove("disabled-button");
      }, 1000);
    };
    localParticipant.tracks.forEach((publication: any) => {
      trackSubscribed(div, publication.track);
    });

    localParticipant.on("trackUnsubscribed", trackUnsubscribed);
    div.appendChild(childDiv); //appending the background-overlay-vedio-tray
    const vtray = document.getElementById("vtray");
    vtray?.appendChild(div);
  };

  const manageRoom = (room: any) => {
    try {
      /* CONNECT */

      // LocalParticipant in the Room
      const localParticipant = room.localParticipant;
      console.log("localParticipant", localParticipant);
      setLocalParticipant(localParticipant);
      setLLocalParticipant(localParticipant);

      createLocalParticipant(localParticipant);

      room.localParticipant.on(
        "networkQualityLevelChanged",
        (level: number) => {
          const signalButtonElement = document.getElementById(
            room.localParticipant.sid + "_signal"
          );

          if (signalButtonElement) {
            if (localParticipant.networkQualityLevel <= 2) {
              signalButtonElement.className =
                "icon overlay-signal overlay-low-signal";
            } else if (
              localParticipant.networkQualityLevel > 2 &&
              localParticipant.networkQualityLevel <= 4
            ) {
              signalButtonElement.className =
                "icon overlay-signal overlay-medium-signal";
            } else if (localParticipant.networkQualityLevel > 4) {
              signalButtonElement.className =
                "icon overlay-signal overlay-high-signal";
            }
          }
        }
      );

      // Log Participants as they connect to the Room
      room.once("participantConnected", (participant: any) => {
        // console.log(
        //   `Participant "${participant.identity}" has connected to the Room, ${participant}`
        // );
      });

      // Any Participants already connected to the Room
      room.participants.forEach(participantConnected);
      // New participant as they connect to the room (Connection Events)
      // Other will receive this (Connection Events)
      room.on("participantConnected", (participant: any) => {
        participantConnected(participant);
        if (!!participant) {
          //show the participant joined pubnub
          vcParticipants(participant, "joined");
        }
      });
      /* DISCONNECT */

      // Log Participants as they disconnect from the Room
      room.once("participantDisconnected", (participant: any) => {
        // console.log(
        //   `Participant "${participant.identity}" has disconnected from the Room`
        // );
      });

      // Other will receive this (Connection Events)
      room.on("participantDisconnected", (participant: any) => {
        participantDisconnected(participant);
        if (!!participant) {
          //show the participant joined pubnub
          vcParticipants(participant, "left");
        }
      });

      room.on("dominantSpeakerChanged", (participant: any) => {
        dominantSpeakerChanged(participant);
      });

      // You can disconnect from a Room, Other will receive "participantDisconnected" event
      room.on("disconnected", (room: any) => {
        // Detach the local media elements
        room.localParticipant.tracks.forEach((publication: any) => {
          if (publication.kind === "audio" || publication.kind === "video") {
            const attachedElements = publication.track.detach();
            attachedElements.forEach((element: any) => element.remove());
          }
        });

        // Remove local view
        const localParticipantDiv = document.getElementById(
          localParticipant.sid
        );
        localParticipantDiv?.parentNode?.removeChild(localParticipantDiv);

        // Remove others from vtray
        room.participants.forEach((participant: any) => {
          const participantSidElem = document.getElementById(participant.sid);
          if (participantSidElem !== null) {
            participantSidElem.remove();
          }
        });

        // Stop camera and mic
        room.localParticipant.tracks.forEach((publication: any) => {
          if (publication.kind === "audio" || publication.kind === "video") {
            publication.track.stop();
            publication.unpublish();
          }
        });

        setTRoom({});
        setRoom({});
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateTwilioRoom = async (room_id: string) => {
    try {
      const twilio_vc_room_name = `${room_id}_${room_name}_${new Date().getTime()}`;
      const data = {
        api: api.room.updateRoomByViewer,
        payLoad: {
          twilio_vc_room_name,
          room_id,
          admin_user_id,
        },
      };
      setLoading(true);
      await backend.save(data);
      setLoading(false);
      return twilio_vc_room_name;
    } catch (err) {
      console.error("Not able to update twilio room in db");
    } finally {
      setLoading(false);
    }
  };

  const removeTwilioLink = async () => {
    try {
      // Remove twilio link from db
      const data = {
        api: api.room.updateRoomByViewer,
        payLoad: {
          twilio_vc_room_name: "",
          room_id,
          admin_user_id,
        },
      };
      setLoading(true);
      await backend.save(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const openBGSelectionModal = () => {
    setTempActiveBG(activeBGRef.current);
    setLocalParticipantBGModalOpen(true);
  };

  /*
   ****************************
   * VC Main Action Controls
   ****************************
   */

  const handleGlobalMute = () => {
    let muteFlag = !muteAll;
    setMuteAll(muteFlag);
    setlocalMute(muteFlag);
    // here mute my self first
    const locParticipant = lParticipantRef.current;
    const audioButton = document.getElementById(locParticipant.sid + "_audio");

    if (muteFlag) {
      if (audioButton) {
        audioButton.className = "icon overlay-audio-off";
      }
      locParticipant.tracks.forEach((publication: any) => {
        if (publication.kind === "audio") {
          if (publication.isTrackEnabled) {
            publication.track.disable();
          }
        }
      });
    } else {
      if (audioButton) {
        audioButton.className = "icon overlay-audio-on";
      }
      locParticipant.tracks.forEach((publication: any) => {
        if (publication.kind === "audio") {
          if (!publication.isTrackEnabled) {
            publication.track.enable();
          }
        }
      });
    }

    // here send data track with global mute variable and everybody will receive and close audio track respectively
    dataTrackRef.current.send(JSON.stringify({ globalMute: muteFlag }));
  };

  const handelAudio = async () => {
    setlocalMute(!localMute);
    const audioButtonElement = document.getElementById(
      localParticipant.sid + "_audio"
    );

    localParticipant.tracks.forEach((publication: any) => {
      if (publication.kind === "audio") {
        if (publication.isTrackEnabled) {
          publication.track.disable();

          // Update Participant Tray Audio Icon
          if (audioButtonElement) {
            audioButtonElement.className = "icon overlay-audio-off";
          }
        } else {
          publication.track.enable();

          // Update Participant Tray Audio Icon
          if (audioButtonElement) {
            audioButtonElement.className = "icon overlay-audio-on";
          }
        }
      }
    });
  };

  const handelVideo = async () => {
    const videoButtonElement = document.getElementById(
      localParticipant.sid + "_video"
    );
    videoButtonElement?.click();
  };

  const getTwilioRoomsParticipants = async () => {
    try {
      const params: any = new URLSearchParams(search);

      const user_id: string = params.get("uid");
      const room_id: string = params.get("rid");

      const data = {
        api: api.room.getTwilioRoomsParticipants,
        queryParam: {
          room_id,
          user_id,
        },
      };
      setLoading(true);
      const roomParticipants = await backend.fetch(data);

      return roomParticipants;
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const completeTwilioRoom = async () => {
    try {
      // Complete Room
      const data = {
        api: api.room.removeRoomByViewer,
        payLoad: {
          admin_user_id,
          room_id,
        },
      };

      setLoading(true);
      const roomdetails = await backend.save(data);
      setLoading(false);

      if (roomdetails && roomdetails.status === "completed") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handelVideoConfEnd = async () => {
    try {
      // check if room present
      if (troom && !isEmpty(troom)) {
        troom.disconnect();
        leaveOrEndConference()

        // Check Participants in room
        // const connectedRoomParticipants = await getTwilioRoomsParticipants();

        // if (connectedRoomParticipants.length === 0) {
        //   await completeTwilioRoom();
        //   await removeTwilioLink();
        // }
        sessionStorage.clear();
        setTRoom({});
        setRoom({});
        setShowPopup(false);
        resetValues(false);
      } else {
        console.log("Error: VC Room not present");
      }

      setStream({});
      clearWhiteBoard();
      leaveChat();
      leaveAnnotateChat();
      leaveGuestInvite();
      leavePublisherPanel();
      // if (isGuestViewer) {
        await auth.doSignOut();
        localStorage.removeItem('url')
        history('/viewerLogoutPage',{ replace: true },);
      // }
      return true;
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  videoConfEndRef.current = handelVideoConfEnd;

  const handelVideoConfEndAll = async (autoLogoutFlag?:boolean) => {
    try {
      if (isGuestViewer) {
        handelVideoConfEnd();
        return;
      }
      
      const dataTrack = troom?.localParticipant?.dataTracks.values().next().value;
      if(dataTrack){
        dataTrack.track.send(
          JSON.stringify({
            endConference: true
          })
        );
      }
     
      if(!autoLogoutFlag){
       setTimeout(()=>{cleanupFunction()},3000)
      
      }else{
        leaveOrEndConference(true);
        // const isTwilioRoomCompleted = await completeTwilioRoom();
        // if (isTwilioRoomCompleted) {
          // await removeTwilioLink();
  
          setTRoom({});
          setRoom({});
        // }
  
        setStream({});
        clearWhiteBoardAll();
        leaveChat();
        leaveAnnotateChat();
        leaveGuestInvite();
        leavePublisherPanel();
        console.log("sessionStorage-clear");
        sessionStorage.clear();
  
        setShowPopup(false);
      }
      await auth.doSignOut();
      localStorage.removeItem('url')
      history('/viewerLogoutPage',{ replace: true },);
      
      // if(!autoLogoutFlag){
      //   resetValues(true);
      // }
    } catch (err) {
      console.error(err);
    } finally{
      setLoading(false)
    }
  };

  const cleanupFunction=()=>{
    leaveOrEndConference(true);
    setTRoom({});
    setRoom({});  
    setStream({});
    clearWhiteBoardAll();
    leaveChat();
    leaveAnnotateChat();
    leaveGuestInvite();
    leavePublisherPanel();
    console.log("sessionStorage-clear");
    sessionStorage.clear();
    setShowPopup(false);
  }

  const leaveOrEndConference = async (endConferenceFlag?: boolean) => {
    try {
      const identity = `${isGuestViewer ? 'member' : 'admin'}#${uuid}_${displayName}`
      const input = {
        endConferenceFlag,
        admin_user_id: admin_user_id,
        room_id: room_id,
        room_code: room_code,
        twilio_vc_room_name: twilio_vc_room_name_ref.current,
        identity: identity,
        roomSid: troomRef.current.sid,
        refreshRoomFlag: refreshRoomFlag,
      };
      const data = {
        api: api.room.leaveOrEndConference,
        payLoad: JSON.stringify(input),
      };
      // history('/viewerLogoutPage',{ replace: true },);
      const result = backend.save(data, true);
      return result;
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  
  const reloadPage = () => {
    setStreamKey(Math.random())
  };
  /*
   ****************************
   * Main Body Content
   ****************************
   */

  // This is show stream component
  const renderStreamContent = () => {
    return (
      <ShowStream
        show_id={showId}
        canvasRef={canvasRef}
        stream={stream}
        iceServers={iceServers}
        refreshKey={streamKey}
        setLoading={setLoading}
        isReady={videoReady}
        showDominantSpeaker={showDominantSpeaker}
        dominantSpeakerID={dominantSpeakerIDRef.current}
        localAudioMuteValue={localAudioMuteRef.current}
        localParticipantID={lParticipantRef.current.sid}
        localParticpantTrack={localParticipantTrackRef.current}
        dominantSpeakerTrack={dominantSpeakerTrackRef.current}
        showStreamUpdate={showStreamUpdateRef.current}
        volumeMute={volumeMute}
        room_id={room_id}
        setHudsState={setHudsState}
        admin_user_id={admin_user_id}
        isGuestViewer={isGuestViewer}
        setLayerOptions={setLayerOptions}
      />
    );
  };

  const renderBodyContent = () => {
    let videoTilesProps = {
      overlay,
      overlayMsg,
      overlayConnected,
      overlayMsgConnected,
      overlayDisconnected,
      overlayMsgDisconnected,
      layoutMode,
      troom,
    };
    let headerProps = {
      handleLogoClick,
      renderHeaderTopVCControls,
      renderFreeSessionTimer,
      renderHeaderRightNavControls,
      renderAnnotationChatWindowFrmHeader,
      showPublisherPanel,
      troom,
      usersUpdated,
      setUsersUpdated,
      roomParticipantsListRef,
      roomParticipantsConnectionStatusRef,
      setGuestUsersList,
      showGuestInvite,
    };
    let chatProps = {
      setExportChartConfirmationOpen,
      fileUploadLoading,
      chatClearLoading,
      setClearChatModalOpen,
      ProgressLoader,
      messagesRef,
      user_imageurl,
      editPencil,
      showEmoji,
      handleChatCopy,
      setInput,
      setShowEmoji,
      sendMessage,
      isModalOpen,
      input,
      typingInd,
      hideGiphyModal,
      pubnub,
      channels,
      setUploadAlert,
      setFileName,
      setUploadFiles,
      resizeCanvas,
      isGuestViewer,
      editorDirectLogin,
    };

    return (
      <div className="show-new-viewer">
        {/* <div> parent has to have position fixed, child only renders content, and the hover triggers the parent width and child content full extend </div>  */}

        <div className="main-content">
          <HeaderView {...headerProps} />
          <div className="content-row">
            <div
              className={`viewer-column ${
                layoutMode === "inverse" ? "inverse-layout" : ""
              }
            ${showChat ? 'viewer-column--with-chat' : ''} 
            ${showPublisherPanel ? 'viewer-column--with-publisher-panel' : ''}
            ${showAnnotationChat ? 'viewer-column--with-locators-for-avid' : ''}
            ${showGuestInvite ? 'viewer-column--with-guest-viewer' : ''}`
            }
            >
              <div className="player-wrapper">{renderStreamContent()}</div>
              {!stream || (stream && isEmpty(stream)) ? (
                <div className="empty-video-tiles-placeholder"></div>
              ) : (
                <VideoTiles {...videoTilesProps} />
              )}
              <VideoTiles {...videoTilesProps} />
            </div>
            {showChat && (
              <div className="chat-wrapper">
                <ChatMenu {...chatProps} />
              </div>
            )}
            {renderAnnotationChatWindowFrmHeader()}
            {!isGuestViewer && (
              <GuestListProvider
                room_id={room_id}
                isHost={!isGuestViewer}
                guestList={guestList}
              >
                {showGuestInvite && (
                  <GuestInvite
                    key={"GuestInvite"}
                    troom={troom}
                    room_id={room_id}
                    user_id={admin_user_id}
                    // guestList={guestList}
                    viewerData={viewerData}
                    displayName={displayName}
                  />
                )}
              </GuestListProvider>
            )}
            {hudsState.openHudStatsPanel && (
              <HudStatsPanel
                hudsState={hudsState}
                setHudsState={setHudsState}
              />
            )}
          </div>
        </div>

        <audio className="audio-element">
          <source src={chimeAudioUrl}></source>
        </audio>
      </div>
    );
  };

  /*
   **************************
   * Header Right Nav Actions
   **************************
   */

  const handleChatWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setChatWindow(true);
    setCloseDisable(true);
    setChatDisable(false);

    setUsers(false);
    setCloseUsers(false);
    setOpenUsers(true);
    setAnnotationChatWindow(false);
    setAnnotationCloseDisable(false);
    setAnnotationChatDisable(true);
    setShowPublisherPanel(false);
    setShowPublisherPanelCloseDisable(false);
    setShowGuestInvite(false);
    setShowGuestInviteCloseDisable(false);

    resizeCanvas();
  };

  const handleCloseChatWindow = async (
       event: React.MouseEvent<HTMLElement>
  ) => {
    setChatWindow(false);
    setCloseDisable(false);
    setChatDisable(true);
    // setAnnotationChatWindow(true);
    // setAnnotationCloseDisable(true);
    // setAnnotationChatDisable(false);

    resizeCanvas();
  };

  const onTimecodePopupConfirm = async () => {
    setTimeCodePopup(false);
  };

  const handleAnnotationChatWindow = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    let showTimeCodePopup = sessionStorage.getItem("showTimeCodePopup");
    if (!showTimeCodePopup) {
      sessionStorage.setItem("showTimeCodePopup", "yes");
      setTimeCodePopup(true);
    }
    setChatWindow(false);
    setCloseDisable(false);
    setChatDisable(true);
    setAnnotationChatWindow(true);
    setAnnotationCloseDisable(true);
    setAnnotationChatDisable(false);
    setShowPublisherPanel(false);
    setShowPublisherPanelCloseDisable(false);
    setShowGuestInvite(false);
    setShowGuestInviteCloseDisable(false);

    resizeCanvas();
  };

  const handlePublisherPanelWindow = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setChatWindow(false);
    setCloseDisable(false);
    setChatDisable(true);
    setAnnotationChatWindow(false);
    setAnnotationCloseDisable(false);
    setAnnotationChatDisable(true);
    setShowPublisherPanel(true);
    setShowPublisherPanelCloseDisable(true);
    setShowGuestInvite(false);
    setShowGuestInviteCloseDisable(false);

    resizeCanvas();
  };
  const handleClosePublisherPanelWindow = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setChatWindow(false);
    setCloseDisable(false);
    setChatDisable(true);
    setShowPublisherPanel(false);
    setShowPublisherPanelCloseDisable(false);

    resizeCanvas();
  };
  const handleGuestInviteWindow = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setChatWindow(false);
    setCloseDisable(false);
    setChatDisable(true);
    setAnnotationChatWindow(false);
    setAnnotationCloseDisable(false);
    setAnnotationChatDisable(true);
    setShowPublisherPanel(false);
    setShowPublisherPanelCloseDisable(false);
    setShowGuestInvite(true);
    setShowGuestInviteCloseDisable(true);
    resizeCanvas();
  };

  const handleAnnotationCloseChatWindow = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setChatWindow(false);
    setCloseDisable(false);
    setChatDisable(true);
    setAnnotationChatWindow(false);
    setAnnotationCloseDisable(false);
    setAnnotationChatDisable(true);

    resizeCanvas();
  };

  /*
   **************************
   * Header Actions
   **************************
   */

  const handleLogoClick = async () => {
    setLoading(true);
    await handelVideoConfEnd();
    setLoading(false);
    const user = auth.getUser();
    const uid = get(user, "uid", "");
    history("/showselection", { state: { userId: uid } });
  };

  /*
   ****************************
   * Header Content
   ****************************
   */

  const renderAnnotateMessage = (messageDesc: any, messageIndex: number) => {
    let URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    let div = messageDesc?.split(" ").map((part: any) => {
      if (URL_REGEX.test(part)) {
        let hrefUrl = part;
        if (part.startsWith("www")) {
          hrefUrl = "//" + hrefUrl;
        }

        return (
          <div>
            <a key={messageIndex} href={hrefUrl} target="_blank">
              {part}
            </a>
          </div>
        );
      } else {
        return part + " ";
      }
    });

    return div;
  };

  const renderAnnotateMessageWithEditButton = (
    message: any,
    messageIndex: number
  ) => {
    let URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    let msg_unix_timestamp = Math.ceil(parseInt(message.timetoken) / 10000);

    let formatted_time = convertDateTimeFromUTCEpoc(msg_unix_timestamp);
    let div = message.description.split(" ").map((part: any) => {
      if (URL_REGEX.test(part)) {
        let hrefUrl = part;
        if (part.startsWith("www")) {
          hrefUrl = "//" + hrefUrl;
        }

        return (
          <div>
            <a key={messageIndex} href={hrefUrl} target="_blank">
              {part}
            </a>
          </div>
        );
      } else {
        return part + " ";
      }
    });

    return (
      <div>
        <div id={message.timetoken} className="annote-chat-message-grey-bg">
          {div}
        </div>
        <div className="chat-time chat-time-box-parent">
          <span>{message.timecode ? message.timecode : formatted_time}</span>
          {message.annotateNote && (
            <span
              className="chat-annote"
              style={{ backgroundColor: message.annotateColor }}
            >
              {message.annotateNote}
            </span>
          )}
        </div>
      </div>
    );
  };

  const handleAnnotateEditMessageClick = (
    messageId: string,
    messageDesc: string
  ) => {
    setEditAnnotateMessage(true);
    setEditingAnnotateMessageId(messageId);
    setAnnotateEditInput(messageDesc);
    let hideEdit = document.getElementById("edit_button" + messageId);
    hideEdit?.setAttribute("style", "display: none");
  };

  const handleChatCopy = (value: any) => {
    const { userDisplay, description, timetoken } = value;
    const newTimeStamp = Math.ceil(parseInt(timetoken) / 10000);
    const formattedTimeStamp = convertDateTimeFromUTCEpoc(newTimeStamp);
    const copyElement = document.createElement("textarea");
    document.body.appendChild(copyElement);
    copyElement.value = `${userDisplay} - ${description} - ${formattedTimeStamp}`;
    copyElement.select();
    document.execCommand("copy");
    document.body.removeChild(copyElement);
  };

  const renderEditableAnnotateMessage = (
    message: any,
    messageIndex: number
  ) => {
    if (editingAnnotateMessageId === message.timetoken) {
      let msg_unix_timestamp = Math.ceil(parseInt(message.timetoken) / 10000);

      let formatted_time = convertDateTimeFromUTCEpoc(msg_unix_timestamp);
      return (
        <div>
          <TextArea
            className="editTxtArea"
            autoFocus
            placeholder="Send a message here"
            rows="2"
            size="small"
            spellCheck="true"
            value={editAnnotateInput}
            onChange={(data: any) =>
              onChangeAnnotateEditMessage(data.value as string)
            }
            // onBlur={(event: any) => {
            //   event.preventDefault();
            //   setEditAnnotateMessage(false);
            //   let hideEdit = document.getElementById(
            //     "edit_button" + message.timetoken
            //   );
            //   hideEdit?.removeAttribute("style");
            //   setEditingAnnotateMessageId("");
            //   updateAnnotateMessage(editAnnotateInput, message, messageIndex);
            // }}
            onKeyPress={(event: any) => {
              if (event.key === "Enter") {
                event.preventDefault();
                updateAnnotateMessage(editAnnotateInput, message, messageIndex);
                let hideEdit = document.getElementById(
                  "edit_button" + message.timetoken
                );
                hideEdit?.removeAttribute("style");
              } else if (event.key === "Esc") {
                event.preventDefault();
                let hideEdit = document.getElementById(
                  "edit_button" + message.timetoken
                );
                hideEdit?.removeAttribute("style");
                renderAnnotateMessage(message.description, messageIndex);
              }
            }}
          />
          <div className="chat-time">
            <span>{message.timecode ? message.timecode : formatted_time}</span>
            <span className="float-right">
              <a
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  setEditAnnotateMessage(false);
                  let hideEdit = document.getElementById(
                    "edit_button" + message.timetoken
                  );
                  hideEdit?.removeAttribute("style");
                  setEditingAnnotateMessageId("");
                  renderAnnotateMessageWithEditButton(message, messageIndex);
                }}
              >
                <img src={crossTick} className="cancelTick-img" alt="cancel" />
              </a>
              <a
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  let hideEdit = document.getElementById(
                    "edit_button" + message.timetoken
                  );
                  hideEdit?.removeAttribute("style");
                  updateAnnotateMessage(
                    editAnnotateInput,
                    message,
                    messageIndex
                  );
                }}
              >
                <img src={greeTick} className="greenTick-img" alt="cancel" />
              </a>
            </span>
            {message.annotateNote && (
              <span
                className="chat-annote"
                style={{
                  backgroundColor: message.annotateColor,
                  marginRight: "20px",
                }}
              >
                {message.annotateNote}
              </span>
            )}
          </div>
        </div>
      );
    } else {
      return renderAnnotateMessageWithEditButton(message, messageIndex);
    }
  };

  const renderAnnotationChatSettings = () => {
    return (
      <ul className={isVCHost ? "" : "hidden"}>
        <li>
          <Dropdown
            className="chat-settings"
            pointing="top right"
            icon={<img src={settingsImg} />}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                text="Clear Locators"
                icon={<img className="clear-image" src={clear} />}
                onClick={clearAnnotationChat}
              />
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
    );
  };

  const renderAnnotationChatWindowFrmHeader = () => {
    if (!showAnnotationChat) {
      return;
    }
    if (!troom || (troom && isEmpty(troom))) {
      return;
    }

    return (
      <div className="avid-locators-panel-wrapper">
        <nav className="main-menu-right-chat" id="annotate-chat-tray">
          <ProgressLoader loading={annotateChatClearLoading} size="small" />
          <div className="chat-h1">
            <span className="chat-text">Locators for Avid</span>
            {renderAnnotationChatSettings()}
          </div>

          <div className="chat-content">
            {annotateMessagesRef.current.map(
              (message: any, messageIndex: number) => {
                let editAnnotateMessageIndex =
                  annotateMessagesRef.current.findIndex(
                    (item) => item.timetoken === editingAnnotateMessageId
                  );

                if (editAnnotateMessageIndex > -1) {
                  setTimeout(function () {
                    var chatTray =
                      document.getElementById("annotate-chat-tray");
                    var currScrollHeight = chatTray?.scrollHeight || 0;
                    var scrollHeight: number =
                      currScrollHeight *
                      (editAnnotateMessageIndex /
                        annotateMessagesRef.current.length);
                    chatTray?.scrollTo(0, scrollHeight - 50);
                  }, 250);
                } else {
                  setTimeout(function () {
                    var chatTray =
                      document.getElementById("annotate-chat-tray");
                    var currScrollHeight = chatTray?.scrollHeight || 0;
                    chatTray?.scrollTo(0, currScrollHeight);
                  }, 250);
                }

                let msg_unix_timestamp = Math.ceil(
                  parseInt(message.timetoken) / 10000
                );

                let formatted_time =
                  convertDateTimeFromUTCEpoc(msg_unix_timestamp);

                if (message.publisher === auth.getUserId()) {
                  // Loggedin user
                  let style = {};
                  if (user_imageurl) {
                    style = {
                      background:
                        'transparent url("' +
                        user_imageurl +
                        '") 0% 0% no-repeat padding-box',
                    };
                  }
                  return (
                    <div
                      className="chat-list-item"
                      key={`message-${messageIndex}`}
                    >
                      <div className="chat-message-body">
                        <div className="chat-heading">
                          {message.annotationTitle}
                          <div className="chat-message-hover-tools-row">
                            <div className="chat-message-tool-icon-container">
                              <Popup
                                content="Copy To Clipboard"
                                trigger={
                                  <span
                                    className="duplicate-icon"
                                    onClick={() => handleChatCopy(message)}
                                  />
                                }
                              />
                            </div>
                            <div className="chat-message-tool-icon-container">
                              <a
                                id={"edit_button" + message.timetoken}
                                className="cursor-pointer"
                                onClick={() =>
                                  handleAnnotateEditMessageClick(
                                    message.timetoken,
                                    message.description
                                  )
                                }
                              >
                                <img
                                  src={editPencil}
                                  className="editPencil-img"
                                  alt="Edit"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        {!editAnnotateMessage &&
                          renderAnnotateMessageWithEditButton(
                            message,
                            messageIndex
                          )}
                        {editAnnotateMessage &&
                          renderEditableAnnotateMessage(message, messageIndex)}
                      </div>
                    </div>
                  );
                } else {
                  // Other users
                  let style = {};
                  if (message.imageurl) {
                    const profile_Image_url = message.imageurl;
                    style = {
                      background:
                        'transparent url("' +
                        profile_Image_url +
                        '") 0% 0% no-repeat padding-box',
                    };
                  }
                  return (
                    <div
                      className="chat-list-item"
                      key={`message-${messageIndex}`}
                    >
                      <div className="chat-image" style={style}></div>
                      <div className="chat-message-body">
                        <div className="chat-heading">
                          {message.annotationTitle}
                          {message?.file ? null : (
                            <div className="float-right">
                              <Popup
                                content="Copy To Clipboard"
                                position="top right"
                                trigger={
                                  <span
                                    className="duplicate-icon"
                                    onClick={() => handleChatCopy(message)}
                                  />
                                }
                              />
                            </div>
                          )}
                        </div>
                        <div className="chat-message-white-bg">
                          {renderAnnotateMessage(
                            message.description,
                            messageIndex
                          )}
                          <div className="chat-time">
                            <span>
                              {message.timecode
                                ? message.timecode
                                : formatted_time}
                            </span>
                            {message.annotateNote && (
                              <span
                                className="chat-annote"
                                style={{
                                  backgroundColor: message.annotateColor,
                                }}
                              >
                                {message.annotateNote}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              }
            )}
          </div>
          <div className="annotation-chat-footer">
            <div className="annotation-chat-msg-input">
              <Input
                className="sendTxt"
                // icon={<Icon name="smile outline" link />}
                placeholder="Type annotation here..."
                size="small"
                spellCheck="true"
                value={annotateInput}
                onChange={(e: any) => onChangeAnnotateMessage(e.target.value)}
                onKeyPress={(event: any) => {
                  if (event.key === "Enter") {
                    const annotateInputText = annotateInput;
                    setAnnotateInput("");
                    event.preventDefault();
                    if (annotateInputText.length > 0)
                      sendAnnotationMessage(annotateInputText, "", "");
                  }
                }}
              >
                <input />

                <Button
                  color="violet"
                  floated="right"
                  className="send-chat-btn"
                  size="medium"
                  onClick={(e: any) => {
                    e.preventDefault();
                    sendAnnotationMessage(annotateInput, "", "");
                  }}
                >
                  <img src={sendMsgIcon} />
                </Button>
              </Input>
            </div>
            <div className="annotation-chat-btn">
              <ProgressLoader loading={annotateLoading} size="small" />
              <button
                className="annotation-chat-color-btn"
                style={{ backgroundColor: VFXcolor }}
                onClick={(e) => {
                  e.preventDefault();
                  sendAnnotationMessage(annotateInput, "VFX Note", VFXcolor);
                }}
              >
                VFX
              </button>
              <button
                className="annotation-chat-color-btn"
                style={{ backgroundColor: SFXcolor }}
                onClick={(e) => {
                  e.preventDefault();
                  sendAnnotationMessage(annotateInput, "SFX Note", SFXcolor);
                }}
              >
                SFX
              </button>
              <button
                className="annotation-chat-color-btn"
                style={{ backgroundColor: MXcolor }}
                onClick={(e) => {
                  e.preventDefault();
                  sendAnnotationMessage(annotateInput, "MX Note", MXcolor);
                }}
              >
                MX
              </button>
              <button
                className="annotation-chat-color-btn"
                style={{ backgroundColor: PIXcolor }}
                onClick={(e) => {
                  e.preventDefault();
                  sendAnnotationMessage(annotateInput, "PIX Note", PIXcolor);
                }}
              >
                PIX
              </button>

              <Button
                circular
                style={{ marginLeft: "10px" }}
                color="grey"
                onClick={(e: any) => {
                  e.preventDefault();
                  setModalAnnotateColorOpen(true);
                }}
              >
                Color
              </Button>

              <a
                className="cursor-pointer download_tc"
                onClick={(e) => {
                  e.preventDefault();
                  downloadAnnotateMessages();
                }}
              >
                <img className="" src={download_chat} alt="Download" />
              </a>
            </div>
            <div className="chat-typing-indicator">{typingInd}</div>
          </div>
          {isModalOpen && (
            <div>
              {/* <GiphyModal hideGiphyModal={hideGiphyModal}></GiphyModal> */}
            </div>
          )}
        </nav>
      </div>
    );
  };

  const hideGiphyModal = function () {
    setModalStatus(false);
  };

  const renderFreeSessionTimer = () =>{
    if(isFreeRoom){
      return <FreeSessionTimer expiryTime={minute_trial_end_time} currentDate={Date.now()} setMessageShown={setMessageShown} messageShown={messageShown} /> 
    }
    return <></>
  }

  const renderRightNavVCControls = () =>{
    return(
      <>
        {showChatDisable && (
          <li>
            <a id="chatArea" onClick={handleChatWindow}>
              <img className="tc_image" src={chatImg} alt="" />
            </a>
          </li>
        )}
        {showCloseDisable && (
          <li>
            <a id="chatArea" className="active" onClick={handleCloseChatWindow}>
              <img className="closeinvite" src={closeImg} alt="" width="16px" />
            </a>
          </li>
        )}
        {!showGuestInvite && isVCHost && (
          <li>
            <a id="guestInviteArea" onClick={handleGuestInviteWindow}>
              <img className="tc_image" src={guestWhite} alt="guest" />
            </a>
          </li>
        )}
        {showGuestInvite &&  isVCHost && (
          <li>
            <a id="guestInviteArea" className="active" onClick={leaveGuestInvite}>
              <img className="closeinvite" src={closeImg} alt="Close button" width="16px" />
            </a>
          </li>
        )}
      </>
    );
  };

  const renderHeaderRightNavControls = () => {
    return (
      <ul>
        {renderRightNavVCControls()}
        <li>
          <div style={{ marginTop: "15px", marginLeft: "10px" }}>
            {!!userLoggedIn ? (
              <AppMenu
                className="header-menu"
                handelVideoConfEnd={handelVideoConfEnd}
                clearCancelNoise={clearCancelNoise}
                editorDirectLogin={editorDirectLogin}
                setHudsStateTwilio={setHudsState}
                isViewer={isViewer}
              />
            ) : (
              <></>
            )}
          </div>
        </li>
      </ul>
    );
  };

  const renderCameraAndMicrophoneSelection = () => {
    return (
      <div
        onClick={selectAudioVideo}
        className={`top-menu-button-item audiovisual-source-dropdown ${
          showDominantSpeaker ? "disableddiv" : ""
        }`}
      >
        <img src={configSource} alt="" />
        <Dropdown icon="" open={cameraDropdownOpen} onClose={selectAudioVideo}>
          <Dropdown.Menu className="cameraMenu">
            <Dropdown.Item>
              <Dropdown
                className="cameraDropdown"
                id="cameraDropdown"
                text={
                  localVideoMute && cameraName === "Select Camera"
                    ? "Camera Disabled"
                    : cameraName
                }
                pointing="left"
                multiple={false}
                fluid
                selection
                placeholder="camera"
                disabled={localVideoMute}
                onChange={changeCamera}
                options={cameraOptions}
                value={get(cameraOptions.find((camera:any)=> camera.value === cameraId),'text','')}
              />
            </Dropdown.Item>
            <Dropdown.Item>
              <Dropdown
                text={micName}
                id="cameraDropdown"
                placeholder="microphone"
                multiple={false}
                fluid
                selection
                pointing="left"
                onChange={changeMicrophone}
                options={micOptions}
              />
            </Dropdown.Item>
            {renderTranscoderOptions()}  
          </Dropdown.Menu>
        </Dropdown>
        <span>Select Camera</span>
      </div>
    );
  };

  const renderTranscoderOptions = () =>{
    if(!layerOptions.length){
      return <></>
    }
    return(
      <Dropdown.Item>
        <Dropdown
          text={selectedLayer}
          id='cameraDropdown'
          placeholder='microphone'
          multiple={false}
          fluid
          selection
          pointing='left'
          onChange={changeSimulcast}
          options={layerOptions}
        />
      </Dropdown.Item>
    )
  }

  const handleVolumeControlMouseOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!showVolume) {
      setShowVolume(true);
    }
  };

  const handleVolumeMouseOut = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!!showVolume) {
      setShowVolume(false);
    }
  };

  const handleVolumeDoubleClick = () => {
    setShowVolume(false);
    const elem: any = document.getElementsByTagName("video")[0];
    elem.muted = !elem.muted;
    setVolumeMute(elem.muted);
    if (!elem.muted && volumeLevel < 1) {
      setVolumeLevel(10);
      elem.volume = 0.10;
    }
    // if (!elem.muted) {
    //   setShowVolume(true);
    // }
  };

  const renderConferenceSelection = (member?: string) => {
    const toggleTools = () => {
      setShowPopup(!showPopup);
    };
    if (!!member) {
      return (
        <div
          className="top-menu-button-item call-options-dropdown"
          onClick={toggleTools}
        >
          <img src={phoneFilled} alt="" />
          <Dropdown icon="" open={showPopup}  onClose={toggleTools}>
            <Dropdown.Menu className="cameraMenu">
              <Dropdown.Item>
                <Button
                  className="ui button viewer-menu-btn"
                  onClick={handelVideoConfEnd}
                  style={{ backgroundColor: "white", width: "183px" }}
                >
                  Leave Conference
                </Button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <span>Action</span>
        </div>
      );
    }

    return (
      <div
        className="top-menu-button-item call-options-dropdown"
        onClick={toggleTools}
      >
        <img src={phoneFilled} alt="" />
        {/* <i aria-hidden="true" className="dropdown icon" style={{ marginTop: "3px", marginLeft: "5px" }}></i> */}
        <Dropdown icon="" open={showPopup} onClose={toggleTools}>
          <Dropdown.Menu className="cameraMenu">
          <Dropdown.Item>
              <Button
                className="ui button viewer-menu-btn"
                onClick={()=>{refreshRoomCode();setLoading(true)}}
              >
                End Conference and Refresh Codes 
              </Button>
            </Dropdown.Item>
            <Dropdown.Item>
              <Button
                className="ui button viewer-menu-btn"
                onClick={()=>{handelVideoConfEndAll();setLoading(true)}}
              >
                End Conference for All
              </Button>
            </Dropdown.Item>
            <Dropdown.Item>
              <Button
                
                className="ui button viewer-menu-btn"
                onClick={handelVideoConfEnd}
              >
                Leave Conference
              </Button>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <span>Action</span>
      </div>
    );
  };

  const onDrawingClick = () => {
    setDrawingOption(!showDrawingOption);
  };

  const renderDrawingOptions = () => {
    return (
      <div
        className="top-menu-button-item pencil-tool-dropdown"
        onClick={() => {
          onDrawingClick();
        }}
      >
        <img src={pencil} alt="" />
        {/* <i aria-hidden="true" className="dropdown icon" style={{ marginTop: "3px", marginLeft: "5px" }}></i> */}
        <Dropdown icon="" open={showDrawingOption} onClose={onDrawingClick}>
          <Dropdown.Menu className="cameraMenu">
            <Dropdown.Item>
              <div className={showDominantSpeaker ? "disableddiv" : ""}>
                <Button
                  className="ui button viewer-menu-btn"
                  onClick={pencilToggled}
                  style={{
                    backgroundColor: isPencilLine ? "grey" : "white",
                    paddingLeft: "70px",
                    paddingRight: "70px",
                  }}
                >
                  Pencil
                </Button>
              </div>
            </Dropdown.Item>
            <Dropdown.Item>
              <div className={showDominantSpeaker ? "disableddiv" : ""}>
                <Button
                  className="ui button viewer-menu-btn"
                  onClick={circleToggled}
                  style={{
                    backgroundColor: isPencilCircle ? "grey" : "white",
                    paddingLeft: "70px",
                    paddingRight: "70px",
                  }}
                >
                  Circle
                </Button>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <span>Annotation</span>
      </div>
    );
  };

  const renderHeaderTopVCControls = () => {
    const showDomSpeaker = showDominantSpeaker;

    const cancelNoiseButton = 
    hasNoiseCancellation ? (
      <div
        className="top-menu-button-item"
 
        onClick={() => {
          setCancelNoise(!cancelNoise);
        }}
      >
        {/* Noise Cancellation */}
        <div className={cancelNoise ? "noise-cancellation-on" : "noise-cancellation" }>
        <img src={cancelNoise ? noiseCancellationOn : noiseCancellation} alt='' />
        </div>
        <span>Noise Cancellation</span>
      </div>
    ) : null;
    // Member
    if (!isVCHost) {
      // Show end button if conf is running and user participated
      if (troom && !isEmpty(troom)) {
        return (
          <Fragment>
            {renderConferenceSelection("member")}
            {/* <span className="dividervertical">&nbsp;</span> */}

            <Reload reloadPage={reloadPage} showDomSpeaker={showDomSpeaker} admin_user_id={admin_user_id} adminCode={adminAccessCode}/>
            {/* <span className="dividervertical">&nbsp;</span> */}

            {renderDrawingOptions()}

            {/* <span className="dividervertical">&nbsp;</span> */}
            {/* Erase */}
            <div
              className={`top-menu-button-item ${
                showDomSpeaker ? "disableddiv" : isEraser ? "eraser-on" : ""
              }`}
              onClick={eraserToggled}
            >
              <img src={erase} alt="" />
              <span>Erase</span>
            </div>
            <div
              onClick={clearWhiteBoardAll}
              className={`top-menu-button-item ${ showDomSpeaker ? "disableddiv" : ""}`}
            >
              <img src={trash} alt="" />
              <span>Clear</span>
            </div>
            {cancelNoiseButton}

            {/* <span className="dividervertical">&nbsp;</span> */}
              {/* clear */}
           

            <div
              className={`top-menu-button-item ${
                showDomSpeaker ? "ui disableddiv" : "ui"
              }`}
              data-position="bottom center"
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: !!showVolume ? "100%" : "100%",
                }}
                onMouseLeave={handleVolumeMouseOut}
                onMouseEnter={handleVolumeControlMouseOver}
              >
                <img
                  onClick={handleVolumeDoubleClick}
                  className="fullscreen-img"
                  src={getVolumeImage()}
                  alt=""
                  style={{ filter: !!volumeMute ? "brightness(1)" : "" }}
                />
                {(!!showVolume && !volumeMute) ? (
                  <div className="volume-control">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      step="1"
                      value={volumeLevel}
                      style={{
                        background: `linear-gradient(to right, #E5E5E5 0%, #E5E5E5 ${volumeLevel}%, #282829  ${volumeLevel}%, #282829 100%)`,
                      }}
                      onInput={(event) => volumeUpDown(event)}
                      onChange={(event) => volumeUpDown(event)}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div
              onClick={handelAudio}
              className={`top-menu-button-item ${
                showDomSpeaker ? "disableddiv" : ""
              }`}
            >
              {!localMute ? (
                <>
                  <img src={microphoneOn} alt="" />
                </>
              ) : (
                <>
                  <img
                    src={microphoneOff}
                    alt=""
                    style={{ filter: "brightness(1)" }}
                  />
                </>
              )}
              <span>Toggle Mic</span>
            </div>

            <div
              onClick={handelVideo}
              className={`top-menu-button-item ${
                showDomSpeaker ? "disableddiv" : ""
              }`}
            >
              {!localVideoMute ? (
                <>
                  <img src={cameraOn} alt="" />
                </>
              ) : (
                <>
                  <img
                    src={cameraOff}
                    alt=""
                    style={{ filter: "brightness(1)" }}
                  />
                </>
              )}
              <span>Toggle Video</span>
            </div>

            <Fragment>{renderCameraAndMicrophoneSelection()}</Fragment>

            {/* <span className="dividervertical">&nbsp;</span> */}

            <div
              onClick={fullScreenVideo}
              className={`top-menu-button-item ${
                showDomSpeaker ? "disableddiv" : ""
              }`}
            >
              <img className="fullscreen-img" src={fitToScreen} alt="" />
              <span>FullScreen Video</span>
            </div>

            <div
              className={`top-menu-button-item ${
                showDomSpeaker ? "disableddiv" : ""
              }`}
              onClick={() => {
                setLayoutMode(layoutMode === "normal" ? "inverse" : "normal");
              }}
            >
              <img src={changeView} alt="" />
              <span>Invert Layout</span>
            </div>

            {/* <span className="dividervertical">&nbsp;</span> */}
          </Fragment>
        );
      }
    }
    // Admin
    if (isVCHost) {
      // Show end button if conf is running
      if (troom && !isEmpty(troom)) {
        // const sId = {localParticipant.sid}+"_audio";
        return (
          <Fragment>
            {renderConferenceSelection()}
            {/* <span className="dividervertical">&nbsp;</span> */}
            <Reload reloadPage={reloadPage} showDomSpeaker={showDomSpeaker} admin_user_id={admin_user_id} adminCode={adminAccessCode}/>
            {/* <span className="dividervertical">&nbsp;</span> */}

            <div
              onClick={handleGlobalMute}
              className={`top-menu-button-item ${
                showDomSpeaker ? "disableddiv" : ""
              }`}
            >
              {muteAll ? (
                <>
                  <img
                    src={golbalAudioUnMute}
                    alt=""
                    style={{ filter: "brightness(1)" }}
                  />
                  <span>Unmute All</span>
                </>
              ) : (
                <>
                  <img src={golbalAudioMute} alt="" />
                  <span>Mute All</span>
                </>
              )}
            </div>
            {/* <span className="dividervertical">&nbsp;</span> */}

            {renderDrawingOptions()}

            {/* <span className="dividervertical">&nbsp;</span> */}

            <div
              className={`top-menu-button-item ${
                showDomSpeaker ? "disableddiv" : isEraser ? "eraser-on" : ""
              }`}
              onClick={eraserToggled}
            >
              <img src={erase} alt="" />
              <span>Erase</span>
            </div>
            <div
              onClick={clearWhiteBoardAll}
              className={`top-menu-button-item ${ showDomSpeaker ? "disableddiv" : ""}`}
            >
              <img src={trash} alt="" />
              <span>Clear</span>
            </div>
            {cancelNoiseButton}
            {/* <span className="dividervertical">&nbsp;</span> */}

            <div
              className={`top-menu-button-item ${
                showDomSpeaker ? "ui disableddiv" : "ui"
              }`}
              data-position="bottom center"
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  height:  "100%",
                }}
                onMouseLeave={handleVolumeMouseOut}
                onMouseEnter={handleVolumeControlMouseOver}
              >
                <img
                  onClick={handleVolumeDoubleClick}
                  className="fullscreen-img"
                  src={getVolumeImage()}
                  alt=""
                  style={{
                    filter: !!volumeMute ? "brightness(1)" : "",
                  }}
                />
                {(!!showVolume && !volumeMute) ? (
                  <div className="volume-control">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      step="1"
                      value={volumeLevel}
                      style={{
                        background: `linear-gradient(to right, #E5E5E5 0%, #E5E5E5 ${volumeLevel}%, #282829  ${volumeLevel}%, #282829 100%)`,
                      }}
                      onInput={(event) => volumeUpDown(event)}
                      onChange={(event) => volumeUpDown(event)}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div
              onClick={handelAudio}
              className={`top-menu-button-item ${
                showDomSpeaker ? "disableddiv" : ""
              }`}
            >
              {!localMute ? (
                <>
                  <img src={microphoneOn} alt="" />
                </>
              ) : (
                <>
                  <img
                    src={microphoneOff}
                    alt=""
                    style={{ filter: "brightness(1)" }}
                  />
                </>
              )}
              <span>Toggle Mic</span>
            </div>

            <div
              onClick={handelVideo}
              className={`top-menu-button-item ${
                showDomSpeaker ? "disableddiv" : ""
              }`}
            >
              {!localVideoMute ? (
                <>
                  <img src={cameraOn} alt="" />
                </>
              ) : (
                <>
                  <img
                    src={cameraOff}
                    alt=""
                    style={{ filter: "brightness(1)" }}
                  />
                </>
              )}
              <span>Toggle Video</span>
            </div>

            {/* <span className="dividervertical">&nbsp;</span> */}

            <Fragment>{renderCameraAndMicrophoneSelection()}</Fragment>

            {/* <span className="dividervertical">&nbsp;</span> */}

            <div
              onClick={fullScreenVideo}
              className={`top-menu-button-item ${
                showDomSpeaker ? "disableddiv" : ""
              }`}
            >
              <img className="fullscreen-img" src={fitToScreen} alt="" />
              <span>FullScreen Video</span>
            </div>

            <div
              className={`top-menu-button-item ${
                showDomSpeaker ? "disableddiv" : ""
              }`}
              onClick={() => {
                setLayoutMode(layoutMode === "normal" ? "inverse" : "normal");
              }}
            >
              <img src={changeView} alt="" />
              <span>Invert Layout</span>
            </div>

            {/* <span className="dividervertical">&nbsp;</span> */}

            {/* <li onClick={() => volumeUpDown("down") }>
                <img className="fullscreen-img" src={volumeDown} alt="" />
                <span>Volume Down</span>
              </li> */}

            {/* <span className="dividervertical">&nbsp;</span>
              <li className="settingBtn">
                <img src={settingsImg} alt="" />
                <span>Setting</span>
              </li>
              <li>
                <img src={golbalAudioUnmute} alt="" />
                <span></span>
              </li>
              <li>
                <img src={golbalVideoMute} alt="" />
                <span>Mute All</span>
              </li>
              <span className="dividervertical">&nbsp;</span> */}
          </Fragment>
        );
      }
    }
  };

  useDisconnectRoomOnPageUnload(troom);

  return (
    <>
      <FullScreen handle={fullScreenHandler}>
        <div className="show-viewer-main" id="show-viewer-main">
          <ProgressLoader loading={loading} />

          {/* Body */}
          {renderBodyContent()}

          <Modal
            open={clearChatModalOpen}
            size={"tiny"}
            onClick={() => setClearChatModalOpen(false)}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            style={{ fontSize: "20px" }}
          >
            <Modal.Header style={{ fontSize: "20px" }}>Clear Chat</Modal.Header>
            <Modal.Content>
              You are about to clear the chat messages
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="green"
                onClick={() => clearChat()}
                style={{ fontSize: "15px" }}
              >
                OK
              </Button>
              <Button
                color="grey"
                onClick={() => setModalOpen(false)}
                style={{ fontSize: "15px" }}
              >
                CANCEL
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={exportChartConfirmationOpen}
            size={"tiny"}
            onClick={() => setExportChartConfirmationOpen(false)}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            style={{ fontSize: "20px" }}
          >
            <Modal.Header style={{ fontSize: "20px" }}>
              Export Chat
            </Modal.Header>
            <Modal.Content>
              Are you sure you want to export the chat log
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="green"
                onClick={downloadMessages}
                style={{ fontSize: "15px" }}
              >
                YES
              </Button>
              <Button
                color="grey"
                onClick={() => setExportChartConfirmationOpen(false)}
                style={{ fontSize: "15px" }}
              >
                CANCEL
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={modalAnnotateColorOpen}
            size={"tiny"}
            onClick={() => setModalAnnotateColorOpen(false)}
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Modal.Header>Choose Annotate Color</Modal.Header>
            <Modal.Content>
              <div>
                <div>
                  <label className="form-input-label">Choose VFX Color</label>
                  <Dropdown
                    placeholder="Select VFX Color"
                    className="form-input"
                    fluid
                    selection
                    options={annotationColorOptions}
                    value={VFXcolor}
                    onChange={(e: any, data: any) => {
                      const value: string = data?.value || "";
                      setVFXcolor(value);
                    }}
                  />
                </div>
                <div>
                  <label className="form-input-label">Choose SFX Color</label>
                  <Dropdown
                    placeholder="Select VFX Color"
                    className="form-input"
                    fluid
                    selection
                    options={annotationColorOptions}
                    value={SFXcolor}
                    onChange={(e: any, data: any) => {
                      const value: string = data?.value || "";
                      setSFXcolor(value);
                    }}
                  />
                </div>
                <div>
                  <label className="form-input-label">Choose MX Color</label>
                  <Dropdown
                    placeholder="Select VFX Color"
                    className="form-input"
                    fluid
                    selection
                    options={annotationColorOptions}
                    value={MXcolor}
                    onChange={(e: any, data: any) => {
                      const value: string = data?.value || "";
                      setMXcolor(value);
                    }}
                  />
                </div>
                <div>
                  <label className="form-input-label">Choose PIX Color</label>
                  <Dropdown
                    placeholder="Select VFX Color"
                    className="form-input"
                    fluid
                    selection
                    options={annotationColorOptions}
                    value={PIXcolor}
                    onChange={(e: any, data: any) => {
                      const value: string = data?.value || "";
                      setPIXcolor(value);
                    }}
                  />
                </div>
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="grey"
                onClick={() => setModalAnnotateColorOpen(false)}
              >
                CLOSE
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal
            open={localParticipantBGModalOpen}
            size={"tiny"}
            // onClick={() => setLocalParticipantBGModalOpen(false)}
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Modal.Header>Choose Virtual Background</Modal.Header>
            <Modal.Content>
              <div className="background-selector-wrapper">
                {backgrounds.map((item: any) => {
                  return (
                    <div
                      className={`background-selector ${
                        tempActiveBG === item.value ? "active" : ""
                      }`}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setTempActiveBG(item.value);
                      }}
                    >
                      <img src={item.thumbImage} alt={item.value} />
                    </div>
                  );
                })}
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="green"
                onClick={() => {
                  const tempActiveBGimg = tempActiveBG;
                  setActiveBG(tempActiveBGimg);
                  setLocalParticipantBGModalOpen(false);
                  changeCameraTrack(cameraIdRef.current);
                }}
              >
                APPLY
              </Button>
              <Button
                color="grey"
                onClick={() => {
                  setLocalParticipantBGModalOpen(false);
                }}
              >
                CANCEL
              </Button>
            </Modal.Actions>
          </Modal>
        <div className="pop-up">
        <ConfirmationalPopup
        open={uploadAlert}
        data={`Are you sure you want to upload ${fileName}`}
        onCancel={handleUploadCancel}
        onOk={handleUploadConfirm}
      />
      </div>
      </div>
      </FullScreen>
    </>
  );
};

export default NewShowViewerDashboard;

import React from 'react';
import { Input } from 'antd';
import './styles.scss';

interface InputProps {
  value: string;
  onChangeMessage: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  sendMessage: (message: string) => void;
}

const TextInput: React.FC<InputProps> = ({ value, onChangeMessage, sendMessage }) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const inputText = event.currentTarget.value;
      event.preventDefault();
      if (inputText.length > 0) {
        sendMessage(inputText);
        event.currentTarget.value = '';
      }
    }
  };
  return (
      <Input
        className="sendTxt"
        placeholder="Send a message here"
        size="small"
        spellCheck={true}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeMessage(event, event.target.value)}
        onKeyDown={handleKeyPress}
        value={value}
      />
  );
}
export default TextInput;
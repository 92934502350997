import { useState } from "react";
import volume from "../../../../pages/Viewer/Images/newVolume.svg";
import volumeMuteIcon from "../../../../pages/Viewer/Images/volume_mute.svg";
import volumeLevel2 from "../../../../pages/Viewer/Images/volumeLevel2.svg"
import volumeLevel3 from "../../../../pages/Viewer/Images/volumeLevel3.svg"
import { useWhiteboardingStore } from "../../../../store";

const Volume = (props: any) => {
    const { showDominantSpeaker } = props

    //Volume show and hide
    const [showVolume, setShowVolume] = useState(false)
    const [volumeLevel, setVolumeLevel] = useState(100);
    const [volumeMute, setVolumeMute] = useState(false);
    const { resetAllTools } = useWhiteboardingStore();
    const handleVolumeControlMouseOver = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (!showVolume) {
            setShowVolume(true);
        }
    };

    const handleVolumeMouseOut = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (!!showVolume) {
            setShowVolume(false);
        }
    };

    const handleVolumeDoubleClick = () => {
        setShowVolume(false);
        resetAllTools();
        const elem: any = document.getElementsByTagName("video")[0];
        elem.muted = !elem.muted;
        setVolumeMute(elem.muted);
        if (!elem.muted && volumeLevel < 1) {
          setVolumeLevel(10);
          elem.volume = 0.10;
        }
        // if (!elem.muted) {
        //   setShowVolume(true);
        // }
    };
    
    const volumeUpDown = async (event: any) => {
      const elem: any = document.getElementsByTagName("video")[0];
      if (elem) {
        const value = event.currentTarget.value;
        setVolumeLevel(value)
        elem.volume = value / 100;
        if (elem.volume === 0) {
          elem.muted = true;
          setVolumeMute(true);
        } else {
          elem.muted = false;
          setVolumeMute(false);
        }
      }
    };

      const getVolumeImage = () => {
        if (volumeMute) {
          return volumeMuteIcon;
        } else if (volumeLevel >= 1 && volumeLevel <= 34) {
          return volume;
        } else if (volumeLevel >= 35 && volumeLevel <= 65) {
          return volumeLevel2;
        } else if (volumeLevel >= 66 && volumeLevel <= 100) {
          return volumeLevel3;
        } else {
          return volume;
        }
      };

    return (<div
        className={`top-menu-button-item ${showDominantSpeaker ? "ui disableddiv" : "ui"
            }`}
        data-position="bottom center"
    >
        <div
            style={{
                position: "relative",
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                height: "100%",
            }}
            onMouseLeave={handleVolumeMouseOut}
            onMouseEnter={handleVolumeControlMouseOver}
        >
            <img
                onClick={handleVolumeDoubleClick}
                className="fullscreen-img"
                src={getVolumeImage()}
                alt=""
                style={{ filter: !!volumeMute ? 'brightness(1)' : '' }}
            />
            {!!showVolume ? (
                <div className="volume-control">
                    <input
                        type="range"
                        min="0"
                        max="100"
                        step="1"
                        value={volumeLevel}
                        style={{
                            background: `linear-gradient(to right, #E5E5E5 0%, #E5E5E5 ${volumeLevel}%, #282829  ${volumeLevel}%, #282829 100%)`,
                        }}
                        onInput={(event) => volumeUpDown(event)}
                        onChange={(event) => volumeUpDown(event)}
                    />
                </div>
            ) : (
                ""
            )}
        </div>
        <span className={"volumeSpan"}>Volume</span>
    </div>)
};

export default Volume;
import React, { useEffect, useState } from "react";
import { Table, Button, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import PageLayout, { PageContent } from "../../../layouts/PageLayout";
import Loader from "../../LoaderHOC";
import messageComponent from "../../../utils/message";
import backend from "../../../service/rest/restService";
import api from "../../../service/rest/restApi";
import "./styles.scss";
import logo from "../../../assets/images/sidebar-logo.png";
import { AppMenu } from "../../AppMenu";

const { Title } = Typography;

const Invoice = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = {
          api: api.stripe.getInvoiceDetails,
        };
        const response: any = await backend.fetch(data, true);
        if (response) {
          const mappedData = response.data.map((item: any) => ({
            customer: item.customer,
            customer_email: item.customer_email,
            id: item.id,
            number: item.number,
            status: item.status,
            created: new Date(item.created * 1000).toLocaleDateString(),
            invoice_pdf: item.invoice_pdf,
          }));

          setData(mappedData);
        }
      } catch (err) {
        messageComponent({
          messages: "Failed to fetch data",
          type: "error",
          top: 10,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const columns = [
    {
      title: "Customer ID",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Email",
      dataIndex: "customer_email",
      key: "customer_email",
    },
    {
      title: "Invoice Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Created Date",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button
            className="invoice-download-btn"
            onClick={() => handleDownload(record)}
          >
            Download
          </Button>
        </Space>
      ),
    },
  ];

  const handleDownload = (record: any) => {
    const link = document.createElement("a");
    link.href = record.invoice_pdf;
    link.download = `invoice_${record.id}.pdf`;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCancel = () => {
    navigate("/home");
  };

  return (
    <Loader loading={loading}>
      <PageLayout>
        <PageContent className="invoice-left-content">
          <img src={logo} alt="logo" className="invoice-logo" />
        </PageContent>
        <PageContent className="invoice-right-content">
          <AppMenu className="invoice-header-menu" />
          <div className="invoice-header">
            <Title level={2} className="invoice-table-title">
              Billing Details
            </Title>
          </div>
          <Table
            columns={columns}
            dataSource={data}
            rowKey="id"
            pagination={{ pageSize: 5 }}
          />
          <Button className="invoice-cancel-btn" onClick={handleCancel}>
            Cancel
          </Button>
        </PageContent>
      </PageLayout>
    </Loader>
  );
};

export default Invoice;

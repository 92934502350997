import { Button, Dropdown } from "semantic-ui-react";
import phoneFilled from "../../../../pages/Viewer/Images/newPhone_outline.svg";
import { useState } from "react";
import { useWhiteboardingStore } from "../../../../store";

const ConferenceSelection = (props: any) => {
    const { role, setShowPopup, showPopup, handelVideoConfEnd, refreshRoomCode, handelVideoConfEndAll } = props;
    const [endorLeaveConferenceLoading, setEndorLeaveConferenceLoading] = useState(false); 
    const { resetAllTools } = useWhiteboardingStore();
    const toggleTools = () => {
        setShowPopup(!showPopup);
        resetAllTools();
    };
      const endConferenceForAll = async () => {
        try {    
          setEndorLeaveConferenceLoading(true);
          await handelVideoConfEndAll(false); 
          setEndorLeaveConferenceLoading(false);
        } catch (err) {
          console.error(err);
          setEndorLeaveConferenceLoading(false);
        }
      };

    if (role !== "host") {
        return (
            <div
                className="top-menu-button-item call-options-dropdown"
                onClick={toggleTools}
            >
                <img src={phoneFilled} alt="" />
                <Dropdown icon="" open={showPopup} onClose={toggleTools}>
                    <Dropdown.Menu className="cameraMenu">
                        <Dropdown.Item>
                            <Button
                                className="ui button viewer-menu-btn"
                                onClick={handelVideoConfEnd}
                                style={{ backgroundColor: "white", width: "183px" }}
                            >
                                Leave Conference
                            </Button>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <span>Action</span>
            </div>
        );
    }

    return (
        <div
            className="top-menu-button-item call-options-dropdown"
            onClick={toggleTools}
        >
            <img src={phoneFilled} alt="" />
            <Dropdown icon="" open={showPopup} onClose={toggleTools}>
                <Dropdown.Menu className="cameraMenu">
                    <Dropdown.Item>
                        <Button
                            className="ui button viewer-menu-btn"
                            onClick={refreshRoomCode}
                        >
                            End Conference and Refresh Codes
                        </Button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <Button
                            className="ui button viewer-menu-btn"
                            onClick={endConferenceForAll}
                        >
                            End Conference for All
                        </Button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <Button
                            className="ui button viewer-menu-btn"
                            onClick={handelVideoConfEnd}                          
                        >
                            Leave Conference
                        </Button>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <span>Action</span>
        </div>
    );
};

export default ConferenceSelection
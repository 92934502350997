// import { Fragment } from "react/jsx-runtime";
import microphoneOn from "../../../../pages/Viewer/Images/micOn.svg";
import microphoneOff from "../../../../pages/Viewer/Images/micOff.svg";
import { useAudio } from "../../../../pages/ZoomViewer/hooks/useAudio";
import { useContext } from "react";
import ZoomContext from '../../../../pages/ZoomViewer/context/zoom-context';
import ZoomMediaContext from '../../../../pages/ZoomViewer/context/media-context';
import { useWhiteboardingStore } from "../../../../store";
import "./styles.scss";
const AudioToggle = (props: any) => {
    const { showDominantSpeaker } = props;
    const zmClient = useContext(ZoomContext);
    const { mediaStream } = useContext(ZoomMediaContext);

    const { isAudioOn, onMicClick } = useAudio(zmClient, mediaStream)
    const { resetAllTools } = useWhiteboardingStore();

    return (<div className={`top-menu-button-item ${showDominantSpeaker ? "disableddiv" : ""}`} onClick={() => 
               {onMicClick();
               resetAllTools();
                }}>
        {isAudioOn ? (
            <>
                <img src={microphoneOn} alt="" />
            </>
        ) : (
            <>
                <img
                    src={microphoneOff}
                    alt=""
                    style={{ filter: "brightness(1)" }}
                />
            </>
        )}
        <span>Toggle Mic</span>
    </div>)
};

export default AudioToggle;

import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements
} from '@stripe/react-stripe-js';
import './styles.scss';
import SetupCard from '../../Wallet/SetupCard';

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env;
let stripePromise: any;
if (REACT_APP_STRIPE_PUBLIC_KEY) {
    stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);
}

const CardDetails = (props: any) => {
    const {setIsAddEditCardVisible} = props
    return (
        <Elements stripe={stripePromise}>
            <SetupCard setIsAddEditCardVisible={setIsAddEditCardVisible}/>
        </Elements>
    )
}
export default CardDetails
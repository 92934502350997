import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Space,
  Form,
  List,
  Typography,
  Dropdown,
  Avatar,
  Menu,
  Tabs,
  notification,
} from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import PageLayout, { PageContent } from "../../../layouts/PageLayout";
import logo from "../../../assets/images/sidebar-logo.png";
import Title from "../../Title";
import InputComponent from "../../InputComponent";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase/utils";
import { AuthContext } from "../../../context/auth";
import { AppMenu } from "../../AppMenu";
import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";
import Loader from "../../LoaderHOC";
import ErrorComponent from "../../ErrorComponent";
import "./styles.scss";
import { isAvidBrowser } from "../../../utils/avidBrowserCheck";

const ChangePassword = (props: any) => {
  const [loading, setLoading] = useState(false);

  const [passwordValid, setPasswordValid] = useState(false);
  const [currentPasswordValid, setCurrentPasswordValid] = useState(false);
  const [currentpassword, setCurrentPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [isCurrentPassword, setIsCurrentPassword] = useState(false)
  const user: any = useContext(AuthContext);
  // const initials = getInitials(user.displayName);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const isAvidBrowserCheck =  isAvidBrowser(); 
 

  const reAuthUser = async (res: any) => {
    const { email } = user
    try {
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        email,
        newpassword
      );
      if (userCredentials) {
        console.log('this is running')
        // call the succes modal();
      }
      return userCredentials

    } catch (err: any) {
      console.error(err);
      if (
        err.message === "Firebase: Error (auth/user-not-found)." ||
        err.message === "Firebase: Error (auth/wrong-password)."
      ) {

      }
    }
  }

  const validateCurrentPassword = async () => {
    const { email } = user
    try {
      setLoading(true)
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        email,
        currentpassword
      );
      if (userCredentials) {
        // call the succes modal();
        setIsCurrentPassword(true)
        console.log('password check passed')


      }
    } catch (err: any) {
      setIsCurrentPassword(false)

      console.error(err);
      console.log('password check failed')
    }
    finally {
      setLoading(false)
    }
  }

  const changePassword = async () => {
    // console.log("Change password");
    try {
      // Call Backend to Create User
      //set value to true
      setSaveButtonDisabled(true)
      const input = {
        password: newpassword,
      };
      const data = {
        api: api.user.changePassword,
        payLoad: JSON.stringify(input),
      };
      setLoading(true);
      const response = await backend.save(data, true);
      if (!!response) {
        passwordChangeSuccess();
        const res = await reAuthUser(response)
        if (res) {
          setCurrentPassword("")
          setNewPassword("")
          setConfirmPassword("")
          navigate("/home");
        }
      }

    } catch (err) {
      // setLoading(false);
      throw err;
    }
    finally {
      setLoading(false)
    }
  };



  const passwordChangeSuccess = () => {
    notification.success({
      message: "successfull!",
      placement: "topRight",
            // onClose: () => setLoading(false)
    });
  };
  const homePage = () => {
    navigate("/home", { replace: true });
  };

  const operations = (
    <AppMenu className="header-menu"/>
  );
  

  console.log('check ccccc:::::', isCurrentPassword)
  return (
    <Loader loading={loading}>
      <PageLayout>
        <PageContent className="changepassword-left-content">
          <img src={logo} alt="logo" className="logo" />
        </PageContent>
        <PageContent className="changepassword-right-content">
          <div className="changepwd-layout">
            <Tabs centered tabBarExtraContent={operations} defaultActiveKey="2">
              <div className="changepassword-container">
                <Form name="changepassword-form" onChange={() => setSaveButtonDisabled(false)} >
                  <div className="change-password-form">
                    <Title level={3}>Change Password </Title>
                    <Form.Item
                      name="currentpassword"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (value.length < 12) {
                              setCurrentPasswordValid(false);
                              return Promise.reject(
                                <ErrorComponent
                                  errorMsg={"Please enter current password"}
                                  setErrorMsg={setErrorMsg}
                                />
                              );
                            } else {
                              validateCurrentPassword()
                              setCurrentPasswordValid(true);
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                    >
                      <InputComponent
                        key={"CURRENTPASSWORD"}
                        label="current password"
                        value={currentpassword}
                        type="password"
                        onChange={setCurrentPassword}
                      />
                    </Form.Item>
                    <Form.Item
                      name="newpassword"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (
                              value !== "" &&
                              /[a-z]/g.test(value) &&
                              /[A-Z]/g.test(value) &&
                              /[0-9]/g.test(value) &&
                              /[!"#$%&'()*+,-./:;<=>?_@]/g.test(value) &&
                              value.length >= 12
                            ) {
                              Promise.resolve();
                              setPasswordValid(true);
                            } else if (value === "") {
                              setPasswordValid(false);
                              return Promise.reject(
                                <ErrorComponent
                                  errorMsg={"Please enter a new password"}
                                  setErrorMsg={setErrorMsg}
                                />
                              );
                            } else if (value.length < 12) {
                              setPasswordValid(false);
                              return Promise.reject(
                                <ErrorComponent
                                  errorMsg={"Please enter a valid password"}
                                  setErrorMsg={setErrorMsg}
                                />
                              );
                            } else {
                              setPasswordValid(false);
                              return Promise.reject(
                                <ErrorComponent
                                  errorMsg={"Please enter a valid password"}
                                  setErrorMsg={setErrorMsg}
                                />
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <InputComponent
                        disabled={!isCurrentPassword}
                        key={"NEWPASSWORD"}
                        label="new password"
                        value={newpassword}
                        type="password"
                        onChange={setNewPassword}
                      />
                    </Form.Item>
                    <Form.Item
                      name="confirmpassword"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (getFieldValue("newpassword") === value) {
                              return Promise.resolve();
                            } else if (value === "") {
                              return Promise.reject(
                                <ErrorComponent
                                  errorMsg={"Please re-enter the password"}
                                  setErrorMsg={setErrorMsg}
                                />
                              );
                            
                            } else if(getFieldValue("newpassword") !== value)  {
                              return Promise.reject(
                                <ErrorComponent
                                   errorMsg={"The passwords does not match"}
                                   setErrorMsg={setErrorMsg}
                                />
                              );
                            }
                          },
                        }),
                      ]}
                    >
                      <InputComponent
                        disabled={!isCurrentPassword}
                        key={"CONFIRMPASSWORD"}
                        label="confirm password"
                        value={confirmpassword}
                        type="password"
                        onChange={setConfirmPassword}
                      />
                    </Form.Item>
                  </div>
                  <div className="bottom-space">
                    <div className="space">
                      <div className="list-head">
                        YOUR PASSWORD MUST INCLUDE
                      </div>
                      <div className="list-data">
                        <span>
                          <CheckCircleTwoTone
                            twoToneColor={newpassword.length >= 12 ? "#42B729" : "#A3A3A3"} />{" "}
                          not less than 12 characters
                        </span>
                        <span>
                          <CheckCircleTwoTone
                            twoToneColor={/[A-Z]/g.test(newpassword) ? "#42B729" : "#A3A3A3"} />{" "}
                          atleast one uppercase letter
                        </span>
                        <span>
                          <CheckCircleTwoTone
                            twoToneColor={/[a-z]/g.test(newpassword) ? "#42B729" : "#A3A3A3"} />{" "}
                          atleast one lowercase letter
                        </span>
                        <span>
                          <CheckCircleTwoTone
                            twoToneColor={/[0-9]/g.test(newpassword) ? "#42B729" : "#A3A3A3"}/>{" "}
                          atleast one number
                        </span>
                        <span>
                          <CheckCircleTwoTone
                            twoToneColor={
                              /[!"#$%&'()*+,-./:;<=>?_@]/g.test(newpassword)
                                ? "#42B729"
                                : "#A3A3A3"
                            } 
                          />{" "}
                          atleast one special character
                        </span>
                      </div>
                    </div>
                    <div className="changepassword-btn-container">
                      {/* <Form.Item> */}
                       <Button
                        className="changepassword-cancel-btn"
                        onClick={homePage}
                      >
                        Cancel
                      </Button>
                      <Button
                        htmlType="submit"
                        className="changepassword-btn"
                        disabled={
                          currentPasswordValid === false ||
                          passwordValid == false ||
                          isCurrentPassword == false ||
                          newpassword !== confirmpassword||
                          isSaveButtonDisabled === true 
                        }
                        onClick={changePassword}
                        
                      >
                        Save
                      </Button>
                      {/* </Form.Item> */}
                    </div>
                    {!isAvidBrowserCheck ?
                    (
                      <div className="need-support-change-password">
                        <a href="mailto:support@pacpostio.zohodesk.com">Need Support?</a>
                      </div>
                    ) : (
                      <div className="need-support-copy-change-password">
                        <span className="need-support-copy-text">Need Support? support@pacpostio.zohodesk.com</span>
                      </div> )}  
                  </div>
                </Form>       
              </div>
            </Tabs>
          </div>
        </PageContent>
      </PageLayout>
    </Loader>
  );
};
export default ChangePassword;

import { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import CardDetails from "../CardDetails";
// import applepay from '../../../../assets/images/applepay.png';
// import googlepay from '../../../../assets/images/googlepay.png';
// import paypal from '../../../../assets/images/paypal.png';
import api from "../../../../service/rest/restApi";
import backend from "../../../../service/rest/restService";
// import { LoadingOutlined, CloseOutlined } from '@ant-design/icons';
import Loader from "../../../LoaderHOC/";
import "./styles.scss";
import SavedCardList from "../../Wallet/SavedCardList";
import Title from "../../../Title";
// import { Label } from '../../../Label';
import { get } from "../../../../utils/helpers";

const AddPaymentMethod = (props: any) => {
  const {
    fetchSavedCardsParent,
    deletePayment,
    loading,
    listStripePaymentMethods,
    setListStripePaymentMethods,
    setIsModalOpen,
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddEditCardVisible, setIsAddEditCardVisible] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchSavedCards = async () => {
    try {
      const data = {
        api: api.stripe.listStripePaymentMethods,
      };
      setIsLoading(true);
      const listStripePaymentMethods: any = await backend.fetch(data, true);
      // setLoading(false)
      setListStripePaymentMethods(
        !!listStripePaymentMethods && listStripePaymentMethods.length > 0
          ? [...listStripePaymentMethods]
          : []
      );
      // if (listStripePaymentMethods && listStripePaymentMethods.length) {
      // } else {
      //     setListStripePaymentMethods([])
      // }
      setIsLoading(false);
    } catch (error: any) {
      console.log(error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleContinue = () => {
    // setIsModalVisible(false);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModalOpen(false);
  };

  useEffect(() => {}, [listStripePaymentMethods]);

  useEffect(() => {
    console.log("isAddEditCardVisible :>> ", isAddEditCardVisible);
    if (!isAddEditCardVisible) {
      fetchSavedCards();
    }
  }, [isAddEditCardVisible]);

  useEffect(() => {
    console.log("isModalVisible :>> ", isModalVisible);
    if (!isModalVisible) {
      fetchSavedCardsParent();
    }
  }, [isModalVisible]);

  return (
    <div>
      <Button
        onClick={() => {
          setIsModalVisible(true);
        }}
        className="add-payment-method-button"
      >
        + Add payment method
      </Button>
      <Modal
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleContinue}
        onCancel={handleCancel}
        destroyOnClose
        className="payment-method-modal"
        bodyStyle={{ borderRadius: "10px" }}
      >
        {!isAddEditCardVisible ? (
          <div className="payment-method-page-wrapper">
            <div className="add-payment-method-header"></div>
            <Title size={20}> Payment Methods </Title>
            {[...listStripePaymentMethods].length === 0 && (
              <>
                <Title size={15} value={20}>
                  ADD A PAYMENT METHOD
                </Title>
                <div className="add-payment-methods-wrapper">
                  {/* <Button className="payment-method-icon" onClick={() => { }}>
                                <img src={applepay} alt="applepay" />
                            </Button>
                            <Button className="payment-method-icon" onClick={() => { }}>
                                <img src={googlepay} alt="googlepay" />
                            </Button>
                            <Button className="payment-method-icon" onClick={() => { }}>
                                <img src={paypal} alt="paypal" />
                            </Button> */}
                  <Button
                    className="add-payment-method-button"
                    onClick={() => {
                      setIsAddEditCardVisible(true);
                      setIsModalOpen(true);
                    }}
                  >
                    + Add debit or credit card
                  </Button>
                </div>
              </>
            )}
            {[...listStripePaymentMethods].length > 0 && (
              <>
                <div>
                  {/* Manage Payment Methods */}
                  <div className="spacer-lg"></div>
                  <Title size={14} value={20}>
                    MANAGE PAYMENT METHODS
                  </Title>
                  <Loader loading={isloading}>
                    <SavedCardList
                      listStripePaymentMethods={[...listStripePaymentMethods]}
                      loading={isloading}
                      error={error}
                      deletePayment={deletePayment}
                    />
                  </Loader>
                </div>
                <div className="add-payment-method-footer">
                  <Button
                    className="btn-continue"
                    size="large"
                    type="primary"
                    onClick={handleContinue}
                  >
                    Continue
                  </Button>
                </div>
              </>
            )}
          </div>
        ) : (
          <div>
            <CardDetails setIsAddEditCardVisible={setIsAddEditCardVisible} />
          </div>
        )}
      </Modal>
      {/* <Modal title="Basic Modal" visible={isAddCardModalVisible} onOk={handleOk} onCancel={handleCancelAddCardModal}
                className="payment-method-modal"
            >
                Modal
            </Modal> */}
    </div>
  );
};

export default AddPaymentMethod;

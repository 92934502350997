import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Layout,
  notification,
  Select,
  Popconfirm,
  Modal,
  Tooltip,
} from "antd";
import logo from "../../../assets/images/logo-dark.png";
import "./styles.scss";
import InputComponent from "../../InputComponent";
import { Label } from "../../Label";
import Title from "../../Title";
import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";
import messageComponent from "../../../utils/message";
import { get } from "../../../utils/helpers";
import useRoomCommands from "../useRoomCommands";
import { ExportOutlined } from "@ant-design/icons";
import { AuthContext } from "../../../context/auth";
import copyIcon from "../../../assets/images/copyIcon.png";
import { isAvidBrowser } from "../../../utils/avidBrowserCheck";
import moment from "moment";

const { Content } = Layout;
const { Option, OptGroup } = Select;

const SelectRoom = (props: any) => {
  const navigate = useNavigate();
  const {
    rooms,
    selectedRoomID,
    setSelectedRoomID,
    fetchActiveRooms,
    setLoading,
  } = props;
  const {reactivateSubscription} = useRoomCommands(setLoading);
  const [streamViewerUrl, setStreamViewerUrl] = useState("");
  const [streamViewerCode, setStreamViewerCode] = useState("");
  const [maxParticipants, setMaxParticipants] = useState("");
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
  const [isReactivateModalVisible, setIsReactivateModalVisible] = useState(false);
  const [isSubscriptionStatus, setIsSubscriptionStatus] = useState("");
  const [isCopyModal, setIsCopyModal] = useState(false);
  const user: any = useContext(AuthContext);
  const isAvidBrowserCheck =  isAvidBrowser();
  const [streamExists, setStreamExists] = useState(true);
  
  const handleChangeRoom = (value: any) => {
    if (value) {
      setSelectedRoomID(value);
    }
  };

  const chosenRoom =  rooms.find((room: any) => room.id === selectedRoomID) ;

  useEffect(() => {
    const populateData = () => {
      const selectedRoom =
        rooms.find((room: any) => room.id === selectedRoomID) || {};
      const { stream_viewer_code, max_vc_participants, tiny_viewer_url, expiry } =
        selectedRoom;

      // set subscription status
      let subStatus = !!rooms && rooms[0]?.stripe_subscription_status;
      setIsSubscriptionStatus(subStatus)
      const shortUrl =
        !!tiny_viewer_url && !!tiny_viewer_url.shortUrl
          ? tiny_viewer_url.shortUrl
          : "";
      setStreamViewerUrl(shortUrl);
      setStreamViewerCode(stream_viewer_code);
      setMaxParticipants(max_vc_participants);

      if (!!expiry && !!expiry._seconds && !!expiry._nanoseconds) {
        const formatted: any = moment(
          new Date(expiry._seconds * 1000 + expiry._nanoseconds / 1000000)
        );
        //Used to check whether stream has expired or not
        const endDateValue = moment(formatted).unix();
        const currentDateValue = moment().unix();
        const expiryValue = endDateValue >= currentDateValue ? true : false;
        setStreamExists(expiryValue);
      }

    };
    populateData();
  }, [selectedRoomID, rooms]);

  const openUserManual =()=>{
    window.open('https://pacpost-live.gitbook.io/public-wiki/pacpost.io')
  }

  const handleAddRoom = () => {
    navigate("/setuproom", {
      replace: true,
      state: {
        prevPath: "home",
      },
    });
  };

  const removeSubscription = async () => {
    try {
      setIsRemoveModalVisible(false);
      setLoading(true);
      const selectedRoom =
        rooms.find((room: any) => room.id === selectedRoomID) || {};
        const input = {
          subscription_id:selectedRoom.stripe_subscription_id
        }
      const data = {
        api: api.stripe.cancelSubscription,
        payLoad: JSON.stringify(input),
      };

      const result = await backend.save(data);
      console.log("result :>> ", result);
      if (result) {
        fetchActiveRooms();
        messageComponent({
          messages: "Removed Subscription Successfully",
          type: "success",
          top: 10,
        });
      }
    } catch (err) {
      console.log("err :>> ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleReactivateSubscription = async () =>{
    setIsReactivateModalVisible(false);
     await reactivateSubscription();
  }

  const getStreamRoom = (activeStreamRooms: any) => {
    const sortedActiveRooms = activeStreamRooms.sort((a: any, b: any) => {
      const fa = a.room_name.slice(7);
      const fb = b.room_name.slice(7);
      const c = parseFloat(fa);
      const d = parseFloat(fb);
      if (c < d) {
        return -1;
      }
      if (c > d) {
        return 1;
      }
      return 0;
    });
    return sortedActiveRooms.map((room: any) => {
      return (
        <Option value={room.id} key={`StreamRoom${room.id}`}>
          &nbsp;&nbsp;{room.stream_room_name || room.room_name}
        </Option>
      );
    });
  };

  const copyText =get(user,'displayName','').toUpperCase()+" has invited you to join Pacpost.io"+"\n"+"\n"+
  "URL: "+streamViewerUrl+"\n"+
  "Session Code: "+streamViewerCode;

  const handleCopyModalOk = () =>{
    navigator.clipboard.writeText(copyText);
    messageComponent({messages:"Guest Invitation Copied" ,type:"info",top:10 })
    setIsCopyModal(false);
  }
  const handleCopyModalCancel = () => {
    setIsCopyModal(false)
  } 

  const getNewRoom = (newRooms: any) => {
    const sortedNewRooms = newRooms.sort((a: any, b: any) => {
      const fa = a.room_name.slice(7);
      const fb = b.room_name.slice(7);
      const c = parseFloat(fa);
      const d = parseFloat(fb);

      if (c < d) {
        return -1;
      }
      if (c > d) {
        return 1;
      }
      return 0;
    });
    return sortedNewRooms.map((room: any, index: number) => {
      return (
        <Option value={room.id} key={`NewRoom${room.id}`}>
          &nbsp;&nbsp;{room.room_name}
        </Option>
      );
    });
  };
  const viewerStreamInfo = rooms.map((room: any) => {
    if (room.id === selectedRoomID) {
      if (!room.stream_vendor_id || room.stream_status !== "Active" || streamExists !== true) {
        return <p  key={"Guest Login Credentials"}></p>;
      } else if (!!room.stream_vendor_id) {
        return (
          <div key={"Guest Login Credentials"}>
            <div>
              <div className="title-wrapper">
                <Title>Guest Login Credentials
                  
                </Title>
                <Tooltip className="copy-invitation"
                        title="Copy Invitation"
                        placement="top">
                  <Button 
                    onClick={()=>setIsCopyModal(true)}
                    className="copy-btn"
                  >
                  <img src={copyIcon} alt={copyIcon} className="copy-icon"/>
                 
                  </Button>
                </Tooltip>
              </div>
          </div>
          <Modal
              visible={isCopyModal}
              onOk={handleCopyModalOk}
              onCancel={()=>setIsCopyModal(false)}
              okText="Copy Guest Invitation"
              className="copy-modal"
              title="Copy Guest Invitation"
            >
              {get(user,'displayName','').toUpperCase() + ""} is inviting you to join a stream. 
              <br/><br/>
              <b>Join pacpost.io Room</b><br/><br/>
              URL : {streamViewerUrl}<br/>
              Session Code : &nbsp;{streamViewerCode}<br/><br/>
            </Modal>
            <InputComponent
              label="session access link"
              value={streamViewerUrl}
              readOnly
              copyToClipBoard={true}
            />
            <InputComponent
              label="SESSION CODE"
              value={streamViewerCode}
              readOnly
              copyToClipBoard={true}
            />
            <div>
              <span className="support-text editor-guest-credentials">
                Please provide this verification code and link for the Guests.
              </span>
            </div>
          </div>
          
        );
      }
    }
  });
  const activeStreamRooms = rooms.filter((room: any) => {
    return !!room.stream_vendor_id;
  });
  const newRooms = rooms.filter((room: any) => {
    return !room.stream_vendor_id;
  });
  const showRemoveModal = () => {
    setIsRemoveModalVisible(true);
  };
  const handleRemoveCancel = () => {
    setIsRemoveModalVisible(false);
  };

  return (
    <Layout>
      <div className="select-room-logo">
        <img src={logo} alt="logo" className="logo" />
      </div>
      <Content className="select-room-content">
        <div className="select-room">
          <Label>SESSIONS</Label>
          <Select
            value={selectedRoomID}
            style={{ width: "100%" }}
            onChange={handleChangeRoom}
            listItemHeight={49}
            className="selectroom-dropdown"
          >
            {activeStreamRooms.length > 0 && (
              <OptGroup key={activeStreamRooms.length} label="Active Sessions">
                {getStreamRoom(activeStreamRooms)}
              </OptGroup>
            )}
            {newRooms.length > 0 && (
              <OptGroup key={newRooms.length} label="New Sessions">
                {getNewRoom(newRooms)}
              </OptGroup>
            )}
          </Select>
        </div>
        <div className="viewer-stream-info">{viewerStreamInfo}</div>
        {!get(chosenRoom, "free_room_flag", false) &&
          !get(chosenRoom, "subscription_end_time", 0) && (
            <div className="subscription-options-wrapper">
              <div className="subscription-actions-title">
                Subscription Options
              </div>

              <Button
                className="remove-subscription-btn"
                onClick={showRemoveModal}
              >
                Remove Subscription
              </Button>
              <Modal
                title="Remove Subscription"
                visible={isRemoveModalVisible}
                onOk={removeSubscription}
                onCancel={handleRemoveCancel}
              >
                <span>Are you sure you want to remove the subscription?</span>
              </Modal>
            </div>
          )}
        {!!get(chosenRoom, "subscription_end_time", 0) && (
          <div className="subscription-options-wrapper">
            <Button
              className="remove-subscription-btn"
              onClick={() => {
                setIsReactivateModalVisible(true);
              }}
            >
              Reactivate Subscription
            </Button>
            <br />
            <Button
              type="link"
              className="back-button"
              onClick={() => {
                navigate("/setuproom");
              }}
            >
              Back
            </Button>
            <Modal
              title="Reactivate Subscription"
              visible={isReactivateModalVisible}
              onOk={handleReactivateSubscription}
              onCancel={() => {
                setIsReactivateModalVisible(false);
              }}
            >
              <span>Are you sure you want to reactivate the subscription?</span>
            </Modal>
          </div>
        )}
        {!isAvidBrowserCheck ?
        (
        <div>
          <a href="mailto:support@pacpostio.zohodesk.com">
            Need Support?
          </a>
        </div>
        ) : (
        <div>
          <span className="need-support-copy-text">Need Support? support@pacpostio.zohodesk.com</span>
        </div>
        )}<br/>
        <span  className="need-support-copy-text" onClick={openUserManual}>
          <Tooltip title='Git Book Link' placement="right">User manual</Tooltip>
        </span>
      </Content>
    </Layout>
  );
};

export default SelectRoom;

import { useState, useRef, useCallback } from 'react';

// Define the hook with appropriate TypeScript typings
function useVratio(initialValue: number = 1.6): [number, (value: number) => void, React.MutableRefObject<number>] {
  const [vratio, setVRatio] = useState<number>(initialValue);
  const vratioRef = useRef<number>(vratio);

  const setVRatioRef = useCallback((value: number) => {
    vratioRef.current = value;  // Update the ref value
    setVRatio(value);          // Update the state value
  }, []);

  return [vratio, setVRatioRef, vratioRef];
}

export default useVratio;

import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/auth";
import { get } from "./helpers";
import { doSignOut } from "../firebase/auth";

const PrivateRoute = ({ children }: any) => {
  const user: any = useContext(AuthContext);
  const isLinkUser = get(user, "linkLogin", false);
  const is2FAEnabled = get(user, 'is2FAEnabled', false);
  const is2FAPassed = get(user, 'is2FAPassed', false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuth = async () => {
      if (!user || (is2FAEnabled && !is2FAPassed)) {
        console.log("Not Authenticated");
        navigate("/");
        if (is2FAEnabled && !is2FAPassed) {
          await doSignOut();
        }
      } else if (isLinkUser) {
        console.log("LinkUser");
        navigate("/404");
      }
      if (!sessionStorage.getItem("is_firstVisit")) {
        await doSignOut();
      }
    };

    handleAuth();
  }, [user]);

  return children;
};

export default PrivateRoute;

import { useEffect, useState } from 'react';
import { RemoteParticipant, Room } from 'twilio-video';

export default function useParticipants(room?: Room) {
  const [participants, setParticipants] = useState(Array.from(room?.participants?.values?.() ?? []));

  useEffect(() => {
    if (room) {
      const participantConnected = (participant: RemoteParticipant) =>
        setParticipants(prevParticipants => [...prevParticipants, participant]);

      const participantDisconnected = (participant: RemoteParticipant) =>
        setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
      if(!!room.on) {
        room.on('participantConnected', participantConnected);
        room.on('participantDisconnected', participantDisconnected);
      }
    
      return () => {
        if (!!participantConnected && !!participantDisconnected && !!room.off) {
          room.off('participantConnected', participantConnected);
          room.off('participantDisconnected', participantDisconnected);
        }    
      };
    }
  }, [room]);

  return participants;
}

import { useEffect, useState } from "react";
import { get } from "../../../utils/helpers";
import "./styles.scss";

import { Button, Modal } from "antd";
import useHudsStatsStore from "../../../store/slices/viewer/hudsStatsStore";

const statsMetaData = {
  audio: {
    "Audio TimeStamp": [
      (x: string) => new Date(x).toLocaleString(),
      "timestamp",
    ],
    "Audio Bitrate": "bitrate",
    "Audio Packet Loss": "totalPacketsLost",
    "Audio Total Received": "totalPacketsReceived",
    "Audio Jitter": "jitter",
    "Audio Id": "id",
    "Audio Mid": "mid",
    "Audio MimeType": "mimeType",
    "Audio PLDPS": "packetsLostDeltaPerSecond",
    "Audio PLRPS": "packetsLostRatioPerSecond",
    "Audio TBR": "totalBytesReceived",
    "Audio TotalPacketsLost": "totalPacketsLost",
  },
  video: {
    FPS: "framesPerSecond",
    "Video Bitrate": "bitrate",
    "Video Total Received": "totalPacketsReceived",
    "Video Packet Loss": "totalPacketsLost",
    "Video Jitter": "jitter",
    "Video Resolution": [
      (a: string, b: string) => a + " x " + b,
      "frameHeight",
      "frameWidth",
    ],
    "Video Id": "id",
    "Video MimeType": "mimeType",
    "Video PLDPS": "packetsLostDeltaPerSecond",
    "Video PLRPS": "packetsLostRatioPerSecond",
    "Video TimeStamp": [
      (x: string) => new Date(x).toLocaleString(),
      "timestamp",
    ],
    "Video TBR": "totalBytesReceived",
    "Video TPR": "totalPacketsReceived",
  },
};
const HudStats = () => {
  const [streamingData, setStreamingData] = useState("");
  const { hudsState, setHudsState } = useHudsStatsStore();
  const audio = get(streamingData, "audio", "");
  const video = get(streamingData, "video", "");

  const milcastView = get(hudsState, "milicastView", undefined);
  useEffect(() => {
    if (!!milcastView) {
      milcastView.webRTCPeer?.initStats({autoInitStats:true,statsIntervalMs:1000});
      //Capture new stats from event every second
      milcastView.webRTCPeer?.on("stats", (stats: any) => {
        setStreamingData(stats);
      });
    }

    return () => {
      milcastView?.webRTCPeer?.stopStats();
      setStreamingData("");
    };
  }, [milcastView, hudsState]);

  const evalObject = (resolvingObject: any, param: any) => {
    const [fn, ...a] = param;
    const aValues = a.map((ab: string) => resolvingObject[ab]);
    return fn.apply(null, aValues);
  };

  const statsData = () => {
    const audioData = get(audio, "inbounds.0", []);
    const videoData = get(video, "inbounds.0", []);
    const audioStats = () =>
      Object.keys(get(statsMetaData, "audio", "")).map((statsKey: any) => (
        <tr>
          <td>{statsKey}</td>
          <td>
            {typeof get(get(statsMetaData, "audio", []), `${statsKey}`, "") ===
            "object"
              ? evalObject(
                  audioData,
                  get(get(statsMetaData, "audio", {}), `${statsKey}`, "")
                )
              : audioData[
                  get(get(statsMetaData, "audio", {}), `${statsKey}`, "")
                ]}
          </td>
        </tr>
      ));
    const VideoStats = () =>
      Object.keys(statsMetaData?.video).map((statsKey: any) => (
        <tr>
          <td>{statsKey}</td>
          <td>
            {typeof get(get(statsMetaData, "video", []), `${statsKey}`, "") ===
            "object"
              ? evalObject(
                  videoData,
                  get(get(statsMetaData, "video", []), `${statsKey}`, "")
                )
              : videoData[
                  get(get(statsMetaData, "video", []), `${statsKey}`, "")
                ]}
          </td>
        </tr>
      ));
    return (
      <table className="stats-table">
        <thead>
          <tr>
            <td>
              <b>Name</b>
            </td>
            <td>
              <b>Value</b>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Current RTT</td>
            <td>{get(streamingData, "currentRoundTripTime", "")}</td>
          </tr>
          <tr>
            <td>Available Outgoing Bitrate</td>
            <td>{get(streamingData, "availableOutgoingBitrate", "")}</td>
          </tr>
          <tr>
            <td>Candidate Type</td>
            <td>{get(streamingData, "candidateType", "")}</td>
          </tr>
          <tr>
            <td>Total totalRoundTripTime</td>
            <td>{get(streamingData, "totalRoundTripTime", "")}</td>
          </tr>

          {audioStats()}
          {VideoStats()}
        </tbody>
      </table>
    );
  };

  const closePanel = () => {
    milcastView?.webRTCPeer?.stopStats();
    setStreamingData("");
    setHudsState({
      openHudStatsPanel: false,
    });
  };

  const renderValues = () => {
    if (!audio && !video) {
      //Render loader
      return <div> Loading....</div>;
    }
    return <div>{statsData()}</div>;
  };

  const renderBody = () => {
    if (!hudsState.activesStreamExists) {
      // Render no stream ui
      return (
        <Modal
          open={true}
          onOk={closePanel}
          centered={true}
          onCancel={closePanel}
          footer={[
            <Button type="primary" onClick={closePanel}>
              OK
            </Button>,
          ]}
        >
          Please wait for the stream to start.
        </Modal>
      );
    }
    return (
      <div className="hudStats-layout">
        <div className="close-btn-wrapper">
          <span className="stats-heading">Media Stats</span>
          <Button
            id="hudStatsPanelArea"
            onClick={closePanel}
            className="layout-close"
            type="link"
          >
            X
          </Button>
        </div>
        <div className="media-stats-scroll">
          <div className="stat"> {renderValues()}</div>
        </div>
      </div>
    );
  };

  return <>{renderBody()}</>;
};
export default HudStats;

import { useEffect, useState } from 'react';
import { Room } from 'twilio-video';

export default function useLocalAudioTrack(room: Room | undefined) {
  const [audioTrack, setAudioTrack] = useState(() => {
    if (!room?.localParticipant) {
      return undefined;
    }

    return Array.from(room.localParticipant.audioTracks.values())[0]?.track;
  });

  const localParticipant = room?.localParticipant;
  useEffect(() => {
    if (localParticipant) {
      const updateAudioTrack = () => {
        const track = Array.from(localParticipant.audioTracks.values())[0]?.track;
        if (track) {
          setAudioTrack(track);
        }
      };

      updateAudioTrack();

      localParticipant.on('trackPublished', updateAudioTrack);
      return () => {
        localParticipant.off('trackPublished', updateAudioTrack);
      };
    }
  }, [localParticipant]);

  return audioTrack;
}

import { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../../assets/images/sidebar-logo.png";
import { Button, Checkbox, Space, Divider, Form, notification } from "antd";
import { doSignOut } from "../../../firebase/auth";
import ErrorComponent from "../../ErrorComponent";

import { AuthContext } from "../../../context/auth";
// import { useForm } from "../../../hooks/useForm";

import PageLayout, { PageContent } from "../../../layouts/PageLayout";
import InputComponent from "../../InputComponent";
import Title from "../../Title";
import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";

import "./styles.scss";
import { auth } from "../../../firebase/utils";
import Loader from "../../LoaderHOC";
import { get } from "../../../utils/helpers";

const OtpPage = (props: any) => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({} as any);
  const [error, setError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [signinErr, setSigninErr] = useState("");
  const [otpValid, setOtpValid] = useState(false);
  const user: any = useContext(AuthContext);
  const navigate = useNavigate();
  const [buttonEnabled,setButtonEnabled] =useState(true);
  const setCurrentUser = get(useContext(AuthContext), 'setCurrentUser', {});

  const [first_name] = user.displayName.split(" ");

  const signout = async () => {
    await doSignOut();
    localStorage.removeItem("url");
    navigate("/", {
      replace: true,
    });
  };

  const validateGoogleOtp = async (profiledata: any) => {
    try {
      const { google_auth_secret } = profiledata;
      const input = {
        userId: userData?.uid,
        token: otp,
        authSecret: google_auth_secret,
        action: "login"
      };

      const data = {
        api: api.otp.validateGoogleAuth2FA,
        payLoad: JSON.stringify(input),
      };
      const res = await backend.save(data, true);
      if (res) {
        setButtonEnabled(false);
        if (!!profiledata.force_password) {
          navigate("/resetpassword");
        }
        else {
          navigate("/home");
        }
      }
    } catch (err: any) {
      if (err) {
        if (err.errCode === 40105) {
          setButtonEnabled(false);
          notification.error({
            message: err.errMessage,
            placement: "topRight",
            duration: 3,
          });
          setTimeout(async () => {
            await signout();
          }, 3000);
        } else {
          notification.error({
            message: err.errMessage,
            placement: "topRight",
            duration: 3,
          });
        }
      }
      console.log("error here:::", err);
    } finally {
      setLoading(false);
    }
  };

  async function loginUser() {
    try {
      const data = {
        api: api.user.get,
      };
      setLoading(true);
      const profiledata = await backend.fetch(data, true);
      if (profiledata) {
        validateGoogleOtp(profiledata);
        setOtpValid(true);
      }
    } catch (err: any) {
      console.error(err);
      setError(err.message);
    }
  }

  return (
    <Loader loading={loading}>
      <PageLayout>
        <PageContent className="left-content">
          <img src={logo} alt="logo" className="logo" />
        </PageContent>
        <PageContent className="signin-right-content">
          <Space
            direction="vertical"
            size={10}
            className="login-form-container"
          >
            <div className="login-form-container">
              <div className="header-wrapper">
                <div className="user_no_image user_list_image" />
                <div className="title-wrapper">
                  <Title value={20} level={3}>
                    Welcome {first_name}
                  </Title>
                </div>
              </div>
              <Divider style={{ border: "1px blue solid" }} />

              <p>Enter the code from the google Authenticator app</p>
              <Form name="login">
                <Form.Item
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: (
                        <ErrorComponent
                          errorMsg={"Please Enter OTP"}
                          setErrorMsg={setErrorMsg}
                        />
                      ),
                    },
                  ]}
                >
                  <InputComponent
                    key={"otp"}
                    value={otp}
                    type="text"
                    onChange={setOtp}
                  />
                </Form.Item>

                <div className="login-btn-container">
                  <Form.Item>
                    <div className="sign-in-err">
                      <ErrorComponent
                        errorMsg={signinErr}
                        setErrorMsg={setErrorMsg}
                      />
                    </div>
                    <Button
                      htmlType="submit"
                      className="login-btn"
                      onClick={loginUser}
                      disabled={otp?.trim() === "" || buttonEnabled === false }
                      // loading={loginLoading}
                    >
                      Verify Code
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>

            <Divider />
            {/* <p>Don't have code ? Resend here or sign out </p> */}
            <div className="otp-footer-wrapper">
              {/* <Button
                className="otp-btn-disabled"
                style={{ marginRight: "10px" }}
                size="large"
                disabled
                type="primary"
              >
                Resend
              </Button> */}
              <Button
                className="otp-btn"
                size="large"
                type="primary"
                onClick={signout}
              >
                Sign Out
              </Button>
            </div>
          </Space>

          {/* </form> */}
        </PageContent>
      </PageLayout>
    </Loader>
  );
};

export default OtpPage;

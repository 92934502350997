
import { create } from 'zustand';
import { turnApiUrl } from "../../../utils/constants";

interface RightPanelState {
  rightActivePanel: string;
}

interface RightPanelActions {
  openChatPanel: () => void;
  openGuestPanel: () => void;
  closeRightPanel: () => void;
}

interface ViewerState {
  layoutMode: string,
  showDomSpeaker: boolean
}

interface HeaderActions {
  setLayoutMode: (mode: string) => void
}

interface StreamState {
  showStreamModal: boolean,
  setShowStreamModal: (value: boolean) => void
  streamKey: number,
  setStreamKey: () => void,
}

interface VCState {
  volumeMute: boolean,
  setVolumeMute: (value: boolean) => void
}

interface ICEServerState {
  iceServers: any[],
  isIceServersLoading: boolean;
  fetchIceServers: () => void;
}
interface ViewerLoadingState {
  streamLoading: boolean,
  setStreamLoading: (value: boolean) => void
}

interface redIcon {
  redIcon:boolean,
  setRedIcon: (value: boolean) => void
}

const resizeCanvas = () => {
  setTimeout(() => window.dispatchEvent(new Event("resize")), 25);
};

export const useViewerSlice = create<RightPanelState & RightPanelActions & ViewerState & HeaderActions & VCState>((set) => ({
  rightActivePanel: '', // Initially no panel is open
  openChatPanel: () => {
    set({ rightActivePanel: 'chat' })
    resizeCanvas()
  },
  openGuestPanel: () => {
    set({ rightActivePanel: 'guest' })
    resizeCanvas()
  },
  closeRightPanel: () => {
    set({ rightActivePanel: '' })
    resizeCanvas()
  },
  layoutMode: 'normal',
  setLayoutMode: (mode: string) =>{
    const rowWrapper = document.getElementById('container-wrapper')
    if(rowWrapper){
      rowWrapper.style.flexDirection = mode === 'inverse' ? 'column-reverse':'column';
    }
    set({ layoutMode: mode })
  },
  showDomSpeaker: false,
  volumeMute: false,
  setVolumeMute: (value: boolean) => set({volumeMute: value}),
}));

export const useStreamSlice = create<ICEServerState & StreamState>((set) => ({
  showStreamModal: false,
  setShowStreamModal: (value: boolean) => set({showStreamModal: value}),
  streamKey: Math.random(),
  setStreamKey: () => set({ streamKey: Math.random()}),
  iceServers: [],
  isIceServersLoading: false,
  fetchIceServers: async () => {
    try {
      set({ isIceServersLoading: true })
      const response = await fetch(turnApiUrl, {
        method: "PUT",
      });

      if (response.status >= 300) {
        throw new Error(
          "TURN API return unexpected status " + response.status
        );
      }

      const result = await response.json();
      console.log("fetchIceServers result: ", result);
      const { iceServers = [] } = result.v;
      set({ iceServers: iceServers });
      
    } catch (err) {
      const msg = "Error while pulling ICE Servers!";
      console.error(msg);
    } finally {
      set({ isIceServersLoading: false })
    }
  },
}))


export const useViewerLoadingSlice = create<ViewerLoadingState>((set) => ({
  streamLoading: false,
  setStreamLoading: (value: boolean) => set({streamLoading: value}),
}))

export const useRedIconSlice = create<redIcon>((set) => ({
  redIcon: false,
  setRedIcon: (value: boolean) => set({redIcon: value}),
}));
import { Result } from "antd";
import React, { useContext } from "react";
import { AuthContext } from "../../context/auth";
import { AppMenu } from "../../components/AppMenu";
import logo from "../../assets/images/logo-dark.png";

interface NotFoundProps {
  is404page?: boolean;
}

const NotFound: React.FC<NotFoundProps> = ({ is404page = false }) => {
  const user: any = useContext(AuthContext); 
  return (
    <>
      {!!user && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div><img src={logo} alt="logo" style={{ margin: "20px" }} /></div>
          <div style={{ margin: "20px" }}><AppMenu  is404Page={true}/></div>

        </div>
      )}
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    </>
  );
};

export default NotFound;

import { useState, useEffect, useCallback } from "react";
import moment from "moment";

export const useCountdownTimer = (
  targetTimestamp: number,
  setRefreshToken: (val: boolean) => void,
  refreshToken: boolean
) => {
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());

  function getTimeRemaining() {
    const currentTime = moment();
    const targetTime = moment(targetTimestamp);
    const duration = moment.duration(targetTime.diff(currentTime));
    return Math.max(0, duration.asSeconds());
  }

  const updateRemainingTime = useCallback(() => {
    setTimeRemaining(getTimeRemaining());
    setRefreshToken(!refreshToken)
  }, [targetTimestamp]);

  useEffect(() => {
    const intervalId = setInterval(updateRemainingTime, 1000);

    // Cleanup function to clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [updateRemainingTime]);

  // Format remaining time for display
  const formattedRemainingTime = moment.duration(timeRemaining, "seconds");

  return formattedRemainingTime;
};

import { useState, useEffect } from "react";
//import { Button, Icon, Grid, Image } from "semantic-ui-react";
//import UserMessage from "../Common/UserMessage";
import { useLocation } from "react-router-dom";
import api from "../../service/rest/restApi";
import backend from "../../service/rest/restService";
import { Button, Modal } from "antd";
import { View } from "@millicast/sdk";
import { get } from "../../utils/helpers";

//const sequence_timecode = require("./sequence_timecode_new.mp4");
// const sequence_timecode = require("../../Assets/videos/sequence_timecode_new.mp4");

interface IProps {
  show_id: string;
  canvasRef: any;
  stream: any;
  iceServers: any[];
  refreshKey: number;
  setLoading: (value: boolean) => void;
  isReady: () => void;
  showDominantSpeaker: boolean;
  dominantSpeakerID: any;
  localAudioMuteValue: boolean;
  localParticipantID: string;
  localParticpantTrack: any;
  dominantSpeakerTrack: any;
  showStreamUpdate: any;
  volumeMute: any;
  room_id: string;
  admin_user_id: string;
  isGuestViewer: boolean;
  setHudsState: (value: any) => void;
  setLayerOptions: (value: any) => void;
}

const ShowStream = (props: IProps) => {
  const { search } = useLocation();

  const params: any = new URLSearchParams(search);

  const user_id: string = params.get("uid");
  const room_id: string = params.get("rid");
  const room_name = sessionStorage.getItem('room_name') || '';

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const elem: any = document.getElementById("video-container");
    elem.addEventListener("fullscreenchange", fullscreenchanged);
    return () => {
      elem.removeEventListener("fullscreenchange", fullscreenchanged);
    };
  }, []);

  const fullscreenchanged = () => {
    const videoElem: HTMLVideoElement = document.getElementById(
      "player"
    ) as HTMLVideoElement;
    if (
      document.fullscreenElement &&
      document.fullscreenElement.id === "video-container" &&
      videoElem
    ) {
      console.log("insdie if");
      videoElem.controls = true;
    } else {
      videoElem.removeAttribute("controls");
      videoElem.controls = false;
    }
  };

  useEffect(() => {
    // let pc: RTCPeerConnection;
    // let ws: WebSocket;
    // const script = document.createElement("script");
    // script.src = "https://webrtc.github.io/adapter/adapter-latest.js"; //May want to locally load or statically host on local site
    // script.async = true;
    // document.body.appendChild(script);
    props.isReady();

    const connect = async () => {
      try {
        setSuccessMessage("");
        setErrorMessage("");

        //Subscribe to Stream
        console.log("** Subscribe to stream");

        const input = {
          room_id: props.room_id,
          room_owner_user_id: props.admin_user_id,
          client_type: "web",
        };

        const data = {
          //api: api.streams.subscribeStream,
          api: api.room.subscribeStream,
          payLoad: JSON.stringify(input),
        };

        props.setLoading(true);
        const result: any = await backend.save(data, true);
        props.setLoading(false);

        if (!result || !result.jwt || !result.url) {
          setShowModal(true);
          return;
        }

        if (result && result.jwt && result.url) {
          const videoElement = document.getElementsByTagName("video")[0];

          const tokenGenerator = async () => {
            return {
              wsUrl: result.url,
              urls: [result.url],
              jwt: result.jwt,
              iceServers: props.iceServers,
            };
          };

          const millicastView = new View(room_name, tokenGenerator, videoElement);

          millicastView.on("track", (event: any) => {
            videoElement.srcObject = event.streams[0];
          });

          millicastView
            .connect({
              events: [
                "active",
                "inactive",
                "stopped",
                "vad",
                "layers",
                "migrate",
                "viewercount",
              ],
            })
            .catch((e: any) => {
              console.log("Connection failed, handle error", e);
            });

          millicastView.on("reconnect", (err: any) => {
            console.log("Error connecting in", err.timeout);
            console.error(err.error);
          });

          const activeSources = new Set();

          millicastView.on("broadcastEvent", (event: any) => {
            const { name, data } = event;
            switch (name) {
              case "active":
                activeSources.add(data.sourceId);
                // millicastView.webRTCPeer?.initStats();
                props.setHudsState((prevState: any) => ({
                  ...prevState,
                  activesStreamExists: true,
                  activesStreamStatus: true,
                }));
                console.log("Active Stream.");
                break;
              case "inactive":
                activeSources.delete(data.sourceId);
                if (activeSources.size === 0) {
                  console.log("No active Stream.");
                }
                props.setHudsState((prevState: any) => ({
                  ...prevState,
                  activesStreamExists: false,
                  activesStreamStatus: false,
                }));
                millicastView.webRTCPeer?.stopStats();
                break;
              case "layers":
                const layers = get(data, "medias.0.layers", []).map(
                  (layer: any) => ({
                    key: layer.encodingId,
                    value: layer,
                    text: layer.encodingId,
                  })
                );
                //TODO: If you need to enable the AUTO option, uncomment below the code
                layers.unshift({
                  key: "Auto",
                  value: {},
                  text: "Auto"
                })
                props.setLayerOptions(layers);
                break;
              default:
                break;
            }
          });
          props.setHudsState((prevState: any) => ({
            ...prevState,
            milicastView: millicastView,
          }));
          return () => {
            millicastView.stop();
          };
        }
      } catch (err: any) {
        let message = "Stream is NOT Live !";
        console.error(err);
        props.setLoading(false);
        setShowModal(true);

        if (err && err.status === "fail") {
          message = err.data.message;
          console.error(message);
        }
      }
    };
    // const stream = { room_id,  'test'}
    if (props.stream && props.iceServers.length) {
      connect();
    }
  }, [props.show_id, props.stream, props.iceServers, props.refreshKey]);

  useEffect(() => {
    const dominantSpeakerContainer: any = document.getElementsByClassName(
      "dominant-speaker-view"
    );

    if (!props.showDominantSpeaker && !!dominantSpeakerContainer) {
      while (
        !!dominantSpeakerContainer[0] &&
        dominantSpeakerContainer[0].firstChild
      ) {
        dominantSpeakerContainer[0].removeChild(
          dominantSpeakerContainer[0].lastChild
        );
      }
    }
    const elem: any = document.getElementsByTagName("video")[0];
    if (!!elem) {
      elem.muted = props.showDominantSpeaker ? true : props.volumeMute;
    }
  }, [
    props.showDominantSpeaker,
    props.localAudioMuteValue,
    props.dominantSpeakerID,
    props.localParticpantTrack,
    props.dominantSpeakerTrack,
    props.showStreamUpdate,
  ]);

  const renderDominantSpeakerView = () => {
    // Get speaker div
    let dominantSpeakerDiv: any = null;
    let track: any = {};
    let tagName = "";

    if (props.showDominantSpeaker) {
      if (!!props.dominantSpeakerID) {
        dominantSpeakerDiv = document.getElementById(props.dominantSpeakerID);
        track = props.dominantSpeakerTrack;
      } else {
        dominantSpeakerDiv = document.getElementById(props.localParticipantID);
        track = props.localParticpantTrack;
      }
    }

    if (!!dominantSpeakerDiv) {
      const div = document.createElement("div");
      div.id = `${dominantSpeakerDiv.id}_clone`;
      // div.style.height = "100%";
      // div.style.width = "calc(100% - 60px)";
      div.setAttribute("class", "dominant-videoparticipant");

      let images = dominantSpeakerDiv.getElementsByTagName("img");

      if (images.length > 0) {
        const image = document.createElement("img");
        image.src = images[0].getAttribute("src");
        image.style.height = "100%";
        image.style.width = "100%";
        div.insertBefore(image, div.childNodes[0]);
        tagName = "IMAGE";
      } else {
        if (!!track) {
          div.appendChild(track.attach());
          tagName = "VIDEO";
        }
      }

      const dominantSpeakerContainer: any = document.getElementsByClassName(
        "dominant-speaker-view"
      );

      if (div.id !== dominantSpeakerContainer[0].childNodes[0]?.id) {
        while (dominantSpeakerContainer[0].firstChild) {
          dominantSpeakerContainer[0].removeChild(
            dominantSpeakerContainer[0].lastChild
          );
        }
        dominantSpeakerContainer[0].appendChild(div);
      } else {
        // if(tagName !== dominantSpeakerContainer[0]?.childNodes[0]?.childNodes[0]?.tagName){
        while (dominantSpeakerContainer[0]?.firstChild) {
          dominantSpeakerContainer[0].removeChild(
            dominantSpeakerContainer[0].lastChild
          );
        }
        dominantSpeakerContainer[0].appendChild(div);
        // }
      }
    }

    return null;
  };

  const getDominantSpeakerShowStatus = () => {
    if (props.showDominantSpeaker) {
      // if(!props.localAudioMuteValue) {
      //   return true;
      // } else if(!!props.dominantSpeakerID) {
      //   return true;
      // } else {
      //   return false;
      // }
      return true;
    }
    return false;
  };

  return (
    <div className="viewerstream">
      <div className="viewcontainer">
        {/* <div className="vieweraspectratio"> </div> */}

        <div className="dominant-speaker-view">
          {getDominantSpeakerShowStatus() && renderDominantSpeakerView()}
        </div>
        <div
          id="video-container"
          className={
            getDominantSpeakerShowStatus()
              ? "d-none video-container"
              : "video-container"
          }
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <video
            className="viewerstream-video"
            id="player"
            width="100%"
            height="100%"
            controls={false}
            autoPlay
            disablePictureInPicture
            playsInline
          ></video>
          <canvas
            className="viewerstream-overlay"
            ref={props.canvasRef}
            id="canvas"
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              zIndex: 1,
              top: "0",
              left: "0",
            }}
          ></canvas>
        </div>
      </div>
      <Modal
        open={showModal}
        centered={true}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={[
          <Button
            type="primary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            OK
          </Button>,
        ]}
      >
        <div className="btn-close">
          Stream is not currently running. Please reload the page once the
          publisher starts the stream.
        </div>
      </Modal>
    </div>
  );
};

export default ShowStream;

import { useState } from "react";
import logo from "../../../assets/images/sidebar-logo.png";
import { Button, Space, Divider, Form } from "antd";
import PageLayout, { PageContent } from "../../../layouts/PageLayout";
import InputComponent from "../../InputComponent";
import Title from "../../Title";
import Loader from "../../LoaderHOC";
import "./styles.scss";
import messageComponent from "../../../utils/message";
import backend from "../../../service/rest/restService";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../../service/rest/restApi";
import { doSignOut } from "../../../firebase/auth";
import { isAvidBrowser } from "../../../utils/avidBrowserCheck";

const EmailOTPPage = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [otpValue, setOtpValue] = useState("")
  const [emailOtpValid,setEmailOtpValid] = useState(false)
  const location: any = useLocation();
  const navigate = useNavigate();
  const isAvidBrowserCheck =  isAvidBrowser();
  const [buttonEnabled,setButtonEnabled] =useState(true);

  const openNotifications = (res:any) => {
    const {status} = res ;
    if( status === "Account not found") {
      messageComponent({messages:"Please check your email for further instructions", type:"success",top:10})
      setButtonEnabled(false);
    }
    else {
      messageComponent({messages:"Please check your email for further instructions", 
      type:"success",
      onClose: () => navigate("/"),
      top:10})
      setButtonEnabled(false);
    }
  };

  const getOTPCode = async () => {
    setLoading(true);
    try {
      // Call Backend to send password via user email
      const input = {
        otp: otpValue,
        user_email: location.state.email
      };
      const data = {
        api: api.otp.validateOTP,
        payLoad: JSON.stringify(input),
      };

      const response = await backend.save(data, true);
      setLoading(false);
      if (response) {
        console.log('forgot password response:::::', response)
        openNotifications(response)
      }

      return;
    } catch (err) {
      messageComponent({messages:"Invalid otp", type:"error",top:10})
      setLoading(false);
      throw err;
    }
  };

  const signout = async () => {
    await doSignOut();
    localStorage.removeItem('url')
    navigate(
      "/",
      { replace: true }
    );
  };
  return (
    <Loader loading={loading}>
      <PageLayout>
        <PageContent className="left-content">
          <img src={logo} alt="logo" className="logo" />
        </PageContent>
        <PageContent className="signin-right-content">
          <Space
            direction="vertical"
            size={10}
            className="login-form-container"
          >
            <div className="login-form-container">
              <div className="header-wrapper">
                <div className="user_no_image user_list_image" />
                <div className="title-wrapper">
                  <Title value={20} level={3}>
                    Welcome {""}
                  </Title>
                </div>
              </div>
              <Divider style={{ border: "1px blue solid" }} />

              <p>Verify the Code from Email</p>
              <Form name="login">
                <Form.Item
                  name="emailOTP"
                  rules={[
                    { validator: async ( _: any,val: any) => {
                      if (val === "") {
                        setEmailOtpValid(false);
                        return Promise.reject("Please enter OTP")
                      }else{
                        setEmailOtpValid(true);
                        setButtonEnabled(true);
                        return Promise.resolve;
                      }}}]}
                 
                >
                  <InputComponent
                    key={"emailOTP"}
                    value={otpValue}
                    type="text"
                     onChange={(value:any) => setOtpValue(value)}
                  />
                </Form.Item>
                <div className="btn-container">
                  <div className="login-btn-container">
                    <Form.Item>
                      <div className="sign-in-err"></div>
                      <Button
                        htmlType="submit"
                        className="login-btn"
                        onClick={getOTPCode}
                        disabled={emailOtpValid === false || buttonEnabled === false}
                        // loading={loginLoading}
                      >
                        Verify Code
                      </Button>
                    </Form.Item>
                  </div>

                  {/* <p>Don't have code ? Resend here or sign out </p> */}
                  <div className="otp-footer-wrapper1">
                        {/* <Button
                        className="otp-btn-disabled"
                        style={{ marginRight: "10px" }}
                        size="large"
                        disabled
                        type="primary"
                        >
                            Resend
                        </Button> */}
                    <Form.Item>
                      <Button
                        className="otp-btn"
                        size="large"
                        type="primary"
                        onClick={signout}
                      >
                        Sign Out
                      </Button>
                    </Form.Item>
                  </div>
                </div>
                {!isAvidBrowserCheck ?
                (
                  <div className="need-support-emailOtp">
                    <a href="mailto:support@pacpostio.zohodesk.com">Need Support?</a>
                  </div>  
                ) : (
                  <div className="need-support-copy-email-otp">
                    <span className="need-support-copy-text">Need Support? support@pacpostio.zohodesk.com</span>
                  </div> )}
              </Form>
            </div>
          </Space>

          {/* </form> */}
        </PageContent>
      </PageLayout>
    </Loader>
  );
};

export default EmailOTPPage;
import { Tooltip } from "antd";
import GuestUsersList from "./GuestUsersList";
import guestLinkCopy from "./Images/guestLinkCopy.svg";

const GuestInvite = (props: any) => {
  const { troom, room_id, viewerData, displayName } = props;

  const handleCopy = () => {
    navigator.clipboard
      .writeText(`${displayName.toUpperCase()} has invited you to join Pacpost.io 
URL: ${viewerData.accessLink}
Session Code: ${viewerData.accessCode}`);
  };

  return (
    <div className="guest-panel-wrapper">
      <nav className="main-menu-right-chat" id="guest-invite-tray">
        <div className="chat-h1">
          <span className="chat-text">GUEST LIST</span>
        </div>
        <div className="guest-invite-content">
          <div className="publisher-panel-content">
            <div className="guest-viewer-url-container">
              <div className="url-container-wrapper">
                <div className="copy-icon">
                  <Tooltip title="Copy Guest Invitation" placement="left">
                    <img
                      alt="Copy URL"
                      src={guestLinkCopy}
                      onClick={() => {
                        handleCopy();
                      }}
                    />
                  </Tooltip>
                </div>
                <div>
                  <input className="link-holder" type="text" value={viewerData.accessLink} disabled />
                  <input className="link-holder" type="text" value={`Passcode : ${viewerData.accessCode}`} disabled />
                </div>
              </div>
            </div>
            <GuestUsersList
              room={troom}
              room_id={room_id}
              // guestList={guestList}
            />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default GuestInvite;

import { useViewerSlice } from "../../../../store/slices/viewer/viewerSlice";
import changeView from "../../../../pages/Viewer/Images/changeView.svg";
import { useWhiteboardingStore } from "../../../../store";

const InvertLayout = () => {
    const { layoutMode, setLayoutMode, showDomSpeaker } =
        useViewerSlice();
        const { resetAllTools } = useWhiteboardingStore();
    return (
        <div
        className={`top-menu-button-item ${
          showDomSpeaker ? "disableddiv" : ""
        }`}
        onClick={() => {
          setLayoutMode(layoutMode === "normal" ? "inverse" : "normal");
          resetAllTools();
        }}
      >
        <img src={changeView} alt="" />
        <span>Invert Layout</span>
      </div>
      );
};

export default InvertLayout;
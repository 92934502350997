import {message} from 'antd';
    
interface IMessageComponentProps {
  messages:string,
  type:string,
  top:number,
  duration:number,
  maxCount: number,
  onClose: () => void
}
    const messageComponent = (props:any)=>{
      const{messages="", onClose,  type="info", top = 10, duration=4 ,maxCount=1}= props as IMessageComponentProps;
      message.config({
          top,
          duration,
           maxCount,
          rtl: true,
          //prefixCls: 'my-message',

        });
       switch(type){
          case "error":
              message.error({
                content:messages,
                style:{
                    fontSize:'14px',
                    color:'red',}
                });
  
              break;
          case "success":
              message.success({
                content:messages,
                onClose: onClose,
                style:{
                    fontSize:'14px',
                    color:'green',}
                });
              break;
          default:
              message.info({
                content:messages,
                style:{
                    fontSize:'14px',
                    }
                })
       }
      
     
  };
  export default messageComponent;
  
  
/* eslint-disable import/first */
/* eslint-disable no-undef */
var mcapi = window.mcapi;
import {
  ConfigureSRTStreamRequest,
  ConfigureSRTStreamRequestBody,
} from "../../grpc-web/MCAPI_Types_pb.js";
import { MCAPIClient } from "../../grpc-web/MCAPI_grpc_web_pb.js";
import messageComponent from "../../utils/message";
const mcapiclient = new MCAPIClient(
  mcapi?.getGatewayServerAddress(),
  null,
  null
);

export const isAVID = () => !!mcapi;
export const handleSRTStream = function (SRTStreamId) {
  console.log("SRTStreamId", SRTStreamId)
  // SRT STREAM
  const srtStreamRequest = new ConfigureSRTStreamRequest();

  const srtStreamRequestBody = new ConfigureSRTStreamRequestBody();
  srtStreamRequestBody.setStreamName(SRTStreamId);
  srtStreamRequestBody.setIpAddress("73999b287c9441368e311faf8f79ccd7-w6RPh8.transcoder.millicast.com");
  srtStreamRequestBody.setPort(10000);
  srtStreamRequestBody.setPassword("");
  srtStreamRequestBody.setLatency(100);
  srtStreamRequestBody.setQualityOption("1");
  srtStreamRequestBody.setModeOption("1");
  srtStreamRequest.setBody(srtStreamRequestBody);

  const md = {
    accessToken: mcapi?.getAccessToken(),
  };

  mcapiclient.configureSRTStream(srtStreamRequest, md, (err, response) => {
    if (err) {
      messageComponent({
        messages: "SRT Stream Configure Error",
        type: "error",
        top: 10,
      });
    } else {
      messageComponent({
        messages: "SRT Stream Configured Successfully",
        type: "success",
        top: 10,
      });
    }
  });
};
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "../../../hooks/useForm";
import logo from "../../../assets/images/sidebar-logo.png";
import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";
import PageLayout, { PageContent } from "../../../layouts/PageLayout";
import Loader from "../../LoaderHOC";
import "./styles.scss";
import { AppMenu } from "../../AppMenu";
import Title from "../../Title";
import { Button, Form, Input } from "antd";
import { Label } from "../../Label";
import messageComponent from "../../../utils/message";
import { isAvidBrowser } from "../../../utils/avidBrowserCheck";
import { AuthContext } from "../../../context/auth";
import { get } from "../../../utils/helpers";
import * as auth from "../../../firebase/auth";
import { updateProfile } from "firebase/auth";



const initialState = {
  first_name: "",
  last_name: "",
  user_email: "",
  organization_name: "",
};
const EditProfile = (props: any) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [disabled,setDisabled]=useState(false);
  const userObject = useContext(AuthContext)
  const setCurrentUser = get(userObject,'setCurrentUser','');
  const { onChange, onSubmit, setNewValues, values, getValues } = useForm(
    updateAccount,
    initialState
  );
  const [isAccountInfo, setIsAccountInfo] = useState('' as any);
  const isAvidBrowserCheck =  isAvidBrowser();

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        const data = {
          api: api.user.get,
        };

        setLoading(true);
        const response = await backend.fetch(data, true);
        setLoading(false);
        if (response) {
          setIsAccountInfo(response)
          const [first_name, last_name] = response.displayName.split(' ');
          const fetched_values: any = {
            first_name: first_name,
            last_name: last_name,
            user_email: response.email,
            organization_name: response.organisation_name
          };
          setNewValues(fetched_values);
        }
       
      } catch (err) {
        setLoading(false);
        throw err;
      }
    };
    getUserProfile();
    saveChangesValid()
  }, []);

  const check=()=>{
    setDisabled(false);
  }

  async function updateAccount() {
    setError("");
   
    const { first_name, last_name, organization_name } =
      values;
    if (!first_name?.trim()) {
      setError("Fisrt name can not be empty");
      messageComponent({messages:"Please enter firstname",type:"error",top:10})
      return;
    }
    if (!last_name?.trim()) {
      setError("Last name can not be empty");
      messageComponent({messages:"Please enter lastname",type:"error",top:10})
      return;
    }
    if (!organization_name) {
      setError("orgn can not be empty");
    //  return;
    }


    try {
      // Call Backend to Create User
      const fn=first_name.replace(/\s/g, '');
      const ln=last_name.replace(/\s/g, '');
      const input = {
        user_name: {
          first_name:fn,
          last_name:ln,
        },
        organization_name
      };
      const data = {
        api: api.user.updateAccount,
        payLoad: JSON.stringify(input),
      };
      setLoading(true);
      const response = await backend.save(data, true);
      setLoading(false);
      console.log(response);
      messageComponent({messages:"Account details updated Successfully",type:"success",top:10})
      setDisabled(true)
      const user = await auth.getUser();
      await updateProfile(user, { displayName: `${fn} ${ln}` });
      const tokenResult = await user.getIdTokenResult(true);
      setCurrentUser({
        user: user,
        claims: get(tokenResult, "claims", {}),
        loading: false,
      });
      return;
    } catch (err) {
      setLoading(false);
      throw err;
    }
  }

  const homePage = () => {
      navigate("/home", { replace: true })
  }

  // check if there's a new change
  const saveChangesValid = () => {
    const formValues = getValues();
    if (!!isAccountInfo) {
      const [first_name, last_name] = isAccountInfo?.displayName?.split(' ');
      const isFirstName = formValues?.first_name !== first_name;
      const isLastName = formValues?.last_name !== last_name;
      const isOrgName = formValues.organization_name !== isAccountInfo.organisation_name;
     
      return !(isOrgName || isFirstName || isLastName)
    }
    return true
  }
  
  
  return (

    <Loader loading={loading}>
      <PageLayout>
        <PageContent className="left-content">
          <img src={logo} alt="logo" className="logo" />
        </PageContent>
        <PageContent className="right-content">
          <AppMenu className="header-menu"/>
          <Form 
          onChange={check}>
            <Title value={30} size={20}> Account Settings</Title>
            <div className="form-input-wrapper">
              <Form.Item
                rules={[{ required: true, message: 'Please input your first name!' }]}
              >
                <Label>FIRST NAME</Label>
                <Input
                  name="first_name"
                  className="input-field"
                  value={values.first_name.replace(/\s/g, '')}
                  onChange={onChange}
                  autoComplete="new-password"
                  maxLength={20}
                  required={true}
                />
              </Form.Item>
              <Form.Item>
                <Label>LAST NAME</Label>
                <Input
                  name="last_name"
                  className="input-field"
                  value={values.last_name.replace(/\s/g, '')}
                  onChange={onChange}
                  maxLength={20}
                  required={true}
                />
              </Form.Item>
            </div>
            <div className="form-wrapper">
              <Form.Item>
                <Label> EMAIL</Label>
                <Input
                  disabled
                  name="user_email"
                  className="input-field"
                  value={values.user_email}
                  onChange={onChange}
                />
              </Form.Item>
            </div>
            <div className="form-wrapper">
              <Form.Item>
                <Label>NAME OF ORGANISATION</Label>
                <Input
                  name="organization_name"
                  className="input-field"
                  value={values.organization_name}
                  onChange={onChange}
                  maxLength={20}
                />
              </Form.Item>
            </div>
            <div className="account-settings-footer">
             <Button 
              className="account-settings-btn-cancel" 
              style={{ marginRight: '10px' }} 
              //size="large" 
              //type="primary" 
              onClick={homePage}>Cancel</Button>
              <Button
                className="account-settings-btn"
                //size="large"
                //type="primary"
                htmlType="submit"
                disabled={saveChangesValid() || disabled}
                onClick={updateAccount}>Save</Button>
            </div>
            {!isAvidBrowserCheck ?
            (
              <div className="need-support-profile">
                <a href="mailto:support@pacpostio.zohodesk.com">Need Support?</a>
              </div>  
            ) : (
              <div className="need-support-copy">
                <span className="need-support-copy-text">Need Support? support@pacpostio.zohodesk.com</span>
              </div> )}
          </Form>
        </PageContent>
      </PageLayout>
    </Loader>
  );
};

export default EditProfile;

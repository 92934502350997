import { Modal } from 'antd';
interface ConfirmationalPopupProps {
  onOk: any;
  data: any;
  onCancel: any;
  open: boolean;
}
const ConfirmationalPopup = (props: ConfirmationalPopupProps) => {
  return (
    <Modal
      centered={true}
      bodyStyle={{ height: 'auto'}}
      width={600}
      open={props.open}
      onOk={props.onOk}
      onCancel={props.onCancel}
    //   footer={null}
    >
      {props.data}
    </Modal>
  );
};
export default ConfirmationalPopup;

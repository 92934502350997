import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { doSignOut } from "../../../firebase/auth";
import {
  Layout,
  Menu,
  Button,
  Tabs,
  DatePicker,
  DatePickerProps,
  Select,
  Modal,
  Switch,
  Popconfirm,
  Checkbox,
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import {
  CloseOutlined,
  PlayCircleFilled,
  SyncOutlined,
} from "@ant-design/icons";
import { AuthContext } from "../../../context/auth";
import { get, getInitials } from "../../../utils/helpers";
import Tab1Image from "../../../assets/images/statistic-board.png";
import AVID from "../../../assets/images/AVID.png";
// import Tab2Image from '../../../assets/images/add-profile.png';
// import Tab3Image from '../../../assets/images/business.png';
import Title from "../../Title";
import "./styles.scss";
import InputComponent from "../../InputComponent";
import { Label } from "../../Label";
import { countryCodes } from "../../../utils/countryCodesISO";
import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";
import { AppMenu } from "../../AppMenu";
import messageComponent from "../../../utils/message";
import logo from "../../../assets/images/logo-dark.png";
import BadgeComponent from "../../BadgeComponent";
import ErrorComponent from "../../ErrorComponent";
import { convertDateFromUTCEpoc } from "../../../utils/utils";
import Loader from "../../LoaderHOC";
import { handleSRTStream } from "../avid";
import { ROOM_STATE } from "../../../utils/constants";
import UpgradeModal from "../UpgradeModal";
import { refreshUser } from "../../../utils/refreshUser";
import {ComponentState} from "../structs"


const { Content } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;

const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  // Can not select days before today and today
  return current && current < moment().endOf("day");
};

let expiredSessionFlag = false;

const AvidCreateRoom = (props: any) => {
  const navigate = useNavigate();
  const user: any = useContext(AuthContext);
  //const initials = getInitials(user?.displayName);

  const {
    rooms,
    selectedRoomID,
    setRooms,
    fetchActiveRooms,
    setLoading,
    loading,
  } = props;
 
  const [rpstate, setRPState] = useState<ComponentState>({
    isCopyModal: false,
    isRefreshModalVisible: false,
    isDeleteModalVisible:false,
    upgradeModalVisible:false,
    isAddRoom :true,
    streamExists: true,
    roomNameValid: true,
    autoAllow: false,
    isExpiredSessionModal: false,
    isRoomId:"",
    selectedCountries : [],
    isfreeRoom: "",
    dayTrialEndDate: '',
    subscriptionEndDate: 0,
    roomName:"",
    streamEndDate: undefined,
    streamName:"",
    errorMsg:"",
    streamAdminUrl: "",
    streamAdminCode:"",
    SRTstream: "",
    publishToken:"",
    trialEnd: "",
    isSubscriptionStatus :"",
    isSubscriptionID: "",
    SRT_passphrase:"",
    countries: Object.keys(countryCodes).map((country: any) => ({
      name: country,
      value: countryCodes[country],
    })),
    isAVIDMode: false,
    ipAddress:"",
    portDetails:"",
  });


  useEffect(() => {
    const populateData = () => {
      console.log("selectedRoom", rooms);
      const selectedRoom =
        rooms.find((room: any) => room.id === selectedRoomID) || {};
      const {
        room_name,
        stream_room_name,
        stream_millicast_name,
        stream_admin_code,
        stream_admin_url,
        stream_vendor_id,
        geo_allowed_countries,
        expiry,
        publish_token,
        tiny_admin_url,
        trial_end,
        srt_stream_id,
        srt_passphrase,
        auto_allow = true,
        isAvidRoom = false, //Todo: REMOVE THE TEMP AVID TEST VALUE
      } = selectedRoom;
      // setIsAVIDMode(isAVID() || !!isAvidRoom);
      const convertTrialEndTS = convertDateFromUTCEpoc(trial_end);
      // moment(trial_end).utc().toString()
      setRPState((prevState) => ({
        ...prevState,
        trialEnd:convertTrialEndTS,
      }));


      // set subscription status

      let subStatus = !!rooms && rooms[0]?.stripe_subscription_status;
      let subID = !!rooms && rooms[0]?.stripe_subscription_id;
      let roomId = !!rooms && rooms[0]?.id;

      setRPState((prevState) => ({
        ...prevState,
        isSubscriptionStatus:subStatus,
        isfreeRoom:get(selectedRoom,'free_room_flag',false),
        subscriptionEndDate:get(selectedRoom,'subscription_end_time',0),
        trialEnd:convertTrialEndTS,
        IsSubscriptionID: subID,
        isRoomId:roomId,
      }));


      if(get(selectedRoom,'room_state','') === ROOM_STATE.DAY_TRIAL && get(selectedRoom,'free_room_flag',false)) {
        setRPState((prevState) => ({
          ...prevState,
          dayTrialEndDate:moment(get(selectedRoom, "day_trial_end", '')).format("LL"),
        }));
      }

      //Refresh the auth token in case display name is null
      if(!get(user,'displayName','')){
        refreshUser()
      } 

      const shortUrl =
        !!tiny_admin_url && !!tiny_admin_url.shortUrl
          ? tiny_admin_url.shortUrl
          : "";

      setRPState((prevState) => ({
        ...prevState,
        autoAllow:get(selectedRoom, "auto_allow", false),
        roomName: !!stream_millicast_name ? stream_room_name || room_name : "",
        streamName: stream_millicast_name,
        streamAdminUrl: shortUrl,
        streamAdminCode:stream_admin_code,
        SRTstream: srt_stream_id,
        SRT_passphrase: srt_passphrase,
        publishToken : publish_token || "",

      }));

      if (!!geo_allowed_countries && rpstate.countries.length > 0) {
        const selectedCountriesList = geo_allowed_countries.map(
          (item: string) => {
            const countryName = rpstate.countries.find(
              (country: any) => country.value === item
            );
            return countryName;
          }
        );
        setRPState((prevState) => ({
          ...prevState,
          selectedCountries: selectedCountriesList.map((country: any) => country.name),
        }));
      } else {
        setRPState((prevState) => ({
          ...prevState,
          selectedCountries: [],
        }));
        
      }

      if (!!expiry && !!expiry._seconds && !!expiry._nanoseconds) {
        const formatted: any = moment(
          new Date(expiry._seconds * 1000 + expiry._nanoseconds / 1000000)
        );
        console.log("expiry", rpstate.roomName, expiry, formatted);
        setRPState((prevState) => ({
          ...prevState,
          streamEndDate:formatted,
        }));

      } else {
        setRPState((prevState) => ({
          ...prevState,
          streamEndDate:undefined,
        }));
      }
      if (stream_admin_url && stream_admin_code) {
        setRPState((prevState) => ({
          ...prevState,
          isAddRoom:false,
        }));

      } else {
        setRPState((prevState) => ({
          ...prevState,
          isAddRoom:true,
        }));

      }
    };
    populateData();
  }, [selectedRoomID, rooms, loading]);

  useEffect(() => {
    //Used to check whether stream has expired or not.
    const endDateValue = moment(rpstate.streamEndDate).unix();
    const currentDateValue = moment().unix();
    const expiryValue = endDateValue >= currentDateValue ? true : false;
    setRPState((prevState) => ({
      ...prevState,
      streamExists: expiryValue
    }));


    if (!expiryValue && !expiredSessionFlag) {
      expiredSessionFlag = true;
      setRPState((prevState) => ({
        ...prevState,
        streamExists: true
      }));

    }

    if (rooms.length > 0) {
      const selectedRoom = rooms.find(
        (room: any) => room.id === selectedRoomID
      );

      const { stream_vendor_id } = selectedRoom;
      if (!!stream_vendor_id) {
        setRPState((prevState) => ({
          ...prevState,
          roomNameValid:true,
          errorMsg:''
        }));
      }
    }
  }, [rpstate.roomName]);

  useEffect(() => {
    const countryData = Object.keys(countryCodes).map((country: any) => {
      return { name: country, value: countryCodes[country] };
    });
    setRPState((prevState) => ({
      ...prevState,
      countries:countryData,
    }));
  }, []);

  const signout = async () => {
    await doSignOut();
    navigate("/", { replace: true });
  };

  const upgradeToPaidSubscription = async () => {
    try {
      if (!rpstate.isSubscriptionID && !rooms) {
        return;
      }

      const data = {
        api: api.stripe.upgradeSubscription,
        payLoad: JSON.stringify({
          subscription_id: rpstate.isSubscriptionID,
          roomId: rpstate.isRoomId,
          subscription_status: "active",
        }),
      };
      setLoading(true);
      const result = await backend.save(data, true);
      // messageComponent({
      //   messages: "You have successfully upgraded your trial subscription",
      //   type: "success",
      //   top: 10,
      // });
      if (result) {
        navigate("/setuproom");
      }
    } catch (error) {
      console.log("subscription upgrade failed", error);
    } finally {
      setLoading(false);
    }
  };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
    if (!!date) {
      const dateObj: any = date;
      console.log("dateObj", dateObj, dateObj.format("YYYY/MM/DD"));
      setRPState((prevState) => ({
        ...prevState,
        streamEndDate:dateObj,
      }));
    } else {
      setRPState((prevState) => ({
        ...prevState,
        streamEndDate:undefined,
      }));
    }
  };

  const handleRoomName = (value: string) => {
    if (/[!"#$%&'()*+,-./:;<=>?@^]/g.test(value) === true) {
      setRPState((prevState) => ({
        ...prevState,
        roomNameValid:false,
        roomName:value.replace(/\s/g, ""),
        errorMsg:'Sorry, only letters, numbers, and "_" are allowed.'
      }));
    } else if (!value) {
      setRPState((prevState) => ({
        ...prevState,
        roomNameValid:false,
        roomName:value.replace(/\s/g, ""),
        errorMsg:'Please enter the room name.'
      }));
    } else {
      setRPState((prevState) => ({
        ...prevState,
        roomNameValid:true,
        roomName:value.replace(/\s/g, ""),
        errorMsg:""
      }));
    }
  };

  const handleChangeCountry = (value: string[]) => {
    if (value.length < 6) {
      setRPState((prevState) => ({
        ...prevState,
       selectedCountries:value,
      }));
    }
  };

  const handleautoAllow = () => {
    const newVal = !rpstate.autoAllow;
    handleUpdateAutoAllowRoom(newVal);
    setRPState((prevState) => ({
      ...prevState,
      autoAllow:newVal,
    }));
  };

  const getUnixTimeStamp = (date: any) => {
    const dateUTS = new Date(date);
    const unixTimestamp = Math.floor(dateUTS.getTime());
    console.log(unixTimestamp);
    return unixTimestamp;
  };

  const handleCreateRoom = async () => {
    try {
      const selectedRoom =
      rooms.find((room: any) => room.id === selectedRoomID) || {};
      const selectedCountriesCodeList = rpstate.selectedCountries.map(
        (item: string) => {
          const countryCode = rpstate.countries.find(
            (country: any) => country.name === item
          );
          return countryCode;
        }
      );
      
      const validRoom = rooms.filter((room: any) => {
        return !!room.stream_vendor_id && room.room_name === rpstate.roomName;
      });
      if (validRoom.length === 0) {
        const input = {
          room_id: selectedRoom.id,
          room_code: selectedRoom.room_code,
          room_name: rpstate.roomName,
          // stream_user_name: streamName,
          geo_allowed_countries: selectedCountriesCodeList.map((country:any) => country.value),
          expiry: !!rpstate.streamEndDate ? getUnixTimeStamp(rpstate.streamEndDate) : null,
          auto_allow: rpstate.autoAllow,
        };

        console.log("input :>> ", input);
        const data = {
          api: api.room.createRoom,
          payLoad: input,
        };
        setLoading(true);
        const result = await backend.save(data, true);
        if (result) {
          fetchActiveRooms("AvidRoomCreated");
          messageComponent({
            messages: "Created a session successfully",
            type: "success",
            top: 10,
          });
        }
      } else {
        messageComponent({
          messages: "This Room Name Already Exists",
          type: "error",
          top: 10,
        });
      }
    } catch (err: any) {
      console.log("err :>> ", err);
      messageComponent({ messages: err.errMessage, type: "error", top: 10 });
      setLoading(false);
    }
  };

  const handleUpdateAutoAllowRoom = async (autoAllow: boolean) => {
    try {
      const selectedRoom =
        rooms.find((room: any) => room.id === selectedRoomID) || {};
      const input = {
        room_id: selectedRoom.id,
        auto_allow: autoAllow,
      };
      console.log("input :>> ", input);
      const data = {
        api: api.room.updateAutoAllowRoom,
        payLoad: input,
        urlParam: selectedRoom.id,
      };
      //setLoading(true);
      const result = await backend.save(data, true);
      console.log("########result:::", result);
    } catch (err: any) {
      console.log("err :>> ", err);
      messageComponent({ messages: err.errMessage, type: "error", top: 10 });
      setLoading(false);
    }
  };

  const refreshRoomCode = async () => {
    try {
      setRPState((prevState) => ({
        ...prevState,
        isRefreshModalVisible: false,
      })); 

      const selectedRoom =
        rooms.find((room: any) => room.id === selectedRoomID) || {};
      const input = {
        room_id: selectedRoom.id,
      };

      const data = {
        api: api.room.refreshCode,
        payLoad: input,
      };
      setLoading(true);
      const result = await backend.save(data, true);
      console.log("result :>> ", result);
      if (result) {
        fetchActiveRooms();
        messageComponent({
          messages:
            "Session's verification code has been successfully refreshed",
          type: "success",
          top: 10,
        });
      }
    } catch (err) {
      console.log("err :>> ", err);
      setLoading(false);
    }
  };

  const deleteSession = async () => {
    try {
      setRPState((prevState) => ({
        ...prevState,
        isDeleteModalVisible: false,
        isExpiredSessionModal:false,
      })); 

      setLoading(true);
      const selectedRoom =
        rooms.find((room: any) => room.id === selectedRoomID) || {};
      const data = {
        api: api.room.deleteSession,
        urlParam: selectedRoom.id,
      };
      const result = await backend.remove(data);
      console.log("result :>> ", result);
      if (result) {
        await fetchActiveRooms();
        setLoading(false);
        messageComponent({
          messages: "Deleted Session Successfully",
          type: "success",
          top: 10,
        });
      }
    } catch (err) {
      console.log("err :>> ", err);
    } finally {
      setLoading(false);
    }
  };
  const showRefreshModal = () => {
    setRPState((prevState) => ({
      ...prevState,
      isRefreshModalVisible: true,
    })); 
  };
  const showDeleteModal1 = () => {
    setRPState((prevState) => ({
      ...prevState,
      isDeleteModalVisible: true,
    })); 
  };
  const handleRefreshCancel = () => {
    setRPState((prevState) => ({
      ...prevState,
      isRefreshModalVisible: false,
    }));
  };
  const handleDeleteCancel = () => {
    setRPState((prevState) => ({
      ...prevState,
      isDeleteModalVisible: false,
    })); 
  };

  const handleExpiredSessionModalOk = () => {
    deleteSession();
  };

  const handleExpiredSessionModalCancel = () => {
    setRPState((prevState) => ({
      ...prevState,
      isExpiredSessionModal: false,
    })); 

  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: <div onClick={() => {}}>Account Settings</div>,
        },
        {
          key: "2",
          label: (
            <div
              onClick={() => {
                navigate("/changepassword");
              }}
            >
              Change Password
            </div>
          ),
        },
        {
          key: "3",
          label: (
            <div
              onClick={() => {
                navigate("/manage2FA");
              }}
            >
              Manage 2FA
            </div>
          ),
        },
        {
          key: "1",
          label: <div onClick={() => signout()}>LogOut</div>,
        },
      ]}
    />
  );

  const operations = <AppMenu className="header-menu" />;
  const tab1Content = () => {
    return (
      <div className="tab1-wrapper">
        <div className="create-room-content">
          <Title>{rpstate.isAddRoom ? `Create a Session` : "Session Details"}</Title>
          <div className="create-room-row1">
            <div>
              <label>
                SESSION NAME<span>*</span>
              </label>
              <InputComponent
                // label="room name"
                value={rpstate.roomName}
                onChange={handleRoomName}
                autoComplete="new-password"
                readOnly={!rpstate.isAddRoom}
                maxLength={20}
                className={rpstate.roomNameValid ? "ant-input" : "ant-input-error"}
              />
              <div className="err-msg-wrapper">
                <ErrorComponent errorMsg={rpstate.errorMsg} />
              </div>
            </div>
            {/* <div className="stream-end-date">
              <Label>STREAM END DATE</Label>
              <DatePicker
                onChange={onChange}
                value={streamEndDate}
                format={"YYYY/MM/DD"}
                inputReadOnly={true}
                disabled={!isAddRoom}
                disabledDate={disabledDate}
              />
            </div> */}
            <div className="select-waiting-wrapper-checkbox">
            <Popconfirm
                title={
                  !rpstate.autoAllow
                    ? "Do you want to disable waiting room ?"
                    : "Do you want to enable waiting room ?"
                }
                onConfirm={handleautoAllow}
                placement={"topLeft"}
              >
              <Checkbox checked={!rpstate.autoAllow}>
                </Checkbox>
                <label className="inline-waiting-room">
                WAITING ROOM
              </label>
            </Popconfirm>
            <div>
              <span className="support-text">Only guests admitted by the editor can join the room.</span>
            </div>
            </div>
          </div>
           <div className="create-room-row1"> 
           <div className="select-countries-wrapper">
            <Label className="inline">
              ALLOWED COUNTRIES
              <span className="support-text">max. 5 countries</span>
            </Label>
            <Select
              mode="multiple"
              allowClear
              showArrow
              style={{ width: "48%" }}
              placeholder="Select allowed countries"
              onChange={handleChangeCountry}
              value={rpstate.selectedCountries}
              disabled={!rpstate.isAddRoom}
            >
              {rpstate.countries.map((country: any) => (
                <Option value={country.name} key={country.value}>
                  {country.name}
                </Option>
              ))}
            </Select>
            <div>
              <span className="support-text editor-guest-credentials">
                USA is allowed by default. 
              </span>
            </div>
          </div> 

           </div>
        </div>
        {!rpstate.isAddRoom && (
          <div className="host-details-wrapper">
            {!!rpstate.streamExists ? (
              <>
                <div>
                  <div className="title-wrapper">
                    <Title>Editor Login Credentials Only</Title>
                  </div>
                </div>
                <div className="host-details-header avid-editor-header-btn host-details-content">
                  <Button className="refresh-btn" onClick={showRefreshModal}>
                    <SyncOutlined  />
                    Refresh Session Codes
                  </Button>
                  <Button
                    className="refresh-btn"
                    onClick={() => {
                      handleSRTStream(rpstate.SRTstream);
                    }}
                  >
                    <img
                      src={AVID}
                      alt="AVID"
                      style={{ margin: "0 5px" }}
                      className="tab-icon"
                    />
                    Configure AVID SRT
                  </Button>
                </div>
                <Modal
                  title="Refresh Verification Code"
                  visible={rpstate.isRefreshModalVisible}
                  onOk={refreshRoomCode}
                  onCancel={handleRefreshCancel}
                >
                  <span>Do you want to refresh the session access code ?</span>
                </Modal>
                <div className="host-details-content">
                  <div>
                    <InputComponent
                      label="Editor's Link"
                      value={rpstate.streamAdminUrl}
                      readOnly
                      copyToClipBoard={false}
                    />
                    <span className="support-text editor-guest-credentials">
                      Please do not share this link or code. Editor link only.
                    </span>
                  </div>
                  <InputComponent
                    label="Session Code"
                    value={rpstate.streamAdminCode}
                    readOnly
                    copyToClipBoard={false}
                  />
                  <div>
                  <InputComponent
                    label="srt passphrase"
                    value={rpstate.SRT_passphrase}
                    readOnly
                    copyToClipBoard={false}
                  />
                  <span className="support-text editor-guest-credentials">
                    Please use passphrase while streaming
                  </span>
                  </div>
                  <div style={{visibility:'hidden'}}> Hidden div </div>
                  {/* <InputComponent
                    label="stream name"
                    value={streamName}
                    readOnly
                    copyToClipBoard={false}
                  />
                  <InputComponent
                    label="stream token"
                    value={publishToken}
                    readOnly
                    copyToClipBoard={false}
                  /> */}
                  {/* <InputComponent
                    label="SRT Stream ID"
                    readOnly
                    copyToClipBoard={false}
                  /> */}
                </div>
              </>
            ) : null}
          </div>
        )}
        <div className="actions-wrapper">
          {rpstate.isAddRoom ? (
            <Button
              type="primary"
              className="ppl-btn"
              disabled={!rpstate.roomName || !rpstate.roomNameValid}
              onClick={handleCreateRoom}
            >
              <PlayCircleFilled style={{ fontSize: "20px" }}  />
              Create Session
            </Button>
          ) : (
            <>
              {/* <Button
                    type='primary'
                    className="ppl-btn" onClick={updateRoom}>
                    <PlayCircleFilled style={{ fontSize: '20px' }} />
                    Update room
                  </Button> */}
              <Button className="ppl-btn delete" onClick={showDeleteModal1}>
                <PlayCircleFilled style={{ fontSize: "20px" }}  />
                Delete Session
              </Button>
              <Modal
                title="Delete Session"
                visible={rpstate.isDeleteModalVisible}
                onOk={deleteSession}
                onCancel={handleDeleteCancel}
              >
                <span>Do you want to delete the Session ?</span>
              </Modal>
            </>
          )}
        </div>
      </div>
    );
  };
  return (
    <Layout className="rooms-layout">
      <Content>
        <Tabs centered tabBarExtraContent={operations} defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <img src={Tab1Image} alt="logo" className="tab-icon" />
              </span>
            }
            key="1"
          >
            {!rpstate.streamExists ? (
              <div className="banner-container">
                <BadgeComponent
                  text={
                    "This session is expired and is no longer valid, please delete the session."
                  }
                />
              </div>
            ) : null}
            {rpstate.isfreeRoom &&
              ((
                <div className="banner-container">
                  <BadgeComponent
                    text={`This is a free session and will end on ${rpstate.dayTrialEndDate}. Upgrade by clicking here`}
                    button={
                      <Button
                        className="upgrade-btn"
                        onClick={() => {
                          setRPState((prevState) => ({
                            ...prevState,
                            upgradeModalVisible: true,
                          }));

                        }}
                      >
                        Upgrade
                      </Button>
                    }
                  />
                </div>
              ) ??
                " ")}
            {!!rpstate.subscriptionEndDate && (
              <div className="banner-container">
                <BadgeComponent
                  text={`Your paid subscription will be ending by ${moment
                    .unix(rpstate.subscriptionEndDate)
                    .format("LL")}.`}
                  // button={
                  //   <Button
                  //     className="upgrade-btn"
                  //     onClick={() => {
                  //       setUpgradeModalVisible(true);
                  //     }}
                  //   >
                  //     Upgrade
                  //   </Button>
                  // }
                />
              </div>
            )}
            <UpgradeModal
              open={rpstate.upgradeModalVisible}
              onCancel={() =>
                setRPState((prevState) => ({
                  ...prevState,
                  upgradeModalVisible: false,
                }))
              }
              destroyOnClose
              className="upgrade-modal"
              bodyStyle={{ borderRadius: "10px", height: "480px" }}
              width={828}
              footer={null}
              closable={false}
              loading={loading}
              logo={logo}
            />
            <Modal
              title={
                <div style={{ fontSize: "1.2em", fontWeight: "bold" }}>Session Expired</div>
              }
              open={rpstate.isExpiredSessionModal}
              onOk={handleExpiredSessionModalOk}
              onCancel={handleExpiredSessionModalCancel}
            >
              <h2>
                This session has expired, and the links are no longer valid.
                Please delete the session by selecting <strong>'OK'</strong>.
              </h2>
            </Modal>
            {tab1Content()}
          </TabPane>
          {/* <TabPane tab={
                        <span>
                            <img src={Tab2Image} alt="logo" className="tab-icon" />
                        </span>
                    } disabled key="2">
                        Content of tab 2
                    </TabPane>
                    <TabPane tab={
                        <span>
                            <img src={Tab3Image} alt="logo" className="tab-icon" />
                        </span>
                    } disabled key="3">
                        Content of tab 3
                    </TabPane> */}
        </Tabs>
      </Content>
    </Layout>
  );
};

export default AvidCreateRoom;

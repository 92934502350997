import { analytics } from "./utils";

export const signUpSuccess = async () => {
  analytics.logEvent(analytics.analyticsData, "signup_success");
  return "success";
};

export const signInSuccess = async () => {
  analytics.logEvent(analytics.analyticsData, "signin_success");
  return "success";
};

import React from "react";
import { Dimmer, Loader, SemanticSIZES } from "semantic-ui-react";
import "./progressLoader.css";

interface ILoaderProps {
  loading: boolean
  size?: SemanticSIZES
}

const ProgressLoader = (props: ILoaderProps) => {
  const render = () => {
    if (!props.loading) {
      return;
    }
    
    let loaderSize: SemanticSIZES = 'massive'
    if(props.size) {
      loaderSize = props.size
    }
    
    return (
      <Dimmer active inverted>
        <Loader size={loaderSize}/>
      </Dimmer>
    );
  };
  return <div>{render()}</div>;
};

export default ProgressLoader;

import { useState, useCallback, useEffect } from 'react';
import { ZoomClient } from '../types/index-types';

import None_thumb from "../../../pages/Viewer/Images/None_thumb.svg";
import Abstract from "../../../pages/Viewer/Images/Abstract.jpg";
import Bookshelf from "../../../pages/Viewer/Images/Bookshelf.jpg";
import CoffeeShop from "../../../pages/Viewer/Images/CoffeeShop.jpg";
import SanFrancisco from "../../../pages/Viewer/Images/SanFrancisco.jpg";
import Blur_thumb from "../../../pages/Viewer/Images/Blur_thumb.svg";
import Abstract_thumb from "../../../pages/Viewer/Images/Abstract_thumb.jpg";
import Bookshelf_thumb from "../../../pages/Viewer/Images/Bookshelf_thumb.jpg";
import CoffeeShop_thumb from "../../../pages/Viewer/Images/CoffeeShop_thumb.jpg";
import SanFrancisco_thumb from "../../../pages/Viewer/Images/SanFrancisco_thumb.jpg";

const backgrounds = [
  { value: "none", image: "none", thumbImage: None_thumb },
  { value: "blur", image: "blur", thumbImage: Blur_thumb },
  { value: "abstract", image: Abstract, thumbImage: Abstract_thumb },
  { value: "bookshelf", image: Bookshelf, thumbImage: Bookshelf_thumb },
  { value: "coffeeshop", image: CoffeeShop, thumbImage: CoffeeShop_thumb },
  { value: "sanfrancisco", image: SanFrancisco, thumbImage: SanFrancisco_thumb },
];

export function useVideo(zmClient: ZoomClient, mediaStream: any,videoPlayerListRef?:any) {
  const [isVideoOn, setIsVideoOn] = useState(false);
  const excludedElementTypes = ['input', 'textarea', 'select'];

  const onCamClick = async () => {
    const activeBGValue = sessionStorage.getItem("activeBG")
    const currentUserInfo = zmClient.getCurrentUserInfo();
    const isVideoEnabled = !!currentUserInfo.bVideoOn;
    const screenShareEnabled = !!currentUserInfo.sharerOn;
    if (isVideoEnabled) {
        await mediaStream?.stopVideo();
        if(screenShareEnabled){
          mediaStream.stopShareScreen()
        } 
        // let selector = `#video-player-${zmClient.getCurrentUserInfo().userId}`;
        // let elem: any = videoPlayerListRef.current[`${zmClient.getCurrentUserInfo().userId}`]
        // console.log('elem', elem)
        
        // const elements = 
        // await mediaStream.detachVideo(zmClient.getCurrentUserInfo().userId);
        // if (Array.isArray(elements)) {
        //     elements.forEach((e: any) => e.remove());
        // } else {
        //     elements?.remove();
        // }

        setIsVideoOn(false);
      } else {
        let videoOptions: any = {
          captureHeight: 160,
          captureWidth: 192
        }
        if(!!activeBGValue) {
          const bgImg = backgrounds.filter(
            (item: any) => item.value === activeBGValue
          )[0];
          if(bgImg?.image){
            videoOptions.virtualBackground = {
              imageUrl: bgImg?.image === "none" ? undefined : bgImg?.image
            }
          }  
        }
        await mediaStream?.startVideo(videoOptions);
        setIsVideoOn(true);
        
        // let userVideo = await mediaStream.attachVideo(zmClient.getCurrentUserInfo().userId, 1, videoPlayerListRef.current[`${zmClient.getCurrentUserInfo().userId}`])
        // let selector = `#video-player-${zmClient.getCurrentUserInfo().userId}`;
        // let elem: any = document.querySelector(selector)
        // if(!!elem && !elem.querySelector('video-player') && userVideo){
        //   elem?.appendChild(userVideo)
        // }
      }
  }

  const onCurrentVideoChange = useCallback((payload: any) => {
    const { state } = payload;
    if (state === 'Started') {
        setIsVideoOn(true)
      } else if (state === 'Stopped') {
        setIsVideoOn(false)
      } else if (state === 'Failed') {
        console.log("Video Capturing change failed")
      }
  }, []);

  useEffect(() => {
    zmClient.on('video-capturing-change', onCurrentVideoChange);
    return () => {
      zmClient.off('video-capturing-change', onCurrentVideoChange);
    };
  }, [zmClient, onCurrentVideoChange]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const target = event.target as HTMLElement;
      if (excludedElementTypes.includes(target.tagName.toLowerCase())) {
        return;
      }
      if (event.key === '\\') {
        onCamClick();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onCamClick]);

  return {
    isVideoOn,
    onCamClick
  };
}

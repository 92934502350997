import { useEffect } from 'react';
import logo from '../../assets/images/logo-dark.png';
import { Result } from 'antd';
import { AppMenu } from '../../components/AppMenu';
import {
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { firestore } from "./../../firebase/utils";
const WaitingRoom = (props: any) => {
  const { handleRejected } = props;
  // useEffect(() => {
  //   window.addEventListener('popstate', handleRejected);
  //   return () => {
  //     window.removeEventListener('popstate', handleRejected);
  //   };
  // }, []);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {/* <img src={logo} alt='logo' style={{ margin: '20px' }} /> */}
        </div>
        <div style={{ margin: '20px' }}>
          <AppMenu 
          handleRejected={handleRejected}
          is404Page={true}
          />
        </div>
      </div>
      <Result
        style={{ margin: '15% 0' }}
        icon={<img alt='logo' src={logo} />}
        title='Welcome to PacPost.io'
        extra={<h1>The host will accept your request in a moment, Please wait...</h1>}
      />
    </>
  );
};

export default WaitingRoom;


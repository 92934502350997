import { Avatar, Dropdown, Menu } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { doSignOut } from "../../firebase/auth";
import { getInitials } from "../../utils/helpers";
import { get } from "../../utils/helpers";
import ZoomVideo from "@zoom/videosdk";
import useHudsStatsStore from "../../store/slices/viewer/hudsStatsStore";

export const AppMenu = ({ className, isViewer = false, is404Page = false, url = false, handelVideoConfEnd = null, handleRejected = null,clearCancelNoise= null, editorDirectLogin=false, setHudsStateTwilio=null,zoomClient=null }: any, ) => {
  const [isSSO, setIsSSO] = useState(false);
  const navigate = useNavigate();
  const location: any = useLocation();
  const pathName = location.pathname;
  const user: any = useContext(AuthContext);
  const isLinkLogin = get(user,'linkLogin',false);
  const displayName = !isLinkLogin ? user?.displayName : `${get(user,'fn','')} ${get(user,'ln','')}`;
  const initials = getInitials(displayName);
  const adminPaths = ['/setuproom','/home','/profile','/changepassword','/resetpassword']
  const { setHudsState } = useHudsStatsStore();
  
  const signout = async () => {
    
    if(!!handelVideoConfEnd) {
      await handelVideoConfEnd()
    }
    
    if(zoomClient){
      ZoomVideo.destroyClient();
    }
    if(!!handleRejected){
      await handleRejected()
    }
    if(!!clearCancelNoise){
      clearCancelNoise()
    }
    await doSignOut();
    localStorage.removeItem('url')
    navigate(
      isLinkLogin
        ? "/viewerLogoutPage"
        : "/",
      { replace: true }
    );
  };

  useEffect(() => {
    if (!user) {
      navigate('/');
    } else if (!!user && isLinkLogin && adminPaths.includes(pathName)) {
      navigate('/404');
    }
  }, [user]);

  const accountSettinsPage = () => {
    navigate("/profile");
  };

  const changePassword = () => {
    navigate("/changepassword");
  };

  const needSupport = () => (
      <a target="_blank" href="mailto:support@pacpostio.zohodesk.com">
        Need Support?
      </a>
  )

  const manage2FA = () => {
    navigate("/manage2FA");
  };

  const invoice = ()=>{
    navigate("/invoice");
  }

  const openHudstats = () =>{
    if(setHudsStateTwilio){
      setHudsStateTwilio((prevState:any)=>({
        ...prevState,
        openHudStatsPanel:true
      }))
    }else{
      setHudsState({openHudStatsPanel:true})
    }
  }

  useEffect(() => {
    user?.providerData[0]?.providerId !== "password" && setIsSSO(true);
  }, [user]);
  
  const menuItems = (isViewer:boolean,isSSO:boolean,is404Page:boolean): ItemType[] => {
    const info: any[] = [
      { handler: accountSettinsPage, label: 'Account Settings', hide: isLinkLogin || editorDirectLogin||isViewer ||is404Page},
      { handler: changePassword, label: 'Change Password', hide: (isSSO || isLinkLogin || editorDirectLogin||isViewer|| is404Page)},
      { handler: manage2FA, label: 'Manage 2FA', hide: (isSSO || isLinkLogin) || editorDirectLogin||isViewer ||is404Page},
      { handler: invoice, label: 'Billing', hide: (isSSO || isLinkLogin) || editorDirectLogin||isViewer ||is404Page},
      { handler: openHudstats, label: 'Media Stats',hide: !(isLinkLogin || editorDirectLogin)},
      { handler: needSupport, label: 'Need Support',hide:(is404Page), isLink:true},
      { handler: signout, label: 'LogOut', hide: false}
    ]
    return info
      .filter(item => !item.hide)
      .map((item,i) => ({
      key: i,
      label: (item.isLink? item.handler():<div onClick={(e) => item.handler(e)}>{item.label}</div>)
    }))
  }
  const menu = (<Menu items={menuItems(isViewer,isSSO,is404Page)}/>)

  return !!user ? (
    <div className={className}>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        arrow
        trigger={["click"]}
        className={className}
      >
        <Avatar
          className="user-avatar"
          style={{ backgroundColor: "#f56a00", verticalAlign: "middle" }}
          size="large"
          gap={4}
        >
          {initials}
        </Avatar>
      </Dropdown>
    </div>
  ) : (
    <></>
  );
};
import { useCallback, useState, useEffect } from "react";

import useEventCallback from "./useEventCallback";
import { SetValue, parseJSON, decrypt, encrypt } from "./storageHelpers";

export default function useSessionStorage<T>(
  key: string,
  initialValue: T,
  _isEncrypted = true
): [T, SetValue<T>, () => void] {
  // Store the initial value of encrypt and never change it
  const [isEncrypted] = useState(_isEncrypted);

  const readValue = useCallback((): T => {
    try {
      let item = window.sessionStorage.getItem(key);
      item = isEncrypted && item ? decrypt(key, item) : item;
      return item ? (parseJSON(item) as T) : initialValue;
    } catch (error) {
      console.warn(`Error reading sessionStorage key “${key}”:`, error);
      return initialValue;
    }
  }, [initialValue, isEncrypted, key]);

  const [storedValue, setStoredValue] = useState<T>(readValue);

  const setValue: SetValue<T> = useEventCallback((value) => {
    try {
      const newValue = value instanceof Function ? value(storedValue) : value;

      const valueToStore =
        isEncrypted && newValue
          ? encrypt(key, JSON.stringify(newValue))
          : JSON.stringify(newValue);
      window.sessionStorage.setItem(key, valueToStore);

      setStoredValue(newValue);
    } catch (error) {
      console.warn(`Error setting sessionStorage key “${key}”:`, error);
    }
  });

  const clear = useEventCallback(() => {
    try {
      window.sessionStorage.removeItem(key);
      setStoredValue(initialValue);
    } catch (error) {
      console.warn(`Error clearing sessionStorage key “${key}”:`, error);
    }
  });

  useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [storedValue, setValue, clear];
}

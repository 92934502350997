import React, { useState, useEffect } from "react";
import { Button, Form, notification, Tabs, Switch, Popconfirm } from "antd";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import PageLayout, { PageContent } from "../../../layouts/PageLayout";
import logo from "../../../assets/images/sidebar-logo.png";
import qrhandscreen from "../../../assets/images/qrhandscreen.png";
import Title from "../../Title";
import InputComponent from "../../InputComponent";
import { AppMenu } from "../../AppMenu";
import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";
import Loader from "../../LoaderHOC";
import { doSignOut } from "../../../firebase/auth";

const TwoFA = (props: any) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({} as any);
  const [gauth, setGauth] = useState(false);
  const [status, setStatus] = useState(false);
  const [twoFAStatus, setTwoFAStatus] = useState("inactive");
  const [otpVerified, setOtpVerified] = useState(false);
  const [passcodeValid, setPasscodeValid] = useState(false);
  const [passcode, setPasscode] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [qrcodeImg, setQRCodeimg] = useState("");
  const [googleAuthSecret, setGoogleAuthSecret] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const initGoogle2FA = async () => {
    try {
      const data = {
        api: api.otp.googleAuth2FA,
      };
      setIsLoading(true);
      const res = await backend.fetch(data, true);
      if (!!res) {
        setTwoFAStatus("pending");
        setQRCodeimg(res.qrcodeImg);
        setGoogleAuthSecret(res.googleAuthSecret);
      }
      setIsLoading(false);
      setGauth(true);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const disableGoogle2FA = async () => {
    try {
      const data = {
        api: api.otp.disableGoogleAuth2FA,
      };
      setIsLoading(true);
      const response = await backend.save(data);
      if (response) {
        setGauth(false);
        setTwoFAStatus("inactive");
        notification.success({
          message: "Disabled google 2FA Authentication",
          placement: "topRight",
          duration: 3,
        });
      }
    } catch (err) {
      setIsLoading(false);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const getUserProfileData = async () => {
    try {
      const data = {
        api: api.user.get,
      };
      setIsLoading(true);
      const profiledata = await backend.fetch(data, true);
      if (!!profiledata) {
        const { two_fa_enabled, google_auth_secret, qr_code_img } = profiledata;
        setGauth(two_fa_enabled);
        const twofastatus = two_fa_enabled ? "active" : "inactive";
        setTwoFAStatus(twofastatus);
        setQRCodeimg(qr_code_img);
        setGoogleAuthSecret(google_auth_secret);
      }
      setUserData(profiledata);
    } catch (error) {
      console.log(error, "::::::::::::error");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getUserProfileData();
  }, [status]);

  const signout = async () => {
    await doSignOut();
    navigate("/");
  };

  const validateGoogleOtp = async () => {
    try {
      setIsLoading(true);
      const input = {
        userId: userData?.uid,
        token: passcode,
        authSecret: googleAuthSecret,
      };
      const data = {
        api: api.otp.validateGoogleAuth2FA,
        payLoad: JSON.stringify(input),
      };
      const res = await backend.save(data, true);
      if (res) {
        setPasscodeValid(false);
        setStatus(true);
        setTwoFAStatus("active");
        notification.success({
          message: "Success: Passcode Verified",
          placement: "topRight",
          duration: 3,
        });
        setTimeout(async () => {
          await signout();
        }, 3000);
      }
    } catch (err: any) {
      if (err) {
        setPasscodeValid(false);
        if (err.errCode === 40105) {
          notification.error({
            message: err.errMessage,
            placement: "topRight",
            duration: 3,
          });
          setTimeout(async () => {
            await signout();
          }, 3000);
        } else {
          notification.error({
            message: err.errMessage,
            placement: "topRight",
            duration: 3,
          });
        }
      }
      console.log("error here:::", err);
    } finally {
      setIsLoading(false);
    }
  };

  const verifiedMessage = () => {
    return <div className="twoFA-msg">Passcode Verified</div>;
  };

  const tab1 = () => {
    return (
      <div className="twoFA-disabled">
        <div className="twoFAmid"></div>
        <div className="twoFAbottom">
            <Button
              className="twoFA-cancel-btn"
              onClick={() => navigate("/home")}
            >
              Cancel
            </Button>
        </div>
      </div>
    );
  };

  const tab2 = () => {
    return (
      <div className="twoFA-enabled">
        <div className="twoFAmid">
          <span className="switch-title">
            SCAN THE QR CODE AND ENTER THE PASSCODE TO VERIFY
          </span>
          <div className="twoFAmid-center">
            <div className="twoFAmid-center-left">
              <div className="qrcode">
                <img src={qrcodeImg} alt="QRcode" />
              </div>
              <div className="passcode-wrap">
                <Form.Item
                  name="passcode"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!value) {
                          setPasscodeValid(false);
                          return Promise.reject("Please enter the passcode");
                        } else if (value.length < 6) {
                          setPasscodeValid(false);
                          return Promise.reject(
                            "Please enter a valid passcode"
                          );
                        } else {
                          setPasscodeValid(true);
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <InputComponent
                    label="passcode"
                    type="password"
                    value={passcode}
                    onChange={setPasscode}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="twoFAmid-center-right">
              <img
                alt="QR-Img"
                src={qrhandscreen}
                height="271px"
                width="222px"
              />
            </div>
          </div>
          <>
            {otpVerified ? (
              verifiedMessage()
            ) : (
              <div className="twoFA-msg-blank"></div>
            )}
          </>
        </div>
        {!status || gauth ? (
          <div className="twoFAbottom">
            <Button
              className="twoFA-verify-btn"
              onClick={validateGoogleOtp}
              disabled={!passcode || passcodeValid === false}
            >
              {otpVerified ? "Save" : "Verify Passcode"}
            </Button>
              <Button
                className="twoFA-cancel-btn"
                onClick={() => {
                  setOtpVerified(false);
                  navigate("/home");
                }}
              >
                Cancel
              </Button>
          </div>
        ) : (
          <div className="twoFAbottom">
            <Button
              className="twoFA-cancel-btn"
              onClick={() => {
                setOtpVerified(false);
                navigate("/home");
              }}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    );
  };

  const changeGauth = () => {
    if (modalVisible === true) {
      setModalVisible(false);
    }
    setGauth(prevState => !prevState);
    if (!gauth) {
      initGoogle2FA();
    } else if (twoFAStatus === "active" && gauth) {
      disableGoogle2FA();
    } else {
      setTwoFAStatus("inactive");
    }
  };

  const operations = (
    <AppMenu className="header-menu"/>
  );

  const confirmtext = "Do you want to turn on google authentication?";
  const rejecttext = "Do you want to turn off google authentication?";

  return (
    <Loader loading={isLoading}>
      <PageLayout>
        <PageContent className="twoFA-left-content">
          <img src={logo} alt="logo" className="logo" />
        </PageContent>
        <PageContent className="twoFA-right-content">
          <div className="twoFA-layout">
            <Tabs centered tabBarExtraContent={operations} defaultActiveKey="3">
              <div className="twoFA-container">
                <Form name="twoFA-form">
                  <div className="change-password-form">
                    <Title level={3}>Two factor authentication </Title>
                    <div className="twoFA-top">
                      <span className="switch-title">TYPE</span>
                      <div className="switch-wrapper">
                        <Popconfirm
                          title={gauth ? rejecttext : confirmtext}
                          onConfirm={changeGauth}
                          //onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                          placement="bottomLeft"
                        >
                          <Switch
                            checked={gauth ? true : false}
                            className="twoFA-switch"
                          />
                        </Popconfirm>
                        <span>Google authentication</span>
                      </div>
                    </div>
                    <div>
                      <>
                        {twoFAStatus === "pending" && gauth ? tab2() : tab1()}
                      </>
                    </div>
                  </div>
                </Form>
              </div>
            </Tabs>
          </div>
        </PageContent>
      </PageLayout>
    </Loader>
  );
};
export default TwoFA;

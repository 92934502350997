/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from "react";
import { AuthContext } from "../../../context/auth";
import chatImg from "../../../pages/Viewer/Images/chat.svg";
import closeImg from "../../../pages/Viewer/Images/close.svg";
import guestWhite from "../../../pages/Viewer/Images/guest_whitebg.svg";
import { useRedIconSlice, useViewerSlice} from "../../../store/slices/viewer/viewerSlice";
import { AppMenu } from "../../AppMenu";
import { get } from "../../../utils/helpers";

const RightNavControls = (props:any) => {
    const userLoggedIn: any = useContext(AuthContext);
    const isGuestViewer = get(userLoggedIn,'guestViewer',false);
    const { handelVideoConfEnd, editorDirectLogin } = props;
    
    const { rightActivePanel, openChatPanel, openGuestPanel, closeRightPanel } =
        useViewerSlice();
    const {redIcon} = useRedIconSlice();
    const isChatOpen = rightActivePanel === "chat";
    const isGuestOpen = rightActivePanel === "guest";
    return (
      <ul>
        <li>
          <a
            id="chatArea"
            className={isChatOpen ? "active" : ""}
            onClick={isChatOpen ? closeRightPanel : openChatPanel}
          >
            <img
              className="tc_image"
              src={isChatOpen ? closeImg : chatImg}
              alt=""
            />
          </a>
        </li>
        {!isGuestViewer && (
          <li>
            <a
              id="guestInviteArea"
              className={isGuestOpen ? "active" : ""}
              onClick={isGuestOpen ? closeRightPanel : openGuestPanel}
            >
              {redIcon &&  <div className={'pulsating-icon'}></div>}
              {!redIcon && 
              <img
                className="tc_image"
                src={isGuestOpen ? closeImg : guestWhite}
                alt=""
              
              />
              }
            </a>
          </li>
        )}
        
        <li>
          <div className="zoom-header-menu">
            {!!userLoggedIn ? (
              <AppMenu
                className="header-menu"
                zoomClient={true}
                isViewer={true}
                handelVideoConfEnd={handelVideoConfEnd}
                editorDirectLogin={editorDirectLogin}
              />
            ) : (
              <></>
            )}
          </div>
        </li>
      </ul>
    );
};

export default RightNavControls;
import { useContext, useRef } from "react";
import useDbTrigger from "../../hooks/useDbtrigger";
import { AuthContext } from "../../context/auth";
import { get } from "../../utils/helpers";
import { ModalStaticFunctions } from "antd/lib/modal/confirm";

interface WaitingRoomLoaderProps {
  user_id: string;
  room_id: string;
  identity: string;
  approvedRef: React.MutableRefObject<boolean>;
  approved: boolean;
  setApproved: React.Dispatch<React.SetStateAction<boolean>>;
  handleRejected: () => Promise<void>;
  modal: Omit<ModalStaticFunctions, "warn">;
  loadingRef: React.MutableRefObject<boolean>;
  handleRejectCall: () => void;
}

const WaitingRoomLoader = (props: WaitingRoomLoaderProps) => {
  const {
    user_id,
    room_id,
    identity,
    approvedRef,
    approved,
    setApproved,
    handleRejected,
    modal,
    loadingRef,
    handleRejectCall,
  } = props;
  const user: any = useContext(AuthContext);
  const waitingRoomDocId = useRef("");
  const waitingRoomNotifications = useDbTrigger("waiting_room", true);
  const waitingRoomTriggerData = waitingRoomNotifications?.doc?.data();
  if (
    waitingRoomTriggerData?.user_id === user?.uid &&
    waitingRoomTriggerData?.host_id === user_id &&
    waitingRoomTriggerData?.room_id === room_id &&
    waitingRoomTriggerData?.identity === identity
  ) {
    waitingRoomDocId.current = waitingRoomNotifications?.doc?.id;
    const approvedStatus = waitingRoomTriggerData?.status === "approved";
    approvedRef.current = approvedStatus;
    if (approved !== approvedStatus) {
      setApproved(approvedStatus);
    }

    if (
      waitingRoomTriggerData?.status === "rejected" ||
      waitingRoomNotifications?.type === "removed"
    ) {
      let message_flag = false;
      if (get(waitingRoomTriggerData, "message", "")) {
        modal.info({
          onOk: handleRejected,
          title:
            "You will not be allowed to room, as it has already reached max limit.",
        });
        message_flag = true;
      }
      const isRefreshed = Boolean(sessionStorage.getItem("isRefreshed"));
      if (!isRefreshed) {
        loadingRef.current = true;
      }
      if (!message_flag) {
        setTimeout(handleRejectCall, 5000);
      }
    }
  }
  return (
    <div
      style={{
        width:'100vh',
        height: "0.5vh",
        backgroundColor:approved ? '#0000' : '#ffff',
        color:approved ? '#ffff' : '#0000'
      }}
    >
        waiting_room
    </div>
  );
};
export default WaitingRoomLoader;

import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Wallet from "../../components/Account/Wallet";
import "./styles.scss";

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env;
let stripePromise: any;
if (REACT_APP_STRIPE_PUBLIC_KEY) {
  stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);
}

const PaymentMethods = () => {
  return (
    <Elements stripe={stripePromise}>
      <Wallet />
    </Elements>
  );
};
export default PaymentMethods;

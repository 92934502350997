import { useRef } from 'react';
import { AudioMutedOutlined } from '@ant-design/icons';
import type { NetworkQuality } from '@zoom/videosdk';
// import { IconFont } from '../../../component/icon-font';
import classNames from 'classnames';
import './avatar.scss';
import { ParticipantType } from '../../pages/ZoomViewer/types/index-types';
// import { useHover } from '../../../hooks';
// import AvatarMore from './avatar-more';
interface AvatarProps {
  participant: ParticipantType;
  style?: { [key: string]: string };
  isActive: boolean;
  className?: string;
  networkQuality?: NetworkQuality;
  videoOn:boolean
}
const networkQualityIcons = ['bad', 'bad', 'normal', 'good', 'good', 'good'];

const ZoomAvatar = (props: AvatarProps) => {
  const { participant, style, isActive, className, networkQuality,videoOn } = props;
  const { displayName, audio, muted,  userId, isInFailover } = participant;
  const avatarRef = useRef(null);
  // const isHover = useHover(avatarRef);

  return (
    <div
      className={classNames('zoom-avatar', { 'avatar-active': isActive }, className)}
      style={{ ...style, background: videoOn ? 'transparent' : 'rgb(26,26,26)', display: videoOn ? 'none' : 'block' }}
      ref={avatarRef}
    >
      {/* {(videoOn || (audio === 'computer' && muted) || isInFailover) && ( */}
        {/* <div className="corner-name"> */}
          {/* {audio === 'computer' && muted && <AudioMutedOutlined style={{ color: '#f00' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />} */}
          {/* {bVideoOn && networkQuality !== undefined && (
            <IconFont
              type={`icon-network-${
                networkQualityIcons[
                  Math.min(networkQuality?.downlink ?? Number.MAX_VALUE, networkQuality?.uplink ?? Number.MAX_VALUE)
                ]
              }`}
            />
          )}
          {isInFailover && (
            <IconFont type="icon-reconnect" style={{ color: '#FF9209', animation: 'loading 3s linear infinite' }} />
          )} */}
          {/* {videoOn && <span>{displayName}</span>} */}
        {/* </div> */}
      {/* )} */}
      {!videoOn && <p className="center-name">{displayName}</p>}
      {/* {!isInFailover && <AvatarMore userId={userId} isHover={isHover} />} */}
    </div>
  );
};

export default ZoomAvatar;

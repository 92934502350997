import { useContext, useState } from "react";
import noiseCancellation from "../../../../pages/Viewer/Images/noise-cancellation.svg";
import noiseCancellationOn from "../../../../pages/Viewer/Images/noise-cancellation-on.svg";
import ZoomMediaContext from "../../../../pages/ZoomViewer/context/media-context";
import { useWhiteboardingStore } from "../../../../store";

const NoiseCancellation = () => {
  const [cancelNoise, setCancelNoise] = useState(false);
  const { mediaStream } = useContext(ZoomMediaContext);
  const { resetAllTools } = useWhiteboardingStore();

  const handleCancelNoise = () => {
    if (!cancelNoise) {
      mediaStream?.enableBackgroundNoiseSuppression(true);
    } else {
      mediaStream?.enableBackgroundNoiseSuppression(false);
    }
    setCancelNoise(!cancelNoise);
    resetAllTools();
  };

  return (
    <div className="top-menu-button-item" onClick={handleCancelNoise}>
      {/* Noise Cancellation */}
      <div
        className={cancelNoise ? "noise-cancellation-on" : "noise-cancellation"}
      >
        <img
          src={cancelNoise ? noiseCancellationOn : noiseCancellation}
          alt=""
        />
      </div>
      <span>Noise Cancellation</span>
    </div>
  );
};
export default NoiseCancellation;

import React, { PropsWithChildren } from 'react';
import { Modal, ModalProps, Button } from 'semantic-ui-react';
import styles from './ConfirmationDialog.module.css';

type ConfirmationDialogProps = PropsWithChildren<Pick<ModalProps, 'open' | 'onClose'> & {
  title: string;
  message?: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
}>;

const ConfirmationDialog = ({ 
  cancelText = "No",
  children,
  confirmText = "Yes",
  message,
  onCancel,
  onClose,
  onConfirm,
  open,
  title,
}: ConfirmationDialogProps) => {

  return (
    <Modal
      open={open} onClose={onClose}
      className={styles.confirmationDialog}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        {message || children}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>{cancelText}</Button>
        <Button onClick={onConfirm}>{confirmText}</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ConfirmationDialog;

import create from 'zustand';
// import { Room } from "twilio-video";

interface guestStore {
  room_id: string;
  setRoomid: (room_id: string) => void;


  // room: Room; 
  // setRoom: (room: Room) => void;

  guestList: any[];
  setGuestList: (guestList: any[]) => void;

  // viewerData: {accessLink: string; accessCode: string;};
  // setViewerData: (data: { accessLink: string; accessCode: string }) => void;

}

export const useGuestStore = create<guestStore>((set) => ({
  room_id: "",
  setRoomid: (room_id) => set({ room_id: room_id }),

  // room: {} as Room,
  // setRoom: (room) => set({ room: room }),

  guestList: [],
  setGuestList: (guestList) => set({ guestList: guestList }),

  // viewerData: {accessLink: '', accessCode: ''},
  // setViewerData: (data) => set({ viewerData: data }),

}));

import { message } from "antd";
import { useState, useContext, useCallback, useMemo, Fragment, useEffect } from "react";
import {
  Button,
  Dropdown,
} from "semantic-ui-react";
import { SharePrivilege, ShareStatus } from '@zoom/videosdk';
import { AuthContext } from "../../../../context/auth";
// import useDbTrigger from "../../../../hooks/useDbtrigger";
import { get } from "../../../../utils/helpers";
//Header VC control images
import volume from "../../../../pages/Viewer/Images/newVolume.svg";
import InvertLayout from "./InvertLayout";
import FullScreen from "./FullScreen";
import Reload from "./ReloadStream";
import ZoomContext from '../../../../pages/ZoomViewer/context/zoom-context';
import ConferenceSelection from "./ConferenceSelection";
import DrawingOptions from "./DrawingOptions";
import AudioToggle from "./AudioToggle";
import VideoToggle from "./VideoToggle";
import Volume from "./Volume";
import GlobalMute from "./GlobalMute";
import { useConferenceCommands } from "../../hooks/useConferenceCommands";
import Devices from './Devices';
import { useWhiteboardingStore } from "../../../../store";
import ProgressLoader from "../../../Common/ProgressLoader";
import NoiseCancellation from "./NoiseCancellation";

const HeaderTopVCControls = (props: any) => {
  const {
    admin_user_id,
    adminAccessCode,
    role,
    room_code,
    room_id,
    onScreenShareClick
  } = props

  const zmClient = useContext(ZoomContext);

  const { isEraser,
    isPencilCircle,
    isPencilLine,
    setEraserStateWithRef,
    setPencilLineStateWithRef,
    setPencilCircleStateWithRef
   } = useWhiteboardingStore()


  //popup
  const [showPopup, setShowPopup] = useState(false);

  // // Dominant Speaker
  const [showDominantSpeaker, setShowDominantSpeaker] = useState(false);
  
  const [showMultiStreamViewer, setShowMultiStreamViewer] = useState(false);

  // const [cameraDropdownOpen, setCameraDropdownOpen] = useState(false);

  const [sharePrivilege, setSharePrivileg] = useState(SharePrivilege.Unlocked);
  const { handelVideoConfEndAll, handelVideoConfEnd, endConferenceandRefreshCode, endorLeaveConferenceLoading } = useConferenceCommands(room_code,room_id,admin_user_id)

  //Drawing option
  const [showDrawingOption, setDrawingOption] = useState(false);

  const onDrawingClick = () => {
    setDrawingOption(!showDrawingOption);
  };

  // eraser toggle function
  const eraserToggled = async () => {
    setEraserStateWithRef(!isEraser);
    setPencilCircleStateWithRef(false);
    setPencilLineStateWithRef(false);
  };


  // Clear WhiteBoard for all
  const clearWhiteBoardAll = async () => {
    setEraserStateWithRef(false);
    setPencilCircleStateWithRef(false);
    setPencilLineStateWithRef(false);
    const canvas: any = document.getElementById("canvas");
    if (canvas) {
      let context = canvas.getContext("2d");
      const canvasWidth = get(canvas, "width", 0);
      const canvasHeight = get(canvas, "height", 0);
      context.clearRect(0, 0, canvasWidth, canvasHeight);
    }
    // also send the clear function to others in the same zoom room
    const clear = true;
    const commandChannel = zmClient.getCommandClient();
    commandChannel.send(JSON.stringify({
      clear
    }))
  };

  // pencil toggle function
  const pencilToggled = async () => {
    console.log('isPencilLine', isPencilLine)
    setEraserStateWithRef(false);
    setPencilCircleStateWithRef(false);
    setPencilLineStateWithRef(!isPencilLine);
    setDrawingOption(!showDrawingOption);
  };

  //circle toggled function
  const circleToggled = async () => {
    setEraserStateWithRef(false);
    setPencilCircleStateWithRef(!isPencilCircle);
    setPencilLineStateWithRef(false);
    setDrawingOption(!showDrawingOption);
  };
 
  useEffect(() => {
    const alertUser  = (event:any) => {
      event.preventDefault()
      event.returnValue = ''
    }
    const handleEndConcert = (event:any) => {
      handelVideoConfEnd();
    };
    window.addEventListener('beforeunload', alertUser)
    window.addEventListener('unload', handleEndConcert)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
      window.removeEventListener('unload', handleEndConcert)
    }
  }, [])

  return (
    <Fragment>
      <ProgressLoader loading={endorLeaveConferenceLoading} />
      <ConferenceSelection
        role={role}
        setShowPopup={setShowPopup}
        showPopup={showPopup}
        handelVideoConfEnd={handelVideoConfEnd}
        refreshRoomCode={endConferenceandRefreshCode}
        handelVideoConfEndAll={handelVideoConfEndAll} />
      <Reload
        showDomSpeaker={showDominantSpeaker}
        admin_user_id={admin_user_id}
        adminCode={adminAccessCode} />
      {role === "host" ? <GlobalMute showDominantSpeaker={showDominantSpeaker} /> : null}
      <DrawingOptions
        showDominantSpeaker={showDominantSpeaker}
        showMultiStreamViewer={showMultiStreamViewer}
        onDrawingClick={onDrawingClick}
        showDrawingOption={showDrawingOption}
        pencilToggled={pencilToggled}
        isPencilLine={isPencilLine}
        circleToggled={circleToggled}
        isPencilCircle={isPencilCircle}
        isEraser={isEraser}
        eraserToggled={eraserToggled}
        clearWhiteBoardAll={clearWhiteBoardAll}
      />
      <NoiseCancellation />
      <Volume
        showDominantSpeaker={showDominantSpeaker}
      />
      <AudioToggle
        showDominantSpeaker={showDominantSpeaker}
      />
      <VideoToggle
        showDominantSpeaker={showDominantSpeaker}
      />
      {/* {renderCameraAndMicrophoneSelection()} */}
      <Devices
        showDominantSpeaker={showDominantSpeaker}
        onScreenShareClick={onScreenShareClick}
      />
      <FullScreen />
      <InvertLayout />
    </Fragment>
  );
};

export default HeaderTopVCControls;

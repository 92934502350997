import React from "react";
import SetupCard from "./SetupCard";
import SavedCardList from "./SavedCardList"

import "./styles.scss";

const Wallet = () => {
  return (
    <div className="wallet">
      <p className="heading-center-aligned">Payment Methods</p>

      <div className="newcard">
        <p className="heading-center-aligned">Add New Card</p>
        <div className="setup">
          <SetupCard />
        </div>
      </div>

      <div className="list-saved-card">
        <p className="heading-center-aligned"> List of Saved Card </p>
        <SavedCardList/>
      </div>
    </div>
  );
};
export default Wallet;

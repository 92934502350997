import { useContext, useEffect, useState } from "react";
import api from "../../../service/rest/restApi";
import { get } from "../../../utils/helpers";
import backend from "../../../service/rest/restService";
import { AuthContext } from "../../../context/auth";

export function useRoom(
  unsubscribeFlag: boolean,
  room_id: string,
  admin_user_id: string
) {
  const userLoggedIn: any = useContext(AuthContext);
  const token = get(userLoggedIn, "auth.currentUser.accessToken", "");
  const [room, setRoom] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchActiveRooms = async () => {
      try {
        const data = {
          api: api.room.getRoom,
          urlParam: room_id,
          queryParam: { admin_user_id },
        };

        const rooms = await backend.fetch(data, true, token);
        console.log("rooms :>> ", rooms);
        setRoom(rooms);
        //   setStreamLabel(rooms[0]?.stream_room_name)
      } catch (err) {
        console.log("err :>> ", err);
      } finally {
        setLoading(false);
      }
    };
    if (!Object.keys(room).length && !!token && !unsubscribeFlag) {
      fetchActiveRooms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { loading, room };
}

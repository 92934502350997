import { createContext, PropsWithChildren, useEffect, useState } from "react";
import useDbTrigger from "../../../hooks/useDbtrigger";
import { notification } from "antd";
import { get } from "../../../utils/helpers";
import { useRedIconSlice } from "../../../store/slices/viewer/viewerSlice";

const GuestListContext = createContext(null as any);
interface GuestListType extends PropsWithChildren {
  room_id: string;
  isHost: boolean;
  guestList: any;
}
const GuestListProvider = ({
  children,
  room_id,
  isHost,
  guestList,
}: GuestListType) => {
  const waitingRoomNotifications = useDbTrigger("waiting_room", isHost);
  const { redIcon, setRedIcon } = useRedIconSlice();
  //const [redIcon, setRedIconState] = useState(false);

  const openNotification = (description: string) => {
    notification.open({
      message: "Guest Notification",
      description,
      placement: "bottomRight",
    });
  };

  const updateRedIcon = () =>{
    const isGuestWaiting = guestList.some((guest: { status: string }) => guest.status === 'waiting');
    if(!(isGuestWaiting && redIcon) && isGuestWaiting !== redIcon){
      setRedIcon(isGuestWaiting);
    } 
  }

  useEffect(() => {
    updateRedIcon();
  }, [guestList]);

  const waitingRoomTriggerData: any = waitingRoomNotifications?.doc?.data();
  if (!!waitingRoomTriggerData && waitingRoomTriggerData?.room_id === room_id) {
    const guestIndex = guestList?.findIndex(
      (guest: any) => guest.id === waitingRoomNotifications?.doc?.id
    );
    switch (waitingRoomNotifications?.type) {
      case "removed":
        if (guestIndex >= 0) {
          guestList.splice(guestIndex, 1);
          updateRedIcon();
        }
        break;
      default:
        if (guestIndex < 0) {
          guestList.push({
            ...waitingRoomTriggerData,
            id: waitingRoomNotifications?.doc?.id,
          });
          updateRedIcon();
          if (get(waitingRoomTriggerData, "status", "") === "waiting") {
            openNotification(
              `${get(
                waitingRoomTriggerData.identity.split("_"),
                "1",
                ""
              )} is in the waiting room.`
            );
          } else if (get(waitingRoomTriggerData, "status", "") === "approved") {
            openNotification(
              `${get(
                waitingRoomTriggerData.identity.split("_"),
                "1",
                ""
              )} has just joined the room.`
            );
          }
        }

        switch (waitingRoomTriggerData?.status) {
          case "rejected":
            if (guestIndex >= 0) {
              guestList.splice(guestIndex, 1);
              updateRedIcon();
            }
            break;
          case "approved":
            guestList.map((guest: any) => {
              if (guest.id === waitingRoomNotifications?.doc?.id) {
                guest.status = "approved";
              }
              return guest;
            });
            updateRedIcon();
            break;
          default:
        }
    }
  }
  return (
    <GuestListContext.Provider value={{ guestList }}>
      <div style={{ visibility: "hidden", width: "0.1px", height: "0.1px" }}></div>

      {children}
    </GuestListContext.Provider>
  );
};
export { GuestListProvider, GuestListContext };
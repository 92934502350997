import React, { useEffect } from "react";
import SavedCard from "../SavedCard";
import "./styles.scss";
import Loader from "../../../LoaderHOC/"

const SavedCardList = (props: any) => {
  const { listStripePaymentMethods, loading, error, deletePayment } = props;
  
  const renderListOFSavedCC = () => {
    // if (loading) {
    //   return <p> Loading!</p>;
    // }
    if (error) {
      return <p> Error while getting Saved Cards</p>;
    }
    if (!listStripePaymentMethods) {
      return <p> No saved CC found. Please add one</p>;
    }


    return (
      <div>
        {listStripePaymentMethods.map((method: any, index: number) => {
          return <Loader loading={loading}>
            <SavedCard
              key={index}
              paymentmethod={method}
              loading={loading}
              index={index}
              deletePayment={deletePayment} />
          </Loader>;
        })}
      </div>
    );
  };

  return (
    <div>
      {renderListOFSavedCC()}
    </div>
  )
};
export default SavedCardList;

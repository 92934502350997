import { create } from 'zustand';
import api from '../../service/rest/restApi';
import backend from '../../service/rest/restService';

interface FeatureFlagState {
  featureFlags: [];
  isFFLoading: boolean;
  fetchFeatureFlags: () => void;
}

export const useFeatureFlagStore = create<FeatureFlagState>((set) => ({
  featureFlags: [],
  isFFLoading: false, // Initial loading state is false
  fetchFeatureFlags: async () => {
    set({ isFFLoading: true }); // Set loading state to true before fetching data
    const data = {
      api: api.featureFlags.listAllFlags,
    };
    try {
      const flags = await backend.fetch(data, true);
      console.log('flags', flags);
      set({ featureFlags: flags });
    } catch (error) {
      console.error('Error fetching feature flags:', error);
      // Handle error state if necessary, e.g., setError(true)
    } finally {
      set({ isFFLoading: false }); // Reset loading state to false after fetching data or if an error occurs
    }
  },
}));
import { useContext } from "react";
import globalAudioMute from "../../../../pages/Viewer/Images/muteAll.svg";
import globalAudioUnMute from "../../../../pages/Viewer/Images/unmuteAll.svg";
import { useAudio } from "../../../../pages/ZoomViewer/hooks/useAudio";
import ZoomContext from '../../../../pages/ZoomViewer/context/zoom-context';
import ZoomMediaContext from '../../../../pages/ZoomViewer/context/media-context';
import { useWhiteboardingStore } from "../../../../store";

const GlobalMute = (props: any) => {  
    const { showDominantSpeaker } = props
    const zmClient = useContext(ZoomContext);
    const { mediaStream } = useContext(ZoomMediaContext);

    const { muteAll, handleGlobalMute } = useAudio(zmClient, mediaStream)
    const { resetAllTools } = useWhiteboardingStore();
    
    return (
    <div
    onClick={() => 
      {handleGlobalMute();
        resetAllTools();
       }}
      className={`top-menu-button-item ${showDominantSpeaker ? "disableddiv" : ""}`}
    >
      {muteAll ? (
        <>
          <img
            src={globalAudioUnMute}
            alt=""
            style={{ filter: "brightness(1)" }}
          />
          <span>Unmute All</span>
        </>
      ) : (
        <>
          <img src={globalAudioMute} alt="" />
          <span>Mute All</span>
        </>
      )}
    </div>
  )
};

  export default GlobalMute;
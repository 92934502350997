import { Spin, Tooltip } from "antd";
import React, { useContext, useMemo, useRef } from "react";
import GuestUsersList from "./GuestUsersList";
import guestLinkCopy from "../../../pages/Viewer/Images/guestLinkCopy.svg";

import { AuthContext } from "../../../context/auth";
import { get } from "../../../utils/helpers";

import { useRoom } from "../../../pages/ZoomViewer/hooks/useRoom";
import ProgressLoader from "../../Common/ProgressLoader";

const GuestInvite = (props: any) => {
  const { admin_user_id, room_id } = props;
  const userLoggedIn: any = useContext(AuthContext);
  const displayName: string = `${get(userLoggedIn, "displayName", "")}`;
  const linkExists = useRef(false);
  const { room, loading } = useRoom(linkExists.current, room_id, admin_user_id);
  linkExists.current = !!get(get(room, "tiny_viewer_url", ""), "shortUrl", "");

  const handleCopy = () => {
    navigator.clipboard
      .writeText(`${displayName.toUpperCase()} has invited you to join Pacpost.io 
URL: ${get(get(room, "tiny_viewer_url", ""), "shortUrl", "")}
Session Code: ${get(room, "dec_viewer_code", "")}`);
  };

  const guestData = useMemo(() => {
    return (
      <div className="guest-viewer-url-container">
        {linkExists.current && (
          <div className="url-container-wrapper">
            <div className="copy-icon">
              <Tooltip title="Copy Guest Invitation" placement="left">
                <img
                  alt="Copy URL"
                  src={guestLinkCopy}
                  onClick={() => {
                    handleCopy();
                  }}
                />
              </Tooltip>
            </div>
            <div>
              <input
                className="link-holder"
                type="text"
                value={get(get(room, "tiny_viewer_url", ""), "shortUrl", "")}
                disabled
              />
              <input
                className="link-holder"
                type="text"
                value={`Passcode : ${get(room, "dec_viewer_code", "")}`}
                disabled
              />
            </div>
          </div>
        )}
      </div>
    );
  }, [room]);

  return (
    <div className="guest-panel-wrapper">
      <nav className="main-menu-right-chat" id="guest-invite-tray">
        <div className="chat-h1">
          <span className="chat-text">GUEST LIST</span>
        </div>
        <div className="guest-invite-content">
          <div className="publisher-panel-content">
            {loading ? <Spin /> : guestData}
            {!loading && <GuestUsersList />}
          </div>
        </div>
      </nav>
    </div>
  );
};
export default GuestInvite;

import { useViewerSlice } from "../../../../store/slices/viewer/viewerSlice";
import fitToScreen from "../../../../pages/Viewer/Images/fullScreen.svg";

const FullScreen = () => {
  const { showDomSpeaker } =
    useViewerSlice();

  const fullScreenVideo = async () => {
    const elem: any = document.getElementById("video-container");
    if(elem){
      const video = elem.getElementsByTagName('video');
      video[0]?.removeAttribute('controls');
    }
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  return (
    <div
      onClick={fullScreenVideo}
      className={`top-menu-button-item ${showDomSpeaker ? "disableddiv" : ""
        }`}
    >
      <img className="fullscreen-img" src={fitToScreen} alt="" />
      <span>FullScreen Video</span>
    </div>
  );
};

export default FullScreen;
import { useEffect } from 'react';

const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();
export default function useDisconnectRoomOnPageUnload(room: any | null) {
  useEffect(() => {
    const confirmExit = (e: any) => {
      if (room) {
        e.preventDefault();
        return e.returnValue = '';
      }
    }

    const disconnect = () => {
      room?.disconnect?.();
    }

    window.addEventListener('beforeunload', confirmExit);
    if (isMobile) {
      window.addEventListener('pagehide', confirmExit);
    }
    window.addEventListener('unload', disconnect);

    return () => {
      window.removeEventListener('beforeunload', confirmExit);
      if (isMobile) {
        window.removeEventListener('pagehide', confirmExit);
      }
      window.removeEventListener('unload', disconnect);
    };
  }, [room]);
}
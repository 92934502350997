import React, { useState, useEffect } from "react";
import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";
import AddPaymentMethod from "./AddPaymentMethod";
import SavedCardList from "../../../components/Account/Wallet/SavedCardList";
import { Button } from "antd";
import './styles.scss';

const PaymentMethods = (props: any) => {
  const { handlePayment, trialStatus, uuid, reactivateFlag } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [listStripePaymentMethods, setListStripePaymentMethods] = useState([] as any);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchSavedCards = async () => {
    try {
      const data = {
        api: api.stripe.listStripePaymentMethods,
      };
      setLoading(true);
      const listStripePaymentMethods: any = await backend.fetch(data, true);
      setLoading(false);
      // console.log("listStripePaymentMethods: ", listStripePaymentMethods);
      setListStripePaymentMethods(!!listStripePaymentMethods && listStripePaymentMethods.length > 0 ? [...listStripePaymentMethods] : [])
      // if (listStripePaymentMethods && listStripePaymentMethods.length) {
      //   setListStripePaymentMethods(listStripePaymentMethods);
      // } else {
      //   setListStripePaymentMethods([])
      // }
    } catch (error: any) {
      // console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const deletePayment = async (paymentMethodId: string) => {
    try {
      const input = {
        payment_method_id: paymentMethodId
      }
      setLoading(true);
      const data = {
        api: api.stripe.detachPayment,
        payLoad: input
      };

      const result: any = await backend.save(data, true);
      setLoading(false);
      if (result) {
        fetchSavedCards();
      }
      // console.log("result: ", result);
    } catch (err: any) {
      // console.log(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    // console.log("Fetch the saved cards");
    fetchSavedCards();
  }, []);

  return !isModalOpen && listStripePaymentMethods.length > 0 ? (
    <div className="payment-method-content">
      <SavedCardList
        listStripePaymentMethods={listStripePaymentMethods}
        loading={loading}
        error={error}
        deletePayment={deletePayment}
      />
      {!!uuid &&
        <Button
          onClick={() => handlePayment(listStripePaymentMethods)}
          disabled={listStripePaymentMethods.length === 0}
          className="payment-btn"
        >
          {reactivateFlag ? 'Reactivate Subscription' : 'Continue to  Payment' }
        </Button>
      }
    </div>
  ) : (
    <div className="payment-method-content">
      <AddPaymentMethod
        fetchSavedCardsParent={fetchSavedCards}
        listStripePaymentMethods={listStripePaymentMethods}
        setListStripePaymentMethods={setListStripePaymentMethods}
        loading={loading}
        deletePayment={deletePayment}
        setIsModalOpen={setIsModalOpen} />
    </div>
  );
};

export default PaymentMethods;

import React from "react";
import { Layout } from 'antd';
import Header from "../components/Header";
// import AppFooter from "./../components/Footer";
import "./styles.scss";

const { Content } = Layout;

const AppLayout = (props: any) => {
    const {header = false} = props;
    return (
    <Layout className="layout-wrapper">
        {header && <Header {...props} />}
        <Content className="layout-content">{props.children}</Content>
      </Layout>
    );
  };
  
  export default AppLayout;
import React, { useRef, useState } from 'react'
import chevronLeft from '../icons/chevron-left.svg'
import chevronRight from '../icons/chevron-right.svg'
import ContentLoader from 'react-content-loader';
import arrowDown from '../../../assets/icons/arrow-down.svg';
import arrowUp from '../../../assets/icons/arrow-up.svg';

interface Props {
    overlay: any;
    overlayMsg: any;
    overlayMsgConnected: any;
    overlayConnected: any;
    overlayDisconnected: any;
    overlayMsgDisconnected: any;
    layoutMode: string;
    troom: any;
  }
  
  const VideoTiles: React.FC<Props> = ({
    overlay,
    overlayMsg,
    overlayConnected,
    overlayMsgConnected,
    overlayDisconnected,
    overlayMsgDisconnected,
    layoutMode,
    troom
  }) => {
  
    const moveLeft = async (event: React.MouseEvent<HTMLElement>) => {
      document.getElementById('vtray')?.scrollBy(-250, 0);
    };
    const moveRight = async (event: React.MouseEvent<HTMLElement>) => {
      document.getElementById('vtray')?.scrollBy(250, 0);
    };
  
    const loaderStyles:React.CSSProperties = { 
      // background: '#141414',
      // fontSize: 26,
      // textAlign: 'center',
      // fontWeight: 700,
      // height: '200px',
      // width: '100%',
      // zIndex: 1000,
      // color:"#000000",
      // paddingTop:'50px'
    }
  
    const [toggleVideoTray, setToggleVideoTray] = useState(false);
  
    const handleToggleVideoTray = () => {
        setToggleVideoTray(!toggleVideoTray)
    }
  
    const renderExpandCollapseArrow = () => {
      if (layoutMode === 'inverse') {
        return !toggleVideoTray ?
          <img src={arrowUp} alt="Collapse Video Tray" className="expand-collapse-icon"/> :
          <img src={arrowDown} alt="Collapse Video Tray" className="expand-collapse-icon"/>;
      }
      return !toggleVideoTray ?
        <img src={arrowDown} alt="Collapse Video Tray" className="expand-collapse-icon"/> :
        <img src={arrowUp} alt="Collapse Video Tray" className="expand-collapse-icon"/>;
    }
  
    return (
      <>
        <div className={`video-tray-collapse-bg ${layoutMode === 'inverse' ? 'inverse-layout' : ''}`}></div>
        <div className={`video-tiles-row-wrapper ${layoutMode === 'inverse' ? 'inverse-layout' : ''} ${toggleVideoTray ? 'collapse-tray' : ''}`}>
          <button className='flex-prev tray-btns' onClick={moveLeft}>
            <img src={chevronLeft} alt="left-btn"/>
          </button>
          <div style={!Object.keys(troom).length ? loaderStyles : { display: "none" }} className='empty-video-tiles-placeholder'>
            <ContentLoader
              width={190}
              height={200}
              backgroundColor="#000005"
              foregroundColor="#1d1d1d"
            >
              <rect x="16" y="16" rx="2" ry="2" width="190" height="125" />
              <rect x="16" y="146" rx="2" ry="2" width="190" height="30" />
            </ContentLoader>
          </div>
          <div className='videotray' id='vtray' style={ Object.keys(troom).length ? {} : {display: "none"}}>
          </div>
  
          <button className='flex-next tray-btns' onClick={moveRight}>
            <img src={chevronRight} alt="right-btn"/>
          </button>
          <div className='overlay-vtry' style={overlay.style}>
            <span className='overlay-vtry-text'>{overlayMsg}</span>
          </div>
  
          <div className='overlay-participant-connected' style={overlayConnected.style}>
            <span className='overlay-vtry-text'>{overlayMsgConnected}</span>
          </div>
  
          <div className='overlay-participant-disconnected' style={overlayDisconnected.style}>
            <span className='overlay-vtry-text'>{overlayMsgDisconnected}</span>
          </div>
        </div>
        <div className="expand-collapse-btn" onClick={handleToggleVideoTray}>
          {renderExpandCollapseArrow()}
        </div>
      </>
    );
  };
export default VideoTiles

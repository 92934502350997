import api from '../../service/rest/restApi';
import backend from '../../service/rest/restService';
import messageComponent from '../../utils/message';
import { useNavigate } from 'react-router-dom';

// interface roomCommandProp {
//   setLoading: (value: React.SetStateAction<boolean>) => void;
// }
export default function useRoomCommands(
  setLoading: (value: React.SetStateAction<boolean>) => void,
) {
  const navigate = useNavigate();
  // const { setLoading } = props;
  const reactivateSubscription = async () => {
    try {
      setLoading(true);
      const savedCards = await fetchSavedCards();
      if (!savedCards.length) {
        messageComponent({
          messages: 'Please add payment method',
          type: 'info',
          top: 10,
        });
        navigate('/setuproom');
        return
      }
      navigate('/setuproom')

      // const input = {
      //   subscription_id: subscriptionId,
      // };
      // const data = {
      //   api: api.stripe.reActivateSubscription,
      //   payLoad: JSON.stringify(input),
      // };
      // const response = await backend.save(data, true);
      // if (response) {
      //   messageComponent({
      //     messages: 'Reactivation Successfull',
      //     type: 'success',
      //     top: 10,
      //   });
      // }
      return true;
    } catch (err: any) {
      messageComponent({
        messages: 'Error while reactivation',
        type: 'error',
        top: 10,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchSavedCards = async () => {
    try {
      const data = {
        api: api.stripe.listStripePaymentMethods,
      };

      const listStripePaymentMethods: any = await backend.fetch(data, true);

      return listStripePaymentMethods;
    } catch (error: any) {
      console.error(error);
    }
  };
  return { reactivateSubscription };
}

import { useRef, useState, useEffect, useContext, useCallback } from "react";
// import { notification } from "antd";
// import useDbTrigger from "../../hooks/useDbtrigger";
import Viewer from "./Viewer";
import { get } from "../../utils/helpers";
// import api from "../../service/rest/restApi";
// import backend from "../../service/rest/restService";
import { useFeatureFlagStore, useGuestStore } from "../../store";
import ZoomShowViewerDashboard from "../ZoomViewer/Viewer";
import { notification } from "antd";
import { AuthContext } from "../../context/auth";
import api from "../../service/rest/restApi";
import backend from "../../service/rest/restService";

const notifiedGuest: any = [];
const HostViewer = (props: any) => {
  const { featureFlags, isFFLoading } = useFeatureFlagStore()
  const userLoggedIn: any = useContext(AuthContext);
  const { user_id, room_id, room_name, firstName, lastName, uuid, resetValues, accessCode, room_code, editorDirectLogin } =
    props;
  const [guestList, setGuestList] = useState([] as any);
  const [isLoading, setIsLoading] = useState(true);
  const guestListLoaded = useRef([]);
  const videoConfEnd = useRef<any>(null);
  const zoomVideoRef = useRef<any>(null);

  useEffect(() => {
    sessionStorage.setItem("isAdmin", "true");
    getGuestList();

    const cleanup = () => {
      if (zoomVideoRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        zoomVideoRef?.current.leave();
      }
    };
    window.addEventListener("unload", cleanup);

    return () => {
      window.removeEventListener("unload", cleanup);
      cleanup();
    };
  }, []);

  const openNotification = (description: string) => {
    notification.open({
      message: "Guest Notification",
      description,
      placement: "bottomRight",
    });
  };

  const getGuestList = useCallback(async () => {
    try {
      const data = {
        api: api.room.getGuestList,
        urlParam: room_id,
      };
      const token = get(userLoggedIn, "auth.currentUser.accessToken", "");
      const guestList: any = await backend.fetch(data, true, token);
      const guestData: any = Array.isArray(guestList) ? guestList : [guestList];
      guestData.forEach((guest: any) => {
        if (!notifiedGuest.includes(get(guest, "identity", ""))) {
          if (get(guest, "status", "") === "waiting") {
            openNotification(
              `${get(
                guest.identity.split("_"),
                "1",
                ""
              )} is in the waiting room.`
            );
            notifiedGuest.push(get(guest, "identity", ""));
          }
        }
      });
      setGuestList(guestData);
      
      // return guestData;
    } catch (err) {
      console.log("err", err);
    } finally {
        setIsLoading(false)
    }
  }, []);
  
  return (
    <>
      {(isFFLoading || isLoading) ?
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <div>Validating in progress</div>
        </div>
        :
        <>
        {get(featureFlags.find((flag: any) => flag.feature_name === 'zoom'), "feature_enable", false) === true ? (
          <ZoomShowViewerDashboard 
            videoConfEndRef={videoConfEnd}
            admin_user_id={user_id}
            room_id={room_id}
            room_name={room_name}
            room_code={room_code}
            role="host"
            firstName={firstName}
            lastName={lastName}
            uuid={uuid}
            resetValues={resetValues}
            guestList={guestList}
            accessCode={accessCode}
            editorDirectLogin={editorDirectLogin}
            zoomVideoRef={zoomVideoRef}
            />
        ) : (
          <Viewer
            videoConfEndRef={videoConfEnd}
            admin_user_id={user_id}
            room_id={room_id}
            room_name={room_name}
            room_code={room_code}
            role="host"
            firstName={firstName}
            lastName={lastName}
            uuid={uuid}
            resetValues={resetValues}
            guestList={guestList}
            accessCode={accessCode}
            editorDirectLogin={editorDirectLogin}
          />
        )}
        </>
      }
    </>
  );
};

export default HostViewer;

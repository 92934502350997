import { useState } from "react";

export const useForm = (callback: Function, initialState: any = {}) => {
  const [values, setValues] = useState(initialState);

  const resetValues = () => {
    setValues({});
  };
  const getValues = () => {
    return values;
  }
  const setNewValues = (values: any) => {
    setValues(values);
  };
  const onChange = (event: any) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  const onSubmit = (event: any) => {
    event.preventDefault();
    callback();
  };
  return {
    onChange,
    onSubmit,
    resetValues,
    setNewValues,
    values,
    getValues
  };
};



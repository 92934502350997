import { View } from "@millicast/sdk";
import { create } from "zustand";

interface HudStatState {
  activesStreamExists?: boolean;
  activesStreamStatus?: boolean;
  openHudStatsPanel?: boolean;
  milicastView?: View | undefined;
}

interface HudsStatsStore {
  hudsState: HudStatState;
  setHudsState: (newState: Partial<HudStatState>) => void;
  layerOptions:[];
  setLayerOptions:(layers:[])=>void;
  selectedLayer:string;
  setSelectedLayer:(selectedLayer:string)=> void;
}

const useHudsStatsStore = create<HudsStatsStore>((set) => ({
  hudsState: {
    activesStreamExists: false,
    activesStreamStatus: false,
    openHudStatsPanel: false,
    milicastView: undefined,
  },
  setHudsState: (newState: Partial<HudStatState>) =>
    set((state: { hudsState: HudStatState }) => ({
      hudsState: {
        ...state.hudsState,
        ...newState,
      },
    })),
    layerOptions:[],
    setLayerOptions:(layerOptions) => set({ layerOptions: layerOptions }),
    selectedLayer:'Video Quality',
    setSelectedLayer:(selectedLayer) => set({ selectedLayer: selectedLayer }),
}));

export default useHudsStatsStore;

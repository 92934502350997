import { useEffect, useState, useContext, useMemo, useRef } from 'react';
import ZoomVideo from '@zoom/videosdk';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ZoomViewer from "../../components/ZoomViewer";
import api from '../../service/rest/restApi';
import backend from '../../service/rest/restService';
import { b64DecodeUnicode } from './utils/utils';
import ZoomContext from './context/zoom-context';
import { AuthContext } from '../../context/auth';
import { get } from '../../utils/helpers';
import { useLocation } from 'react-router-dom';

const ZoomShowViewerDashboard = (props: any) => {
  const { search } = useLocation();
  const {
    videoConfEndRef,
    admin_user_id,
    room_id,
    room_name,
    room_code,
    role,
    firstName,
    lastName,
    uuid,
    resetValues,
    guestList,
    accessCode,
    editorDirectLogin,
    zoomVideoRef
  } = props

  //full screen
 const [redIcon,setRedIcon] = useState(false);
  const fullScreenHandler = useFullScreenHandle();
  const zmClient = useMemo(() => ZoomVideo.createClient(), []);
  const userLoggedIn: any = useContext(AuthContext);
  const isGuestViewer = get(userLoggedIn, 'guestViewer', false);
  if (zoomVideoRef) zoomVideoRef.current = isGuestViewer ? ZoomVideo : zmClient ;
  const [signature, setSignature] = useState('');
  const [status, setStatus] = useState(false);
  const displayName: string = !editorDirectLogin ? `${get(userLoggedIn, 'fn', '')} ${get(userLoggedIn, 'ln', '')}` : `${get(userLoggedIn, 'displayName', '')}`
  const participant_type = isGuestViewer ? 'member' : 'admin';
  const identity = `${participant_type}#${uuid}_${displayName}`;
 

  const meetingArgs = useMemo(() => {
    const defaults = {
      webEndpoint: 'zoom.us',
      topic: `${room_name}_${room_code}`,
      name: displayName,
      password: '',
      signature: '',
      sessionKey: '',
      userIdentity: identity,
      role: role === "host" ? 1 : 0,
      enforceGalleryView: 1,
      enforceVB: 1,
    };
    let meetingArgsDefault: any = Object.fromEntries(new URLSearchParams(search));

    if (!meetingArgsDefault.sdkKey || !meetingArgsDefault.topic || !meetingArgsDefault.name || !meetingArgsDefault.signature) {
      meetingArgsDefault = { ...defaults, ...meetingArgsDefault };
    }

    if (meetingArgsDefault.web && meetingArgsDefault.web !== '0') {
      ['topic', 'name', 'password', 'sessionKey', 'userIdentity'].forEach((field) => {
        if (Object.hasOwn(meetingArgsDefault, field)) {
          try {
            meetingArgsDefault[field] = b64DecodeUnicode(meetingArgsDefault[field]);
          } catch (e) {
            console.log('ignore base64 decode', field, meetingArgsDefault[field]);
          }
        }
      });
    }
   
    meetingArgsDefault.role = meetingArgsDefault.role || 1;
    console.log('meetingArgsDefault', meetingArgsDefault)
    return meetingArgsDefault;
  }, [search, room_name, displayName, identity, role]);

  useEffect(() => {
    const fetchSignature = async () => {
      const data = {
        api: api.zoom.generateVideoSDKJWT,
        payLoad: {
          host: role === "host",
          name: displayName,
          sessionName: `${room_name}_${room_code}`,
          userIdentity:identity
      }
      };
      const token = get(userLoggedIn, 'auth.currentUser.accessToken', '');
      try {
        const response = await backend.save(data, true, token);
        setSignature(response);
      } catch (error) {
        console.error('Error fetching signature:', error);
      }
    };

    if (!meetingArgs.signature && meetingArgs.topic) {
      fetchSignature();
    }
  }, [meetingArgs.signature, meetingArgs.topic, firstName, lastName, room_name, role, userLoggedIn]);

  return (
    <>
      <ZoomContext.Provider value={zmClient}>
        <FullScreen handle={fullScreenHandler}>
          <div className="zoom-viewer-main" id="show-viewer-main">
            <ZoomViewer
              videoConfEndRef={videoConfEndRef}
              admin_user_id={admin_user_id}
              room_id={room_id}
              room_name={room_name}
              room_code={room_code}
              role={role}
              firstName={firstName}
              lastName={lastName}
              uuid={uuid}
              resetValues={resetValues}
              guestList={guestList}
              accessCode={accessCode}
              meetingArgs={meetingArgs}
              signature={signature}
              editorDirectLogin={editorDirectLogin}
            />
    </div>
        </FullScreen>
      </ZoomContext.Provider>
    </>
  )
};
export default ZoomShowViewerDashboard;
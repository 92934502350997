import { useEffect, useState } from "react";
import { Spin, Result, Button } from "antd";
import {useNavigate } from "react-router-dom";
import { LoadingOutlined, MehOutlined } from "@ant-design/icons";
import PageLayout, { PageContent } from "../../layouts/PageLayout";
import SelectRoom from "./SelectRoom";
import CreateRoom from "./CreateRoom";
import "./styles.scss";
import api from "../../service/rest/restApi";
import backend from "../../service/rest/restService";
import { handleSRTStream, isAVID } from "./avid";
import AvidCreateRoom from "./AvidCreateRoom";
import AvidSelectRoom from "./AvidSelectRoom";
import { get } from "../../utils/helpers";
import { isAvidBrowser } from "../../utils/avidBrowserCheck";
import { doSignOut } from "../../firebase/auth";
const Rooms = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [rooms, setRooms] = useState(null as any);
  const [selectedRoomID, setSelectedRoomID] = useState("");
  const selectedRoom =
    (!!rooms && rooms.filter((room: any) => room.id === selectedRoomID)) || [];
  const isAvidBrowserCheck = isAvidBrowser();
  const isAVIDMode =
    isAVID() || get(selectedRoom, "0.isAvidRoom", false) || isAvidBrowserCheck;

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (Array.isArray(rooms) && rooms.length === 0) {
  //     handleAddRoom();
  //   }
  // }, [rooms]);

  // const handleAddRoom = () => {
  //   navigate("/setuproom", {
  //     replace: true,
  //     state: {
  //       prevPath: "home",
  //     },
  //   });
  // };

  const fetchActiveRooms = async (actionType?: string) => {
    try {
      const data = {
        api: api.room.getAllActiveRoomsOfUser,
      };

      setLoading(true);
      const rooms = await backend.fetch(data, true);
      console.log("rooms :>> ", rooms);

      if (rooms) {
        setRooms(rooms);

        if (selectedRoomID === "") {
          setSelectedRoomID(rooms[0]?.id);
        } else {
          const updatedRoomsList = rooms.filter(
            (item: any) => item.id === selectedRoomID
          );

          if (updatedRoomsList.length === 0) {
            setSelectedRoomID(rooms[0]?.id);
          }
        }

        // Check if actionType = "AvidRoomCreated"
        if (actionType === "AvidRoomCreated") {
          const selectedRoom = rooms.find(
            (room: any) => room.id === selectedRoomID
          );

          if (selectedRoom && selectedRoom.srt_stream_id) {
            handleSRTStream(selectedRoom.srt_stream_id);
          }
        }
      } else {
        setSelectedRoomID("");
      }
    } catch (err) {
      console.log("err :>> ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActiveRooms();
  }, []);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 75, color: "#6A29FF" }} spin />
  );

  const SelectRoomContainer = () => {
    return (
      <PageContent className="left-room-content">
        {isAVIDMode ? (
          <AvidSelectRoom
            rooms={rooms}
            selectedRoomID={selectedRoomID}
            setSelectedRoomID={setSelectedRoomID}
            fetchActiveRooms={fetchActiveRooms}
            setLoading={setLoading}
          />
        ) : (
          <SelectRoom
            rooms={rooms}
            selectedRoomID={selectedRoomID}
            setSelectedRoomID={setSelectedRoomID}
            fetchActiveRooms={fetchActiveRooms}
            setLoading={setLoading}
          />
        )}
      </PageContent>
    );
  };

  const CreateRoomContainer = () => {
    return (
      <PageContent className="right-room-content">
        {isAVIDMode ? (
          <AvidCreateRoom
            rooms={rooms}
            selectedRoomID={selectedRoomID}
            setRooms={setRooms}
            fetchActiveRooms={fetchActiveRooms}
            setLoading={setLoading}
            loading={loading}
          />
        ) : (
          <CreateRoom
            rooms={rooms}
            selectedRoomID={selectedRoomID}
            setRooms={setRooms}
            fetchActiveRooms={fetchActiveRooms}
            setLoading={setLoading}
            loading={loading}
          />
        )}
      </PageContent>
    );
  };
  const signout = async () => {
    await doSignOut();
    localStorage.removeItem('url')
    navigate(
      "/",
      { replace: true }
    );
  };

  const ExpiredRoom = () => (
    <div className="expired_room">
    <Result
    icon={<MehOutlined />}
    title="Your Free Trial Has Expired"
    subTitle="We appreciate your interest in our product and service. To continue using it, please consider subscribing to a full membership."
    extra={
    <div>
      <Button type="primary" onClick={() => navigate("/setuproom")} size={"large"}>
        Upgrade
      </Button>
      <div className="Logout-btn"  onClick={() => signout()}>
        Logout
      </div>
    </div>
  }
/>
    </div>
  );

  const room_state = get(selectedRoom, "0.room_state", "");
  console.log("room_state", room_state);
  return (
    <Spin spinning={loading} indicator={antIcon}>
      {!!rooms && (
        <PageLayout>
          {room_state === "expired" ? (
            <ExpiredRoom />
          ) : (
            <>
              <SelectRoomContainer />
              <CreateRoomContainer />
            </>
          )}
        </PageLayout>
      )}
    </Spin>
  );
};

export default Rooms;

import { useState, useContext } from "react";
import ViewerLogin from "../../components/Viewer/Login";
import HostViewer from "./HostViewer";
import api from "../../service/rest/restApi";
import backend from "../../service/rest/restService";
import { AuthContext } from "../../context/auth";
import { get } from "../../utils/helpers";

const ViewerPage = (props: any) => {
  const { user_id, room_id,room_name,room_code, editorDirectLogin, uuid } = props;
  
  const user: any = useContext(AuthContext);
  if(!!user) {
    sessionStorage.setItem("first_name", get(get(user, 'displayName', '').split(' '), '0', ''));
    sessionStorage.setItem("last_name", get(get(user, 'displayName', '').split(' '), '1', ''))
  }
  const [firstName, setFirstName] = useState(
    sessionStorage.getItem("first_name") || ""
  );
  const [lastName, setLastName] = useState(
    sessionStorage.getItem("last_name") || ""
  );
  const [roomName, setRoomName] = useState(
    sessionStorage.getItem("room_name") || ""
  );
  // const [uuid, setUuid] = useState(sessionStorage.getItem("uuid") || "");

  const [accessCode, setAccessCode] = useState("");

  const [accessValidate, setAccessValidate] = useState(
    sessionStorage.getItem("accessValidate") || false
  );
 
  const resetValues = async (end: boolean) => {
    sessionStorage.removeItem("first_name");
    sessionStorage.removeItem("last_name");
    sessionStorage.removeItem("room_name");
    sessionStorage.removeItem("uuid");
    sessionStorage.removeItem("accessValidate");
    setFirstName("");
    setLastName("");
    setRoomName("");
    // setUuid("");
    setAccessValidate(false);
    const displayName = `${firstName} ${lastName}`;
    const identity = `member#${uuid}_${displayName}`;
    const removeWaitingRoom = {
      api: api.room.clearWaitingRoom,
      payLoad: JSON.stringify({
        room_code,
        user_id,
        room_id,
        identity,
        end,
      }),
    };
    backend.save(removeWaitingRoom);
  };
  return (
    <>
      {/* {!accessValidate && (
        <ViewerLogin
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          setAccessValidate={setAccessValidate}
          setRoomName={setRoomName}
          setUuid={setUuid}
          accessCode={accessCode}
          setAccessCode={setAccessCode}
          user_id={user_id}
          room_id={room_id}
        />
      )} */}
      {/* {!!accessValidate && ( */}
        <HostViewer
          user_id={user_id}
          room_id={room_id}
          room_name={room_name}
          room_code={room_code}
          firstName={firstName}
          lastName={lastName}
          uuid={uuid}
          resetValues={resetValues}
          accessCode={accessCode}
          editorDirectLogin={editorDirectLogin}
        />
      {/* )} */}
    </>
  );
};

export default ViewerPage;

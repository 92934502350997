import pencil from "../../../../pages/Viewer/Images/pencil.svg";
import erase from "../../../../pages/Viewer/Images/eraser.svg";
import trash from "../../../../pages/Viewer/Images/trash.svg";
import { Button, Dropdown } from "semantic-ui-react";

const DrawingOptions = (props: any) => {
    const {
        showDominantSpeaker,
        showMultiStreamViewer,
        onDrawingClick,
        showDrawingOption,
        pencilToggled,
        isPencilLine,
        circleToggled,
        isPencilCircle,
        isEraser,
        eraserToggled,
        clearWhiteBoardAll,
        enablePencilIcon,
        enableEraserIcon,
    } = props;

    if (showMultiStreamViewer) {
        return <></>
    }
    return (
        <>
            <div
                className={`top-menu-button-item pencil-tool-dropdown ${enablePencilIcon ? "canvas-pencil-on" : ""}`}
                onClick={onDrawingClick}
            >
                <img src={pencil} alt="" />
                <Dropdown icon="" open={showDrawingOption} onClose={onDrawingClick}>
                    <Dropdown.Menu className="cameraMenu">
                        <Dropdown.Item>
                            <div className={showDominantSpeaker ? "disableddiv" : ""}>
                                <Button
                                    className="ui button viewer-menu-btn"
                                    onClick={pencilToggled}
                                    style={{
                                        backgroundColor: isPencilLine ? "grey" : "white",
                                        paddingLeft: "70px",
                                        paddingRight: "70px",
                                    }}
                                >
                                    Pencil
                                </Button>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <div className={showDominantSpeaker ? "disableddiv" : ""}>
                                <Button
                                    className="ui button viewer-menu-btn"
                                    onClick={circleToggled}
                                    style={{
                                        backgroundColor: isPencilCircle ? "grey" : "white",
                                        paddingLeft: "70px",
                                        paddingRight: "70px",
                                    }}
                                >
                                    Circle
                                </Button>
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <span>Annotation</span>
            </div>
            <div
                className={`top-menu-button-item ${showDominantSpeaker ? "disableddiv" : isEraser ? "eraser-on" : ""} ${enableEraserIcon ? "canvas-eraser-on" : ""
                    }`}
                onClick={eraserToggled}
            >
                <img src={erase} alt="" />
                <span>Erase</span>
            </div>

            <div
                onClick={clearWhiteBoardAll}
                className={`top-menu-button-item ${showDominantSpeaker ? "disableddiv" : ""
                    }`}
            >
                <img src={trash} alt="" />
                <span>Clear</span>
            </div>
        </>
    );
};

export default DrawingOptions;
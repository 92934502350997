import { CollectionReference, DocumentData, collection, doc, getFirestore, onSnapshot, query } from 'firebase/firestore';
import { app } from '../firebase/utils';
import { useEffect, useState } from 'react';

let subscriptionCheck: Boolean = false;
export default function useGetroomupdates(db_collection: string, subscribeflag: boolean, admin_user_id:string) {
  const [dbChanges, setDbChanges] = useState([] as any);
  let unSubscribe: any = null;
  // const [subscribeflag, setSubscriptionCheck] = useState(subscribeflag);
  subscriptionCheck = subscribeflag;

  useEffect(() => {
    async function checkDbChange() {
      const getDbCollectionData:CollectionReference<DocumentData> = collection(getFirestore(app), db_collection);
      const documentRef = doc(getDbCollectionData,admin_user_id?.trim());
      const subcollectionRef:CollectionReference<DocumentData> = collection(documentRef, 'rooms');
      const subcollectionQuery = query(subcollectionRef);
      // const subcollectionRef = getDbCollectionData.collection()
      unSubscribe = onSnapshot(subcollectionQuery, (snap: any) => {
        snap.docChanges().forEach((element: any) => {
          console.log(":::::::::::::::",element)
          setDbChanges(element);
        });
      },(error) => {
        // Handle errors here
        console.error('Error getting documents:', error);
      });
    }

    if (subscribeflag && !unSubscribe) {
      checkDbChange();
    }
    return () => {
      if (!!unSubscribe && subscribeflag) {
        unSubscribe();
      }
    };
  }, [db_collection, subscribeflag]);

  return dbChanges;
}

import { useState } from "react";
import { Button, Space, Form, notification } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import "./styles.scss";
import PageLayout, { PageContent } from "../../../layouts/PageLayout";
import logo from "../../../assets/images/sidebar-logo.png";
import Title from "../../Title";
import InputComponent from "../../InputComponent";
import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";
import Loader from "../../LoaderHOC";
import ErrorComponent from "../../ErrorComponent";
import messageComponent from "../../../utils/message";
import { isAvidBrowser } from "../../../utils/avidBrowserCheck";



const ForgotPassword = (props: any) => {

  const location: any = useLocation();
  const isViewer: boolean = location?.state?.isViewer || false;
  const url: any = location?.state?.url || false;

  const [userMail, setUserMail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState("");
  const [buttonEnabled,setButtonEnabled] =useState(true);
  const isAvidBrowserCheck =  isAvidBrowser();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const navigate = useNavigate();

  const sendPassword = () => {
    console.log("Password sent to email");
    setButtonEnabled(false)
  };

  const openNotifications = (res: any) => {
    const { status } = res;
    if (status === "Account not found") {
      messageComponent({
        messages: "Please check your email for further instructions",
        type: "success",
        top: 10,
      });
      setButtonEnabled(false);
    } else {
      messageComponent({
        messages: "Please check your email for further instructions",
        type: "success",
        onClose: () =>
          navigate("/emailOTP", {
            state: {
              email: userMail,
            },
          }),
        top: 10,
      });
      setButtonEnabled(false);
    }
  };

  const getPassword = async () => {
    setLoading(true);

    try {
      const input = {
        user_email: userMail,
      };

      const data = {
        api: api.otp.requestOTP,
        payLoad: JSON.stringify(input),
      };

      const response = await backend.save(data, true);
      setLoading(false);

      if (response && response.message !== "") {
        console.log("forgot password response:::::", response);
        openNotifications(response);
      }
      return;
    } catch (err) {
      messageComponent({
        messages: "Please check your email for further instructions",
        type: "success",
        top: 10,
      });

      setButtonEnabled(false);
      setLoading(false);
      throw err;
    }
  };
  
  return (
    <Loader loading={loading}>
    <PageLayout>
      <PageContent className="left-content">
        <img src={logo} alt="logo" className="logo" />
      </PageContent>
      <PageContent className="signin-right-content">
        <div className="forgot-container">
          <div className="forgot-title">
            <Title level={3}>Enter email to get password </Title>
            <Button
              type="link"
              className="register-link"
              onClick={() => navigate(!!url && !!isViewer ? `${url}` : "/") }
            >
              Login
            </Button>
          </div>
          <div className="forgot-content">
            <Form
              name="login"
              initialValues={{ remember: true }}
              onFinish={sendPassword}
            >
              <Space
                direction="vertical"
                size={212}
                className="forgot-container"
              >
                <div>
                  <Form.Item
                    name="userMail"
                    rules={[
                      {
                        validator: async (_: any, val: any) => {
                          if (val === "") {
                            setEmailValid(false);
                            return Promise.reject(<ErrorComponent
                              errorMsg={"Please enter email"}
                              setErrorMsg={setErrorMsg}
                            />);
                          } else if (!emailRegex.test(val)) {
                            setEmailValid(false);
                            return Promise.reject(
                              <ErrorComponent
                              errorMsg={"Invalid email format"}
                              setErrorMsg={setErrorMsg}
                            />
                            );
                          } else {
                            setEmailValid(true);
                            setButtonEnabled(true);
                            Promise.resolve()
                          }
                        },
                      },
                    ]}
                  >
                    <InputComponent
                      key={"userMail"}
                      label="email"
                      value={userMail}
                      onChange={setUserMail}
                    />
                  </Form.Item>
                </div>
                <div className="button-box">
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className="getPassword-btn"
                      onClick={getPassword}
                      disabled={emailValid === false || buttonEnabled === false}
                    >
                      Get Password
                    </Button>
                  </Form.Item>
                </div>
                {!isAvidBrowserCheck ?
                (
                  <div className="need-support-forgot">
                    <a href="mailto:support@pacpostio.zohodesk.com">Need Support?</a>
                  </div>
                ) : (
                  <div className="need-support-copy-forgot-password">
                    <span className="need-support-copy-text">Need Support? support@pacpostio.zohodesk.com</span>
                  </div> )}
              </Space>
            </Form>
            
            
          </div>
        </div>
      </PageContent>
    </PageLayout>
    </Loader>
  );
};
export default ForgotPassword;

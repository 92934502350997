import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Space, Divider, Avatar, Dropdown, Menu, Spin } from "antd";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import logo from "../../../assets/images/sidebar-logo.png";
import PageLayout, { PageContent } from "../../../layouts/PageLayout";
import PaymentMethods from "../../Account/PaymentMethods";
import Title from "../../Title";
import "./styles.scss";
import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";
import { AuthContext } from "../../../context/auth";
import { getInitials, get } from "../../../utils/helpers";
import { AppMenu } from "../../AppMenu";
import messageComponent from "../../../utils/message";

const PurchaseRoom = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const prevPath = get(location, "state.prevPath", null);
  const user: any = useContext(AuthContext);
  const initials = getInitials(user?.displayName ?? " ");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [roomsCount, setRoomsCount] = useState(1);
  const [taxes, setTaxes] = useState(0);
  const [pricePerRoom, setPricePerRoom] = useState(0);
  const [pricingDetails, setPricingDetails] = useState({});
  const [subscriptionStatus, setSubscriptionStatus] = useState()
  const [subscriptionExists,setSubscriptionExists] = useState(false)
  const [subscriptionId,setSubscriptionId] = useState("");
  const [roomId,setRoomId] = useState("");
  // const [isOnTrial, setIsOnTrial] = useState(false);
  const [isRooms, setIsRooms] = useState([])
  const [uiid, setUiid] = useState('')

  const fetchSavedCards = async () => {
    try {
      const data = {
        api: api.stripe.getPricingDetails,
      };
      const roomsData = {
        api: api.room.getAllActiveRoomsOfUser,
      };

      const userInfo = {
        api: api.user.get,
      };


      setLoading(true);
      const userInfoResponse = await backend.fetch(userInfo, true);
      const rooms = await backend.fetch(roomsData, true);
      const pricingDetails: any = await backend.fetch(data, true);

      setPricingDetails(pricingDetails);
      setIsRooms(rooms)
      // If subscription status is canceled, a new subscription needs to be created 
      if(get(rooms,'0.stripe_subscription_id','') && get(rooms,'0.stripe_subscription_status','') !== 'canceled'){
        setSubscriptionExists(true);
        setSubscriptionId(get(rooms,'0.stripe_subscription_id',''));
        setRoomId(get(rooms,'0.id',''))
      }
      const price = Number((get(pricingDetails, "unit_amount", 0)/100).toFixed(2));
      // let subStatus = !!rooms && rooms[0].stripe_subscription_status;

     
      if (!!userInfoResponse) {
        setUiid(userInfoResponse.uid)
      //  setIsOnTrial(userInfoResponse.trial_enabled)
      }

      // setSubscriptionStatus(subStatus)
      // if(!userInfoResponse?.trial_enabled) {
      //   setPricePerRoom(0);
      // }
      // else {
        setPricePerRoom(price);
      // }
    } catch (error: any) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchSavedCards();
  }, []);

  const handlePayment = async (paymentMethods: any) => {
    try {
      let data;
      if (!subscriptionExists) {
        const pricingDetailsObj: any = pricingDetails;
        const input = {
          stripe_customer_id: paymentMethods[0].customer,
          price_id: pricingDetailsObj.id,
          custom_plan_name: "PPL-LIGHT",
          item_quantity: roomsCount,
          default_payment_method: paymentMethods[0].id,
          trial: false,
        };

        data = {
          api: api.stripe.createSubscription,
          payLoad: JSON.stringify(input),
        };
      } else {
        const input = {
          subscription_id: subscriptionId,
          room_id: roomId
        };
        data = {
          api: api.stripe.reActivateSubscription,
          payLoad: JSON.stringify(input),
        };
      }

      setLoading(true);
      const response = await backend.save(data, true);
      if(response){
        messageComponent({
          messages: !subscriptionExists ? "Payment Successfull" : "Reactivation Successfull",
          type: "success",
          top: 10,
        });
        setTimeout(()=>{navigate("/home");},3500)
      }
     
    } catch (err) {
      messageComponent({ messages: "Payment Error", type: "error", top: 10 });
      console.log("err :>> ", err);
      setLoading(false);
    } 
  };

  const handleBack = () => {
    navigate("/home", { replace: true });
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 75, color: "#6A29FF" }} spin />
  );
  return (
    <Spin spinning={loading} indicator={antIcon}>
      <PageLayout>
        <PageContent className="left-content">
          <img src={logo} alt="logo" className="logo" />
        </PageContent>
        <PageContent className="right-content">
          <AppMenu className="header-menu" />
          <div className="top-section">
            <div className="header-wrapper">
              {isRooms?.length > 0 && (
                <Button
                  type="link"
                  className="back-button"
                  onClick={handleBack}
                >
                  <ArrowLeftOutlined /> Back
                </Button>
              )}
              {!subscriptionExists  ? <Title>Purchase your session rooms to continue</Title> : <Title>Reactivate your subscription </Title>}
            </div>
            {!subscriptionExists &&
            <div className="top-content-wrapper">
              <div className="no-of-rooms-wrapper">
                <Title size={14}>NUMBER OF ROOMS</Title>
                <div className="input-group">
                  <input
                    type="button"
                    value="-"
                    className={
                      roomsCount === 1 
                        ? "button-minus disabled"
                        : "button-minus"
                    }
                    data-field="quantity"
                    onClick={() => {
                      if (roomsCount > 1) {
                        setRoomsCount(roomsCount - 1);
                      }
                    }}
                    disabled={roomsCount === 1}
                    // disabled={(!isOnTrial || subscriptionStatus === "trialing"  ) && roomsCount >= 1}
                  />
                  <input
                    type="number"
                    value={roomsCount}
                    name="roomsCount"
                    className={!!subscriptionExists ? "quantity-field inactive" : "quantity-field"}
                    readOnly
                  />
                  <input
                    type="button"
                    value="+"
                    className= "button-plus disabled"
                    data-field="quantity"
                    onClick={() => {
                      setRoomsCount(roomsCount + 1);
                    }}
                    disabled={true}
                  />
                </div>
                <div>Rooms are charged USD {pricePerRoom.toFixed(2)}/mo each</div>
              {subscriptionExists ? <div className="reactivation-message">Since you are reactivating the subscription you will not be charged.</div> :<div className="reactivation-message">The service will be auto renewed unless cancelled</div> }
              </div>
              <div className="no-of-orders-wrapper">
                <Title size={14}>ORDER RESUME</Title>
                <div className="order-resume-wrapper">
                  <div className="order-resume-row">
                    <span className="support-text">Room</span>
                    <span className="support-text">x{roomsCount}</span>
                    <span className={!!subscriptionExists ? "support-text disabled" : "support-text bold"}>${(pricePerRoom * roomsCount).toFixed(2)}</span>
                  </div>
                  <Divider></Divider>
                  <div className="order-resume-row">
                    <span className="support-text">SubTotal</span>
                    <span className={!!subscriptionExists ? "support-text disabled" : "support-text bold"}>
                      ${(pricePerRoom * roomsCount).toFixed(2)}
                    </span>
                  </div>
                  <Divider></Divider>
                  <div className="order-resume-row">
                    <span className="support-text">Taxes</span>
                    <span className={!!subscriptionExists ? "support-text disabled" : "support-text bold"}>{taxes}</span>
                  </div>
                  <Divider></Divider>
                  <div className="order-resume-row">
                    <span className="support-text bold uppercase">Total</span>
                    <span className={!!subscriptionExists ?"inactive":"total-amount bold"}>
                      ${(pricePerRoom * roomsCount + taxes).toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </div>}
          </div>
          <Divider></Divider>
          <div className="bottom-section">
            <Title>Payment Methods</Title>
            <PaymentMethods uuid={uiid}  handlePayment={handlePayment} reactivateFlag={subscriptionExists} />
          </div>
        </PageContent>
      </PageLayout>
    </Spin>
  );
};

export default PurchaseRoom;

// import { useState, useEffect } from "react";
//import useDbTrigger from "../../hooks/useDbtrigger";
import useToggle from "../../../utils/useToggle";
import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";
import { get } from "../../../utils/helpers";
import { Button, Modal } from "antd";
import { useState, useContext } from "react";
import { GuestListContext } from "../../../pages/ZoomViewer/context/guest-context";
import { AuthContext } from "../../../context/auth";
import ConfirmationDialog from "../../../pages/Viewer/ConfirmationDialog/Index";
import ZoomContext from "../../../pages/ZoomViewer/context/zoom-context";
import { useParticipantsChange } from "../../../pages/ZoomViewer/hooks/useParticipantsChange";

const GuestUsersList = () => {
  const { guestList } = useContext(GuestListContext);
  const zmClient = useContext(ZoomContext);

  const [showModal, setShowModal] = useState(false);
  const [participants, setParticipants] = useState(zmClient.getAllUser());
  const userLoggedIn: any = useContext(AuthContext);
  const token = get(userLoggedIn, "auth.currentUser.accessToken", "");

  useParticipantsChange(zmClient, (participants) => {
    setParticipants(participants);
  });

  const sortedGuestList = guestList.sort((guestA: any, guestB: any) => {
    if (get(guestA, "status", "") < get(guestB, "status", "")) {
      return -1;
    }
    return 1;
  });

  const activeParticipantIds = participants.map(
    (participant: any) => participant.userIdentity
  );
  console.log(":::activeParticipantIds::::", activeParticipantIds);
  const [
    removeConfirmationOpen,
    showRemoveConfirmation,
    hideRemoveConfirmation,
    confirmationData,
  ] = useToggle<{ displayName: string; identity: string; guestId: string }>();

  const updateGuestStatus = async (id: string, status: string) => {
    console.log("called");
    try {
      const data = {
        api: api.room.updateGuestStatus,
        urlParam: id,
        payLoad: JSON.stringify({
          status,
        }),
      };
      await backend.save(data, true, token);

      hideRemoveConfirmation();
    } catch (err: any) {
      console.log("err", err);
      if (err.errCode === 10206) {
        setShowModal(true);
      }
    }
  };

  return (
    <>
      {sortedGuestList.map((guest: any, key: any) => {
        const displayName = `${guest?.identity?.split("_")[1]}`;
        const allowed = guest?.status === "approved";
        const status = allowed ? "Active" : "Waiting";
        return (
          <>
            {((guest?.status === "approved" &&
              activeParticipantIds.includes(guest?.identity.slice(0,47))) ||
              guest?.status === "waiting") && (
              <div className="guest-viewer-url-container" key={guest?.identity}>
                <div className="guest-viewer-url-container-item">
                  <div>
                    <div>{`${displayName}`}</div>
                    <div>
                      Status:&nbsp;<i>{status}</i>
                    </div>
                  </div>
                </div>

                <div
                  className="guest-viewer-url-container-item"
                  onClick={() => {}}
                >
                  <div className="guest-user-remove-btn-wrapper">
                    {guest?.status === "approved" && (
                      <button
                        key={guest?.id}
                        className="guest-user-list-remove-btn"
                        onClick={() => {
                          const { identity } = guest;
                          showRemoveConfirmation.withData({
                            displayName,
                            identity,
                            guestId: guest?.id,
                          });
                        }}
                      >
                        Remove
                      </button>
                    )}
                    {!allowed && (
                      <button
                        key={guest?.id}
                        className="guest-user-list-remove-btn"
                        onClick={() => {
                          updateGuestStatus(guest?.id, "approved");
                        }}
                      >
                        Allow
                      </button>
                    )}
                  </div>

                  <div className="guest-user-status-circle">
                    <div
                      className={
                        !allowed
                          ? "guest-user-status-waiting"
                          : "guest-url-status-active"
                      }
                    ></div>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      })}
      <ConfirmationDialog
        open={removeConfirmationOpen}
        onClose={hideRemoveConfirmation}
        onConfirm={() => {
          updateGuestStatus(confirmationData?.guestId || "", "rejected");
        }}
        onCancel={hideRemoveConfirmation}
        title={`Remove ${confirmationData?.displayName}`}
        message="Are you sure you want to remove this guest?"
      />
      <Modal
        open={showModal}
        centered={true}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={[
          <Button
            type="primary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            OK
          </Button>,
        ]}
      >
        <div className="btn-close">
          You can't allow as the room has reached it's limit.
        </div>
      </Modal>
    </>
  );
};

export default GuestUsersList;

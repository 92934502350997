import { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { doSignOut } from "../../firebase/auth";
import Viewer from "./Viewer";
import WaitingRoom from "./WaitingRoom";
// import useDbTrigger from "../../hooks/useDbtrigger";
// import { AuthContext } from "../../context/auth";
import { auth } from "../../firebase/utils";
import api from "../../service/rest/restApi";
import backend from "../../service/rest/restService";
import {
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { firestore } from "./../../firebase/utils";
import { get } from "../../utils/helpers";
import { Button, Modal } from "antd";
import { useFeatureFlagStore } from "../../store";
import ZoomShowViewerDashboard from "../ZoomViewer/Viewer";
import WaitingRoomLoader from "./WaitingRoomLoader";

const ParticipantViewer = (props: any) => {
  const { featureFlags, isFFLoading } = useFeatureFlagStore()
  const navigate = useNavigate();
  
  const videoConfEnd = useRef(null as any);
  const {
    user_id,
    room_id,
    room_code,
    room_name,
    firstName,
    lastName,
    uuid,
    waitingRoomid,
    resetValues,
  } = props;
  const [autoAllow, setAutoAllow] = useState(false);
  const [approved, setApproved] = useState(false);
  const [progress, setProgress] = useState(true);
  const [modal, contextHolder] = Modal.useModal();
  const loadingRef = useRef(false);
  const progressRef = useRef(true);
  const autoAllowRef = useRef(false);
  const approvedRef = useRef(false);
  const zoomVideoRef = useRef<any>(null);
  const displayName = `${firstName} ${lastName}`;
  const identity = `member#${uuid}_${displayName}`;

  const pageAccessedByReload = (
    (window?.performance?.navigation && window.performance.navigation.type === 1) ||
      window.performance
        .getEntriesByType('navigation')
        .map((nav:any) => nav.type)
        .includes('reload')
  );


  useEffect(() => {
    

    // Add user to waiting room
    const userDocRef = doc(firestore, "waiting_room", waitingRoomid);
  
    // setDoc(userDocRef, { timestamp: serverTimestamp() });
    const dataToUpdate = {
      modified_ts: serverTimestamp()}
      updateDoc(userDocRef, dataToUpdate)
    // Heartbeat mechanism
    // const interval = setInterval(() => {
    //   updateDoc(userDocRef, { timestamp: serverTimestamp() });
    // }, HEARTBEAT_INTERVAL);
    const leaveZoomVc = () =>{
      if(zoomVideoRef.current){
        // eslint-disable-next-line react-hooks/exhaustive-deps
        zoomVideoRef?.current?.destroyClient()
      }
    }

    // Cleanup function
    const cleanup = () => {
      
     console.log(":::::::::::",!autoAllowRef.current,!approvedRef.current,!progressRef.current)
      // clearInterval(interval);
      if((!autoAllowRef.current && !approvedRef.current && !progressRef.current)){
        // We are directlty accesseing the db.
        // Need to revisit
        // if(!pageAccessedByReload){
          deleteDoc(userDocRef);
        // }
        
      }
    };

    window.addEventListener("beforeunload", cleanup);
    window.addEventListener("unload", leaveZoomVc);

    return () => {
      window.removeEventListener("beforeunload", cleanup);
      window.removeEventListener("unload", leaveZoomVc);
      sessionStorage.setItem('isRefreshed',`${pageAccessedByReload}`)
      // alert(pageAccessedByReload)
   
      cleanup();
    };
  }, []);
  
  const handleRejectCall = () =>{
    const isRefreshed = Boolean(sessionStorage.getItem('isRefreshed'))
      console.log("::::isRefreshed:::",isRefreshed)
    if(!isRefreshed){
      handleRejected();
    }
    sessionStorage.setItem('isRefreshed',`${false}`)
  }
   

  const handleRejected = async () => {
    if (videoConfEnd?.current) {
      await videoConfEnd?.current();
      await doSignOut();
      navigate("/viewerLogoutPage", { replace: true });
    }

    resetValues()
    await doSignOut();
    navigate("/viewerLogoutPage", { replace: true });
  };

 

  useEffect(() => {
    getAutoAllow();
    // return () => {
    //   if(componentMounted.current){
    //     console.log(":::::::::::::::>>>>>")
    //     // handleRejected();
    //   }
    //   componentMounted.current = true
    // };
  }, []);

  const getAutoAllow = async () => {
    try {
      progressRef.current = true;
      const autoAllowData = {
        api: api.room.autoAllow,
        payLoad: JSON.stringify({
          room_code,
          user_id,
          room_id,
          identity,
        }),
      };
      const autoAllowRes: any = await backend.save(autoAllowData);

      const { auto_allow = false, guestApproved = false } = autoAllowRes;
      setAutoAllow(auto_allow);
      setApproved(guestApproved);
      autoAllowRef.current = auto_allow;
      approvedRef.current = guestApproved;
      sessionStorage.setItem('waiting_room_id',get(autoAllowRes,'waiting_room_id',''))
    } catch (err) {
      console.log("err", err);
    } finally{
      progressRef.current =false;
      setProgress(false)
    }
  };
  return (
    <>
      {!!progress || loadingRef.current || isFFLoading ? (
        <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div>Validating in progress</div>
      </div>
        
      ) : // <div
      //   style={{
      //     display: "flex",
      //     alignItems: "center",
      //     justifyContent: "center",
      //     height: "100vh",
      //   }}
      // >
      //   <div>Validating in progress</div>
      // </div>
      !autoAllow && !approved && !progress ? (
        <WaitingRoom approved={approved} handleRejected={handleRejected} />
      ) : get(
          featureFlags.find((flag: any) => flag.feature_name === "zoom"),
          "feature_enable",
          false
        ) === true ? (
        <ZoomShowViewerDashboard
          videoConfEndRef={videoConfEnd}
          admin_user_id={user_id}
          room_id={room_id}
          room_name={room_name}
          room_code={room_code}
          role="viewer"
          firstName={firstName}
          lastName={lastName}
          uuid={uuid}
          resetValues={resetValues}
          zoomVideoRef={zoomVideoRef}
        />
      ) : (
        <Viewer
          videoConfEndRef={videoConfEnd}
          admin_user_id={user_id}
          room_id={room_id}
          room_name={room_name}
          room_code={room_code}
          role="viewer"
          firstName={firstName}
          lastName={lastName}
          uuid={uuid}
          resetValues={resetValues}
        />
      )}
      <WaitingRoomLoader
          user_id={user_id}
          room_id={room_id}
          identity={identity}
          approvedRef={approvedRef}
          approved={approved}
          setApproved={setApproved}
          handleRejected={handleRejected}
          modal={modal}
          loadingRef={loadingRef}
          handleRejectCall={handleRejectCall}
        />
      {contextHolder}
    </>
  );
};

export default ParticipantViewer;

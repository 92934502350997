import Rooms from "../../components/Rooms";
import "./styles.scss";

const HomePage = (props: any) => {
  return (
      <Rooms />
  );
};

export default HomePage;

import { collection, getFirestore, onSnapshot } from 'firebase/firestore';
import { app } from "../firebase/utils";
import { useEffect, useState } from 'react';

let subscriptionCheck: Boolean = false;
export default function useDbTrigger(db_collection: string, subscribeflag: boolean) {
  const [dbChanges, setDbChanges] = useState([] as any);
  let unSubscribe: any = null;
  // const [subscribeflag, setSubscriptionCheck] = useState(subscribeflag);
  subscriptionCheck = subscribeflag;

  useEffect(() => {
    async function checkDbChange() {
      const getDbCollectionData = collection(getFirestore(app), db_collection);
      unSubscribe = onSnapshot(getDbCollectionData, (snap: any) => {
        snap.docChanges().forEach((element: any) => {
          console.log("useDbTrigger-element", element);
          setDbChanges(element);
        });
      });
    }

    if (subscribeflag && !unSubscribe) {
      checkDbChange();
    }
    return () => {
      if (!!unSubscribe && subscribeflag) {
        unSubscribe();
      }
    };
  }, [db_collection, subscribeflag]);

  return dbChanges;
}
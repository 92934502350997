import React, { 
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
  DOMAttributes,
  HTMLAttributes,
  DetailedHTMLProps, 
  useMemo,
  memo} from 'react'
import ContentLoader from 'react-content-loader';
import classnames from 'classnames';
import { type VideoPlayerContainer, type VideoPlayer, VideoQuality, Participant, ExecutedFailure } from '@zoom/videosdk';
import ZoomContext from '../../pages/ZoomViewer/context/zoom-context';
import ZoomMediaContext from '../../pages/ZoomViewer/context/media-context';
import { useParticipantsChange } from '../../pages/ZoomViewer/hooks/useParticipantsChange';
import { useActiveVideo } from '../../pages/ZoomViewer/hooks/useActiveVideo';
import { useVideoAspect } from '../../pages/ZoomViewer/hooks/useVideoAspectRatio';
import { usePrevious } from '../../pages/ZoomViewer/hooks/usePrevious';
import chevronLeft from '../../assets/icons/chevron-left.svg';
import chevronRight from '../../assets/icons/chevron-right.svg';
import arrowDown from '../../assets/icons/arrow-down.svg';
import arrowUp from '../../assets/icons/arrow-up.svg';
import ZoomVideoTile from './ZoomVideoTile';

import './styles.scss';
import { ParticipantType } from '../../pages/ZoomViewer/types/index-types';
// import { useUniqueParticipants } from './useUniqueParticipants';
import { sortedParticipants } from './VideoTrayHelpers';
import { get } from '../../utils/helpers';
import _ from 'lodash';
import { useViewerSlice } from '../../store';

type CustomElement<T> = Partial<T & DOMAttributes<T> & { children: any }>;

interface Props {
  overlay: any;
  overlayMsg: any;
  overlayMsgConnected: any;
  overlayConnected: any;
  overlayDisconnected: any;
  overlayMsgDisconnected: any;
  troom: any;
  users: any;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['video-player']: DetailedHTMLProps<HTMLAttributes<VideoPlayer>, VideoPlayer> & { class?: string };
      ['video-player-container']: CustomElement<VideoPlayerContainer> & { class?: string };
    }
  }
}
  
const ZoomVideoTray = (props:any) => {
  const {alreadyJoinedGuests} =props;
  const zmClient = useContext(ZoomContext);
  const { mediaStream } = useContext(ZoomMediaContext);
  const currentUser = zmClient.getCurrentUserInfo();
  const [participants, setParticipants] = useState(sortedParticipants(zmClient.getAllUser(),get(currentUser,'userIdentity','')))
  const [subscribers, setSubscribers] = useState<number[]>(participants.filter((user) => user.bVideoOn).map((u) => u.userId));
  const videoPlayerListRef = useRef<Record<string, VideoPlayer>>({});
  // const activeVideo = useActiveVideo(zmClient);
  const aspectRatio = useVideoAspect(zmClient);
  const previousSubscribers = usePrevious(subscribers);

  const { layoutMode } = useViewerSlice();


  // Check the change in user object 
  type Changes = {
    [key: string]: 'Deleted' | 'Added' | { from: any, to: any }
  };
  
  function difference(obj1: object, obj2: object): Changes {
    const changes: Changes = {};
    const differences = {}
    function compare(obj1: object, obj2: object, changes: Changes, path: string[] = []): void {
      for (const key in obj1) {
        const currentPath = path.concat(key);
        if (_.isEqual((obj1 as any)[key], (obj2 as any)[key])) continue;
        if (!obj2.hasOwnProperty(key)) {
          changes[currentPath.join('.')] = 'Deleted';
        } else if (typeof (obj1 as any)[key] === 'object' && typeof (obj2 as any)[key] === 'object') {
          compare((obj1 as any)[key], (obj2 as any)[key], changes, currentPath);
        } else {
          // console.log("::updated user:::",changes,path, key, obj1,obj2)
          Object.assign(differences,{
            userId:get(path,'0',''),
            isVideoChanged:(key === 'bVideoOn') ,
            videoState:get(obj2,'bVideoOn',false),
            muteState:get(obj2,'muted',false),
            userIdentity:get(obj2,'userIdentity','')
          })
          changes[currentPath.join('.')] = { from: (obj1 as any)[key], to: (obj2 as any)[key] };
          // console.log("::updated user:::",changes)
        }
      }
      for (const key in obj2) {
        if (!obj1.hasOwnProperty(key)) {
          const currentPath = path.concat(key);
          changes[currentPath.join('.')] = 'Added';
        }
      }
    }
  
    compare(obj1, obj2, changes);
    return differences;
  }
  
  
  useParticipantsChange(zmClient, async (updatedparticipants) => {
    const updatedParticipantObject = Object.assign(
      {},
      ...updatedparticipants.map((item) => ({ [item.userId]: item }))
    );
    const participantObject = Object.assign(
      {},
      ...participants.map((item) => ({ [item.userId]: item }))
    );
    const paticipantUpdates = difference(
      participantObject,
      updatedParticipantObject
    );
    const user_id = Number(`${get(paticipantUpdates, "userId", "")}`);
    const user_identity = get(paticipantUpdates, "userIdentity", "")
    const participant_removed = participants.length && (updatedparticipants.length < participants.length)
    // console.log("::::::paticipantUpdates::::::",paticipantUpdates)
    // console.log("::::::isVideoChanged::::::",get(paticipantUpdates,'isVideoChanged',false))
    // console.log("::::::videoState::::::",get(paticipantUpdates,'videoState',false))
    // if(get(paticipantUpdates,'isVideoChanged',false) && get(paticipantUpdates,'videoState',false)){
    //   // Participant video turned on. Attach the video
    //   console.log("::::::video turned on::::::")
    //   let video_player:any = document.getElementById(`player_${user_id}`)
    //   mediaStream?.attachVideo(user_id,1,video_player);
    //   alreadyJoinedGuests.current.push(user_id)
    // }else if(get(paticipantUpdates,'isVideoChanged',false) && alreadyJoinedGuests.current.includes(user_id)){
    //   //Participant video turned off
    //   console.log("::::::video turned off::::::")
    //   mediaStream?.detachVideo(user_id);
    //   alreadyJoinedGuests.current = alreadyJoinedGuests.current.filter((user_ids:number)=> user_ids !== user_id)
    // }
    // console.log("::::::::::partcipant old video",participantObject[Number(user_id)]?.bVideoOn )
    // console.log("::::::::::partcipant old mute", participantObject[Number(user_id)]?.muted )
    // console.log("::::::::::paticipantvideoState video",paticipantUpdates.videoState)
    // console.log("::::::::::paticipantvideoState mute",paticipantUpdates.muteState)
    const changeInVideo = participantObject[Number(user_id)]?.bVideoOn !== paticipantUpdates.videoState;
    // console.log("::::::::::changeInVideo",changeInVideo);
    // console.log("::::::::::paticipantUpdates.videoState",paticipantUpdates.videoState);
    if (changeInVideo) {
      if (paticipantUpdates.videoState) {
        //Attach
        // setTimeout(async () => {
        // await mediaStream?.detachVideo(user_id);
        const userVideo: VideoPlayer | ExecutedFailure | undefined = await mediaStream?.attachVideo(user_id, 1);
        let video_player_container: any = document.getElementById(`player_${user_identity}`);
        if (!get(video_player_container, "children", "").length) {
          const childArray = Array.from(video_player_container.children);

          if (childArray.length) {
            console.log(":::: children", childArray);
            childArray.forEach((child: any) => {
              console.log("::::removed children", child);
              video_player_container.removeChild(child);
            });
          }
          console.log("::::appendd child", user_identity);
          video_player_container.appendChild(userVideo);
          setVideoPlayerRef(user_identity,userVideo)
        }

        // }, 500);
      } else {
        //Detach
        console.log(":::hooks:::video turned off::::::", user_identity);
        let video_player: any = document.getElementById(`player_${user_identity}`);
        if (video_player?.children?.length) {
          console.log(":::: children", video_player?.children);
          Array.from(video_player?.children).forEach((child: any) => {
            console.log("::::removed children", child);
            video_player.removeChild(child);
          });
          await mediaStream?.detachVideo(user_id);
          delete videoPlayerListRef.current[`${user_identity}`]
        } 
      }
    }

    //Reattach all the other participants
    if(participant_removed){
      updatedparticipants.forEach((participant:Participant)=>{
      const videoContainer = document.getElementById(`player_${participant.userIdentity}`)
      if(videoContainer && videoContainer.children.length){
        console.log("::::replacing all videos",participant.userIdentity)
        videoContainer?.replaceChild(videoPlayerListRef.current[`${participant.userIdentity}`],videoContainer.children[0]);
      }
      })
    }

    setParticipants(updatedparticipants);
    const subscriberArray = updatedparticipants
      .filter((user) => user.bVideoOn)
      .map((u) => u.userId);
    setSubscribers(subscriberArray);
  });

  const setVideoPlayerRef = (userIdentity: number, element: any) => {
    if (element) {
     videoPlayerListRef.current[`${userIdentity}`] = element
    }
  };  

  useEffect(() => {
    const checkforExisting = setTimeout(() => {
      const addedUsers = subscribers.filter((user) => !(previousSubscribers || []).includes(user));
      const removedUsers = (previousSubscribers || []).filter((user) => !subscribers.includes(user));
      
      //Detach the video from already attached video players and attach again.
      // subscribers.forEach((userId: number) => {
      //   if (alreadyJoinedGuests.current.includes(userId)) {
      //     removedUsers.push(userId);
      //     addedUsers.push(userId);
      //   }
      // });
   
      if (removedUsers.length > 0) {
        removedUsers.forEach(async (userId) => {
          if(mediaStream && userId !== currentUser.userId){
            console.log(":::useffect:::video turned off::::::")
            const parti = participants.find((particpant:Participant)=> particpant.userId === userId) 
            console.log("::::removed::>>",parti?.displayName)
            await mediaStream?.detachVideo(userId);
            alreadyJoinedGuests.current = alreadyJoinedGuests.current.filter((user_ids:number)=> user_ids !== userId)
            delete videoPlayerListRef.current[`${userId}`]
          }
        });
      }
    
      if (addedUsers.length > 0) {       
        addedUsers.forEach(async (userId) => {
          if (mediaStream) {
            const joinedParticipant = participants.find((particpant:Participant)=> particpant.userId === userId) 
            console.log(":::useffect:::video turned on::::::")
            console.log("::::attached::>>",joinedParticipant?.displayName)
            // let video_player:any = document.getElementById(`player_${userId}`)
            // console.log("::::attached::video-player>>",video_player)
            const userVideo = await mediaStream?.attachVideo(userId, 1);
            let video_player:any = document.getElementById(`player_${joinedParticipant?.userIdentity}`)
            video_player.appendChild(userVideo);
            setVideoPlayerRef(get(joinedParticipant,'userIdentity',''),userVideo)
            alreadyJoinedGuests.current.push(userId)
            // videoPlayerListRef.current[`${userId}`].setAttribute('videoattached','true')
          }
        });
      }
    }, 1000)

    
    return ()=>{
      clearTimeout(checkforExisting);
    }
  }, []);
  
  const moveLeft = async (event: React.MouseEvent<HTMLElement>) => {
    document.getElementById('vtray')?.scrollBy(-250, 0);
  };
  const moveRight = async (event: React.MouseEvent<HTMLElement>) => {
    document.getElementById('vtray')?.scrollBy(250, 0);
  };

  const [toggleVideoTray, setToggleVideoTray] = useState(false);

  const handleToggleVideoTray = () => {
    setToggleVideoTray(!toggleVideoTray)
  }

  const renderExpandCollapseArrow = () => {
    if (layoutMode === 'inverse') {
      return !toggleVideoTray ?
        <img src={arrowUp} alt="Collapse Video Tray" className="expand-collapse-icon"/> :
        <img src={arrowDown} alt="Collapse Video Tray" className="expand-collapse-icon"/>;
    }
    return !toggleVideoTray ?
      <img src={arrowDown} alt="Collapse Video Tray" className="expand-collapse-icon"/> :
      <img src={arrowUp} alt="Collapse Video Tray" className="expand-collapse-icon"/>;
  }

  return (
    <>
      <div className={`video-tray-collapse-bg ${layoutMode === 'inverse' ? 'inverse-layout' : ''}`}></div>
        <div className={`video-tiles-row-wrapper ${layoutMode === 'inverse' ? 'inverse-layout' : ''} ${toggleVideoTray ? 'collapse-tray' : ''}`}>
        <button className='flex-prev tray-btns' onClick={moveLeft}>
          <img src={chevronLeft} alt="left-btn"/>
        </button>
        {participants.length ? (
          <div className="videotray" id="vtray">
            <div
              className={classnames(
                "video-container",
                "video-container-attech"
              )}
            >
              <div className="video-container-wrap">
                <ul className="user-list">
                <video-player-container
                        // className={`video-player-container-${user.userId}`}
                        // id={`video-player-container-div${user.userId}`}
                        >
                  {participants.map((user) => {
                    return (
                      <li  id={`video-player-container-div${user.userIdentity}`}
                      key={`video-player-container-div${user.userIdentity}`}>
                       
                          <ZoomVideoTile
                            zmClient={zmClient}
                            mediaStream={mediaStream}
                            aspectRatio={aspectRatio}
                            videoPlayerListRef={videoPlayerListRef}
                            key={`video-tray-player-key${user.userIdentity}`}
                            // setVideoPlayerRef={setVideoPlayerRef}
                            user={user}
                            // enableControls={activeVideo === user.userId}
                            currentUser={currentUser}
                          />
                       
                      </li>
                    );
                  })}
                   </video-player-container>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="empty-video-tiles-placeholder">
            <ContentLoader
              width={190}
              height={200}
              backgroundColor="#000005"
              foregroundColor="#1d1d1d"
            >
              <rect x="16" y="16" rx="2" ry="2" width="190" height="125" />
              <rect x="16" y="146" rx="2" ry="2" width="190" height="30" />
            </ContentLoader>
          </div>
        )}

        <button className='flex-next tray-btns' onClick={moveRight}>
          <img src={chevronRight} alt="right-btn"/>
        </button>
        {/* <div className='overlay-vtry' style={overlay.style}>
          <span className='overlay-vtry-text'>{overlayMsg}</span>
        </div>

        <div className='overlay-participant-connected' style={overlayConnected.style}>
          <span className='overlay-vtry-text'>{overlayMsgConnected}</span>
        </div>

        <div className='overlay-participant-disconnected' style={overlayDisconnected.style}>
          <span className='overlay-vtry-text'>{overlayMsgDisconnected}</span>
        </div> */}
      </div>
      <div className={layoutMode === "normal" ? "expand-collapse-btn" : "expand-collapse-btn expand-collapse-btn-inverse"} onClick={handleToggleVideoTray}>
        {renderExpandCollapseArrow()}
      </div>
    </>
  );
};
export default memo(ZoomVideoTray);

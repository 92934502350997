import { useEffect, useState } from "react";
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { StripeCardNumberElement, } from "@stripe/stripe-js";
import './styles.scss';
import { Alert, Button, Form, Spin } from "antd";
import InputComponent from "../../../InputComponent";

import api from "../../../../service/rest/restApi";
import backend from "../../../../service/rest/restService";
import Title from "../../../Title";
import messageComponent from "../../../../utils/message";
import Loader from "../../../LoaderHOC";


const SetupCard = (props: any) => {
  const { setIsAddEditCardVisible } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [isDeclinedMessage, setIsDeclinedMessage] = useState(" ")
  const [error, setError] = useState("");
  const [isInputComplete, setIsInputComplete] = useState({
    firstName: true,
    lastName: true,
    cardNumber: true,
    cardCvc: true,
    expiryDate: true,
  })

  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')

  useEffect(() => {

  }, [isInputComplete.cardNumber, isInputComplete.cardCvc, isInputComplete.expiryDate,isDeclinedMessage])

  const createStripeUserPaymentMethod = async (payment_method_name: string, payment_method_token: string) => {
    try {
      if (!payment_method_name || !payment_method_token) {
        return;
      }

      const input = {
        payment_method_name,
        payment_method_token
      };
      console.log(input)

      const data = {
        api: api.payment_method.createPaymentMethod,
        payLoad: JSON.stringify(input),
      };
      setLoading(true);
      const response = await backend.save(data, true);
      setIsAddEditCardVisible(false)

      if (response && response.id) {
        setSuccess(true);
        setMessage("Card Saved Successfully");
        setLoading(false);

      }

    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  const confirmCardSetup = async (client_secret: string) => {
    if (!client_secret || !stripe || !elements || !name || !surname) {
      setLoading(false)
      return;
    }

    const cardElement: StripeCardNumberElement | null =
      elements.getElement(CardNumberElement);

    if (!cardElement) {
      return;
    }
    const result: any = await stripe.confirmCardSetup(client_secret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: `${name} ${surname}`
        },
      },
    });

    if (result.error) {
      // Error
      const { message } = result.error;
      setIsDeclinedMessage(message)
      setLoading(false)

    } else {
      // Success
      if (result && result.setupIntent && result.setupIntent.payment_method) {

        createStripeUserPaymentMethod(result.setupIntent.payment_method, "ISPLCard");
      }
    }
  };

  const createStripeSetupIntent = async () => {
    try {
      const data = {
        api: api.stripe.createSetupIntent,
      };

      setLoading(true);
      const stripeSetupIntent = await backend.save(data, true);
      // setLoading(false);

      if (stripeSetupIntent && !stripeSetupIntent.client_secret) {
        setError("Error while setting up the card");
        return;
      }
      const client_secret: string = stripeSetupIntent.client_secret;

      confirmCardSetup(client_secret);
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsDeclinedMessage("")

    setError("");
    setMessage("");
    setSuccess(false);

    if (!stripe || !elements) {
      return;
    }

    createStripeSetupIntent();
  };

  let elementStyles = {
    base: {
      fontFamily: 'sans-serif',
      lineHeight: '46px',
      fontSize: '16px',
      '::placeholder': {
        color: '#aaa',
      },
      ':-webkit-autofill': {
        color: '#e39f48',
      },
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
  };

  const handleCardNumber = (e: any) => {
    if (e.complete) {
      setIsDeclinedMessage("");
      console.log("typing in card number......")
      setIsInputComplete((state) => ({
        ...state,
        cardNumber: false
      }))
    } else if (e.empty || e.error) {
      console.log("typing in card number......")
      setIsInputComplete((state) => ({
        ...state,
        cardNumber: true
      }))
    }
  }

  const handleCardExpiry = (e: any) => {
    if (e.complete) {
      setIsInputComplete((state) => ({
        ...state,
        expiryDate: false
      }))
    } else if (e.empty || e.error) {
      setIsInputComplete((state) => ({
        ...state,
        expiryDate: true
      }))
    }
  }

  const handleCardCVC = (e: any) => {
    if (e.complete) {
      setIsInputComplete((state) => ({
        ...state,
        cardCvc: false
      }))
    } else if (e.empty || e.error) {
      setIsInputComplete((state) => ({
        ...state,
        cardCvc: true
      }))
    }
  }

  const handleFirstName = (value: any) => {
    setIsInputComplete((state) => ({
      ...state,
      firstName: !value
    }))
    setName(value)

  }

  const handleLastName = (value: any) => {
    console.log(value)
    setIsInputComplete((state) => ({
      ...state,
      lastName: !value
    }))
    setSurname(value)
  }


  return (
    <Loader loading={loading}>
    <div className="setup-card">

      <form className="setup-card-form" onSubmit={handleSubmit}>
        <div>
          <Title value={10} size={20}> Add credit or debit card </Title>
          {success && <p> {message} </p>}
          {error && <p>{error}</p>}
          <br />
          <div className="setup-card-input-wrapper">
            {/* <Form.Item
              name="Name"
              rules={[
                { required: true, message: "Name cannot be empty" },
              ]}
            > */}
            <div>
              <label>FIRST NAME</label>
              <InputComponent
                className="input-field"
                //label="first name"
                value={name}
                onChange={handleFirstName}
                autoComplete="new-password"
              />
            </div>
            {/* </Form.Item> */}
            <div>
              <label>LAST NAME</label>
              <InputComponent
                className="input-field"
                //label="last name"
                value={surname}
                onChange={handleLastName}
                autoComplete="new-password"
              />
            </div>
          </div>

          <label>CARD NUMBER</label>
          <CardNumberElement
            options={{
              style: elementStyles,
              placeholder: 'Card Number',
              showIcon: true,
            }}
            onChange={handleCardNumber}
            className="stripe-card-input"
            id="card-number"
          />

          <label>EXPIRATION DATE</label>
          <CardExpiryElement
            options={{
              style: elementStyles
            }}
            onChange={handleCardExpiry}
            className="stripe-card-input" />

          <label>CVV</label>
          <CardCvcElement
            options={{
              style: elementStyles
            }}
            onChange={handleCardCVC}

            className="stripe-card-input" />

          <br />

        </div>
        <div className="btn-wrapper">
          <Button
            onClick={handleSubmit}
            className="btn-continue"
            disabled={isInputComplete.cardNumber || isInputComplete.cardCvc || isInputComplete.expiryDate || isInputComplete.lastName || isInputComplete.firstName}
            size="large"
            type="primary" > Continue
          </Button>

        </div>
        <br />
        {!!isDeclinedMessage && isDeclinedMessage != " " && ( <Alert closable message={isDeclinedMessage} type="error" showIcon />)}
      </form>
      {/* <div className="setup-card-info">
        <div className="payment-card-wrapper">
          <div className="payment-card-header">header</div>
          <div className="payment-card-body">
            <p className="payment-card-number">4213 0032 1010 5589</p>
            <div className="payment-card-name">
              <p className="name">George Martin</p>
              <p className="expiry">01/27</p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
    </Loader>
  );
};

export default SetupCard;

export const isAvidBrowser = () => {
  const userAgentString = window.navigator.userAgent.toLowerCase();

  // Extracting browser name and version
  const browserMatch = userAgentString.match(
    /(chrome|safari|firefox|msie|trident|edge|opera|opr)\/([\d.]+)/i,
  );

  // const userBrand = navigator.userAgent.brands[0].brand;
  // const isChromium =
  //   userAgentString.includes('chrome') &&
  //   userAgentString.includes('safari') &&
  //   userAgentString.includes('chromium');
  // const browserName = browserMatch ? browserMatch[1] : 'Unknown Browser';
  const browserVersion = browserMatch ? browserMatch[2] : 'Unknown Version';
  if (browserVersion === '87.0.4280.144') {
    return true;
  }
  return false;
};

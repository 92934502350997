import refreshIcon from "../../../../pages/Viewer/Images/reload.svg";
import messageComponent from "../../../../utils/message";
import useGetroomupdates from "../../../../hooks/useGetroomupdates";
import { get } from "../../../../utils/helpers";
import { useStreamSlice, useWhiteboardingStore } from "../../../../store";

interface ReloadProps {
    showDomSpeaker: any;
    admin_user_id: string;
    adminCode: string;
}
let streamingStatus = false;
const Reload = (props: ReloadProps) => {
    const { setStreamKey } = useStreamSlice();
    const reloadPage = () => { setStreamKey() };
    const { resetAllTools } = useWhiteboardingStore();
    const { showDomSpeaker, admin_user_id } = props;
    const reloadFlag = useGetroomupdates("users", true, admin_user_id);
    const triggerData = reloadFlag?.doc?.data();
    const room_name = sessionStorage.getItem('room_name') || ''; 
    if (
        streamingStatus !== get(triggerData, "streaming", false) &&
        get(reloadFlag, "type", "") === "modified" &&
        get(triggerData, "stream_room_name", "") === room_name &&
        get(triggerData, "stream_status", "") !== "Deleted"
    ) {
        streamingStatus = get(triggerData, "streaming", false);
        if (streamingStatus) {
            messageComponent({
                messages: "Streaming started",
                type: "success",
                top: 10,
            });
            reloadPage();
        }
        sessionStorage.setItem('room_name',get(triggerData, "stream_room_name", "") )
    }
    return (
        <div
        onClick={() => 
            {reloadPage();
                resetAllTools();
             }}
            className={`top-menu-button-item ${showDomSpeaker ? "disableddiv" : ""}`}
        >
            <img src={refreshIcon} alt="" />
            <span>Reload Page</span>
        </div>
    );
};
export default Reload;

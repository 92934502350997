const dateFormat = 'DD/MM/YYYY'
export const getInitials = (name: any) => {
  if (!name) {
    return '';
  }
  return name
    .split(" ")
    .map((item: any) => item.split("")[0])
    .join("")
    .toString()
    .toUpperCase();
};
export const get = (source: any, path: string, defaultValue: any) => {
  if (!!source && !!path) {
    const parts = path.split(".");
    const length = parts.length;
    let result = source;
    for (let i = 0; i < length; i++) {
      let item = result[parts[i]];
      if (item === null || item === undefined) {
        return item || defaultValue;
      }
      result = item;
    }
    return result;
  }
  return defaultValue;
};

export const isIOS = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && navigator.maxTouchPoints > 1);
}



// export const trialEndDate = (creationDate: any) => {
//   // let creationDate = creationTime + (3 * 86400000);
//   let trialStartDate = new Date(creationDate);
//   console.log('check', creationDate)
//   trialStartDate.setDate(trialStartDate.getDate() + 2)

//   return moment(trialStartDate).format(dateFormat)
// }

import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Modal, Space } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "../../../firebase/utils";
import logo from "../../../assets/images/sidebar-logo.png";
import PageLayout, { PageContent } from "../../../layouts/PageLayout";
import InputComponent from "../../InputComponent";
import Title from "../../Title";
import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";
import Loader from "../../LoaderHOC";
import ErrorComponent from "../../ErrorComponent";

import "./styles.scss";
import { get } from "../../../utils/helpers";

const reCaptchaSiteKey: string = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";
interface openAuthProps{
  role:string
}

const ViewerOpenAuth = (props:openAuthProps) => {
  const {role} = props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [errModalOpen, setErrModalOpen] = useState(false);

  const { search } = useLocation();

  const viewerLogin = async () => {
    try {
      const token = new URLSearchParams(search).get("token");
      const input = {
        firstName,
        lastName,
        accessCode,
        token,
        role
      };

      const data = {
        api: api.user.viewerLogin,
        payLoad: JSON.stringify(input),
      };

      setLoading(true);
      setError("");
      console.log("data", data);
      const response = await backend.save(data, true);
      console.log("response :>> ", response);
      if (get(response, 'waiting_room_id', '')) {
        sessionStorage.setItem('waiting_room_id', get(response, 'waiting_room_id', ''))
      }
      if (response?.role) {
        const customAuthRes = await signInWithCustomToken(auth, response?.token);
        console.log("customAuthRes", customAuthRes);
        sessionStorage.setItem('is_firstVisit', '1')
        sessionStorage.setItem("uuid", response?.uid);
        sessionStorage.setItem("accessValidate", "true");
        sessionStorage.setItem("room_name", response?.room_name);
        sessionStorage.setItem("first_name", firstName);
        sessionStorage.setItem("last_name", lastName);
      } else {
        setError("Invalid code");
      }
    } catch (err: any) {
      console.log("err :>> ", err);
      setErrModalOpen(true);
      setError(err.errMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleVerificationSuccess = (value: any) => {
    if (value) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };

  const getEnterRoomBtnStatus = () => {
    if (captchaVerified && firstName && lastName && accessCode) {
      return false;
    }
    return true;
  };

  return (
    <Loader loading={loading}>
      <PageLayout>
        <PageContent className="left-side-signup-content">
          <img src={logo} alt="logo" className="logo" />
        </PageContent>
        <PageContent className="right-side-signup-content">
          <Space direction="vertical" size={32} className="loginForm-container">
            <Title>Enter Viewer Details</Title>
            <div className="signup-name-row">
              <InputComponent
                label="first name"
                value={firstName}
                onChange={setFirstName}
                autoComplete="new-password"
                maxLength={20}
              />
              <InputComponent
                label="last name"
                value={lastName}
                onChange={setLastName}
                autoComplete="new-password"
                maxLength={20}
              />
            </div>
            <div className="access-code-input">
              <InputComponent
                label="Access Code"
                value={accessCode}
                onChange={setAccessCode}
                autoComplete="new-password"
              />
            </div>
            <div className="flex-center">
              <ReCAPTCHA
                sitekey={reCaptchaSiteKey}
                onChange={handleVerificationSuccess}
              />
            </div>
            <div className="flex-center">
              <div className="viewer-btn-wrapper">
                <Button
                  disabled={getEnterRoomBtnStatus()}
                  className="ppl-btn viewer-login-btn"
                  onClick={viewerLogin}
                >
                  Enter Session
                </Button>
              </div>
            </div>
            <div className="error-message" style={{ textAlign: "center" }}>
              {error && error}
            </div>
            <Modal
              open={errModalOpen}
              // onOk={()=>{setErrModalOpen(false)}}
              // onCancel={()=>{setErrModalOpen(false)}}
              closable={false}
              footer={
                <div className="err-modal-btn-warpper">
                  <Button
                    className="err-modal-btn"
                    onClick={() => {
                      setErrModalOpen(false);
                    }}
                  >
                    Ok
                  </Button>
                </div>
              }
            >
              <div>
                <ErrorComponent errorMsg={error} />
              </div>
            </Modal>
          </Space>
        </PageContent>
      </PageLayout>
    </Loader>
  );
};

export default ViewerOpenAuth;

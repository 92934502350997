import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const LoaderHOC = (props: any) => {
    const antIcon = <LoadingOutlined style={{ fontSize: 75, color: "#6A29FF" }} spin />;

    return (
        <Spin spinning={props.loading} indicator={antIcon}>
            {props.children}
        </Spin>
    )
}

export default LoaderHOC;
import { Button, Avatar, Modal, Checkbox, Tooltip } from "antd";
import apple from "../../../assets/images/apple.png";
import google from "../../../assets/images/google.png";
import facebook from "../../../assets/images/facebook.png";
import twitter from "../../../assets/images/twitter.png";
import logo from "../../../assets/images/logo-dark.png";
import "./styles.scss";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";

import {
  auth,
  GoogleProvider,
  FacebookProvider,
  TwitterProvider,
  AppleProvider,
} from "../../../firebase/utils";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";
import TermsAndConditions from "../TermsAndConditions";
import { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { isAvidBrowser } from "../../../utils/avidBrowserCheck";

const SocialSignupLogin = (props: any) => {
  const [section, setSection] = useState("");
  const [modal1Visible, setModal1Visible] = useState(false);
  const [termsAndConditionsModal, setTermsAndConditionsModal] = useState(false);
  const [ssotype, setSsotype] = useState("0");
  const ttt = new TwitterAuthProvider();
  const { setLoading, setError } = props;
  const { termsAgreed } = props;
  const navigate = useNavigate();
  const isAvidBrowserCheck =  isAvidBrowser();

  const navigateUser = async () => {
    try {
      const data = {
        api: api.user.get,
      };
      setLoading(true);
      const response = await backend.fetch(data, true);
      if (!response || !response.two_fa_enabled) {
        navigate("/home"
        );
      } else {
        navigate(
         "/otp",
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      setModal1Visible(false);
      setError("");
      console.log("Google provider :>> ", GoogleProvider);

      // const result = await signInWithPopup(auth, GoogleProvider);
      ;
      const result = await signInWithRedirect(auth, GoogleProvider)
      
      // if (result) {
      //   // The signed-in user info.
      //   const user = result.user;
      //   console.log(user);

      //   const credential: any = GoogleAuthProvider.credentialFromResult(result);
      //   if (credential) {
      //     const accessToken: any = credential || credential.accessToken;
      //     const idToken: any = credential || credential.idToken;
      //     console.log(accessToken); // Google token
      //     console.log(idToken); // Firebase auth token
      //     navigateUser();
      //   }
      //   // await createUser(user)
      // }
    } catch (err: any) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      setLoading(true);
      setModal1Visible(false);
      setError("");
      console.log("FAcebook provider :>> ", FacebookProvider);
      console.log("FAcebook auth :>> ", auth);
      const result: any = await signInWithPopup(auth, FacebookProvider);
      if (result) {
        // The signed-in user info.
        const user = result.user;
        console.log(user);

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential: any =
          FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        console.log(accessToken);
        navigateUser();

        // await createUser(user)
      }
    } catch (err: any) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAppleSignIn = async () => {
    try {
      setLoading(true);
      setModal1Visible(false);
      setError("");
      console.log("Apple provider :>> ", AppleProvider);
      console.log("Apple auth :>> ", auth);

      // error comming from here
      const result: any = await signInWithRedirect(auth, AppleProvider);
      console.log("check result value::::::::", result)
      // if (result) {
      //   // The signed-in user info.
      //   const user = result.user;
      //   console.log("user ::::::::",user);

      //   // This gives you a Apple Access Token. You can use it to access the Apple API.
      //   const credential: any =
      //   OAuthProvider.credentialFromResult(result);
      //   console.log("credential ::::::::",credential);

      //   const accessToken = credential.accessToken;
      //   console.log("access token:::::::: ",accessToken);
      //   navigateUser();

      //   // await createUser(user)
      // }
    } catch (err: any) {
      console.error("error ::::::::",err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTwitterSignIn = async () => {
    try {
      setLoading(true);
      setModal1Visible(false);
      setError("");
      console.log("Twiiter provider :>> ", TwitterProvider);
      console.log("Twiiter auth :>> ", auth);
      const result: any = await signInWithPopup(auth, TwitterProvider);

      console.log("Twiiter result :>> ", result);
      if (result) {
        // // The signed-in user info.
        // const user = result.user;
        // console.log(user);

        // // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        // const credential: any =
        //     TwitterAuthProvider.credentialFromResult(result);
        // const accessToken = credential.accessToken;
        // console.log(accessToken);
        // // navigateUser()

        // // await createUser(user)
        console.log("worked here  ");
      }
    } catch (err: any) {
      console.error("couldnt login:::::::::::", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  
  const selectTermsSection=(sectionNumber:number)=>{
    //This is used to select the sections within the terms and conditions modal &
    //Used to set headings of sections
    setTermsAndConditionsModal(true);
    setSection(sectionNumber === 1?"termsAndConditions":
    (sectionNumber ===2?"privacyPolicy":"subscriptionAgreement"))
  }

  const selectApple = () => {
    //This section is used to prompt the user to select terms & conditions &
    //Redirect the user to apple sign in.
    setSsotype("1");
    setModal1Visible(true);
  };
  const selectGoogle = () => {
    setSsotype("2");
    setModal1Visible(true);
  };

  {
    /*const selectFacebook = () => {
    setSsotype("3");
    setModal1Visible(true);
  };
  const selectTwitter = () => {
    setSsotype("4");
    setModal1Visible(true);
  };*/
  }
  const goToSignIn=()=>{
    //This section navigates us to the required sso based on the ssotype.
    localStorage.setItem("agreed", "true");
    ssotype === "1"? handleAppleSignIn():handleGoogleSignIn();
  }
  
  return (
    <div>
    <div className="social-signin-wrapper">
      {/* <Tooltip
        title={
          termsAgreed === false
            ? "Please click on I agree to enable the button "
            : ""
        }
      >
        {termsAgreed === false ? (
          <Button onClick={() => {}} className="social-button-disabled">
            <Avatar
              src={<img src={apple} alt="apple" className="social-icon" />}
              size={48}
            />
          </Button>
        ) : (
          <Button
            onClick={
              termsAgreed || localStorage.getItem("agreed") === "true"
                ? handleAppleSignIn : selectApple
            }
            className="social-button"
          >
            <Avatar
              src={<img src={apple} alt="apple" className="social-icon" />}
              size={48}
            />
          </Button>
        )}
      </Tooltip> */}
      <Tooltip
        title={
          termsAgreed === false
            ? "Please click on I agree to enable the button "
            : ""
        }
      >
        {termsAgreed === false ? (
          <Button className={"social-button-disabled"}>
            <Avatar
              src={<img src={google} alt="google" className="social-icon" />}
              size={48}
            />
          </Button>
        ) : (
          <Button
            onClick={
              termsAgreed || localStorage.getItem("agreed") === "true"
                ? handleGoogleSignIn : selectGoogle
            }
            className={"social-button"}
          >
            <Avatar
              src={<img src={google} alt="google" className="social-icon" />}
              size={48}
            />
          </Button>
        )}
      </Tooltip>
      {/*<Tooltip title={termsAgreed === false?"Please click on I agree to enable the button ":"h"} placement="top">
          <Button onClick={(termsAgreed || localStorage.getItem("agreed") === "true")?handleFacebookSignIn:selectFacebook} className="social-button" disabled={termsAgreed == false}>
        
          <Avatar
            src={<img src={facebook} alt="facebook" className="social-icon" />}
            size={48}
          />
          </Button>
        </Tooltip>
        <Tooltip title={termsAgreed === false?"Please click on I agree to enable the button ":"h"}>
          <Button onClick={(termsAgreed || localStorage.getItem("agreed") === "true")?handleTwitterSignIn:selectTwitter} className="social-button" disabled={termsAgreed == false}>
          <Avatar
            src={<img src={twitter} alt="twitter" className="social-icon" />}
            size={48}
          />
          </Button>
        </Tooltip>*/}
      <Modal
        visible={modal1Visible}
        maskClosable={true}
        closable={false}
        bodyStyle={{ borderRadius: 50 }}
        width={650}
        className="popupModal"
        afterClose={() => { setModal1Visible(false);}}
        title={
        <button className="modal-close-btn" onClick={() => {setModal1Visible(false);}}>
          <CloseOutlined />
        </button>
        }
        footer={<div style={{height:'56px'}}></div>}
      >
        <div className="modal1-title-bar">
          <div className="logo-wrapper">
            <div className="logo-containr">
              <img src={logo} alt={"logo"}/>
            </div>
          </div>
          <span className="modal1-title">
            {ssotype === "2" ? "Sign in with Google" : "Sign in with Apple" }
          </span>
          </div>
          <div>
          <div className="terms-text-common">
            <span className="terms-text">
              By creating an account or continuing to use a Pacpost.io you
              acknowledge and agree that you have accepted the {" "}
              <a onClick={() => { selectTermsSection(1);}}>Terms of Service</a> {" "}
              and reviewed the {" "}<a onClick={() => {selectTermsSection(2);}}>
              Privacy Policy</a> {" "}and {" "}<a onClick={() => {selectTermsSection(3);}}>
              Subscription Agreement</a> {" "}.
            </span>
          </div>
          <div className="agree-btn-wrapper">
          <div>
        <Button
          className="terms-check-box"
         // checked={termsAgreed1}
          onClick={goToSignIn}
        >
          {" "}
          I Agree{" "}
        </Button>
      </div>
          </div>
        </div>
        <Modal
          title={null}
          visible={termsAndConditionsModal}
          className="termsModal"
          width={900}
          closable={false}
          footer={null}
        >
          <TermsAndConditions section={section} />
          <div className="btn-wrapper">
          <div>
          <Button
            onClick={() => setTermsAndConditionsModal(false)}
            className="close-btn"
          >
            Close
          </Button>
          </div></div>
        </Modal>
      </Modal>
    </div>
    {!isAvidBrowserCheck ?
        (
          <div className="need-support"> 
            <a className="" href="mailto:support@pacpostio.zohodesk.com">Need Support?</a>
          </div>
        ) : (
          <div className="need-support">
            <span className="need-support-copy-text">Need Support? support@pacpostio.zohodesk.com</span>
          </div> )}
  </div>
  );
};

export default SocialSignupLogin;

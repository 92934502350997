import { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ParticipantViewer from "../Viewer/ParticipantViewer";
import ViewerPage from "../Viewer";
import { AuthContext } from "../../context/auth";
import ViewerOpenAuth from "../../components/Viewer/OpenAuth";
import api from "../../service/rest/restApi";
import backend from "../../service/rest/restService";
import { get } from "../../utils/helpers";
import { doSignOut } from "../../firebase/auth";
import NotFound from "../404";
import { notification } from "antd";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/utils";
import { useFeatureFlagStore } from "../../store";

let editorLoginCheckFlag = false;
let notificationTriggered=false;
const GuestViewer = () => {
  const { fetchFeatureFlags, featureFlags, isFFLoading } = useFeatureFlagStore()

  const effectRan = useRef(false);
  const { search } = useLocation();
  const navigate = useNavigate();
  const [validRoom, setValidRoom] = useState(false);
  const [urlValue, setUrlValue] = useState({});
  const [editorDirectLoginStatus, setEditorDirectLoginStatus] = useState("");
  const user: any = useContext(AuthContext);
  const isGuestViewer = get(user, "guestViewer", false);
  const isLinkLogin:boolean = get(user, "linkLogin", false);
  const waitingRoomid = sessionStorage.getItem("waiting_room_id");

  const type = get(urlValue, "rote", "");
  const user_id = get(urlValue, "rouid", "");
  const room_id = get(urlValue, "roi", "");
  const room_code = get(urlValue, "roc", "");
  const room_name = get(urlValue, "ron", "");
  const editorLoginCheck = !!user && !isGuestViewer && !isLinkLogin;
  

  useEffect(() => {
    // const checkUserAuthetication = async () => {
    //   if (!!user && type === "viewer") {
    //     if (!isGuestViewer || !waitingRoomid) {
    //       await doSignOut();
    //       return;
    //     }
    //   }
    // };
    //Check if user is guest authenticated or not.
    if (!sessionStorage.getItem("is_firstVisit") && isLinkLogin) {
      const signOutUser = async () => {
        await doSignOut();
      };
      signOutUser();
    }

    if(!!user && !isFFLoading) {
      getFeatureFlags()
    }

    if (
      !effectRan.current ||
      (editorLoginCheck && !editorLoginCheckFlag)
    ) {
      // checkUserAuthetication();
      identifyUser();
    }

    return () => {
      effectRan.current = true;
    };
  }, [user]);

  const getFeatureFlags = async () => {
    try {
      if(featureFlags.length === 0){
        await fetchFeatureFlags();
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const resetValues = async (end: boolean) => {
    const { user_id, roi, roc, fn, ln, rouid } = user;
    sessionStorage.removeItem("accessValidate");
    const displayName = `${fn} ${ln}`;
    const identity = `member#${user_id}_${displayName}`;
    const removeWaitingRoom = {
      api: api.room.clearWaitingRoom,
      payLoad: JSON.stringify({
        room_code: roc,
        user_id: rouid,
        room_id: roi,
        identity,
        end,
      }),
    };
    await backend.save(removeWaitingRoom);
  };

  const identifyUser = async () => {
    try {
      const token = new URLSearchParams(search).get("token");
      const data = {
        api: api.user.identify,
        urlParam: token,
      };
      const response = await backend.fetch(data, true);
      if (editorLoginCheck && !!response) {
        if (!sessionStorage.getItem("is_firstVisit")) {
          await doSignOut();
          navigate('/') 
        }
        getTwilioRoomsParticipants(
          get(response, "rouid", ""),
          get(response, "roi", "")
        );
      }
      setUrlValue(response);
      setValidRoom(true);
    } catch (error) {
      navigate("/viewer/404", { state: { isViewer: true } });
    }
  };

  const getParticipantViewer = () => {
    if (!!user && isGuestViewer && !!waitingRoomid) {
      const { user_id, roi, roc, ron, fn, ln, rouid } = user;
      return (
        <ParticipantViewer
          key={user_id}
          user_id={rouid}
          room_id={roi}
          room_code={roc}
          room_name={ron}
          firstName={fn}
          lastName={ln}
          uuid={user_id}
          resetValues={resetValues}
          waitingRoomid={waitingRoomid}
        />
      );
    } else {
      return <ViewerOpenAuth role={"viewer"} />;
    }
  };

  const getHostViewer = () => {
    if (!!user && !isGuestViewer && isLinkLogin) {
      const { user_id, roi, roc, ron, fn, ln, rouid } = user;
      // localStorage.setItem("url", `${pathname}${search}`);
      // localStorage.setItem("isViewer", `true`);
      // navigate("/viewer/login", {
      //   state: { isViewer: true, url: `${pathname}${search}` },
      // });
      return (
        <ViewerPage user_id={rouid} room_id={roi} room_code={roc} room_name={room_name} uuid={user_id}/>
        // <HostViewer
        //   user_id={rouid}
        //   room_id={roi}
        //   room_name={ron}
        //   room_code={roc}
        //   firstName={fn}
        //   lastName={ln}
        //   uuid={user_id}
        //   resetValues={resetValues}
        //   // accessCode={accessCode}
        // />
      );
    } else if (!!user && !isGuestViewer && !isLinkLogin) {
      return renderEditorViewer();
    } else {
      return <ViewerOpenAuth role={"host"} />;
    }
  };

  const renderEditorViewer = () => {
    const { user_id  } = user;
    if(!sessionStorage.getItem('room_name')){
      sessionStorage.setItem('room_name',room_name);
    }
    switch (editorDirectLoginStatus) {
      case "error":
        return <NotFound />;
      case "success":
        return (
          <ViewerPage
            user_id={user_id}
            room_id={room_id}
            room_name={room_name}
            room_code={room_code}
            editorDirectLogin={true}
            uuid={user_id}
          />
        );
      case "":
        return progress();
    }
  };

  const getTwilioRoomsParticipants = async (
    user_id: string,
    room_id: string
  ) => {
    try {
      // const params: any = new URLSearchParams(search);

      // const user_id: string = params.get("uid");
      // const room_id: string = params.get("rid");
      const adminJoinedFlag = sessionStorage.getItem('isAdmin') === 'true' 
      if(adminJoinedFlag){
        if(editorDirectLoginStatus !== 'success'){
          setEditorDirectLoginStatus('success')
        }
        return;
      }

      const data = {
        api: api.room.getTwilioRoomsParticipants,
        queryParam: {
          room_id,
          user_id,
        },
      };

      const roomParticipants = await backend.fetch(data, true);

      const adminId = roomParticipants.filter(
        (participant: any) => participant?.identity.split("#")[0] === "admin"
      );

      if (adminId.length) {
        setEditorDirectLoginStatus("error");
        if(!notificationTriggered)
        notification.error({
          message: "Access Denied",
          placement: "topRight",
        });
        notificationTriggered=true;
        return;
      }
      setEditorDirectLoginStatus("success");
      return;
    } catch (err) {
      setEditorDirectLoginStatus("error");
      console.error(err);
    } finally {
      editorLoginCheckFlag = true;
    }
  };

  const getViewer = () => {
    switch (type) {
      case "viewer":
        return getParticipantViewer();
      case "admin":
        return getHostViewer();
    }
  };

  const progress = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div>Validating in progress</div>
    </div>
  );

  return !!validRoom ? <>{getViewer()}</> : progress();
};

export default GuestViewer;

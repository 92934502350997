import React, {useRef } from 'react';
interface InputProps {
  uploadfileIcon: string;
}

const FileUploadComponent: React.FC<InputProps> = ({ uploadfileIcon }) => {
  const onBrowseClick = () => {
    const browse = document.getElementById("file-input");
    if (browse) {
      browse.click();
    }
  };

  return (
      <span className="upload-btn" onClick={onBrowseClick}>
        <img src={uploadfileIcon} alt="uploadIcon" />
      </span>
  );
};

export default FileUploadComponent;
import { get } from "../../../utils/helpers";
import ShowStream from "./Stream";
import useVratio from "../hooks/useVRatio";
import { useCallback, useContext, useEffect, useState } from "react";
import ZoomContext from '../../../pages/ZoomViewer/context/zoom-context';
import { useWhiteboardingStore } from "../../../store";
import ColorHash from "color-hash";

/*
 * White boarding
 */
type Coordinate = {
    x: number;
    y: number;
};

const Stream = (props: any) => {
    const zmClient = useContext(ZoomContext);
    
    const { canvasRef, room_id, admin_user_id } = props;

    const { isEraser, myEraserRef, myPencilCircleRef, myPencilLineRef, isPencilLine, isPencilCircle, setMyPencilLine } = useWhiteboardingStore();

    // White boarding
    const inMemCanvas: HTMLCanvasElement = document.createElement("canvas");
    const [vratio, setVRatio, vratioRef] = useVratio(1.6);

    const [isPainting, setIsPainting] = useState(false);
    const [mousePosition, setMousePosition] = useState<Coordinate | undefined>(
        undefined
    );
    const colorHash = new ColorHash();

    const getPlayerBox = (rect: DOMRect, ratio: number): DOMRect => {
        let r = new DOMRect();
        const rectWidth = get(rect, "width", 0);
        const rectHeight = get(rect, "height", 0);
        let ar = rectWidth / rectHeight;

        if (ar < ratio) {
            let vidh = rectWidth / ratio;
            r.width = rectWidth; // Width is OK
            r.height = Math.floor(vidh); // We know the aspect ratio so we can calculate the height
            r.x = 0; // The width fits
            r.y = Math.floor((rectHeight - vidh) / 2); // But there's a vertical gap
        } else {
            let vidw = rectHeight * ratio;
            r.width = Math.floor(vidw); // We know the aspect ratio so we can calculate the width
            r.height = rectHeight; // Height is OK
            r.x = Math.floor((rectWidth - vidw) / 2); // Horizontal gap
            r.y = 0; // No vertical gap
        }

        return r;
    };

    const renderCanvas = (canvas: any, ratio: any) => {
        const video_player: HTMLVideoElement = document.getElementById(
            "player"
        ) as HTMLVideoElement;

        if (video_player && !!canvas) {

            // The width and height of the video element
            let bb = video_player?.getBoundingClientRect();
            let player_box = getPlayerBox(bb, ratio);
            const player_box_width = get(player_box, "width", 0);
            const player_box_height = get(player_box, "height", 0);
            canvas.width = player_box_width;
            canvas.height = player_box_height;

            canvas.style.width = `${player_box_width}px`;
            canvas.style.height = `${player_box_height}px`;
            canvas.style.marginTop = `${player_box.y}px`;
            canvas.style.marginLeft = `${player_box.x}px`;
            return { w: player_box_width, h: player_box_height };
        }
        return { w: 0, h: 0 };
    };

    const playbackStarted = () => {
        const video_player: HTMLVideoElement = document.getElementById(
            "player"
        ) as HTMLVideoElement;

        if (
            !!video_player &&
            !!video_player.videoHeight &&
            video_player.videoHeight !== 0
        ) {
            let new_ratio = video_player.videoWidth / video_player.videoHeight;
            vratioRef.current = new_ratio;
            const canvas: any = document.getElementById("canvas");
            renderCanvas(canvas, new_ratio);
        }
    };

    const setupCanvas = () => {
        window.addEventListener("resize", () => {
            const canvas: any = document.getElementById("canvas");
            if (canvas) {
                const ctx: CanvasRenderingContext2D = canvas.getContext("2d");

                inMemCanvas.width = canvas.width;
                inMemCanvas.height = canvas.height;
                const memCtx = inMemCanvas.getContext("2d");
                // console.log("memCtx", memCtx, canvas);
                if (memCtx) {
                    memCtx.drawImage(canvas, 0, 0);
                }

                const size = renderCanvas(canvas, vratioRef.current); // The new size
                if (memCtx) {
                    let ctx2: CanvasRenderingContext2D = canvas.getContext("2d");
                    ctx2.drawImage(inMemCanvas, 0, 0, size.w, size.h);
                }
            }
        });
    };

    const videoReady = () => {
        const video_player: HTMLVideoElement = document.getElementById(
            "player"
        ) as HTMLVideoElement;

        video_player.addEventListener("loadedmetadata", playbackStarted);
        setupCanvas();
    };

    const getCoordinates = (event: MouseEvent): Coordinate | undefined => {
        if (!canvasRef.current) {
            return;
        }

        const canvas: HTMLCanvasElement = canvasRef.current;
        const BB = canvas?.getBoundingClientRect();

        return {
            x: event.clientX - BB.left,
            y: event.clientY - BB.top,
        };
    };

    // Get the position of a touch relative to the canvas
    const getTouchCoordinates = (
        touchEvent: TouchEvent
    ): Coordinate | undefined => {
        if (!canvasRef.current) {
            return;
        }

        const canvas: HTMLCanvasElement = canvasRef.current;
        const BB = canvas?.getBoundingClientRect();
        return {
            x: touchEvent.touches[0].clientX - BB.left,
            y: touchEvent.touches[0].clientY - BB.top,
        };
    };

    const startPaint = (coordinates: Coordinate) => {
        if (coordinates) {
            setMousePosition(coordinates);
            setIsPainting(true);

            if (!canvasRef.current) {
                return;
            }
            const canvas: HTMLCanvasElement = canvasRef.current;
            if (myPencilCircleRef.current || myPencilLineRef.current) {
                canvas.className = "canvas-pencil-on";
            }

            if (myEraserRef.current) {
                canvas.className = "canvas-eraser-on";
            }
        }
    };


    const startPaintMouse = useCallback((event: MouseEvent) => {
        const coordinates = getCoordinates(event);
        if (coordinates) {
            startPaint(coordinates);
        }
    }, []);

    const startPaintTouch = useCallback((event: TouchEvent) => {
        const coordinates = getTouchCoordinates(event);
        if (coordinates) {
            startPaint(coordinates);
        }
    }, []);

    const drawLine = (
        color: any,
        originalMousePosition: Coordinate,
        newMousePosition: Coordinate,
        erase: Boolean
    ) => {
        if (!canvasRef.current) {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;

        const context = canvas.getContext("2d");
        if (context) {
            context.lineJoin = context.lineCap = "round";

            context.beginPath();

            if (!erase) {
                context.globalCompositeOperation = "source-over";
                context.strokeStyle = color;
                context.lineWidth = 5;
            } else {
                context.globalCompositeOperation = "destination-out";
                context.lineWidth = 20;
            }

            context.moveTo(originalMousePosition.x, originalMousePosition.y);
            context.lineTo(newMousePosition.x, newMousePosition.y);
            context.closePath();

            context.stroke();
        }
    };

    const drawCircle = (
        color: any,
        originalMousePosition: Coordinate,
        newMousePosition: Coordinate
    ) => {
        if (!canvasRef.current) {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;

        const context = canvas.getContext("2d");
        if (context) {
            context.lineJoin = context.lineCap = "round";

            context.beginPath();
            context.globalCompositeOperation = "source-over";
            context.strokeStyle = color;
            context.lineWidth = 3;

            // calculating the midX and midY
            var midY =
                originalMousePosition.y +
                (newMousePosition.y - originalMousePosition.y) * 0.5;
            var midX =
                originalMousePosition.x +
                (newMousePosition.x - originalMousePosition.x) * 0.5;
            var radius =
                Math.hypot(
                    newMousePosition.x - originalMousePosition.x,
                    newMousePosition.y - originalMousePosition.y
                ) / 2;

            context.arc(midX, midY, radius, 0, 2 * Math.PI);

            context.stroke();
        }
    };

    const paint = (
        newMousePosition: Coordinate,
        isPainting: boolean,
        mousePosition: Coordinate | undefined,
        isCircle: boolean
    ) => {
        let erase: Boolean = false;

        if (!canvasRef.current) {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        if (isPainting && (myPencilCircleRef.current || myPencilLineRef.current)) {
            if (mousePosition && newMousePosition) {
                const color = colorHash.hex(String(zmClient.getCurrentUserInfo().userId));
                if (isCircle) {
                    drawCircle(color, mousePosition, newMousePosition);
                } else {
                    drawLine(color, mousePosition, newMousePosition, false);
                }
                canvas.className = "canvas-pencil-on";

                const icanvas: any = document.getElementById("canvas");
                const canvasWidth = get(icanvas, "width", 0);
                const canvasHeight = get(icanvas, "height", 0);
                const incoming_canvas = {
                    width: canvasWidth,
                    height: canvasHeight,
                };

                const commandChannel = zmClient.getCommandClient();
                commandChannel.send(JSON.stringify({
                    isPainting,
                    color,
                    mousePosition,
                    newMousePosition,
                    incoming_canvas,
                    erase,
                    isCircle,
                }))
                setMousePosition(newMousePosition);
            }
        } else if (isPainting && myEraserRef.current) {
            erase = true;
            if (mousePosition && newMousePosition) {
                const color = colorHash.hex(String(zmClient.getCurrentUserInfo().userId));
                drawLine(color, mousePosition, newMousePosition, true);
                canvas.className = "canvas-eraser-on";
                const icanvas: any = document.getElementById("canvas");
                const incoming_canvas = {
                    width: icanvas.width,
                    height: icanvas.height,
                };
                //TODO
                const commandChannel = zmClient.getCommandClient();
                commandChannel.send(JSON.stringify({
                    isPainting,
                    color,
                    mousePosition,
                    newMousePosition,
                    incoming_canvas,
                    erase,
                    isCircle,
                }))
                setMousePosition(newMousePosition);
            }
        }
    };

    const paintMouse = useCallback(
        (event: MouseEvent) => {
            if (isPencilLine || isEraser) {
                const newMousePosition = getCoordinates(event);
                if (newMousePosition) {
                    paint(newMousePosition, isPainting, mousePosition, false);
                }
            }
        },
        [isPainting, mousePosition]
    );

    const paintTouch = useCallback(
        (event: TouchEvent) => {
            const newMousePosition = getTouchCoordinates(event);
            if (newMousePosition) {
                paint(newMousePosition, isPainting, mousePosition, false);
            }
        },
        [isPainting, mousePosition]
    );

    const exitPaint = useCallback((event: TouchEvent) => {
        setIsPainting(false);
        setMousePosition(undefined);
        if (!canvasRef.current) {
            return;
        }

        const canvas: HTMLCanvasElement = canvasRef.current;
        canvas.className = "canvas-cursor-auto";
    }, []);

    const mouseExitPaint = useCallback(
        (event: MouseEvent) => {
            if (!canvasRef.current) {
                return;
            }
            const canvas: HTMLCanvasElement = canvasRef.current;
            if (event.type === "mouseleave" && isPainting) {
                setIsPainting(false);
                setMyPencilLine(false);
                canvas.className = "canvas-cursor-auto";
            }
            if (isPencilCircle && event.type === "mouseup") {
                const newMousePosition = getCoordinates(event);
                console.log('newMousePosition', newMousePosition)
                if (newMousePosition) {
                    paint(newMousePosition, isPainting, mousePosition, true);
                }
            }

            if (isPencilLine) {
                setIsPainting(false);
                setMousePosition(undefined);
            }
            canvas.className = "canvas-cursor-auto";
        },
        [isPainting, mousePosition, isPencilLine, isPencilCircle, mousePosition, paint]
    );

    const preventDragging = useCallback((e: TouchEvent) => {
        e.preventDefault();
    }, []);

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;

        canvas.addEventListener("mousedown", startPaintMouse);
        canvas.addEventListener("mousemove", paintMouse);
        canvas.addEventListener("mouseup", mouseExitPaint);
        canvas.addEventListener("mouseleave", mouseExitPaint);

        canvas.addEventListener("touchstart", startPaintTouch);
        canvas.addEventListener("touchmove", paintTouch);
        canvas.addEventListener("touchend", exitPaint);

        window.addEventListener("touchmove", preventDragging, { passive: false });

        return () => {
            canvas.removeEventListener("mousedown", startPaintMouse);
            canvas.removeEventListener("mousemove", paintMouse);
            canvas.removeEventListener("mouseup", mouseExitPaint);
            canvas.removeEventListener("mouseleave", mouseExitPaint);

            canvas.removeEventListener("touchstart", startPaintTouch);
            canvas.removeEventListener("touchmove", paintTouch);
            canvas.removeEventListener("touchend", exitPaint);

            window.removeEventListener("touchmove", preventDragging);
        };
    }, [startPaint, paint, exitPaint]);

    return (
        <ShowStream
            canvasRef={canvasRef}
            isReady={videoReady}
            room_id={room_id}
            admin_user_id={admin_user_id}
        />
    );

}

export default Stream;
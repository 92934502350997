import React, { useCallback, useRef, useState, useEffect, useContext } from "react";
// import GiphyModal from "../GiphyModal";
import {
  Card,
  Icon,
  Input,
  Button,
  Dropdown,
  Popup,
  Grid,
  Modal,
  Confirm,
  TextArea,
  Tab,
} from "semantic-ui-react";
import data from "@emoji-mart/data";
import { Picker } from "emoji-mart";
import emoji from ".././Images/emoji.svg";
import sendMsgIcon from ".././Images/send-chat.svg";
import clear from ".././Images/clear.svg";
import downloadButtonIcon from ".././Images/interface/download-button-icon.svg";
import documentExport from ".././Images/newDocumentExport.svg";
import * as auth from "../../../firebase/auth";
import { convertDateTimeFromUTCEpoc } from "../../../utils/utils";

import { get } from "../../../utils/helpers";
import crossTick from "../Images/red-cancel.png";
import greeTick from "../Images/green-tick.png";
import uploadIcon from "../Images/upload-icon.svg";
import { AuthContext } from "../../../context/auth";
interface Props {
  setExportChartConfirmationOpen: any;
  fileUploadLoading: any;
  chatClearLoading: any;
  setClearChatModalOpen: any;
  ProgressLoader: any;
  messagesRef: React.MutableRefObject<any[]>;
  user_imageurl: any;
  editPencil: any;
  showEmoji: any;
  handleChatCopy: any;
  setInput: any;
  setShowEmoji: any;
  sendMessage: any;
  isModalOpen: any;
  input: string;
  typingInd: any;
  hideGiphyModal: any;
  pubnub: any;
  channels: any;
  setUploadAlert: any;
  setFileName: any;
  setUploadFiles: any;
  resizeCanvas: any;
  isGuestViewer:any;
  editorDirectLogin:any;
}

const ChatMenu: React.FC<Props> = ({
  setExportChartConfirmationOpen,
  fileUploadLoading,
  chatClearLoading,
  setClearChatModalOpen,
  ProgressLoader,
  messagesRef,
  user_imageurl,
  editPencil,
  showEmoji,
  handleChatCopy,
  setInput,
  setShowEmoji,
  sendMessage,
  isModalOpen,
  input,
  typingInd,
  hideGiphyModal,
  pubnub,
  channels,
  setUploadAlert,
  setFileName,
  setUploadFiles,
  resizeCanvas,
  isGuestViewer,
  editorDirectLogin
}) => {
  const [editingMessageId, setEditingMessageId] = useState("");
  const [editMessage, setEditMessage] = useState(false);
  const [editInput, setEditInput] = useState("");

  useEffect(() => {
    resizeCanvas();
  }, []);

  const sendTextMessageInput = useRef<HTMLInputElement>(null);
  const chatContentRef = useRef<HTMLDivElement>(null);
  const scrollChatToBottom = () => {
    chatContentRef.current?.scrollTo(0, 100000);
  };
  const userLoggedIn: any = useContext(AuthContext);
  const displayName:string = !editorDirectLogin ? `${get(userLoggedIn,'fn','')} ${get(userLoggedIn,'ln','')}` : `${get(userLoggedIn,'displayName','')}`
  /* Don't comment this please */
  // if (!showChat) {
  //   return;
  // }
  // if (!troom || (troom && isEmpty(troom))) {
  //   return;
  // }
  /* We don't want to show chat window
    without conf running */
  const handleEditMessageClick = (messageId: string, messageDesc: string) => {
    setEditMessage(true);
    setEditingMessageId(messageId);
    setEditInput(messageDesc);
    let hideEdit = document.getElementById("edit_button_" + messageId);
    hideEdit?.setAttribute("style", "display: none");
  };

  const onChangeEditMessage = async (input: string) => {
    setEditInput(input);
    await pubnub.signal({
      channel: channels[0],
      message: `typing_on:${displayName}`,
    });
  };

  useEffect(() => {
    scrollChatToBottom();
  }, [fileUploadLoading]);

  const updateMessage = useCallback(
    async (input: any, message: any, messageIndex: any) => {
      if (input.length > 0) {
        const user = auth.getUser();
        let hideEdit = document.getElementById(
          "edit_button_" + message.timetoken
        );
        hideEdit?.removeAttribute("style");
        const uid = get(user, "uid", "");
        await pubnub.publish({
          channel: channels[0],
          message: {
            message_id: message.message_id,
            userDisplay: displayName,
            description: input,
            userid: uid, // Needed for old msgs as they dont have publisher id
            imageurl: user_imageurl,
            usecase: "update",
            deleted: false,
            is_update: true,
          },
        });

        await pubnub.signal({
          channel: channels[0],
          message: `typing_off:${displayName}`,
        });
      }

      setEditInput("");
      renderMessage(input, messageIndex);
    },
    [pubnub, channels, setEditInput]
  );

  const renderEditableMessage = (message: any, messageIndex: number) => {
    if (editingMessageId === message.timetoken) {
      let msg_unix_timestamp = Math.ceil(parseInt(message.timetoken) / 10000);

      let formatted_time = convertDateTimeFromUTCEpoc(msg_unix_timestamp);
      return (
        <div>
          <TextArea
            className="editTxtArea"
            autoFocus
            placeholder="Send a message here"
            rows="2"
            size="small"
            spellCheck="true"
            value={editInput}
            onChange={(event, data) =>
              onChangeEditMessage(data.value as string)
            }
            onKeyPress={(event: any) => {
              if (event.key === "Enter") {
                event.preventDefault();
                updateMessage(editInput, message, messageIndex);
              } else if (event.key === "Esc") {
                event.preventDefault();
                renderMessage(message.description, messageIndex);
                let hideEdit = document.getElementById(
                  "edit_button_" + message.timetoken
                );
                hideEdit?.removeAttribute("style");
              }
            }}
          />
          <span className="chat-time">{formatted_time}</span>
          <div className="float-right">
            <a
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                setEditMessage(false);
                setEditingMessageId("");
                let hideEdit = document.getElementById(
                  "edit_button_" + message.timetoken
                );
                hideEdit?.removeAttribute("style");
                renderMessageWithEditButton(message, messageIndex);
              }}
            >
              <img src={crossTick} className="cancelTick-img" alt="cancel" />
            </a>
            <a
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                updateMessage(editInput, message, messageIndex);
              }}
            >
              <img src={greeTick} className="greenTick-img" alt="cancel" />
            </a>
          </div>
        </div>
      );
    } else {
      // Render this normal method message here.
      return renderMessageWithEditButton(message, messageIndex);
    }
  };

  const renderMessagewithFile = (message: any) => {
    return (
      <Card>
        <Card.Content>
          <Card.Meta>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="file-name">{message?.file?.name}</div>
              <div>
                <img
                  alt={message?.file?.name}
                  src={downloadButtonIcon}
                  onClick={() =>
                    downloadFiles(
                      message?.file?.id,
                      message?.file?.name,
                      channels[0]
                    )
                  }
                  className="download-file-icon"
                />
              </div>
            </div>
          </Card.Meta>
        </Card.Content>
      </Card>
    );
  };

  const downloadFiles = async (id: any, name: any, channel: any) => {
    const file = await pubnub.downloadFile({
      channel: channel,
      id: id,
      name: name,
    });

    let fileContent = await file.toBlob();

    var hiddenElement = document.createElement("a");
    hiddenElement.href = URL.createObjectURL(fileContent);
    hiddenElement.target = "_blank";
    hiddenElement.download = name;
    hiddenElement.click();
  };

  const onChangeMessage = async (event: any, input: any) => {
    if (event.target.files) {
      setUploadAlert(true);
      let files: any = event.target.files;
      for (let i = 0; i < files.length; i++) {
        let reader = new FileReader();
        let file = files[i];
        setFileName(file.name);
        reader.onloadend = () => {
          setUploadFiles([file]);
        };
        reader.readAsDataURL(file);
      }
    }
    //setbrowseInput(false);
    setInput(input);
    await pubnub.signal({
      channel: channels[0],
      message: `typing_${input?'on':'off'}:${displayName}`,
    });
  };

  const renderMessageWithEditButton = (message: any, messageIndex: number) => {
    let URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    let msg_unix_timestamp = Math.ceil(parseInt(message.timetoken) / 10000);

    let formatted_time = convertDateTimeFromUTCEpoc(msg_unix_timestamp);
    let div = message.description?.split(" ").map((part: any) => {
      if (URL_REGEX.test(part)) {
        let hrefUrl = part;
        if (part.startsWith("www")) {
          hrefUrl = "//" + hrefUrl;
        }

        return (
          <div>
            <a key={messageIndex} href={hrefUrl} target="_blank">
              {part}
            </a>
          </div>
        );
      } else {
        return part + " ";
      }
    });

    return (
      <div className="chat-timestamp-and-message-wrapper">
        <div id={message.timetoken} className="annote-chat-message-grey-bg">
          {div}
        </div>
        <div className="chat-time">{formatted_time}</div>
      </div>
    );
  };

  const onBrowseClick = () => {
    const browse = document.getElementById("file-input");
    if (browse) {
      browse.click();
    }
  };

  const renderMessage = (messageDesc: any, messageIndex: number) => {
    let URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    let div = messageDesc?.split(" ").map((part: any) => {
      if (URL_REGEX.test(part)) {
        let hrefUrl = part;
        if (part.startsWith("www")) {
          hrefUrl = "//" + hrefUrl;
        }

        return (
          <div>
            <a key={messageIndex} href={hrefUrl} target="_blank">
              {part}
            </a>
          </div>
        );
      } else {
        return part + " ";
      }
    });

    return div;
  };

  const addEmoji = (emoji: any) => {
    setInput(input + emoji.native);
    sendTextMessageInput?.current?.focus();
  };

  const EmojiPicker = (Emojiprops: any) => {
    console.log("test")
    const ref: any = useRef();
    useEffect(() => {
      new Picker({ ...Emojiprops, data, ref });
      return () => {
        if (ref.current) {
          ref.current.removeChild(ref?.current?.lastChild);
        }
      };
    }, []);
    return <div key="emojiPickerDiv" ref={ref} />;
  };

  return (
    <nav className="main-menu-right-chat" id="chat-tray">
      <div className="chat-h1">
        <span className="chat-text">Group Chat</span>
        <div className="chat-options-wrapper">
        {!isGuestViewer && <Popup
            content="Clear Chat"
            position="left center"
            trigger={
              <img
                alt=""
                className="clear-image clear-chat-button"
                src={clear}
                onClick={() => {
                  setClearChatModalOpen(true);
                }}
              />
            }
          /> }
        {!isGuestViewer && <Popup
            content="Export Chat"
            position="left center"
            trigger={
              <img
                alt=""
                className="clear-image clear-chat-button export-chat-img"
                src={documentExport}
                onClick={(e) => {
                  e.preventDefault();
                  setExportChartConfirmationOpen(true);
                }}
              />
            }
          /> }
        </div>
      </div>
      {(fileUploadLoading || chatClearLoading) && (
        <div>
          <ProgressLoader
            loading={fileUploadLoading || chatClearLoading}
            size="small"
          />
        </div>
      )}
      <div className="chat-content" ref={chatContentRef}>
        {messagesRef.current.map((message: any, messageIndex: number) => {
          let editMessageIndex = messagesRef.current.findIndex(
            (item) => item.timetoken === editingMessageId
          );

          if (editMessageIndex > -1) {
            setTimeout(function () {
              var chatTray = document.getElementById("chat-tray");
              var currScrollHeight = chatTray?.scrollHeight || 0;
              var scrollHeight: number =
                currScrollHeight *
                (editMessageIndex / messagesRef.current.length);
              chatTray?.scrollTo(0, scrollHeight - 20);
            }, 250);
          } else {
            setTimeout(function () {
              var chatTray = document.getElementById("chat-tray");
              var currScrollHeight = chatTray?.scrollHeight || 0;
              chatTray?.scrollTo(0, currScrollHeight);
            }, 250);
          }

          let message_timeToken: any =
            String(message.timetoken).length === 17
              ? message.timetoken
              : String(message.timetoken) + "0000";
          let msg_unix_timestamp = Math.ceil(
            parseInt(message_timeToken) / 10000
          );
          let formatted_time = convertDateTimeFromUTCEpoc(msg_unix_timestamp);
          let publisher = message?.file
            ? message?.message?.publisher
            : message.publisher;
          const publisher_id = get(get(publisher?.split('#'),'1','')?.split('_'),'0','')
          if (publisher_id === auth.getUserId()) {
            // Loggedin user
            let style = {};
            if (user_imageurl) {
              style = {
                background:
                  'transparent url("' +
                  user_imageurl +
                  '") 0% 0% no-repeat padding-box',
              };
            }
            return (
              <div
                className="chat-list-item chat-local-message"
                key={`message-${messageIndex}`}
              >
                <>
                  <div className="chat-message-body chat-local-message-body">
                    {message?.file ? null : (
                      <div className="chat-message-hover-tools-row">
                        <div className="chat-message-tool-icon-container">
                          <Popup
                            content="Copy To Clipboard"
                            position="top right"
                            trigger={
                              <span
                                className="duplicate-icon"
                                onClick={() => handleChatCopy(message)}
                              />
                            }
                          />
                        </div>
                        <div className="chat-message-tool-icon-container">
                          <Popup
                            content="Edit Message"
                            position="top right"
                            trigger={
                              <span
                                id={"edit_button_" + message.timetoken}
                                className="duplicate-icon edit-message-icon"
                                onClick={() =>
                                  handleEditMessageClick(
                                    message.timetoken,
                                    message.description
                                  )
                                }
                              />
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className="chat-heading">
                      {message?.file
                        ? message?.message?.userDisplay
                        : message?.userDisplay}
                    </div>
                    {message?.file ? (
                      <div className="file-message-grey-bg chat-timestamp-and-message-wrapper">
                        {renderMessagewithFile(message)}
                        <div className="chat-time timestamp-in-file-message">
                          {formatted_time}
                        </div>
                      </div>
                    ) : !editMessage ? (
                      renderMessageWithEditButton(message, messageIndex)
                    ) : (
                      renderEditableMessage(message, messageIndex)
                    )}
                  </div>
                  {scrollChatToBottom()}
                </>
              </div>
            );
          } else {
            // Other users
            let style = {};
            let profile_Image_url = message?.file
              ? message?.message?.imageurl
              : message?.imageurl;
            if (profile_Image_url) {
              style = {
                background:
                  'transparent url("' +
                  profile_Image_url +
                  '") 0% 0% no-repeat padding-box',
              };
            }
            // else if()
            return (
              <>
                {message?.status === "left" || message?.status === "joined" ? (
                  <div
                    className="chat-list-item"
                    key={`message-${messageIndex}`}
                  >
                    <div className={"chat-notification"}>
                      {renderMessage(message.description, messageIndex)}
                    </div>
                  </div>
                ) : (
                  <div
                    className="chat-list-item"
                    key={`message-${messageIndex}`}
                  >
                    <div className="chat-message-body">
                      {message?.file ? null : message.status ? null : (
                        <div className="chat-message-hover-tools-row">
                          <div className="chat-message-tool-icon-container">
                            <Popup
                              content="Copy To Clipboard"
                              position="top right"
                              trigger={
                                <span
                                  className="duplicate-icon"
                                  onClick={() => handleChatCopy(message)}
                                />
                              }
                            />
                          </div>
                        </div>
                      )}
                      <div className="chat-heading">
                        {message?.file
                          ? message?.message?.userDisplay
                          : message.userDisplay}
                      </div>
                      {message?.file ? (
                        <div className="file-message-white-bg">
                          {renderMessagewithFile(message)}
                        </div>
                      ) : (
                        <div className="chat-message-white-bg">
                          {renderMessage(message.description, messageIndex)}
                        </div>
                      )}
                      <div className="chat-time from-message-body-fix">
                        {formatted_time}
                      </div>
                    </div>
                  </div>
                )}
                {scrollChatToBottom()}
              </>
            );
          }
        })}
      </div>
      <div className="emoji-picker"> 
          <div
            style={{
              position: "absolute",
              bottom: "80px",
              background: "#141414",
              display: showEmoji ? "block" : "none"
            }}
          >
            <EmojiPicker
              key={"emojiPicker"}
              onEmojiSelect={addEmoji}
              theme="dark"
              title=""
              emoji=""
              showSkinTones={true}
            />
          </div>
      </div>

      <div className="chat-msg-input">
        <Input
          className="sendTxt"
          placeholder="Send a message here"
          size="small"
          spellCheck="true"
          onChange={(event, data) => {
            let input: any = data.value.replace("C:\\fakepath\\", "") as string;
            onChangeMessage(event, input);
          }}
          onKeyPress={(event: any) => {
            if (event.key === "Enter") {
              const inputText = input;
              setInput("");
              event.preventDefault();
              if (inputText.length > 0) sendMessage(inputText);
            }
          }}
        >
          {/* <button>Show Modal</button> */}
          {/* <Icon size="big" className="giphy-btn" onClick={showGiphyModal} /> */}
          <span className="upload-btn" onClick={onBrowseClick}>
            <img src={uploadIcon} alt={"uploadIcon"} />
          </span>
          <input value={input} ref={sendTextMessageInput} />
          {
            // browseInput === true &&
            <input type="file" id="file-input" style={{ display: "none" }} />
          }
          <div className="emoji-img-wrapper">
            <img
              src={emoji}
              className="emoji-img"
              alt="Emoji"
              onClick={() => {
                setShowEmoji(!showEmoji);
              }}
            />
          </div>
          <Button
            color="violet"
            floated="right"
            className="send-chat-btn"
            size="medium"
            onClick={(e) => {
              const inputText = input;
              setInput("");
              e.preventDefault();
              if (inputText.length > 0) sendMessage(inputText);
            }}
          >
            <img src={sendMsgIcon} alt="sendMsg" />
          </Button>
        </Input>
      </div>
      <div className="chat-typing-indicator">{typingInd}</div>

      {isModalOpen && (
        <div>
          {/* <GiphyModal hideGiphyModal={hideGiphyModal}></GiphyModal> */}
        </div>
      )}
      {/* </>
      )} */}
    </nav>
  );
};

export default ChatMenu;

// import { useState, useEffect } from "react";
//import useDbTrigger from "../../hooks/useDbtrigger";
import useToggle from "../../utils/useToggle";
import api from "../../service/rest/restApi";
import backend from "../../service/rest/restService";
import { Room } from "twilio-video";
//import useGuestViewerCommands from "./useGuestViewerCommands";
import ConfirmationDialog from "./ConfirmationDialog/Index";
import { get } from "../../utils/helpers";
import useParticipants from "./hooks/useParticipants";
import { Button, Modal } from "antd";
import { useContext, useState } from "react";
import { GuestListContext } from "../ZoomViewer/context/guest-context";

interface GuestUsersListProps {
  room?: Room;
  // guestList: any;
  room_id: string;
}

const GuestUsersList = (props: GuestUsersListProps) => {
  const [showModal, setShowModal] = useState(false);
  const { guestList } = useContext(GuestListContext);
  const { room } = props; 
  const participants = useParticipants(room);
  const connectedGuests = participants.filter((participant:any)=> participant.state === 'connected') 
  const guestIds = connectedGuests.map((guest:any)=>guest.identity)
  const sortedGuestList = guestList.sort((guestA:any,guestB:any)=>{
    if(get(guestA,'status','') < get(guestB,'status','')){
      return -1
    }
    return 1
  })

  const [
    removeConfirmationOpen,
    showRemoveConfirmation,
    hideRemoveConfirmation,
    confirmationData,
  ] = useToggle<{ displayName: string; identity: string; guestId: string }>();

  console.log("GuestUsersList-guestList", guestList);

  const updateGuestStatus = async (id: string, status: string) => {
    try {
      const data = {
        api: api.room.updateGuestStatus,
        urlParam: id,
        payLoad: JSON.stringify({
          status,
        }),
      };
      const guestList: any = await backend.save(data);
      console.log("guestList", guestList);
    } catch (err:any) {
      console.log("err", err);
      if(err.errCode === 10206) {
        setShowModal(true);
      }
    }
  }; 

  return (
    <>
      {/* <div className="guest-viewer-url-container">
        <div className="guest-viewer-url-container-item">
          <div className="guest-users-list-title">Guests:</div>
          </div>
          <div className="guest-viewer-url-container-item">({guestList.length} / {})</div>
        </div> */}

      {sortedGuestList.map((guest: any,key:any) => {
        console.log("guest", guest);
        const displayName = `${guest?.identity?.split("_")[1]}`;
        const allowed = guest?.status === "approved";
        const status = allowed ? "Active" : "Waiting";
        return (
          <>
            {((guest?.status === "approved" && guestIds.includes(guest?.identity))|| guest?.status === "waiting") && (
              <div className="guest-viewer-url-container" key={guest?.identity}>
                <div className="guest-viewer-url-container-item">
                  <div>
                    <div>{`${displayName}`}</div>
                    <div>
                      Status:&nbsp;<i>{status}</i>
                    </div>
                  </div>
                </div>

                <div
                  className="guest-viewer-url-container-item"
                  onClick={() => {}}
                >
                  <div className="guest-user-remove-btn-wrapper">
                    {guest?.status === "approved" && (
                      <button
                        key={guest?.id}
                        className="guest-user-list-remove-btn"
                        onClick={() => {
                          const { identity } = guest;
                          showRemoveConfirmation.withData({
                            displayName,
                            identity,
                            guestId: guest?.id,
                          });
                        }}
                      >
                        Remove
                      </button>
                    )}
                    {!allowed && (
                      <button
                        key={guest?.id}
                        className="guest-user-list-remove-btn"
                        onClick={() => {
                          updateGuestStatus(guest?.id, "approved");
                        }}
                      >
                        Allow
                      </button>
                    )}
                  </div>

                  <div className="guest-user-status-circle">
                    <div
                      className={
                        !allowed
                          ? "guest-user-status-waiting"
                          : "guest-url-status-active"
                      }
                    ></div>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      })}
      <ConfirmationDialog
        open={removeConfirmationOpen}
        onClose={hideRemoveConfirmation}
        onConfirm={() => {
          hideRemoveConfirmation();
          updateGuestStatus(confirmationData?.guestId || "", "rejected");
        }}
        onCancel={hideRemoveConfirmation}
        title={`Remove ${confirmationData?.displayName}`}
        message="Are you sure you want to remove this guest?"
      />
      <Modal
        open={showModal}
        centered={true}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={[
          <Button
            type='primary'
            onClick={() => {
              setShowModal(false);
            }}
          >
            OK
          </Button>,
        ]}
      >
        <div className='btn-close'>
          You can't allow as the room has reached it's limit.
        </div>
      </Modal>
    </>
  );
};

export default GuestUsersList;
import { useCallback, useState } from 'react';

interface OpenFn<TData>{
  (): void;
  withData: (data: TData) => void;
};

export default function useToggle<T = boolean>() {
  const [toggled, setToggled] = useState<T | boolean | null>();

  const open: any = useCallback(() => {
    setToggled(true);
  }, []);

  open.withData = useCallback((data: T) => {
    setToggled(data);
  }, [])
  
  const close = useCallback(() => setToggled(null), []);
  
  return [
    Boolean(toggled),
    open as OpenFn<T>,
    close,
    typeof toggled === 'boolean' ? null : toggled
  ] as const;
}
import { get } from "../../../utils/helpers";

function useWhiteboarding() {

    const getPlayerBox = (rect: DOMRect, ratio: number): DOMRect => {
        let r = new DOMRect();
        const rectWidth = get(rect, "width", 0);
        const rectHeight = get(rect, "height", 0);
        let ar = rectWidth / rectHeight;
    
        if (ar < ratio) {
          let vidh = rectWidth / ratio;
          r.width = rectWidth; // Width is OK
          r.height = Math.floor(vidh); // We know the aspect ratio so we can calculate the height
          r.x = 0; // The width fits
          r.y = Math.floor((rectHeight - vidh) / 2); // But there's a vertical gap
        } else {
          let vidw = rectHeight * ratio;
          r.width = Math.floor(vidw); // We know the aspect ratio so we can calculate the width
          r.height = rectHeight; // Height is OK
          r.x = Math.floor((rectWidth - vidw) / 2); // Horizontal gap
          r.y = 0; // No vertical gap
        }
    
        return r;
      };
    
      const renderCanvas = (canvas: any, ratio: number) => {
        const video_player: HTMLVideoElement = document.getElementById(
          "player"
        ) as HTMLVideoElement;
    
        if (video_player && !!canvas) {
    
          // The width and height of the video element
          let bb = video_player?.getBoundingClientRect();
          let player_box = getPlayerBox(bb, ratio);
          const player_box_width = get(player_box, "width", 0);
          const player_box_height = get(player_box, "height", 0);
          canvas.width = player_box_width;
          canvas.height = player_box_height;
    
          canvas.style.width = `${player_box_width}px`;
          canvas.style.height = `${player_box_height}px`;
          canvas.style.marginTop = `${player_box.y}px`;
          canvas.style.marginLeft = `${player_box.x}px`;
          return { w: player_box_width, h: player_box_height };
        }
        return { w: 0, h: 0 };
      };

  return {getPlayerBox, renderCanvas};
}

export default useWhiteboarding;
import { auth } from "../firebase/utils";

export const refreshUser = () => {
  const user: any = auth?.currentUser;
  user
    .reload()
    .then(() => {
      console.log("user refreshed");
    })
    .catch((err: any) => {
      console.error("error while refreshing user");
    });
};

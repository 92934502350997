import React, { useState, useRef, useCallback,useContext,useEffect } from 'react';
import '../styles.scss';
import ZoomContext from '../../../pages/ZoomViewer/context/zoom-context';
import '../newshowviewer_zoom.scss';
import { Layout } from 'antd';
import {
  Input,
  Button,
  Dropdown,
  Popup,
  Modal,
  Confirm,
  TextArea,
} from "semantic-ui-react";
import ChatMenu from "./chat"
import * as auth from "../../../firebase/auth";
import axios from "axios";
import PubNub from "pubnub";
import { AuthContext } from "../../../context/auth";
import { get } from "../../../utils/helpers";
import ProgressLoader from "../../../components/Common/ProgressLoader";
import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";
import { Parser } from "json2csv";
import chatImg from "../../../pages/Viewer/Images/chat.svg";
import closeImg from "../../../pages/Viewer/Images/close.svg";
import editPencil from "../../../pages/Viewer/Images/pencil.png";
import pacpost_img from "../../../pages/Viewer/Images/logo_PP_white_header.png";
import { isViewInScreen, convertDateTimeFromUTCEpoc } from "../../../utils/utils";
import ConfirmationalPopup from "../ConfirmationModal";
import ClearChatModal from "../ConfirmationModal/ClearChat";
import ExportChatModal from "../ConfirmationModal/ExportChat";
import { useChatStore } from '../../../store';
import { useRoom } from '../../../pages/ZoomViewer/hooks/useRoom';
import { ParticipantType } from '../../../pages/ZoomViewer/types/index-types';

const { Header, Footer, Sider, Content } = Layout;

const ChatMain = (props:any) => {
  const {editorDirectLogin,admin_user_id,room_id,clearChatFlag,setclearChatFlag} = props;
  const userLoggedIn: any = useContext(AuthContext);
  const isGuestViewer = get(userLoggedIn,'guestViewer',false)
  const displayName: string = !editorDirectLogin ? `${get(userLoggedIn, 'fn', '')} ${get(userLoggedIn, 'ln', '')}` : `${get(userLoggedIn, 'displayName', '')}`
  const uuid = `${get(userLoggedIn,'uid','')}`
  const zmClient = useContext(ZoomContext);
 
  const { setShowEmoji,setFileUploadLoading,setChatClearLoading,
    exportChartConfirmationOpen,setExportChartConfirmationOpen,setClearChatModalOpen,
    uploadFiles, setUploadFiles,setInput,
    pubnub ,setPubNub, setChannels, channels,
    _setMessages,messages, allMessages, setAllMessages, setTypingInd,
    setUploadAlert,uploadAlert,fileName, setChatWindow, setChatDisable, setCloseDisable,
    setChatExportLoading, setModalStatus, streamLabel,setStreamLabel
     } = useChatStore();

     const vcParticipants = async (participant: any, status: any) => {
      const user = auth.getUser();
      const uid = get(user, "uid", "");
      let identity = participant.userIdentity.split("_");
      const participantdisplayName = identity[1];
      const currTime = new Date().getTime();
      // Update local state with new msg
      const new_msg: any = {
        message_id: uid + String(currTime),
        userDisplay: "Pacpost.io",
        description: `${participantdisplayName} has ${status} the room`,
        userid: uid,
        imageurl: pacpost_img,
        status: status,
        timetoken: currTime,
      };
      const msgs = [...messagesRef.current];
      msgs.push(new_msg);
      setMessages(msgs);
    };
     const callbackAdded = useCallback(
       (updatedParticipants?: ParticipantType[]) => {
         vcParticipants(get(updatedParticipants, "0", ""), "joined");
       },
       []
     );
     const callbackRemoved = useCallback(
       (updatedParticipants?: ParticipantType[]) => {
         vcParticipants(get(updatedParticipants, "0", ""), "left");
       },
       []
     );
     useEffect(() => {
      zmClient.on('user-added', callbackAdded);
      zmClient.on('user-removed', callbackRemoved);
     
      return () => {
        zmClient.off('user-added', callbackAdded);
        zmClient.off('user-removed', callbackRemoved);
       
      };
    }, [zmClient]);


  const messagesRef = useRef(messages);
  const setMessages = (data: any[]) => {
    messagesRef.current = data;
    _setMessages(data);
    if(clearChatFlag){
      setclearChatFlag(false)
    }
  };
  let photoURL: any;
  if (auth && auth.getUser() && auth.getUser().photoURL) {
    photoURL = auth.getUser().photoURL;
  }
  const [user_imageurl, setProfileImageUrl] = useState(photoURL);

  const participant_type = isGuestViewer ? 'member' : 'admin';
  const identity = `${participant_type}#${uuid}_${displayName}`;
  const channelNameRef = useRef('');
  const {room} = useRoom(!!channelNameRef.current,room_id,admin_user_id);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const channelsName = get(room, "room_code", "");
        channelNameRef.current = channelsName;
        joinPubNubChat(identity, channelsName);
      } catch (err) {
        console.error("Error while joining chat", err);
      }
    };
    if (!isEmpty(room)) {
      fetchData();
    }
  }, [room]);

  useEffect(() => {
    if(messages.length && clearChatFlag ) {
      setMessages([]) 
    }
  },[clearChatFlag]);

  const handleChatCopy = (value: any) => {
    const { userDisplay, description, timetoken } = value;
    const newTimeStamp = Math.ceil(parseInt(timetoken) / 10000);
    const formattedTimeStamp = convertDateTimeFromUTCEpoc(newTimeStamp);
    const copyElement = document.createElement("textarea");
    document.body.appendChild(copyElement);
    copyElement.value = `${userDisplay} - ${description} - ${formattedTimeStamp}`;
    copyElement.select();
    document.execCommand("copy");
    document.body.removeChild(copyElement);
  };
  const isEmpty = (obj: any) => {
    return Object.keys(obj).length === 0;
  };
  const leaveChat = () => {
    // Disconnect from chat channel
    if (pubnub && !isEmpty(pubnub)) {
      pubnub.unsubscribe({
        channels: [channels[0]],
      });

      const msgs = messagesRef.current;
      msgs.length = 0;
      setMessages(msgs);

      //Close chat window
      setChatWindow(false);
      setCloseDisable(false);
      setChatDisable(true);
    }
  };
  

  const clearChat = useCallback(async () => {
    setClearChatModalOpen(false);
    setChatClearLoading(true);

    const deleteUrl = `https://ps.pndsn.com/v3/history/sub-key/${process.env.REACT_APP_PN_SUBSCRIBE}/channel/${channels[0]}`;

    const deleteResponse = await axios.delete(deleteUrl);

    // Removing the local messages too so the local chat window will be clear.
    setMessages([]);
    const clearGroupChat = true;
    const commandChannel = zmClient.getCommandClient();
    commandChannel.send(JSON.stringify({clearGroupChat}));
    setChatClearLoading(false);
    
    const user = auth.getUser();
    const currTime = new Date().getTime();
    const uid = get(user, "uid", "");
    setTimeout(async () => {
        await pubnub.publish({
            channel: channels[0],
            message: {
                message_id: uid + currTime,
                userDisplay: displayName,
                description: `${displayName} has just cleared all chat.`,
                userid: uid, // Needed for old msgs as they don't have publisher id
                imageurl: user_imageurl,
                usecase: "clear"
            },
        });

        await pubnub.signal({
            channel: channels[0],
            message: `typing_off:${displayName}`,
        });
  }, 500);
}, [channels]);


  const handleUploadConfirm = async (event: any) => {
    setUploadAlert(false);
  };

  const getAllMessages = async (
    timetoken: string,
    pubnubObj?: any,
    channelsArray?: any
  ) => {
    const pubNubInstance = pubnubObj ? pubnubObj : pubnub;

    const channelsInstance = channelsArray ? channelsArray : channels;

    try {
      const historyResponse = await pubNubInstance.history({
        channel: channelsInstance[0],
        stringifiedTimeToken: true, // false is the default
        start: timetoken, // start time token to fetch
      });

      if (historyResponse) {
        let allMsgs = allMessages;
        allMsgs.push(...historyResponse.messages);
        setAllMessages(allMsgs);
        let start = historyResponse.startTimeToken;

        // if 100 msgs were retrieved, there might be more; call history again
        if (historyResponse.messages.length === 100) {
          await getAllMessages(start, pubNubInstance, channelsInstance);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const downloadMessages = async () => {
    setChatExportLoading(true);

    await getAllMessages("");

    const newLine = "\n";
    const newTab = "\t";

    const allMessagesList = allMessages.map((message: any) => {
      return {
        userDisplay: `${newLine}${message.entry.userDisplay}${newTab}`,
        description: message.entry.description,
        timetoken: `${newLine}${convertDateTimeFromUTCEpoc(
          Math.ceil(parseInt(message.timetoken) / 10000)
        )}`,
      };
    });

    const fields = ["userDisplay", "description", "timetoken"];

    const json2csvParser = new Parser({
      delimiter: "",
      fields: fields,
      quote: "",
    });

    const finalCSV = json2csvParser.parse(allMessagesList);

    const fileBlob = new Blob([finalCSV.replaceAll(",", " ")], {
      type: "application/octet-binary",
    });
    const hiddenElement = document.createElement("a");
    hiddenElement.href = URL.createObjectURL(fileBlob);
    // hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(finalCSV);
    hiddenElement.target = "_blank";

    //hiddenElement.download = `${streamLabel}.txt`;
    //Toto:Need to replace the file name with streamlabel
    hiddenElement.download = `chat_export.txt`

    hiddenElement.click();

    sendMessage(`${displayName} exported chat`,'export');
    setExportChartConfirmationOpen(false)
    setChatExportLoading(false);
  };

  // JOIN PUBNUB
  const joinPubNubChat = async (identity: any, roomName: string) => {
    let new_pubnub;
    new_pubnub = new PubNub({
      publishKey: process.env.REACT_APP_PN_PUBLISH || "",
      subscribeKey: process.env.REACT_APP_PN_SUBSCRIBE || "",
      uuid: identity,
      ssl: true,
    });

    if (!new_pubnub) {
      return;
    }
    setPubNub(new_pubnub);

    const mainChannelName = roomName;
    let new_channels = [mainChannelName];
    setChannels(new_channels);
    // Subscribe to channel
    new_pubnub.subscribe({ channels: new_channels, withPresence: true });

    // Fetch if you have any recent messages (Max 25 msg per channel)
    const recentmsg_responses: any = await new_pubnub.fetchMessages({
      channels: new_channels,
      count: 100,
    });

    if (recentmsg_responses && recentmsg_responses.channels) {
      const channel_msgs = recentmsg_responses.channels;

      if (channel_msgs && channel_msgs[mainChannelName]) {
        const recet_msgs: any[] = channel_msgs[mainChannelName] || [];

        const msgs = messagesRef.current;
        for (let msg of recet_msgs) {
          const old_msg: any = msg.message;

          // Recent msgs doesn't have publisher id, so need to pass userid in message
          old_msg.publisher = old_msg.userid;
          old_msg.timetoken = msg.timetoken;

          if (msg.channel === mainChannelName) {
            let outerIndex = -1;
            msgs.forEach((iMsg, index) => {
              if (
                msg.message.message_id &&
                iMsg.message_id &&
                iMsg.message_id === msg.message.message_id
              ) {
                outerIndex = index;
                return index;
              }
            });

            if (outerIndex < 0) {
              if (!old_msg.deleted) {
                msgs.push(old_msg);
              }
            } else {
              if (old_msg.deleted) {
                msgs.splice(outerIndex, 1);
              } else {
                msgs[outerIndex] = old_msg;
              }
            }
          }
        }

        setMessages([...msgs]);
      }
    }

    // Listen to messages
    new_pubnub.addListener({
      message: (messageEvent: any) => {
        const new_msg: any = messageEvent.message;
        new_msg.publisher = messageEvent.publisher;
        new_msg.timetoken = messageEvent.timetoken;

        if (messageEvent.channel === mainChannelName) {
          const msgs = messagesRef.current;
          let outerIndex = -1;
          msgs.forEach((iMsg, index) => {
            if (
              new_msg.message_id &&
              iMsg.message_id &&
              iMsg.message_id === new_msg.message_id
            ) {
              outerIndex = index;
              return index;
            }
          });

          if (outerIndex < 0) {
            if (!new_msg.deleted) {
              msgs.push(new_msg);
            }
          } else {
            if (new_msg.deleted) {
              msgs.splice(outerIndex, 1);
            } else {
              msgs[outerIndex] = new_msg;
            }
          }

          setMessages([...msgs]);
        }
      },
      signal: (signal: any) => {
        var message = signal.message; // The Payload
        var publisher = signal.publisher; //The Publisher
        const participant_type = isGuestViewer ? 'member' : 'admin';
        const identity = `${participant_type}#${uuid}_${displayName}`;
        if (publisher.indexOf(`${identity}`) === -1) {
          var signalName = message.split(":")[0];

          if (signalName === "typing_on") {
            var senderName = message.split(":")[1];
            if (senderName) {
              setTypingInd(senderName + " typing ...");
            }
          } else if (signalName === "typing_off") {
            setTypingInd("");
          }
        }
      },
      // @ts-ignore
      file: (event: any) => {
        const all_msgs = [...messagesRef.current];
        all_msgs.push(event);
        setMessages([...all_msgs]);
        setFileUploadLoading(false);
      },
    });
    return new_pubnub;
  };

  const handleUploadCancel = () => {
    setUploadAlert(false);
    setInput("");
    setUploadFiles([]);
  };


    // Group Chat
    const sendMessage = useCallback(
      async (message: any, action?:any) => {
        if (uploadFiles.length > 0) {
          setFileUploadLoading(true);
          //const user = auth.getUser();
          const uid = `#${uuid}_${displayName}`;
          const currTime = new Date().getTime();
          const result = await pubnub.sendFile({
            channel: channels[0],
            file: uploadFiles[0],
            message: {
              userDisplay: displayName,
              imageurl: user_imageurl,
              message_id: uid + currTime,
              description: "",
              userid: identity, // Needed for old msgs as they dont have publisher id
              publisher: identity,
            },
          });
        } else if (message.length > 0) {
          const currTime = new Date().getTime();
          const uid = `#${uuid}_${displayName}`;
          // const displayName = get(user, "displayName", "");
          await pubnub.publish({
            channel: channels[0],
            message: {
              message_id: uid + currTime,
              userDisplay: displayName,
              description: message,
              userid: identity, // Needed for old msgs as they dont have publisher id
              imageurl: user_imageurl,
              usecase: action,
              publisher: identity,
            },
          });
        }
        await pubnub.signal({
          channel: channels[0],
          message: `typing_off:${displayName}`,
        });
        setUploadFiles([]);
        setInput("");
        setShowEmoji(false);
      },
      [pubnub, channels, uploadFiles, user_imageurl]
    );
    
    const hideGiphyModal = function () {
      setModalStatus(false);
    };
    const resizeCanvas = () => {
      setTimeout(() => window.dispatchEvent(new Event("resize")), 25);
    };

  let chatProps = {
    ProgressLoader,
    messagesRef,
    user_imageurl,
    editPencil,
    handleChatCopy,
    sendMessage,
    hideGiphyModal,
    resizeCanvas,
    isGuestViewer,
    editorDirectLogin,
    identity
  };


  return (
    <div>
      <div className="sidebar-section">
        <div className="chat-wrapper">
            <ChatMenu {...chatProps} />
          </div>
      </div>

      <ClearChatModal
      clearChat={clearChat}
      />
       <ExportChatModal
        open={exportChartConfirmationOpen}
        onClose={() => setExportChartConfirmationOpen(false)}
        onDownload={downloadMessages}
      />
      <ConfirmationalPopup
        open={uploadAlert}
        data={`Are you sure you want to upload ${fileName}`}
        onCancel={handleUploadCancel}
        onOk={handleUploadConfirm}
      />
      </div>
  );
}

export default ChatMain;